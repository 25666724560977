import { GenerateRefreshToken } from "./RefreshTokenService";
import EncryptDecryptService from "./EncryptDecryptService";
import { M_IDENTITY_MANAGER } from "../constants/Constants";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */

type Headers = {
  "Content-Type": string;
  Authorization: any;
  //   Access-Control-Allow-Origin": string
};

async function getService(
  endpoint: string = "",
  method: string = "GET",
  microService = M_IDENTITY_MANAGER,
  loadingParam: boolean = false
) {
  const externalElement = document.getElementById(
    "overlay"
  ) as HTMLDivElement | null;
  if (externalElement && loadingParam) {
    externalElement.style.display = "flex";
  }

  // const refreshToken = localStorage.getItem("refresh_token");
  let token = await GenerateRefreshToken();

  const headers: Headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: token,
  };
  try {
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        method: "GET",
        data: {},
      }),
    });
    const data = await response.json();

    // return data;

    let decrypteddata = await JSON.parse(
      EncryptDecryptService.decryptData(data)
    );
    if (externalElement && loadingParam) {
      externalElement.style.display = "none";
    }
    return decrypteddata;
  } catch (error) {}
}

async function service(
  endpoint: string = "",
  method: string = "POST",
  dataObj: any = "",
  loadingParam: boolean = false,
  microService: string = M_IDENTITY_MANAGER, 
  entryptDecrypt: boolean = true
) {
  try {
    const externalElement = document.getElementById(
      "overlay"
    ) as HTMLDivElement | null;
    if (externalElement && loadingParam) {
      externalElement.style.display = "flex";
    }

    let token = await GenerateRefreshToken();
    const headers: Headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      Authorization: token,
    };
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;    
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrer: "no-referrer",
      redirect: "follow",
      body: JSON.stringify({
        method: "POST",
        data: entryptDecrypt ? EncryptDecryptService.encryptData(JSON.stringify(dataObj)) : dataObj,
      }),
      headers: headers,
    });
    const data = await response.json();
    let decrypteddata = entryptDecrypt ? await JSON?.parse(
      EncryptDecryptService?.decryptData(data)
    ) : data;
    if (externalElement && loadingParam) {
      externalElement.style.display = "none";
    }
    return decrypteddata;
  } catch (error) {
    console.log(error);
  }
}
export const ApiCall = {
  service,
  getService,
};
