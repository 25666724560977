import { useEffect, useState } from "react";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import RenderInputFields from "./RenderInputFields";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import "./defaultLowCoefficient.css";
import CustomNotify from "components/atoms/CustomNotify";
import {
  scrollToTop,
  validateForm,
  validateSelectField,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import { Option, OptionProps, ValidationRules } from "utils/TypeAnnotations";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_COMPANY_CREATION } from "../../../../../../constants/Constants";
import BackButton from "components/atoms/BackButton";

const LowCoefficientTableForm = () => {
  interface RangeProfileTypes {
    min: string | null;
    desired: string | null;
    max: string | null;
    minStatus: boolean;
    desiredStatus: boolean;
    maxStatus: boolean;
  }

  interface RegionCountry {
    region: Option | null;
    country: Option | null;
  }

  interface EmployeeTypeObj {
    categotyExist: boolean;
    category: string | null;
    employeeType: string;
    [key: number]: RangeProfileTypes;
  }

  interface EmployeeCategoryType {
    [key: number]: EmployeeTypeObj;
  }

  interface InitialState {
    [key: number]: EmployeeCategoryType;
  }

  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isEditMode = location.pathname.includes("edit");
  const navigate = useNavigate();
  const [data, setData] = useState<InitialState>({});
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [dropDowns, setDropDowns] = useState({
    regionDropdown: [] as OptionProps[],
    countryDropdown: [] as OptionProps[],
  });
  const [countryRegionData, setCountryRegionData] = useState<RegionCountry>({
    region: null,
    country: null,
  });
  const initialdataErrors: { [key: string]: string } = {};
  const [dataError, setDataError] = useState<{ [key: string]: string }>(
    initialdataErrors
  );

  useEffect(() => {
    const fetchNestedCofficientsObject = async () => {
      const url = id
        ? `${ENDPOINTS.GET_NESTED_LOW_COFFICIENTS}/${id}`
        : ENDPOINTS.GET_NESTED_LOW_COFFICIENTS;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_COMPANY_CREATION,
        true
      );
      if (response.status === 200) {
        const countryRegionDetails = response.details["countryRegion"];
        if (countryRegionDetails) {
          setCountryRegionData((prevData) => ({
            ...prevData,
            ...countryRegionDetails,
          }));
        }

        const regions = mapToSelect(response.details["region"]);
        const countries = response.details["country"];
        setDropDowns({
          regionDropdown: regions,
          countryDropdown: countries,
        });
        setData(response.data);
      }
    };
    fetchNestedCofficientsObject();
  }, []);
  const updateCofficientObj = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string,
    payType: number,
    key: string
  ) => {
    const value = event.target.value;
    const employeeType = parseInt(empType);
    const newData = { ...data };
    const newValue = value.replace(/[^0-9,]/g, "");
    const regex = /^(\d{1,2})(,\d{0,4})?$/;
    let matches = regex.exec(newValue);
    if (matches || value === "") {
      if (key === "min") {
        newData[category][employeeType][payType]["min"] = newValue;
      } else if (key === "desired") {
        newData[category][employeeType][payType]["desired"] = newValue;
      } else {
        newData[category][employeeType][payType]["max"] = newValue;
      }
    }
    const currentObject = newData[category][employeeType][payType];
    const { min, desired, max } = currentObject;
    if (
      min !== null &&
      min !== "" &&
      max !== null &&
      max !== "" &&
      parseFloat(min.replace(",", ".")) >= parseFloat(max.replace(",", "."))
    ) {
      newData[category][employeeType][payType]["minStatus"] = true;
    } else {
      newData[category][employeeType][payType]["minStatus"] = false;
    }
    if (
      desired !== null &&
      min !== null &&
      max !== null &&
      desired !== "" &&
      min !== "" &&
      max !== "" &&
      (parseFloat(desired.replace(",", ".")) <=
        parseFloat(min.replace(",", ".")) ||
        parseFloat(desired.replace(",", ".")) >=
          parseFloat(max.replace(",", ".")))
    ) {
      newData[category][employeeType][payType]["desiredStatus"] = true;
    } else {
      newData[category][employeeType][payType]["desiredStatus"] = false;
    }

    // Check if the current value is different from the initial state
    if (
      value !== newData[category][employeeType][payType].desired ||
      newData[category][employeeType][payType].max ||
      newData[category][employeeType][payType].min
    ) {
      setDirty(true);
    }
    setData(newData);
    setError(hasTrueValue(data));
  };

  const dropDownsValidation = (
    name: string,
    value: string | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...countryRegionData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(error).length > 0) {
      setDataError((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDataError(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const hasTrueValue = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (hasTrueValue(obj[key])) {
          return true;
        }
      } else if (
        key === "minStatus" ||
        key === "desiredStatus" ||
        key === "maxStatus"
      ) {
        if (obj[key] === true) {
          return true;
        }
      }
    }
    return false;
  };

  //fetching countries list based on region
  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const regionBasedCountries = dropDowns.countryDropdown.filter(
        (country) =>
          country.region_id !== undefined &&
          selectedRegion === country.region_id
      );
      return mapToSelect(regionBasedCountries);
    }
    return [];
  };
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setCountryRegionData((prevData) => ({
        ...prevData,
        country: null,
      }));
    }
    setCountryRegionData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));

    dropDownsValidation(fieldName, selectedOption, true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    if (dropDownsValidation(name, value)) {
      if (!hasTrueValue(data) && dirty) {
        const payload = {
          countryRegionDetails: countryRegionData,
          coefficientValues: data,
        };

        const url = id
          ? `${ENDPOINTS.UPDATE_LOW_COEFFICIENTS}/${id}`
          : ENDPOINTS.UPDATE_LOW_COEFFICIENTS;
        const response = await ApiCall.service(
          url,
          "POST",
          payload,
          false,
          M_COMPANY_CREATION
        );
        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: response.msg,
            autoClose: 2000,
          });
          navigate("/manage/default/low-coefficients");
        } else if (response.status === 400) {
          setDataError({ country: response.msg });
          CustomNotify({
            type: "error",
            message: response.msg,
            autoClose: 2000,
          });
        }
      } else {
        scrollToTop();
        let msg = !dirty
          ? "Please update the field!!"
          : "Please correct the errors as per error message shown";
        CustomNotify({ type: "error", message: msg, autoClose: 2000 });
      }
    }
  };

  return (
    <>
      <div className="">
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">
              {isEditMode
                ? "Edit low default coefficients"
                : "Add low default coefficients"}
            </h1>
          </div>
        </div>

        <div className="row my-3">
          <div className="col">
            {error && (
              <div className="text-danger error-coefficients">
                <span>
                  Please change the highlighted values, minimum value should be
                  less than nice to have value.
                </span>
                <br />
                <span>
                  The desired value should be in between minimum and nice to
                  have values.
                </span>
              </div>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-height">
            <div className="row">
              <div className="col-md-6">
                <SelectWithSearch
                  title="Region"
                  search={true}
                  isMandatory={true}
                  options={dropDowns.regionDropdown}
                  isDisabled={isEditMode}
                  placeHolder="Select region"
                  onChange={(e) => handleSelectChange(e, "region")}
                  isMulti={false}
                  className="select-field"
                  name="region"
                  value={countryRegionData.region}
                  error={dataError.region}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  title="Country"
                  search={true}
                  isMandatory={true}
                  isDisabled={isEditMode}
                  options={getCountriesForRegion()}
                  placeHolder="Select country"
                  onChange={(e) => handleSelectChange(e, "country")}
                  isMulti={false}
                  className="select-field"
                  name="country"
                  value={countryRegionData.country}
                  error={dataError.country}
                />
              </div>
            </div>
            <table className="table table-bordered default-coefficient-table">
              <thead>
                <tr className="TableHeader">
                  <th rowSpan={2} className="text-center">
                    Employee category types
                  </th>
                  <th
                    rowSpan={2}
                    className="text-center align-middle"
                    scope="rowgroup"
                  >
                    Employee type
                  </th>
                  <th colSpan={3} className="text-center" scope="colgroup">
                    Payroll
                  </th>
                  <th colSpan={3} className="text-center" scope="colgroup">
                    Selectie
                  </th>
                </tr>
                <tr className="TableHeader">
                  <th className="text-center">
                    <strong>Min</strong>
                  </th>
                  <th className="text-center">
                    <strong>Desired</strong>
                  </th>
                  <th className="text-center">
                    <strong>Nice to have</strong>
                  </th>
                  <th className="text-center">
                    <strong>Min</strong>
                  </th>
                  <th className="text-center">
                    <strong>Desired</strong>
                  </th>
                  <th className="text-center">
                    <strong>Nice to have</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  Object.keys(data).map(
                    (key: any) =>
                      data[key] &&
                      Object.entries(data[key]).map(([key1, value]) => (
                        <tr key={key1} className="align-middle ">
                          {value["categotyExist"] && (
                            <th
                              className="text-center"
                              rowSpan={Object.keys(data[key]).length}
                              scope="rowgroup"
                            >
                              {value["category"]}
                            </th>
                          )}
                          <th scope="row">{value["employeeType"]}</th>
                          <RenderInputFields
                            inputObj={value[0]}
                            employeeCategoryId={key}
                            employeeType={key1}
                            payType={0}
                            updateCofficientObj={updateCofficientObj}
                          />
                          <RenderInputFields
                            inputObj={value[1]}
                            employeeCategoryId={key}
                            employeeType={key1}
                            payType={1}
                            updateCofficientObj={updateCofficientObj}
                          />
                        </tr>
                      ))
                  )}
              </tbody>
            </table>
          </div>
          <div className="row mb-3">
            <div className="col-md-4 align-self-center">
              <BackButton />
            </div>
            <div className="col-md-8">
              <Button
                title={isEditMode ? "Save" : "Add"}
                type="submit"
                className="btn form-button float-end text-uppercase rounded-0 shadow-none"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LowCoefficientTableForm;
