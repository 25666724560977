import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessDenied from "pages/AccessDenied/AccessDenied";
import CommonServices from "services/CommonService";


interface Props {
   requiredPermissions?: any;
   children?: ReactNode;
   renderNoAccess?: boolean;
   strict?: boolean;
   actions?: boolean;
   override?: boolean;
};


const AccessControl: React.FC<Props> = ({
   requiredPermissions = [],
   children,
   renderNoAccess = false,
   strict = true,
   actions,
   override = false,
}) => {
   const userAuth = useSelector(selectAuth);
   const userPermissions = userAuth.userPermissions;
   const [permitted, setPermitted] = useState<boolean>(false);
   const [noAccess, setNoAccess] = useState<boolean>(false);


   useEffect(() => {
       const checkUserPermissions = async () => {
           const isPermitted = await CommonServices.checkPermissions(
               userPermissions,
               requiredPermissions,
               strict,
               actions,
               override
           );
           if (isPermitted) {
               setPermitted(true);
               setNoAccess(false);
           } else {
               setPermitted(false);
               setNoAccess(true);
           }
       };
       userPermissions != undefined && checkUserPermissions();
   }, [userPermissions, requiredPermissions]);


   if (noAccess && renderNoAccess) {
       return <AccessDenied />;
   }
   if (permitted) {
       return <>{children}</>;
   }
   return <></>
};


export default AccessControl;
