import React from "react";
import TitleAtom from "../atoms/Title";

interface TitleFieldMoleculeProps {
  title?: string;
  classname?: string;
}

const TitleFieldMolecule: React.FC<TitleFieldMoleculeProps> = ({
  title,
  classname,
}) => {
  return (
    <div className={`row header-sticky position-sticky ${classname}`}>
      <TitleAtom title={title} />
    </div>
  );
};
export default TitleFieldMolecule;
