import { useState } from "react";
import { useFormContext } from "../../context/Context";
import { REQUIREMENT, UPDATE_FIELDS } from "../../annotation/VacancyConstants";
import { scrollToTop } from "services/validation/ValidationService";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import Navigation from "../form-navigation/Navigation";

const VacancyRequirementDetails = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = event.target;

    if (name === "equiWorkExperience") {
      const checked = (event.target as HTMLInputElement).checked;
      const newValue = checked ? 1 : 0;
      dispatch({ type: UPDATE_FIELDS, template: REQUIREMENT, field: name, value: newValue });
    } else {
      dispatch({ type: UPDATE_FIELDS, template: REQUIREMENT, field: name, value: value });
    }
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_FIELDS, template: REQUIREMENT, field: name,
      value: Array.isArray(selectedOption) ? selectedOption?.map((item: any) => item?.value) : selectedOption?.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({ type: UPDATE_FIELDS, template: REQUIREMENT, field: name, value: content });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="vacancyRequirementWrapper">
          <div className="position-relative">
            <div className="form-border" style={{ paddingTop: "3vw" }}>
              <div className="form pb-4 pt-2">
                <div className="row ">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <SelectWithSearch
                      title={t("Years of experience")}
                      name="experience"
                      placeHolder={t("Select")}
                      search={true}
                      options={state.options.Experience}
                      value={state.requirement.experience}
                      onChange={(e) => handleSelectChange(e, "experience")}
                      isMulti={false}
                      className="select-field"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 work-experience-checkbox addBtnMargin" style={{ paddingTop: "0.8vw" }}>
                    <CheckBoxField
                      label={t("Equivalent by work experience")}
                      name="equiWorkExperience"
                      onChangeHandler={handleFieldChange}
                      isChecked={state.requirement.equiWorkExperience === 1}
                      id="equiWorkExperience"
                      lineHeight="1.5vw"
                    />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <SelectWithSearch
                          title={t("Driver licences")}
                          name="driverLicence"
                          placeHolder={t("Select")}
                          search={true}
                          options={state.options.DrivingLicense}
                          value={state.requirement.driverLicence}
                          onChange={(e) => handleSelectChange(e, "driverLicence")}
                          isMulti={true}
                          className="select-field"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <SelectWithSearch
                          title={t("Studies")}
                          name="education"
                          placeHolder={t("Select")}
                          search={true}
                          options={state.options.EducationLevels}
                          value={state.requirement.education}
                          onChange={(e) => handleSelectChange(e, "education")}
                          isMulti={true}
                          className="select-field"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <SelectWithSearch
                          title={t("Additional certificates")}
                          name="additional"
                          placeHolder={t("Select")}
                          search={true}
                          options={state.options.Additional}
                          value={state.requirement.additional}
                          onChange={(e) => handleSelectChange(e, "additional")}
                          isMulti={true}
                          className="select-field"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <LabelWithInputField
                          isMandatory={false}
                          name="other"
                          handleChange={handleFieldChange}
                          value={state.requirement.other}
                          id="other"
                          label={t("Other")}
                          type="text"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <SelectWithSearch
                          title={t("Tags")}
                          name="tags"
                          placeHolder={t("Select")}
                          search={true}
                          options={state.options.Tag}
                          value={state.requirement.tags}
                          onChange={(e) => handleSelectChange(e, "tags")}
                          isMulti={true}
                          className="select-field"
                        />
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-md-12">
                        <LabelWithCKEditorField
                          label={t("Profile description")}
                          name="profileDescription"
                          value={state.requirement.profileDescription}
                          placeholder={t("Type here") + "..."}
                          handleChange={(event, editor) => handleCKEditorChange(event, editor, "profileDescription")}
                        // className="field-shadow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default VacancyRequirementDetails;