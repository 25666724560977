import React, { useState, useEffect } from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { ApiCall } from "services/ApiServices";
import { Option, OptionProps, ValidationRules } from "utils/TypeAnnotations";
import { mapToSelect } from "utils/MapToSelect";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import LabelField from "components/atoms/LabelField";
import {
  validateForm,
  validateSelectField,
} from "services/validation/ValidationService";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from '../../../../../constants/Constants';
import BackButton from "components/atoms/BackButton";


// Define the interface for CityProps
export interface CityProps {
  region: Option | null;
  country: Option | null;
  lowPercentage: string;
  mediumPercentage: string;
  highPercentage: string;
  lowCity: OptionProps[];
  mediumCity: OptionProps[];
  highCity: OptionProps[];
}

const AddCityProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isViewMode = location.pathname.includes("view");
  const isEditMode = location.pathname.includes("edit");
  const navigate = useNavigate();

  // State for form data and city profile details
  const [formData, setFormData] = useState<CityProps>({
    region: null,
    country: null,
    lowCity: [],
    mediumCity: [],
    highCity: [],
    lowPercentage: "",
    mediumPercentage: "",
    highPercentage: "",
  });
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [cityProfileDetails, setCityProfileDetails] = useState({
    region: [] as OptionProps[],
    country: [] as OptionProps[],
    city: [] as OptionProps[],
  });
  const [cityError, setCityError] = useState("");
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  useEffect(() => {
    const fetchCityProfileDetails = async () => {
      const url = id
        ? `${ENDPOINTS.GET_CITY_DETAILS}/${id}`
        : `${ENDPOINTS.GET_CITY_DETAILS}`;

      const response = await ApiCall.getService(url, "GET", M_COMPANY_CREATION);

      if (response.status === 200) {
        const countryRegionDetails = response.data["countryRegionDetails"];
        setFormData((prevData) => ({
          ...prevData,
          ...countryRegionDetails,
        }));

        // Extract and set linked cities from the response
        const linkedCities = response.data["linkedCities"];
        if (linkedCities) {
          setFormData((prevData) => ({
            ...prevData,
            ...countryRegionDetails,
            ...linkedCities,
          }));
        }

        const regions = mapToSelect(response.data["region"]);
        const countries = response.data["countries"];
        const cities = response.data["cities"];

        setCityProfileDetails((prevData) => ({
          city: cities,
          region: regions,
          country: countries,
        }));
      }
    };

    fetchCityProfileDetails();
  }, [id]);

  const validation = (
    //validation function related field validations
    name: string,
    value: string | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const dropDown = cityProfileDetails.country.filter(
        (func) =>
          func.region_id !== undefined && selectedRegion === func.region_id
      );

      return mapToSelect(dropDown);
    }

    return [];
  };

  // Handle changes in input fields
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const regex = /^(\d{0,2})(,\d{0,4})?$/;
    let matches = regex.exec(value);
    if (matches) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleCountryChange = (selectedOption: any) => {
    const countryId = selectedOption.value;
    if (countryId) {
      const fetchCityDetailsForCountry = async () => {
        const response = await ApiCall.getService(
          `${ENDPOINTS.GET_CITY_DETAILS}/${countryId}`,
          "GET",
          M_COMPANY_CREATION
        );
        const cities = response.data["cities"];
        if (response.status === 200) {
          setCityProfileDetails((prevData) => ({
            ...prevData,
            city: cities,
          }));
        }
      };
      fetchCityDetailsForCountry();
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setFormData((prevData) => ({
        ...prevData,
        country: null,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "country") {
      setFormData((prevData) => ({
        ...prevData,
        lowCity: [],
        mediumCity: [],
        highCity: [],
        lowPercentage: "",
        mediumPercentage: "",
        highPercentage: "",
      }));
      handleCountryChange(selectedOption);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const filterCity = () => {
    const lowCities = formData.lowCity.map((city) => city.value);
    const mediumCities = formData.mediumCity.map((city) => city.value);
    const highCities = formData.highCity.map((city) => city.value);

    if (
      lowCities.length > 0 ||
      mediumCities.length > 0 ||
      highCities.length > 0
    ) {
      const selectedCities = [...lowCities, ...mediumCities, ...highCities];

      const filteredCities = cityProfileDetails.city.filter(
        (func) => !selectedCities.includes(func.id as number)
      );
      return mapToSelect(filteredCities);
    }
    return mapToSelect(cityProfileDetails.city);
  };

  const validateCitySelection = () => {
    if (
      formData.lowCity.length === 0 &&
      formData.mediumCity.length === 0 &&
      formData.highCity.length === 0
    ) {
      setCityError("At least one city profile should be filled.");
      // return false;
      return [false, "At least one city profile should be filled."];
    } else {
      setCityError("");
      // return true;
      return [true, ""];
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const isCitySelected = validateCitySelection();

    if (validation(name, value) && isCitySelected[0]) {
      const url = id
        ? `${ENDPOINTS.UPDATE_CITY}/${id}`
        : `${ENDPOINTS.STORE_CITY}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        navigate("/manage-city-profile");
      } else if (response.status === 400) {
        setErrors({
          country: response.msg,
        });
        CustomNotify({ type: "error", message: response.msg });
      }
    } else {
      CustomNotify({ type: "error", message: isCitySelected[1] });
    }
  };

  return (
    <>
      <div className="row header-sticky position-sticky">
        <div className="col-md-12">
          <h1 className="py-4 page-title mb-0">
            {isEditMode
              ? "Edit city profile"
              : isViewMode
              ? "View city profile"
              : "Add city profile"}
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-height">
            <div className="row">
              <div className="col-md-6">
                <SelectWithSearch
                  title="Region"
                  search={true}
                  options={cityProfileDetails.region}
                  isDisabled={isEditMode || isViewMode}
                  placeHolder="Select region"
                  onChange={(e) => handleSelectChange(e, "region")}
                  isMulti={false}
                  className="dummy-class"
                  name="region"
                  value={formData.region}
                  error={errors.region}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  title="Country"
                  search={true}
                  options={getCountriesForRegion()}
                  placeHolder="Select country"
                  isDisabled={isEditMode || isViewMode}
                  onChange={(e) => handleSelectChange(e, "country")}
                  isMulti={false}
                  className="dummy-class"
                  name="country"
                  value={formData.country}
                  error={errors.country}
                />
              </div>
            </div>
            <div className="city-profile-table">
              <table className="table">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-3">Profile</th>
                    <th>Cities</th>
                    <th>Factor</th>
                  </tr>
                </thead>
                <tbody className="">
                  {/* Low City */}
                  <tr className="border align-middle">
                    <td className="ps-4 city-label">
                      <LabelField
                        title="Low"
                        className="col-md-1 align-self-center"
                      />
                    </td>
                    <td>
                      <SelectWithSearch
                        search={true}
                        options={filterCity()}
                        placeHolder="Select city"
                        isDisabled={isViewMode}
                        onChange={(e) => handleSelectChange(e, "lowCity")}
                        isMulti={true}
                        className="select-field"
                        name="lowCity"
                        value={formData.lowCity}
                      />
                    </td>
                    <td>
                      <LabelWithInputField
                        isMandatory={false}
                        name="lowPercentage"
                        handleChange={changeHandler}
                        value={formData.lowPercentage}
                        readOnly={isViewMode}
                        id="lowPercentage"
                        type="text"
                      />
                    </td>
                  </tr>
                  {/* Medium City */}
                  <tr className="border align-middle">
                    <td className="ps-4 city-label">
                      <LabelField
                        title="Medium"
                        className="col-md-1 align-self-center"
                      />
                    </td>
                    <td>
                      <SelectWithSearch
                        search={true}
                        options={filterCity()}
                        placeHolder="Select city"
                        isDisabled={isViewMode}
                        onChange={(e) => handleSelectChange(e, "mediumCity")}
                        isMulti={true}
                        className="selectfield"
                        name="mediumCity"
                        value={formData.mediumCity}
                      />
                    </td>
                    <td>
                      <LabelWithInputField
                        isMandatory={false}
                        name="mediumPercentage"
                        handleChange={changeHandler}
                        value={formData.mediumPercentage}
                        readOnly={isViewMode}
                        id="mediumPercentage"
                        type="text"
                      />
                    </td>
                  </tr>
                  {/* High City */}
                  <tr className="border align-middle">
                    <td className="ps-4 city-label">
                      <LabelField
                        title="High"
                        className="col-md-1 align-self-center"
                      />
                    </td>
                    <td>
                      <SelectWithSearch
                        search={true}
                        options={filterCity()}
                        placeHolder="Select city"
                        isDisabled={isViewMode}
                        onChange={(e) => handleSelectChange(e, "highCity")}
                        isMulti={true}
                        className="select-field"
                        name="highCity"
                        value={formData.highCity}
                      />
                    </td>
                    <td>
                      <LabelWithInputField
                        isMandatory={false}
                        name="highPercentage"
                        handleChange={changeHandler}
                        value={formData.highPercentage}
                        readOnly={isViewMode}
                        id="highPercentage"
                        type="text"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {cityError && <p className="text-danger">{cityError}</p>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-6 align-self-center">
              <BackButton />
            </div>
            <div className="col-md-6 text-lg-end">
              {!isViewMode && (
                <Button
                  title={isEditMode ? "Save city" : "Add city"}
                  type="submit"
                  className="btn form-button rounded-0 shadow-none text-uppercase button-width"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCityProfile;
