import { FormMode } from "components/common/CommonEnums";

export const SWITCH_TAB = "SWITCH_TAB";
export const SET_OPTIONS = "SET_OPTION";
// Contact tab
export const ADD_CONTACT = "ADD_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const UPDATE_SELECTED_CONTACT_FIELD = "UPDATE_SELECTED_CONTACT_FIELD";
export const UPDATE_CONTACT_SUBFORM_FIELD = "UPDATE_CONTACT_SUBFORM_FIELD";
export const UPDATE_SELECTED_CONTACT_TAG_EDIT =
  "UPDATE_SELECTED_CONTACT_TAG_EDIT";

export const UPDATE_CURRENT_TAB = "UPDATE_CURRENT_TAB";

// General tab
export const SET_FIELD_GENERAL_TAB = "SET_FIELD_GENERAL_TAB";
export const SET_FIELD_ERROR_GENERAL_TAB = "SET_FIELD_ERROR_GENERAL_TAB";
export const SET_CREATE_LOCATION_GENERAL_TAB =
  "SET_CREATE_LOCATION_GENERAL_TAB";
export const SET_ERROR_SUBFIELD_GENERAL_TAB = "SET_ERROR_SUBFIELD_GENERAL_TAB";

//address tab
export const SET_FIELD_ERROR_ADDRESS_TAB = "SET_FIELD_ERROR_ADDRESS_TAB";
export const SET_ERRORS_ADDRESS_TAB = "SET_ERRORS_ADDRESS_TAB";
//location tab
export const SET_ERRORS_LOCATION_TAB = "SET_ERRORS_LOCATION_TAB";

// Tab validations
export const SET_ERRORS_GENERAL_TAB = "SET_ERRORS_GENERAL_TAB";
export const UPDATE_ERRORS_CONTACTPERSONS_TAB =
  "UPDATE_ERRORS_CONTACTPERSONS_TAB";

export const UPDATE_FIELD_ERROR = "UPDATE_FIELD_ERROR";

// while editing prefilling data
export const UPDATE_TAB_DATA = "UPDATE_TAB_DATA";

export const INITIAL_STATE = "INITIAL_STATE";

export const SET_FIELD_INVOICE_TAB = "SET_FIELD_INVOICE_TAB";
export const SET_COMPOSITION_DATA = "SET_COMPOSITION_DATA";

export const UPDATE_SPECIAL_AGREEMENT_FIELD = "UPDATE_SPECIAL_AGREEMENT_FIELD";
export const SET_BLUE_COLLAR = "SET_BLUE_COLLAR";
export const SET_WHITE_COLLAR = "SET_WHITE_COLLAR";
export const UPDATE_WHITE_COLLOR_FIELD_ERROR =
  "UPDATE_WHITE_COLLAR_FIELD_ERROR";
export const ADD_WHITECOLLAR_FIELD = "ADD_WHILECOLLAR_FILED";
export const SET_PC_TABLE_DATA = "SET_PC_TABLE_DATA";
export const ADD_BLUE_COLLAR_FIELD = "ADD_BLUE_COLLAR_FILED";
export const REMOVE_WHITE_PC = "REMOVE_WHITE_PC";
export const REMOVE_BLUE_PC = "REMOVE_BLUE_PC";
export const SET_COEFFICIENT_DATA="SET_COEFFICIENT_DATA";
export const ADD_EMPLOYEE_TYPE="ADD_EMPLOYEE_TYPE";
export const DELETE_EMPLOYEE_TYPE="DELETE_EMPLOYEE_TYPE";
export const ADD_FUNCTION_PROFILE="ADD_FUNCTION_PROFILE";
export const DELETE_FUNCTION_TYPE="DELETE_FUNCTION_TYPE";
export const CLONE_FUNCTION_TYPE="CLONE_FUNCTION_TYPE";
export const UPDATE_COEFFCIENT_TAB_FIELD="UPDATE_BILLING_TAB_FIELD"
export const UPDATE_EMPLOYEE_TYPE_PC="UPDATE_EMPLOYEE_TYPE_PC";
export const UPDATE_COEFFICIENT_TAB="UPDATE_COEFFICIENT_TAB";
export const UPDATE_CONTACT_FIELD_ERROR="UPDATE_CONTACT_FIELD_ERROR";
export const SET_CONTACT_PERSON_OPTION="SET_CONTACT_PERSON_OPTION";

//SHIFTS TABLE
export const WEEK_DAYS = [
  // "Default",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const DISABLED_DAYS = ["Unknown"];
export const SHIFT_FIELDS = {
  from: "From",
  till: "Till",
  pause: "Pause",
  total: "Total",
};

export const INITIAL_STATE_OBJECT = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "general",
      title: "General",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "contactsPersons",
      title: "Contacts Persons",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "address",
      title: "Address",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "location",
      title: "Location",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "employeeTypeAndCoeffcient",
      title: "Employee & Coeffcient",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "coefficient",
      title: "Coeffcient",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "specialAgreement",
      title: "Special Agreement",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "invoice",
      title: "Invoice",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  general: {
    data: {
      id: "",
      vat: "",
      code: "",
      businessUnitNumber: "",
      name: "",
      type: "",
      company: "",
      info: "",
      isActive: true,
      retentionCount:"",
      retentionPeriod:"",
    },
    errors: {
      vat: "",
      code: "",
      businessUnitNumber: "",
      name: "",
      type: "",
      company: "",
    },
  },
  address: {
    data: {
      street: "",
      number: "",
      box: "",
      zipCode: "",
      country: null,
      city: "",
      biStreet: "",
      biNumber: "",
      biBox: "",
      biZipCode: "",
      biCountry: null,
      biCity: "",
      hqAddress: 0,
      sameAddress: 1,
    },
    errors: {
      street: "",
      number: "",
      zipCode: "",
      country: "",
      city: "",
      biStreet: "",
      biNumber: "",
      biZipCode: "",
      biCountry: "",
      biCity: "",
      hqAddress: "",
      sameAddress: "",
    },
  },
  location: {
    data: [
      {
        id: "",
        locationName: "",
        locationEmail: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCity: "",
        locationCountry: null,
      },
    ],
    errors: [
      {
        id: "",
        locationName: "",
        locationEmail: "",
        locationStreet: "",
        locationNumber: "",
        locationBox: "",
        locationZipCode: "",
        locationCity: "",
        locationCountry: "",
      },
    ],
  },

  contactsPersons: {
    data: {
      selectedContactPersons: [],
      selectedContactsTagList: [],

      // subform data
      contactSubformIsVisible: false,
      contactsPersons: [],
    },
    errors: {
      selectedContactPersons: "",
      selectedContactsTagList: [],

      // subform errors
      contactsPersons: [],
    },
  },
  inVoice: {
    data: {
      paymentTerm: null,
      amount: "",
      inVoiceNumber: "",
      inVoiceDate: "",
      inVoiceDocumnet: "",
      inVoiceStatus: null,
    },
  },
  wageElement: [],
  employeeCoefficient: {
    whiteCollar: [],
    blueCollar: [],
    errors: {
      bluePc: "",
      blueEmployeeType: "",
      whitePc: "",
      whiteEmployeeType: "",
    },
  },
  companyParitairCommittee: [],
  coefficient:[],

  currentTab: "general",

  options: {
    businessUnit: [],
    tempAgencyTypes: [],
    company: [],
    locations: [],
    companyUsers: [],
    companyParitairCommittee: [],
    gender: [],
    language: [],
    roles: [],
    functions: [],
    vatRate: [],
    billingRate: [],
    paymentTerm: [],
    InvoiceStatus: [],
    coefficient: [],
    countries: [],
    paritairCommittee: [],
    blueCollar: [],
    whiteCollar: [],
    whiteEmployeeType: [],
    blueEmployeeType: [],
    retentionPeriod:[],
    coefficientLevel:[],
  },
};
