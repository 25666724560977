import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { TodoHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { DELETE_TODO, USER_LIST } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import {
  PATH_MASTER_DATA,
  PATH_TODO_CREATE,
  PATH_TODO_VIEW,
} from "constants/Paths";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { Todo } from "./Manage";
import { TodoObject } from "./Manage";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

interface TodoPageProps {
  todoObject: TodoObject | undefined;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

// interface UserItem {
//   value: number;
//   label: string;
//   name: string;
// }

const ManageTodoPage: React.FC<TodoPageProps> = ({
  todoObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  // const [userList, setUserList] = useState<UserItem[] | null>(null);

  // useEffect(() => {
  // const fetchData = async () => {
  //   try {
  //     const response = await ApiCall.getService(
  //       USER_LIST,
  //       "get",
  //       M_MASTER_DATA
  //     );
  //     if (response?.status === 200) {
  //       setUserList(response?.data);
  //     } else {
  //       console.error(ERROR);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // fetchData();
  // }, []);

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_TODO}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response?.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleDelete = (Todo: Todo | any) => {
    setShowModal(true);
    setDeleteId(Todo.id);
  };

  const handleEdit = (Data: Todo | any) => {
    if (Data.id) {
      navigate(`${PATH_TODO_CREATE}/${Data.id}`);
    }
  };

  const handleView = (Data: Todo | any) => {
    if (Data.id) {
      navigate(`${PATH_TODO_VIEW}/${Data.id}`);
    }
  };

  const handleChange = (entity: any, fieldName: string) => {
    searchValue(fieldName, entity.value);
  };
  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Todo",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage todo followup")} />
        <div className="row">
          <div className="col-2" style={{ marginBottom: "1vw" }}>
            <LabelWithInputField
              className="form-control"
              type="date"
              value={searchData.start_date}
              handleChange={handleSearchChange}
              name="start_date"
              placeholder={t("Start date")}
            />
          </div>
          <div className="col-2">
            <LabelWithInputField
              className="form-control"
              type="date"
              handleChange={handleSearchChange}
              placeholder={t("End date")}
              name="end_date"
              value={searchData.end_date}
            />
          </div>
          <div className="col-2">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Search type")}
              name="entity_type"
              value={searchData?.entity_type}
            />
          </div>
          <div className="col-2">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Search todo type")}
              name="todo_type"
              value={searchData.todo_type}
            />
          </div>
          <div className="col-2">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Search info")}
              name="info"
              value={searchData.info}
            />
          </div>
          <div className="col-2">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Find business unit")}
              name="business_unit"
              value={searchData.business_unit}
            />
          </div>
          <div className="col-2">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("User search")}
              name="name"
              value={searchData.name}
            />
          </div>
          <div className="col-2">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Todo status")}
              name="todo_status"
              value={searchData?.todo_status}
            />
          </div>
          <div className="col-3 ms-auto">
            <div className="d-flex align-items-center justify-content-end">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <AccessControl
          requiredPermissions={[
            {
              permission: "Todo",
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink={PATH_TODO_CREATE}
                title={t("Create follow up todo")}
                icon={faPlus}
              />
            </div>
          </div>
        </AccessControl>

        <div className="table-responsive tableSection">
          <div className="tableWithSearchBar">
            <DynamicTableStructure
              isAction
              headers={TodoHeader}
              data={todoObject ? todoObject?.todoData : []}
              permission={"Todo"}
              handleEdit={handleEdit}
            />
          </div>

          <div
            className="pagination justify-content-center align-items-center"
            style={{ paddingTop: "0.25vw" }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "1vw 0" }}>
        <BackButton />
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageTodoPage;
