// import LabelField from "components/common/atoms/LabelField";
// import SelectWithSearch from "components/common/atoms/SelectWithSearch";
// import React from "react";
// import PCTable from "./PCTable";
// import { Option } from "components/common/utlis/TypeAnnotations";
// import Button from "components/common/atoms/Button";
// import {
//   t,
//   translate,
// } from "components/CentralDataMangement/translation/Translation";
import Button from "components/atoms/Button";
import LabelField from "components/atoms/LabelField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import { Option } from "components/common/CommonInterfaces";
import PCTable from "./PCTable";
interface ParitairCommittee {
  pc: {
    id: number;
    label: string;
    value: Option | null;
    categoryId?: number; // Optional property for handling variations
  };
  employeeTypes: {
    id: number;
    label: string;
    value: Option | null; // Ensuring the property in the interface
    category?: number; // Optional property for handling variations
  }[];
}

interface WhiteCollarSectionProps {
  optionsList: any[];
  selectedValue: Option | null;
  selectedEmpType: Option | null;
  handlePCFields: (e: React.ChangeEvent, name: string) => void;
  state: any; // Add the state type
  addParitiarComitee: (type: string) => void;
  pcChangeHandler: (id: number, type: string) => void;
  pcDeleteHandler: (id: number, type: string) => void;
  employeeTypes: any[];
  isWhiteCollar: boolean;
  isBlueCollar: boolean;
}

const WhiteCollarSection: React.FC<WhiteCollarSectionProps> = ({
  optionsList,
  selectedValue,
  selectedEmpType,
  handlePCFields,
  state,
  addParitiarComitee,
  pcChangeHandler,
  pcDeleteHandler,
  employeeTypes = [],
  isWhiteCollar,
  isBlueCollar,
}) => {
  const companyParitairCommittee: ParitairCommittee[] =
    state.companyParitairCommittee;

  const whiteCollarParriter: ParitairCommittee[] = [];
  const blueCollarParriter: ParitairCommittee[] = [];
  const whiteCollarPc = state.options.whiteCollar;
  const whiteEmployeeType = state.options.whiteEmployeeType;
  const whitePcError = state.employeeCoefficient.errors.whitePc;
  const whiteEmployeeTypeError =
    state.employeeCoefficient.errors.whiteEmployeeType;

  // Check if companyParitairCommittee is not empty
  if (companyParitairCommittee.length > 0) {
    companyParitairCommittee.forEach((item) => {
      const categoryId = item.pc.categoryId ?? item.pc.categoryId;
      if (categoryId === 1) {
        whiteCollarParriter.push(item);
      } else if (categoryId === 2) {
        blueCollarParriter.push(item);
      }
    });
  }

  return (
    <div
      className={`${
        isWhiteCollar && isBlueCollar
          ? "col-lg-6 col-12 border-end"
          : "col-xxl-12 col-12 mx-auto"
      }`}
    >
      <div className="">
        <LabelField
          title={t("Paritair committee for white collar")}
          className="tab-subtitle pb-1"
        />
        <PCTable
          data={state.employeeCoefficient.whiteCollar}
          pcChangeHandler={pcChangeHandler}
          type="whiteCollar"
          pcDeleteHandler={pcDeleteHandler}
        />
        {/* {true && (
          <div className="text-danger">{state.fieldError.paritairWhite}</div>
        )} */}
        <div className="row">
          <div className={`col-6`}>
            <SelectWithSearch
              onChange={(e) => {
                handlePCFields(e, "pc");
              }}
              title={t("Paritair committee (PC) 1")}
              placeHolder={t("Select")}
              search={true}
              options={optionsList}
              value={selectedValue}
              isDisabled={false}
              isMulti={false}
              isMandatory={true}
              error={whitePcError}
              name={"pc"}
              id={"pc"}
            ></SelectWithSearch>
          </div>
          <div className={`col-6`}>
            <SelectWithSearch
              onChange={(e) => {
                handlePCFields(e, "employeeType");
              }}
              title={t(
                "Selection of employee types (statuut) that can be used"
              )}
              placeHolder={t("Select")}
              search={true}
              options={whiteEmployeeType}
              value={selectedEmpType}
              isDisabled={false}
              isMulti={true}
              isMandatory={true}
              error={whiteEmployeeTypeError}
              name={"employeeType"}
              id={"employeeType"}
            ></SelectWithSearch>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Button
              title={t("Add Paritair committee for white collar")}
              handleClick={() => addParitiarComitee("whiteCollar")}
              className="form-button float-end"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteCollarSection;
