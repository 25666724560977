import React, { useState } from "react";
import roleData from "./dashboard.json";
import Question from "static/images/Question";
import DashboardIcon from "static/images/DashboardIcon";
import Configurationsandsettings from "static/images/Configurationsandsettings";
import Company from "static/images/Company";
import Translate from "static/images/Translate";
import EmailTemplate from "static/images/EmailTemplate";
import Candidate from "static/images/Candidate";
import VacancyIcon from "static/images/VacancyIcon";
import CooperationAgreementIcon from "static/images/CooperationAgreementIcon";
import SignatureIcon from "static/images/SignatureIcon";
import { Link } from "react-router-dom";
import MatchingIcon from "static/images/matchingIcon";
import EmployeeTypeIcon from "static/images/EmployeeTypeIcon";
import Role from "static/images/Role";
import Staricons from "static/images/Staricons";
import Microphone from "static/images/Microphone";
import UsersIcon from "static/images/UsersIcon";
import StartFlag from "static/images/StartFlag";
import BudgetsAndTargetsIcon from "static/images/BudgetsAndTargetsIcon";
import VerifiedUser from "static/images/VerifiedUser";
import Call from "static/images/Call";
import NewIcon from "static/images/NewIcon";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import Count from "components/atoms/Count";
import CardTitle from "components/atoms/CardTitle";
import CardCircle from "components/atoms/CardCircle";
import HomePage from "pages/dashboards/HomePage";
import { rolesTilesData } from "pages/dashboards/DashboardConstants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const Targets: React.FC<{ params: any }> = ({ params }) => {
  const { userAuth, CONST, roletype } = params;
  const userData = useSelector(selectAuth);

  return (
    <>
      <div className="recruiter-action-tabs">
        <div className="row equal-cols h-100">
          {rolesTilesData &&
          roletype &&
          userAuth.role &&
          userAuth.role?.includes(roletype) ? (
            Object?.values(rolesTilesData)?.map((role: any, index: number) => (
              <AccessControl
                key={index}
                requiredPermissions={role?.requiredPermissions}
                override={userData.isSuperAdmin}
              >
                <div
                  className={"col-md-6 col-lg-4 recruitmentTabsAdmin"}
                  style={{ marginTop: "1.5vw" }}
                  key={role.id}
                >
                  <Link
                    to={role.path}
                    className="card border-0 card-shadow justify-content-center h-100"
                    style={{ padding: "1vw" }}
                  >
                    <RecruitmentCard
                      className={`d-flex justify-content-between align-items-center w-100 h-100`}
                    >
                      <div>
                        <Count
                          value={role.value}
                          totalValue={role.totalValue}
                          value_punctuation={"of"}
                          className={`recruitment-card-num`}
                        ></Count>
                        <CardTitle
                          title={role.title}
                          className={`recruitment-card-title`}
                        />
                      </div>
                      <CardCircle
                        width="3.5vw"
                        height="3.5vw"
                        boxShadow="rgb(0, 165, 206) 0vw 0.104vw 0.417vw 0vw"
                        className="float-end justify-content-center hoverShadow saledDashboard"
                      >
                        {role.imageSrc ? (
                          <>
                            <img
                              src={role.imageSrc}
                              className="img-fluid"
                              width="1vw"
                              alt={role.altText}
                            />
                          </>
                        ) : (
                          <>{role.component}</>
                        )}
                      </CardCircle>
                    </RecruitmentCard>
                  </Link>
                </div>
              </AccessControl>
            ))
          ) : (
            <>
              <HomePage />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Targets;
