import { faSort } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'pages/microservices/masterData/translation/Translation'
import React from 'react'
import { handleSort } from 'services/util/UtilService'

const SortAtomForManagePage = (props: any) => {
  return (
    <span
        className="cursor-pointer sortIcon"
        title={t(`Sort by ${((props?.history?.filters?.orderBy ?? "") !== props?.value?.alias || (props?.history?.filters?.sortDirection ?? "") === 'desc') ? 'ascending' : 'descending'}`)}
        onClick={() => handleSort(props)}
    >
        <FontAwesomeIcon icon={faSort} />
    </span>
  )
}

export default SortAtomForManagePage