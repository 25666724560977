import BackButton from "components/atoms/BackButton";
import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import FormNavigationModel from "./FormNavigationModel";

export interface IFormNavigationOrganismProps {
  isFirstTab: boolean;
  isSubmitTab?: boolean;
  draftButtonIsVisible?: boolean;
  closePopup?: boolean
  formExitBackTo: string;
  isHideNext?: boolean;

  handlePreviousStep: MouseEventHandler<HTMLButtonElement>;
  handleNextStep: MouseEventHandler<HTMLButtonElement>;
  handleSubmit?: MouseEventHandler<HTMLButtonElement>;
  handleDraft?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * @returns
 * Form navigation with Back, Next, Submit actions
 * 
 * @example
 <FormNavigationOrganism
  isFirstTab={state.currentTab === state.tabs[0].id}
  isSubmitTab={state.currentTab === state.tabs[state.tabs.length - 1].id}
  draftButtonIsVisible={state.mode === FormMode.CREATE}
  formExitBackTo={PATH_PROJECTS_MANAGE}

  handlePreviousStep={handlePreviousStep}
  handleNextStep={handleNextStep}
  handleSubmit={handleSubmit}
  handleDraft={handleDraft}
></FormNavigationOrganism>;
 */
const FormNavigationOrganism: React.FC<IFormNavigationOrganismProps> = ({
  isFirstTab,
  closePopup,
  isSubmitTab,
  isHideNext,
  draftButtonIsVisible,
  formExitBackTo,
  handlePreviousStep,
  handleNextStep,
  handleSubmit,
  handleDraft,

}) => {
  const [isBack, setIsBack] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const navigate = useNavigate();
  const handleBackButton = () => {
    setIsBack(true);
  }

  useEffect(() => {
    const unblock = (event: any) => {
      if (isBack) {
        setIsBack(true); // Show your custom pop-up
        event.preventDefault(); // Prevent navigation
      }
    };

    window.addEventListener('popstate', unblock);

    return () => {
      window.removeEventListener('popstate', unblock);
    };
  }, [isBack]);


  const renderBackButton = () => {
    return (
      <Button
        type="button"
        title={t("Back")}
        handleClick={isFirstTab ? handleBackButton : handlePreviousStep}
        className="back-btn btn p-0 text-decoration-underline text-uppercase border-0"
      />
    );
  };

  const renderDraftButton = () => {
    return (
      handleDraft && (
        <Button
          type="submit"
          title={t("Save as draft")}
          handleClick={handleDraft}
          className="form-button float-end me-3"
        />
      )
    );
  };

  return (
    <>
      <div className="row" style={{ padding: "1vw 0" }}>
        <div className="col-6 align-self-center">
          <div className="col-6 align-self-center">{renderBackButton()}</div>
        </div>
        <div className="col-6">
          {!isHideNext && (
            <Button
              type="button"
              title={isSubmitTab ? t("Submit") : t("Next")}
              handleClick={isSubmitTab ? handleSubmit : handleNextStep}
              className="form-button float-end"
            />
          )}
          {renderDraftButton()}
        </div>
      </div>
      {isBack && (
        <FormNavigationModel
          setModalChange={setIsBack}
        />
      )}
    </>
  );
};

export default FormNavigationOrganism;
