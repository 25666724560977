import React, { ChangeEvent } from "react";
import LabelField from "../atoms/LabelField";
import InputTextfield from "../atoms/InputTextField";

interface LabelWithInputFieldProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  maxLenght?:number;
  errorclass?: string;
}

/**
 * Text field with Label
 * 
 * @param type
 * @see: https://www.w3schools.com/html/html_form_input_types.asp
 * 
 * @example
 *  
<LabelWithInputField
  type={"text"}
  placeholder={t("")}
  handleChange={}
  label={t("")}
  value={}
  readOnly={false}
  isDisabled={false}
  isMandatory={true}
  labelClassName={""}
  className={""}
  error={}
  name={"myFieldName"}
  id={"myFieldId"}
></LabelWithInputField>;
 */
export const LabelWithInputField: React.FC<LabelWithInputFieldProps> = ({
  type,
  className = "form-control field-shadow form-control",
  placeholder,
  handleChange,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  min,
  step,
  max,
  readOnly,
  errorclass,
 // maxLenght,
}) => {
  return (
    <>
      <div className="col-md-12">
        {label && <LabelField
          title={label}
          className={labelClassName}
          isMandatory={isMandatory}
          htmlfor={id}
        />}
        <InputTextfield
          id={id}
          type={type}
          className={`${className} form-control field-shadow`}
          value={value}
          isDisabled={isDisabled}
          placeholder={placeholder}
          customStyle={customStyle}
          handleChange={handleChange}
          name={name}
          error={error}
          min={min}
          step={step}
          max={max}
          readOnly={readOnly}
          errorclass={errorclass}
         // maxLenght={maxLenght}
        />
      </div>
    </>
  );
};
