import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_MATERIAL, PATH_EDIT_MATERIAL } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  DOWNLOAD_PROJECT_PDF,
  COMPANY_LIST,
  MATERIAL_DELETE,
  FLEET_AND_MATERIAL_FILTER_OPTIONS,
  FLEET_EMP_CREATE,
  FLEET_EMP_DELETE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import FleetEmployeeModal from "../FleetEmployeeModal";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { IconData } from "services/IconData";
import TitleAtom from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import { MaterialTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManageMaterialProps {
  data?: any;
  fleetProp?: any;
  setMaterialData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageMaterial: React.FC<ManageMaterialProps> = ({
  data,
  fleetProp,
  setMaterialData,
  dispatch,
  history,
  pageData,
  handleRefresh,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const { id: routeId, userType: userType } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showFleetEmployeeModal, setShowFleetEmployeeModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    status: [],
    companies: [],
  });
  const [fleetData, setFleetData] = useState({
    fromDate: "",
    toDate: "",
    type: "material",
    userId: routeId,
    fleetId: "",
    status: false,
    purchaseDate: "",
    userType,
  });

  const navigate = useNavigate();
  /** Adding fleet material to employee */
  const handleAddFleetEmp = async () => {
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          FLEET_EMP_CREATE,
          "POST",
          fleetData,
          false,
          M_MASTER_DATA
        );
        if (response.status == 200) {
          CustomNotify({ type: "success", message: response.message });
          setShowFleetEmployeeModal(false);
          handleRefresh();
        } else {
          CustomNotify({ type: "warning", message: response.message });
        }
      } catch (error) {
        console.log("Error");
      }
    }
  };

  const handleFleetEmpModalClose = () => {
    setFleetData({
      fromDate: "",
      toDate: "",
      type: "material",
      userId: routeId,
      fleetId: "",
      status: false,
      purchaseDate: "",
      userType,
    });
    setShowFleetEmployeeModal(false);
  };

  const handleRemove = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_EMP_DELETE,
        "POST",
        { fleetId: fleetData.fleetId, userId: routeId, type: "material" },
        false,
        M_MASTER_DATA
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response?.message });
        handleRefresh();
        setShowFleetEmployeeModal(false);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        MATERIAL_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnit: filterData.data.businessUnit,
          status: [
            { value: '0', label: "Draft" },
            { value: '1', label: "Open" },
            { value: '2', label: "Maintainance" },
            { value: '3', label: "Assigned" },
            { value: '4', label: "Reserved" }
          ],
          // companies: filterData?.data?.materialCompany
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleLink = (id: any) => {
    setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    setShowFleetEmployeeModal(true);
  };

  const handleRemoveMaterial = (id: any) => {
    const filteredData = data.filter(
      (eachItem: any) => eachItem.status == 3 && eachItem.id == id
    );
    if (filteredData.length > 0) {
      const { fromDate, toDate, purchaseDate } = filteredData[0];
      setFleetData({
        fromDate: fromDate,
        toDate: toDate,
        type: "material",
        userId: routeId,
        fleetId: id,
        status: true,
        purchaseDate,
        userType,
      });
    } else {
      setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    }
    setShowFleetEmployeeModal(true);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_MATERIAL}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };

  const [error, setErrors] = useState({
    fromDate: "",
    toDate: "",
  });

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      fromDate: [validateRequired],
      toDate: [validateRequired],
    };
    const validationErrors: any = validateForm(
      { ...fleetData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        const fromDate = new Date(fleetData.fromDate);
        const lastDate = new Date(fleetData.toDate);
        const purchaseDate = new Date(fleetData.purchaseDate);
        if (fromDate > lastDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or before to date",
          }));
          return false;
        }
        if (fromDate < purchaseDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or after purchase date",
          }));
          return false;
        }
        return true;
      }
    }
  };

  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: filterOptions?.businessUnit, placeholder: "Business unit", filterType: 'search' },
    { name: 'ownerCompany', fieldType: "text", placeholder: "Company", filterType: 'search' },
    { name: 'brand', fieldType: "text", placeholder: "Brand", filterType: 'search' },
    { name: 'model', fieldType: "text", placeholder: "Model", filterType: 'search' },
    { name: 'reference', fieldType: "text", placeholder: "Reference", filterType: 'search' },
    { name: 'user', fieldType: "text", placeholder: "User", filterType: 'search' },
    { name: 'serialNumber', fieldType: "text", placeholder: "Serial number", filterType: 'search' },
    { name: 'fromDate', fieldType: "date", end: history?.filters?.toDate, placeholder: "From date", filterType: 'search' },
    { name: 'toDate', fieldType: "date", start: history?.filters?.fromDate, placeholder: "To date", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: filterOptions?.status, placeholder: "Status", filterType: 'search' },
  ];

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Material",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          {fleetProp !== "fleet" ? (
            <TitleAtom title={t("Manage material")} />
          ) : (
            <></>
          )}
          {fleetProp !== "fleet" ? (
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              class={{
                rowClass: 'd-grid manageCarSearchCol manageMaterialSearch',
                searchBtnClass: 'col-2 ms-auto'
              }}
              handleRefresh={handleRefresh}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="position-relative tableMainWrapper">
          {fleetProp !== "fleet" && (
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Material",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <Link to={PATH_CREATE_MATERIAL} className="form-button">
                    + {"Create material"}
                  </Link>
                </div>
              </div>
            </AccessControl>
          )}
          <div className="tableSection table-responsive">
            <div className="manageMaterial">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {MaterialTableHeader?.map((header) => (
                      <th key={header.alias}>
                        <>
                          {t(header.name)}
                          {header.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                    <th className="table_actions">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Material",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: number) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">
                            {record?.businessUnits}
                          </td>
                          <td className="align-middle">
                            {record?.ownerCompany}
                          </td>
                          <td className="align-middle">{record?.brand}</td>
                          <td className="align-middle">{record?.model}</td>
                          <td className="align-middle">{record?.reference}</td>
                          <td className="align-middle">{record?.user}</td>
                          <td className="align-middle">
                            {record?.serialNumber}
                          </td>
                          <td className="align-middle">
                            {record?.fromDate}
                          </td>
                          <td className="align-middle">
                            {record?.toDate}
                          </td>
                          <td className="align-middle">
                            <div
                              className={getStatusColor(record.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getTooltipMessage(record.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              {...(fleetProp !== "fleet"
                                ? {
                                  handleEdit: handleEdit,
                                  handleArchive: handleDelete,
                                } //handleSendMaterialMail: () => { }
                                : record.status == 1
                                  ? { handleAddFleet: handleLink }
                                  : { handleRemove: handleRemoveMaterial })}
                              value={record.id}
                              permission={"Material"}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {showFleetEmployeeModal && (
          <FleetEmployeeModal
            handleClose={handleFleetEmpModalClose}
            data={fleetData}
            errors={error}
            setFleetData={setFleetData}
            handleSave={handleAddFleetEmp}
            handleRemove={handleRemove}
            validationFn={validationFunction}
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageMaterial;
