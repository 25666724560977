import { Reducer } from "react";
import {
    ChildCompetence,
    VacancyFormData
} from "../annotation/VacancyInterfaces";
import { FormAction } from "./Actions";
import * as CONST from "../annotation/VacancyConstants";
import { getPCAndItsParameters } from "../utils/Utils";

const FormReducer: Reducer<VacancyFormData, FormAction> = (
    state: any,
    action: any
) => {
    switch (action.type) {
        case CONST.NEXT_STEP:
            const currentTab = state.tabDetails.find(
                (tab: any) => tab.showStatus
            );
            if (currentTab) {
                const currentIndex = state.tabDetails.findIndex(
                    (tab: any) => tab.id === currentTab.id
                );
                const nextIndex = (currentIndex + 1) % state.tabDetails.length;

                const updatedTabDetails = state.tabDetails.map((tab: any) => ({
                    ...tab,
                    showStatus: tab.id === state.tabDetails[nextIndex].id
                }));

                return {
                    ...state,
                    tabDetails: updatedTabDetails
                };
            }
            return state;

        case CONST.PREVIOUS_STEP:
            const currentTabPrev = state.tabDetails.find(
                (tab: any) => tab.showStatus
            );
            if (currentTabPrev) {
                const currentIndexPrev = state.tabDetails.findIndex(
                    (tab: any) => tab.id === currentTabPrev.id
                );
                const previousIndex =
                    (currentIndexPrev - 1 + state.tabDetails.length) %
                    state.tabDetails.length;

                const updatedTabDetailsPrev = state.tabDetails.map(
                    (tab: any) => ({
                        ...tab,
                        showStatus:
                            tab.id === state.tabDetails[previousIndex].id
                    })
                );

                return {
                    ...state,
                    tabDetails: updatedTabDetailsPrev
                };
            }
            return state;

        case CONST.UPDATE_TAB_DETAILS:
            return {
                ...state,
                tabDetails: action.tabDetails
            };

        case CONST.UPDATE_TAB_ERROR:
            const { tabIndex, error } = action;
            return {
                ...state,
                tabDetails: state.tabDetails.map((tab: any, index: any) => ({
                    ...tab,
                    error: index === tabIndex ? error : tab.error
                }))
            };

        case CONST.UPDATE_FIELDS:
            if (action?.field) {
                return {
                    ...state,
                    [action.template]: {
                        ...state[action.template],
                        [action.field]: action.value
                    }
                };
            }
            return {
                ...state,
                [action.template]: {
                    ...state[action.template],
                    ...action?.value,
                }
            };            

        case CONST.SET_OPTIONS:            
            if (action?.field) {                
                return {
                    ...state,
                    options: {
                        ...state.options,
                        [action?.field]: action?.payload
                    }
                };
            }
            let options: any = { ...state.options, ...action.payload }            
            if (action?.payload?.ParitairComitee?.whiteCollar) {
                let mergedData = getPCAndItsParameters(action?.payload);
                options = { ...options, ...mergedData };               
            }
            return {
                ...state,
                options: options
            };

        case CONST.UPDATE_FIELD_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.field]: action.error
                }
            };

        case CONST.COMPETENCE_CHANGE:
            return {
                ...state,
                competences: action?.field ? competenceSelected(state, action) : competenceRange(state, action)
            }

        default:
            return state;
    }
};

export default FormReducer;

export const competenceSelected = (state: any, action: any) => {
    const selection = action.payload.selection;
    const selectedData = action.payload.selected;
    const selectedId = action.payload.id;
    let updatedForm: any = [...state.competences?.competence];
    if (selectedData.length === 0) {
        updatedForm = updatedForm?.filter((item: { key: any }) => item?.key !== selection);
    } else {
        const existingSelection = updatedForm?.find((item: { key: any }) => item.key === selection);
        if (existingSelection) {
            existingSelection.options = selectedData;
        } else {
            const newSelection = {
                competence_id: selectedId,
                key: selection,
                label: selection,
                options: selectedData,
            };
            updatedForm?.push(newSelection);
        }
    }
    return { ...state.competences, competence: updatedForm };
};
  // Reducer function to handle COMPETENCE_RANGE action
export const competenceRange = (state: any, action: any) => {
    const { value, section, index } = action.payload;
    let updatedForm: any = [...state?.competences?.competence];
    updatedForm?.forEach((item: any) => {
        if (item.key === section && item.options[index]) {
            item.options[index].range = value;
        }
    });
    return {
        ...state.competences,
        competence: updatedForm
    };
};