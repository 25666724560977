import { FormMode } from "components/common/CommonEnums";

export const SWITCH_TAB = "SWITCH_TAB";
export const SET_OPTIONS = "SET_OPTION";
export const SET_MODE = "SET_MODE";

// Contact tab
export const ADD_CONTACT = "ADD_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const UPDATE_SELECTED_CONTACT_FIELD = "UPDATE_SELECTED_CONTACT_FIELD";
export const UPDATE_CONTACT_SUBFORM_FIELD = "UPDATE_CONTACT_SUBFORM_FIELD";
export const UPDATE_SELECTED_CONTACT_TAG_EDIT =
  "UPDATE_SELECTED_CONTACT_TAG_EDIT";
export const UPDATE_CONTACT_FIELD_ERROR = "UPDATE_CONTACT_FIELD_ERROR";
export const SET_CONTACT_PERSON_OPTION = "SET_CONTACT_PERSON_OPTION";
export const COMPETENCE_CHANGE = "COMPETENCE_CHANGE";
export const COMPETENCE_TAB_DATA = "COMPETENCE_TAB_DATA";
// Responsible tab
export const UPDATE_RESPONSIBLE_PERSON_FIELD =
  "UPDATE_RESPONSIBLE_PERSON_FIELD";
export const UPDATE_CURRENT_TAB = "UPDATE_CURRENT_TAB";

// General tab
export const SET_FIELD_GENERAL_TAB = "SET_FIELD_GENERAL_TAB";
export const SET_FIELD_ERROR_GENERAL_TAB = "SET_FIELD_ERROR_GENERAL_TAB";
export const SET_CREATE_LOCATION_GENERAL_TAB =
  "SET_CREATE_LOCATION_GENERAL_TAB";
export const SET_ERROR_SUBFIELD_GENERAL_TAB = "SET_ERROR_SUBFIELD_GENERAL_TAB";

// Tab validations
export const SET_ERRORS_GENERAL_TAB = "SET_ERRORS_GENERAL_TAB";
export const UPDATE_ERRORS_CONTACTPERSONS_TAB =
  "UPDATE_ERRORS_CONTACTPERSONS_TAB";
export const UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB =
  "UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB";

export const UPDATE_ERRORS_BILLING_TAB = "UPDATE_ERRORS_BILLING_TAB";
export const UPDATE_ERRORS_SHIFT_TAB = "UPDATE_ERRORS_SHIFT_TAB";
export const UPDATE_BILLING_FIELD = "UPDATE_BILLING_FIELD";
export const UPDATE_SHIFT_TABLE = "UPDATE_SHIFT_TABLE";
export const UPDATE_FIELD_ERROR = "UPDATE_FIELD_ERROR";
export const UPDATE_ERRORS_PARAMETER_TAB = "UPDATE_ERRORS_PARAMETER_TAB";

// while editing prefilling data
export const UPDATE_TAB_DATA = "UPDATE_TAB_DATA";

//SHIFTS
export const UPDATE_SHIFT_TIME = "UPDATE_SHIFT_TIME";
export const SHIFT_UPDATE_FIELD = "SHIFT_UPDATE_FIELD";

/** Sequence */
export const SET_SEQUENCE_NAME = "SET_SEQUENCE_NAME";
export const SET_WEEKLY_SHIFTS = "SET_WEEKLY_SHIFTS";
export const ADD_ANOTHER_SEQUENCE = "ADD_ANOTHER_SEQUENCE";
export const EDIT_SEQUENCE = "EDIT_SEQUENCE";
export const DELETE_SEQUENCE = "DELETE_SEQUENCE";
export const UPDATE_ERRORS_SEQUENCE_TAB = "UPDATE_ERRORS_SEQUENCE_TAB";

//Project parameter tab

export const UPDATE_PARAMETER_FORM = "UPDATE_PARAMETER_FORM";
export const UPDATE_PROJECT_PARAMETERS = "UPDATE_PROJECT_PARAMETERS";
export const UPDATE_PARAMETERS_ERRORS = "UPDATE_PARAMETERS_ERRORS";

export const INITIAL_STATE = "INITIAL_STATE";
export const SIGNATURE_IMAGE = "SIGNATURE_IMAGE";

export const TITLE_ALREADY_EXISTS = "Title already exists";
export const SET_LOCATION_OPTION = "SET_LOCATION_OPTION";
export const SET_PROJECT_LOCATION_FIELD_EMPTY =
  "SET_PROJECT_LOCATION_FIELD_EMPTY";

export const UPDATE_FETCH_DATA = "UPDATE_FETCH_DATA";

export const UPDATE_SHIFT_BREAK_FIELDS = "UPDATE_SHIFT_BREAK_FIELDS";

//SHIFTS TABLE
export const WEEK_DAYS = [
  // "Default",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const DISABLED_DAYS = ["Unknown"];
export const SHIFT_FIELDS = {
  from: "From",
  till: "Till",
  pause: "Pause",
  total: "Total",
};

export const NEW_SHIFT = {
  Default: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Monday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Tuesday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Wednesday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Thursday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Friday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Saturday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
  Sunday: {
    from: "00:00",
    till: "00:00",
    pause: "00:00",
    total: "00:00",
  },
};

export const INITIAL_STATE_OBJECT = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "general",
      title: "General",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "contactsPersons",
      title: "Contacts persons",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "projectParameters",
      title: "Parameters",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "competences",
      title: "Competences",
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "shifts",
      title: "Shifts",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "sequence",
      title: "Sequence",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "billing",
      title: "Billing",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  general: {
    data: {
      id: "",
      businessUnitNumber: "",
      projectName: "",
      functions: {
        selected: { value: "", label: "Select", capacity: 0 },
        tableData: [],
        isEdit: false,
      },
      company: "",
      locations: "",
      startDate: null,
      endDate: null,
      createLocationIsVisible: false,
      info: "",
      isActive: true,
    },
    errors: {
      businessUnitNumber: "",
      projectName: "",
      capacity: "",
      functions: "",
      company: "",
      locations: "",
      startDate: "",
      endDate: "",
      info: "",
    },
  },
  competences: {
    competence: [],
    candidateLevels: [],
    childCompetences: {}
  },
  projectLocation: {
    data: {
      company: null,
      location: "",
      phone: "",
      street: "",
      number: "",
      box: "",
      zipcode: "",
      city: "",
      country: null,
      info: "",
    },
    errors: {
      company: null,
      location: "",
      phone: "",
      street: "",
      number: "",
      zipcode: "",
      city: "",
      country: null,
    },
  },
  contactsPersons: {
    data: {
      selectedContactPersons: [],
      selectedContactsTagList: [],
      // subform data
      contactSubformIsVisible: false,
      contactsPersons: {
        tittle: null,
        fName: "",
        lName: "",
        email: "",
        company: null,
        mobNumber: "",
        language: null,
        roles: [],
        locations: [],
        businessUnit: [],
        info: "",
      },
      // timeSheet: [],
      // billing: [],
      // leave: [],
      // sickness: [],
      // holiday: [],
      // safety: [],
    },
    errors: {
      selectedContactPersons: "",
      selectedContactsTagList: [],

      // subform errors
      contactsPersons: {
        fName: "",
        lName: "",
        email: "",
        company: null,
        mobNumber: "",
        language: null,
        roles: [],
        locations: [],
        info: "",
      },
      // timeSheet: "",
      // billing: "",
      // leave: "",
      // sickness: "",
      // holiday: "",
      // safety: "",
    },
  },

  responsiblePerson: {
    data: {
      timeSheet: [],
      billing: [],
      leave: [],
      sickness: [],
      holiday: [],
      safety: [],
    },
    errors: {
      timeSheet: "",
      billing: "",
      leave: "",
      sickness: "",
      holiday: "",
      safety: "",
    },
  },
  projectParameters: {
    data: {
      templates: "",
      fromdate: "",
      fetchData: false,
      sections: [],
    },
    errors: {
      templates: "",
      fromdate: "",
    },
  },

  shifts: {
    data: {
      shifts: [],
      shiftTable: [],
    },
    errors: {},
  },
  sequence: {
    data: {
      sequenceName: "",
      weeklyShifts: {},
      sequence: [],
      edit: "",
    },
    errors: {},
  },
  billing: {
    data: {
      vatRate: "",
      billingStatus: "",
      paymentTerm: "",
      email: [],
      proformaInvoice: false,
      splitInvoicePerPerson: false,
      referenceRequired: false,
      poNumber: "",
      reference: "",
    },
    errors: {
      vatRate: "",
      billingStatus: "",
      paymentTerm: "",
      reference: "",
      poNumber: "",
      email: ""
    },
  },

  currentTab: "general",

  options: {
    businessUnit: [],
    projectTypes: [],
    company: [],
    locations: [],
    companyUsers: [],
    gender: [],
    language: [],
    roles: [],
    functions: [],
    templates: [],
    shifts: [],
    vatRate: [],
    billingRate: [],
    paymentTerm: [],
    countries: [],
    companyBusinessUnit: [],
    Competence: [],
    PcFunctions: [],
    tittle: [],
  },
};
export const PROJECT_OVERVIEW = "PROJECT_OVERVIEW";

export const WEEKS = [
  {
    weeknumber: "1",
    handle: "week1",
    label: "Week",
  },
  {
    weeknumber: "2",
    handle: "week2",
    label: "Week",
  },
  {
    weeknumber: "3",
    handle: "week3",
    label: "Week",
  },
  {
    weeknumber: "4",
    handle: "week4",
    label: "Week",
  },
  {
    weeknumber: "5",
    handle: "week5",
    label: "Week",
  },
  {
    weeknumber: "6",
    handle: "week6",
    label: "Week",
  },
  {
    weeknumber: "7",
    handle: "week7",
    label: "Week",
  },
];

export const OverTimeDependency: any = {
  "17": ["18"],
  "18": ["17"],
};

export const OverTimeFieldDependency: any = {
  "17": ["20"],
  "18": ["21"],
  "19": ["22"],
  "23": ["26"],
  "24": ["27"],
  "25": ["28"],
  "29": ["32"],
  "30": ["33"],
  "31": ["34"],
};
