import { M_MASTER_DATA } from "constants/Constants";
import { PROJECTS_OPTIONS, VACANCY_EDIT } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { SET_OPTIONS } from "../annotation/VacancyConstants";

const checKVdabIsSelectedOrNot = (state: any) => {
    return state?.options?.PostMediums?.some(
        (item: any) => item?.label?.toLowerCase() === "vdab" && state?.general?.postjobto[item?.value] === 1
    )
}

const fetchProjectOptions = async (optionsPayload: any, url = PROJECTS_OPTIONS, loading = false) => {
    const response = await ApiCall.service(url, "POST", optionsPayload, loading, M_MASTER_DATA);    
    if (response.status === 200) {      
      return response?.data;
    }
    return [];
};

const getVdabCompetences = async (state: any, dispatch: any, payload: any, url = VACANCY_EDIT) => {
  payload = {
    ...payload,
    onChangeOptions: true,
  }
  let options = await fetchProjectOptions(payload, url, true);
  dispatch({ type: SET_OPTIONS, payload: options });
  return options;
};

const getPcFunctionOptions = async (state: any, dispatch: any, payload: any, url = VACANCY_EDIT) => {
  payload = {
    ...payload,
    onChangeOptions: true,
    options: ["Competence"],
  }
  let options = await fetchProjectOptions(payload, url);
  dispatch({ type: SET_OPTIONS, payload: options });
  return options;
};

const getOnChangeProjects = async (state: any, dispatch: any, payload: any, url = VACANCY_EDIT) => {
  payload = {
    ...payload,
    onChangeOptions: true,
    options: ["Location", "BusinessUnit", "ContactPersons", "ProjectCompetence", "PcFunctions"]
  }
  let options = await fetchProjectOptions(payload, url);
  let { ProjectCompetence, ...others } = options;
  dispatch({ type: SET_OPTIONS, payload: others});
  return options;
};

const getCompanyOptions = async (state: any, dispatch: any, payload: any, url = VACANCY_EDIT) => {
  payload = {
    ...payload,
    onChangeOptions: true,
    options: ["Project", "ParitairComitee", "ContactPersons", "BusinessUnit", "Location"]
  }
  let options = await fetchProjectOptions(payload, url);
  dispatch({ type: SET_OPTIONS, payload: options });
  return options;
};

const getPCAndItsParameters = (payload: any) => {
  let mergedData: any = {ParitairComitee: [], EmployeeType: [], PcCategories: [], PcFunctions: []};                
  const combinedData = [...payload.ParitairComitee?.blueCollar ?? [], ...payload.ParitairComitee?.whiteCollar ?? []];                
  combinedData.forEach((item: any) => {
    mergedData?.ParitairComitee.push({
      ...(item?.pc ?? item),
      pcCategories: item?.pcCategories ?? [],
      pcFunctions: item?.pcFunctions ?? [],
      employeeType: item?.employeeType ?? [],
    });
    (item?.pcCategories?.length > 0) && mergedData.PcCategories.push(...item.pcCategories);
    (item?.pcFunctions?.length > 0) && mergedData.PcFunctions.push(...item.pcFunctions);
    (item?.employeeType?.length > 0) && mergedData.EmployeeType.push(...item.employeeType);
  });
  return mergedData;
}

export {
  checKVdabIsSelectedOrNot, fetchProjectOptions, getVdabCompetences, getPcFunctionOptions,
  getCompanyOptions, getOnChangeProjects, getPCAndItsParameters,
}