import Button from "components/atoms/Button";
import CalenderWithoutInputField from "components/atoms/CalenderWithoutInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import AddPlusIcon from "static/images/AddPlusIcon";
import CloneIcon from "static/images/CloneIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";

interface ChildProps {
  empRowIndex: number;
  funcData: any;
  empTypeData: any;
  funcDropdown: any;
  empTypeDropdown: any;
  funcIndex: number;
  pcIndex: number;
  coeffIndex: number;
  coeffData: any;
  dropDown: any;
  role: string;
  coeffOption: any;
  // stateData: AgreementFormData;
  addFunctionProfile: (pcIndex: number) => void;
  cloneFunctionType: (pcIndex: number, funcIndex: number) => void;
  deleteFunctionType: (pcIndex: number, funcIndex: number) => void;
  addEmployeeType: (pcIndex: number, funcIndex: number) => void;
  deleteEmployeeType: (
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => void;
  handleSelectChange: (
    selectedOption: any,
    title: string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => void;
  handleFieldChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number,
    coeffIndex: number
  ) => void;
  // acceptOrReject: (
  //   pcIndex: number,
  //   funcIndex: number,
  //   empRowIndex: number,
  //   coeffIndex: number,
  //   status: number
  // ) => void;
  handleDateChange: (
    date: Date | null,
    name: string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => void;
}

const TableData: React.FC<ChildProps> = ({
  pcIndex,
  empRowIndex,
  funcData,
  empTypeData,
  funcDropdown,
  empTypeDropdown,
  coeffIndex,
  coeffData,
  dropDown,
  funcIndex,
  coeffOption,
  // stateData,
  role,
  addFunctionProfile,
  cloneFunctionType,
  deleteFunctionType,
  addEmployeeType,
  deleteEmployeeType,
  handleSelectChange,
  handleFieldChange,
  //acceptOrReject,
  handleDateChange,
}) => {
  const { state, dispatch } = useFormContext();

  return (
    <>
      {empRowIndex === 0 && (
        <td
          rowSpan={funcData.employeeTypes.length}
          className="text-center agreement-profile position-relative"
        >
          <SelectWithSearch
            onChange={(e) =>
              handleSelectChange(e, "function", pcIndex, funcIndex, empRowIndex)
            }
            title={t("")}
            placeHolder={t("Select")}
            search={true}
            options={funcDropdown}
            value={funcData.function}
            isDisabled={false}
            isMulti={false}
            isMandatory={true}
            error={""}
            name={"function"}
            className="text-center mb-3 shadow-none"
            id={"function"}
          ></SelectWithSearch>

          <div className="text-center mb-2 table-action-icons">
            <span
              onClick={() => addFunctionProfile(pcIndex)}
              className="table-action-btn cursor-pointer me-2"
              title={t("Add profile")}
            >
              <AddPlusIcon />
            </span>
            <span
              onClick={() => cloneFunctionType(pcIndex, funcIndex)}
              className="table-action-btn cursor-pointer me-2"
              title={t("Clone profile")}
            >
              <CloneIcon />
            </span>
            {funcIndex !== 0 && (
              <span
                onClick={() => deleteFunctionType(pcIndex, funcIndex)}
                className="table-action-btn cursor-pointer me-2"
                title={t("Delete profile")}
              >
                <DeleteIcon />
              </span>
            )}
          </div>
        </td>
      )}
      <td
        className={`text-center employee-type ${
          empTypeData.empTypeErrorStatus ? "error-border" : ""
        }`}
      >
        <SelectWithSearch
          onChange={(e) =>
            handleSelectChange(e, "empType", pcIndex, funcIndex, empRowIndex)
          }
          title={t("")}
          placeHolder={t("Select")}
          search={true}
          options={empTypeDropdown}
          value={empTypeData.empType}
          isDisabled={false}
          isMulti={false}
          isMandatory={true}
          error={""}
          name={"empType"}
          id={"empType"}
        ></SelectWithSearch>
      </td>
      <td
        className={`text-center employee-type ${
          empTypeData.levelErrorStatus ? "error-border" : ""
        }`}
      >
        <SelectWithSearch
          onChange={(e) =>
            handleSelectChange(e, "level", pcIndex, funcIndex, empRowIndex)
          }
          title={t("")}
          placeHolder={t("Select")}
          search={true}
          options={state.options.coefficientLevel}
          value={empTypeData.level}
          isDisabled={false}
          isMulti={false}
          isMandatory={true}
          error={""}
          name={"level"}
          id={"level"}
        ></SelectWithSearch>
      </td>
      {/* {Object.keys(coeffData)
        .filter(
          (key) =>
            !key.endsWith("_error_status") &&
            !key.endsWith("_changed") &&
            !key.endsWith("startDate") &&
            !key.endsWith("Id") &&
            !key.endsWith("_min")
        )
        .map((key: string, index: number) => {
          const item = coeffData[key]; // Access the corresponding item using the key
          console.log(item);
          const dynamicErrorStatus = coeffData[key + "_error_status"];

          // Construct dynamic name based on the key
          const dynamicName: string = key;

          return (
            <td
              className="coeffDataDynamic"
              // colSpan={1}
              // key={`coeff-${dynamicName}-${index}`} // Assign a unique key
              // className={`${
              //   dynamicErrorStatus ? "error-border" : ""
              // } position-relative coeffDataDynamic`}
            >
              <LabelWithInputField
                key={`coeff-name-${index}`}
                handleChange={(event) =>
                  handleFieldChange(
                    event,
                    pcIndex,
                    funcIndex,
                    empRowIndex,
                    coeffIndex
                  )
                }
                name={dynamicName}
                value={item ?? ""}
                className="text-center form-control"
              />
            </td>
          );
        })} */}
      {coeffOption.map((coeff: any, index: number) => {
        const coefName = coeff.name.toLowerCase();
        // Access the item in coeffData based on the lowercase name
        const item = coeffData[coefName];
        const dynamicName: string = coefName;
        return (
          <td
            className="coeffDataDynamic"
            // colSpan={1}
            // key={`coeff-${dynamicName}-${index}`} // Assign a unique key
            // className={`${
            //   dynamicErrorStatus ? "error-border" : ""
            // } position-relative coeffDataDynamic`}
          >
            <LabelWithInputField
              key={`coeff-name-${index}`}
              handleChange={(event) =>
                handleFieldChange(
                  event,
                  pcIndex,
                  funcIndex,
                  empRowIndex,
                  coeffIndex
                )
              }
              name={dynamicName}
              value={item ?? ""}
              className="text-center form-control"
            />
          </td>
        );
      })}

      <td className="text-center table-action-icons">
        <div className="d-inline-block position-relative me-2">
          <CalenderWithoutInputField
            onDateChange={(date) => {
              handleDateChange(
                date,
                "startDate",
                pcIndex,
                funcIndex,
                empRowIndex
              );
            }}
            selectedDate={
              empTypeData.startDate != null
                ? new Date(empTypeData.startDate)
                : new Date()
            }
            name="startDate"
            title={`Start date - ${
              empTypeData.startDate != null
                ? new Date(empTypeData.startDate).toLocaleDateString()
                : new Date().toLocaleDateString()
            }`}
          />
        </div>
        <span
          title={t("Add employee type")}
          className="table-action-btn cursor-pointer me-2"
          onClick={() => addEmployeeType(pcIndex, funcIndex)}
        >
          <AddPlusIcon />
        </span>
        {empRowIndex !== 0 && (
          <span
            onClick={() => deleteEmployeeType(pcIndex, funcIndex, empRowIndex)}
            className="table-action-btn cursor-pointer"
            title={t("Delete")}
          >
            <DeleteIcon />
          </span>
        )}
      </td>
    </>
  );
};

export default TableData;
