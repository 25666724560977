import React, { ReactNode, CSSProperties } from 'react';

const style = {};

interface Props {
  title?: ReactNode;
  customStyle?: CSSProperties;
  isMandatory?: boolean;
  className?: string;
  htmlfor?:string;
}

const LabelField: React.FC<Props> = ({
  title,
  customStyle = {},
  isMandatory = false,
  className = 'form-label field-label mb-0',
  htmlfor
}) => {
  return (
    <>
      <label
        htmlFor={htmlfor}
        style={{ ...style, ...customStyle }}
        className={className}
      >
        {title}
        {isMandatory && <span className='text-danger'>*</span>}
      </label>
    </>
  );
};

export default LabelField;
