import React, { useState, useEffect } from "react";
import { OptionProps } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { mapToSelect } from "utils/MapToSelect";
import { Link } from "react-router-dom";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";
import Button from "components/atoms/Button";
import EditIcon from "../../../../../../static/images/EditIcon";
import ViewIcon from "../../../../../../static/images/ViewIcon";
import Pagination from "components/atoms/Pagination";
import CustomNotify from "components/atoms/CustomNotify";
import { M_COMPANY_CREATION } from "../../../../../../constants/Constants";
import BackButton from "components/atoms/BackButton";

interface inflationDataProps {
  regionName: string;
  countryName: string;
  fromDate: string;
  endDate: string;
  inflationId: number;
}

interface inflationProps {
  regions: OptionProps[];
  countries: OptionProps[];
  fromDate: Date | null;
  endDate: Date | null;
}

const ManageInflations = () => {
  const [formData, setFormData] = useState<inflationProps>({
    regions: [],
    countries: [],
    fromDate: null,
    endDate: null,
  });

  const [inflationDetails, setInflationDetails] = useState({
    regions: [] as OptionProps[],
    countries: [] as OptionProps[],
  });

  const [inflationData, setInflationData] = useState(
    [] as inflationDataProps[]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 20;
  let pageIndex = (currentPage - 1) * itemsPerPage + 1;

  useEffect(() => {
    fetchInflationsData();
  }, []);

  const fetchInflationsData = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_INFLATIONS,
      "POST",
      requestData,
      false,
      M_COMPANY_CREATION
    );

    if (response.status === 200) {
      const regions = mapToSelect(response.regions);
      const countries = mapToSelect(response.countries);

      setInflationDetails({
        regions: regions,
        countries: countries,
      });
      setInflationData(response.data);
      setTotalPages(response.totalPages);
    }
    return;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "countries") {
      const selectedCountryIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "regions") {
      const selectedRegionIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleRegionChange(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const fetchRegionForCountryDetails = async () => {
      const response = await ApiCall.service(
        `getRegionForCountryDetails`,
        "POST",
        selectedCountryIds,
        false,
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        setFormData((prevData) => ({
          ...prevData,
          regions: response.data,
        }));
      }
    };
    fetchRegionForCountryDetails();
  };

  const handleRegionChange = (selectedRegionIds: any) => {
    const fetchCountryDetailsForRegion = async () => {
      const response = await ApiCall.service(
        `getCountryForRegionDetails`,
        "POST",
        selectedRegionIds,
        false,
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        const countries = mapToSelect(response.data);
        setInflationDetails((prevData) => ({
          ...prevData,
          countries: countries,
        }));
      }
    };
    fetchCountryDetailsForRegion();
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: date,
    }));
  };

  const handleReset = () => {
    setFormData({
      regions: [],
      countries: [],
      fromDate: null,
      endDate: null,
    });
    setCurrentPage(1);
    fetchInflationsData();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchInflationsData(formData, newPage);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      formData.regions.length === 0 &&
      formData.countries.length === 0 &&
      formData.fromDate === null &&
      formData.endDate === null
    ) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
      fetchInflationsData();
    } else {
      fetchInflationsData(formData, currentPage);
    }
  };

  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">Manage Inflations</h1>
          </div>
        </div>
        <div className="search-bar row">
          <div className="col-md-12 mb-3">
            <Link
              to="/add/inflation"
              className="form-button float-end d-flex align-items-center text-decoration-none text-uppercase"
            >
              + Add inflation
            </Link>
          </div>
          <form action="" className="search-bar manage-infaltion-search">
            <div className="row">
              <div className="col-md-3">
                <SelectWithSearch
                  search={true}
                  options={inflationDetails.regions}
                  placeHolder="Select region"
                  onChange={(e) => handleSelectChange(e, "regions")}
                  isMulti={true}
                  className="select-field"
                  name="region"
                  value={formData.regions}
                />
              </div>
              <div className="col-md-3">
                <SelectWithSearch
                  search={true}
                  options={inflationDetails.countries}
                  placeHolder="Select country"
                  onChange={(e) => handleSelectChange(e, "countries")}
                  isMulti={true}
                  className="select-field"
                  name="country"
                  value={formData.countries}
                />
              </div>
              <div className="col-md-3 position-relative inflation-date">
                <Calender
                  onChange={(date) => handleDateChange(date, "fromDate")}
                  selectedDate={formData.fromDate}
                  name="inflationFromDate"
                  minDate={new Date()}
                  maxDate={null}
                  isMandatory={false}
                  placeHolder="From date"
                />
              </div>
              <div className="col-md-3 position-relative inflation-date">
                <Calender
                  onChange={(date) => handleDateChange(date, "endDate")}
                  selectedDate={formData.endDate}
                  name="inflationEndDate"
                  minDate={formData.fromDate ?? new Date()}
                  maxDate={null}
                  isMandatory={false}
                  placeHolder="End date"
                />
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 my-3">
                <div className="row justify-content-end">
                  <div className="col-lg-3 col-md-6 d-flex justify-content-lg-end justify-content-md-between">
                    <Button
                      title="Reset"
                      type="reset"
                      handleClick={handleReset}
                      className="btn delete-btn rounded-0 shadow-none text-uppercase me-3 px-4 search-btns w-50"
                    />
                    <Button
                      title="Search"
                      type="submit"
                      handleClick={handleSubmit}
                      className="btn form-button rounded-0 shadow-none text-uppercase search-btns w-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                <th className="ps-lg-4">#</th>
                <th>Region</th>
                <th>Country</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {inflationData && inflationData.length > 0 ? (
                inflationData.map((inflation) => (
                  <tr
                    key={inflation.inflationId}
                    className="border mb-3 box-shadow align-middle"
                  >
                    <td>{pageIndex++}</td>
                    <td>{inflation.regionName}</td>
                    <td>{inflation.countryName}</td>
                    <td>{inflation.fromDate}</td>
                    <td>{inflation.endDate}</td>
                    <td className="table-action-icons px-2">
                      {inflation.endDate === null && (
                        <Link
                          to={`/edit/${inflation.inflationId}/inflation`}
                          className="btn p-0 border-0 me-2"
                          title="Edit"
                        >
                          <EditIcon />
                        </Link>
                      )}
                      <Link
                        to={`/view/${inflation.inflationId}/inflation`}
                        className="btn p-0 border-0 me-2"
                        title="View"
                      >
                        <ViewIcon />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border">
                  <td colSpan={7} className="border-0 text-center py-3">
                    <span className="text-danger fw-bold">No records</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination justify-content-center align-items-center my-3 mb-4">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(newPage) => handlePageChange(newPage)}
        />
      </div>
      <div className="col-md-4 mt-3 mb-4 align-self-center">
        <BackButton />
      </div>
    </>
  );
};

export default ManageInflations;
