import React, { useState } from "react";
import Pagination from "components/atoms/Pagination";
import { useNavigate } from "react-router-dom";
import { M_IDENTITY_MANAGER, UserStatus } from "constants/Constants";
import { UserData } from "./ManageUsers";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import "static/css/users.css";
import {
  DELETE_USER,
  SEND_ACTIVATION_MAIL,
  UNARCHIVE_USER,
} from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { UserTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Title from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import FilterMolecule from "components/molecules/FilterMolecule";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { convertArrayToOptions, handleClear } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import { PATH_CANDIDATE_CORNER } from "constants/Paths";

interface ManageUsersPageProps {
  usersObj: UserData[];
  pageData: any;
  history: any;
  deleteFilter: (deleteId: number) => void;
  options?: any;
  dispatch: any;
  handleRefresh: () => void;
}

const ManageUsersPage: React.FC<ManageUsersPageProps> = ({
  usersObj,
  pageData,
  history,
  deleteFilter,
  options,
  dispatch,
  handleRefresh,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);

  // start of index
  const columns = [
    "businessUnits",
    "first_name",
    "last_name",
    "email",
    "phoneNumber",
    "role",
    "status",
  ];

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_USER}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        const url = `archiveUser/${deleteID}`; //archive user in candidate-creation
        // await ApiCall.getService(url, "GET", "candidate-creation");
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleUnArchiveChanges = async (userId: number) => {
    if (userId) {
      const url = `${UNARCHIVE_USER}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        deleteFilter(userId);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const deleteUser = (userData: UserData | any) => {
    setShowModal(true);
    setDeleteId(userData.id);
  };

  const handleEdit = (userData: UserData | any) => {
    if (userData.id) {
      navigate(`/user/edit/${userData.id}`);
    }
  };

  const handleView = (userData: UserData | any) => {
    if (userData.id) {
      navigate(`/user/view/${userData.id}`);
    }
  };

  const handleUnArchive = (userData: UserData | any) => {
    if (userData.id) {
      handleUnArchiveChanges(userData.id);
    }
  };

  const sendActivationMail = async (userData: any) => {
    try {
      const response = await ApiCall.service(
        SEND_ACTIVATION_MAIL,
        "POST",
        { ...userData },
        false,
        M_IDENTITY_MANAGER
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleSendMail = (userData: UserData | any) => {
    if (userData.id) {
      sendActivationMail(userData);
    }
  };

  const filters = [
    {name: 'businessUnits', fieldType: "multiSelect", options: options?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    {name: 'firstName', fieldType: "text", placeholder: "First name", filterType: 'search' },
    {name: 'lastName', fieldType: "text", placeholder: "Last name", filterType: 'search' },
    {name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
    {name: 'phoneNumber', fieldType: "text", placeholder: "Phone number", filterType: 'search' },
    {name: 'role', fieldType: "singleSelect", options: options?.roles, placeholder: "Role", filterType: 'search' },
    {name: 'status', fieldType: "singleSelect", options: convertArrayToOptions(UserStatus), placeholder: "Status", filterType: 'search' },
  ];

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage users")} />
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Role",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/user/create"
                  title={t("Create user")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-users tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
            <div className="TableHeight">
              <TableStructure
                isAction
                history={history}
                headers={UserTableHeader}
                data={usersObj}
                values={columns}
                handleEdit={handleEdit}
                handleArchive={deleteUser}
                handleUnArchive={handleUnArchive}
                permissionType={"Users"}
                handleView={handleView}
                handleSendMail={handleSendMail}
                handleRefresh={handleRefresh}
                mailTooltip={"Send password reset mail"}
              />
            </div>
            <div
              className="pagination d-block"
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>

        <div>
          <div
            className="col-md-6 align-self-center"
            style={{ padding: "0.5vw 0" }}
          >
            <BackButton />
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageUsersPage;
