import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateNumber,
  validatePercentage,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { ApiCall } from "services/ApiServices";
import {
  CREATE_SALARY_BENEFITS,
  GET_SALARY_BENEFITS,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_SALARY_BENEFITS_MANAGE } from "constants/Paths";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputFieldAndUnit } from "../templates/atoms/LabelWithInputFieldAndUnit";
import { ValidationRules } from "utils/TypeAnnotations";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import ModalPopup from "components/atoms/ModalPopup";
import { occurenceFieldOptions, salaryBenefitTypes } from "./salaryBenefitConstants";


interface initialFormData {
  name: string,
  salaryBenefitType: number | null,
  salaryBenefitValue: number | undefined,
  benefitValue: number | undefined,
  occurence: number | null,
  isMandatory: boolean,
  isBenefitGranted: boolean,
  wageCode: string
}

function CreateSalaryBenefits() {
  const [formData, setFormData] = useState<initialFormData>({
    name: "",
    salaryBenefitType: null,
    salaryBenefitValue: undefined,
    benefitValue: undefined,
    occurence: null,
    isMandatory: false,
    isBenefitGranted: false,
    wageCode: ''
  });

  const { id } = useParams<{ id: string }>();
  const [showSalaryBenefitValues, setShowSalaryBenefitValues] = useState(true);
  const [unit, setUnit] = useState("€");
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({
    name: "",
    salaryBenefitType: "",
    salaryBenefitValue: "",
    benefitValue: "",
    occurence: "",
    isMandatory: "",
    isBenefitGranted: "",
  });
  const [modalShow, setModalShow] = useState(false);  // Move to state

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // if (id) {
    try {
      const editData = { id: id };
      const response = await ApiCall.service(
        GET_SALARY_BENEFITS,
        "POST",
        editData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        if (id) {
          setFormData(response?.data);
          let data = response?.data;
          if (data.salaryBenefitType == 2) {
            setShowSalaryBenefitValues(false);
          }
          if (data.salaryBenefitValue == 2) {
            setUnit("%");
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    // }
  };

  const validateInput = (
    name: string | any,
    value: string | boolean | any,
    isSingleFieldValidation: boolean = false
  ) => {
    let validationRules: ValidationRules = {
      name: [validateRequired],
      salaryBenefitType: [validateRequired],
      salaryBenefitValue: [validateSelectField],
      occurence: [validateRequired],
    };

    const validationErrors = validateForm(
      {
        ...formData,
        [name]: value,
      },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = value;
    if (type === "checkbox") {
      setFormData((prevData: any) => ({ ...prevData, [name]: checked }));
    } else {
      if (name === "benefitValue") {
        updatedValue = updatedValue.replace(/[^0-9,]/g, '');
      }
      setFormData((prevData: any) => ({ ...prevData, [name]: updatedValue }));
    }
    validateInput(name, updatedValue, true);
  };

  const handleSelectChange = (selectedOption: Option, name: string) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: selectedOption.value }));
    if (selectedOption.name === "wagecode") {
      setShowSalaryBenefitValues(false);
    }
    if (selectedOption.name === "premium") {
      setShowSalaryBenefitValues(true);
    }
    if (selectedOption.name === "percentage") {
      setUnit("%");
    }
    if (selectedOption.name === "euro") {
      setUnit("€");
    }
    validateInput(name, selectedOption, true);
  };

  //Save api call
  const saveBenefitData = async () => {
    if (id) {
      Object.assign(formData, { id: id });
    }
    const response = await ApiCall.service(
      CREATE_SALARY_BENEFITS,
      "POST",
      formData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      CustomNotify({
        type: "success",
        message: response?.message,
      });
      navigate(PATH_SALARY_BENEFITS_MANAGE);
    }
  }

  const handleSubmit = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();  // Prevent default form submission
    }

    const isWageCodeValid = formData.wageCode && formData.wageCode !== null;

    if (!isWageCodeValid) {
      setModalShow(true);
      return;
    }

    const isFormValid = validateInput('name', formData.name);

    if (isFormValid) {
      saveBenefitData();
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Salary benefits",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  // Close the popup
  const handleCloseModal = () => {
    setModalShow(false);
  }
  const handleConfirmPopUp = () => {
    setModalShow(false);
    saveBenefitData();
  };

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <TitleAtom
            title={id ? t("Edit salary benefit") : t("Create salary benefit")}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-border">
            <div className="row">
              <div className="form-group col-12">
                <LabelWithInputField
                  isMandatory={true}
                  name="name"
                  handleChange={(e) => handleFieldChange(e)}
                  value={formData.name}
                  id="name"
                  label={t("Name")}
                  placeholder={t("Name")}
                  type="text"
                  error={errors.name}
                />
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <SelectWithSearch
                    title={t("Salary benefit types")}
                    name="salaryBenefitType"
                    id="salaryBenefitType"
                    isMandatory={true}
                    search={true}
                    options={salaryBenefitTypes}
                    placeHolder="Select"
                    value={formData.salaryBenefitType}
                    onChange={(e) =>
                      handleSelectChange(e, "salaryBenefitType")
                    }
                    isMulti={false}
                    className="select-field"
                    containerClassName="col-md-12 position-relative"
                    error={errors.salaryBenefitType}
                  />
                </div>
                <div className="col">
                  <LabelWithInputField
                    isMandatory={false}
                    name="wageCode"
                    handleChange={(e) => handleFieldChange(e)}
                    value={formData.wageCode}
                    id="wageCode"
                    label={t("Wage code")}
                    placeholder={t("Wage code")}
                    type="text"
                  />
                </div>
                {showSalaryBenefitValues && (
                  <div className="form-group col-md-6">
                    <SelectWithSearch
                      title={t("Salary benefit value")}
                      name="salaryBenefitValue"
                      id="salaryBenefitValue"
                      isMandatory={true}
                      search={true}
                      options={[
                        { name: "euro", label: "Salary in euro", value: 1 },
                        {
                          name: "percentage",
                          label: "Salary in percentage",
                          value: 2,
                        },
                      ]}
                      placeHolder="Select"
                      value={formData.salaryBenefitValue}
                      onChange={(e) =>
                        handleSelectChange(e, "salaryBenefitValue")
                      }
                      isMulti={false}
                      className="select-field"
                      containerClassName="col-12 position-relative"
                      error={errors.salaryBenefitValue}
                    />
                  </div>
                )}
                {showSalaryBenefitValues && (
                  <div className="form-group col-md-6">
                    <LabelWithInputFieldAndUnit
                      label={t("Benefit value")}
                      labelClassName="form-label field-label"
                      name="benefitValue"
                      id="benefitValue"
                      handleChange={(e) => handleFieldChange(e)}
                      value={formData.benefitValue}
                      placeholder={t("Select")}
                      className="form-control field-shadow"
                      type="text"
                      error={errors.benefitValue}
                      // unit="€"
                      unit={unit}
                      containerClassName="col-12 position-relative"
                    />
                  </div>
                )}
                <div className="form-group col-md-6">
                  <SelectWithSearch
                    title={t("Occurence")}
                    name="occurence"
                    id="occurence"
                    isMandatory={true}
                    search={true}
                    options={occurenceFieldOptions}
                    placeHolder="Select"
                    value={formData.occurence}
                    onChange={(e) => handleSelectChange(e, "occurence")}
                    isMulti={false}
                    className="select-field"
                    error={errors.occurence}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <CheckBoxField
                    name="isMandatory"
                    label="Is this mandatory?"
                    isChecked={formData.isMandatory}
                    id="isMandatory"
                    onChangeHandler={handleFieldChange}
                    lineHeight="1.5vw"
                  />
                </div>
                <div className="form-group col-md-6">
                  <CheckBoxField
                    name="isBenefitGranted"
                    label="Is the benfit granted in case of absence of the employee?"
                    isChecked={formData.isBenefitGranted}
                    id="isBenefitGranted"
                    onChangeHandler={handleFieldChange}
                    lineHeight="1.5vw"
                  />
                </div>
              </div>
            </div>
          </div>
          {
            modalShow && <ModalPopup
              show={modalShow}
              onHide={handleCloseModal}
              title="Wage code "
              body={t("Wage is not filled")}
              onConfirmButtonClick={handleConfirmPopUp}
              confirmTitle="Ok"
            />
          }

          <div className="d-flex justify-content-between my-3 align-items-center">
            <div className="">
              <div className="">
                <BackButton />
              </div>
            </div>
            <div className="">
              <Button
                title={t("Save")}
                handleClick={handleSubmit}
                className="form-button float-end"
              />
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
}
export default CreateSalaryBenefits;
