import React from "react";

const ArchieveIcon: React.FC = () => {
  return (
    <span title="Archive ">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="-3 0 24 18"
        data-toggle="tooltip"
        height="1.5vw"
        width="1.5vw"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M17.5,2.2 L16.1,0.5 C15.9,0.2 15.5,0 15,0 L3,0 C2.5,0 2.1,0.2 1.8,0.5 L0.5,2.2 C0.2,2.6 0,3 0,3.5 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,3.5 C18,3 17.8,2.6 17.5,2.2 L17.5,2.2 Z M9,14.5 L3.5,9 L7,9 L7,7 L11,7 L11,9 L14.5,9 L9,14.5 L9,14.5 Z M2.1,2 L2.9,1 L14.9,1 L15.8,2 L2.1,2 L2.1,2 Z"></path>
      </svg>
    </span>
  );
};

export default ArchieveIcon;
