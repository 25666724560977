import { FormProvider } from "./Context";
import VacancyForm from "../create-vacancy/form-content/VacancyForm";

const MultiFormProviderVacancy = () => {
  return (
    <>
      <FormProvider>
        <VacancyForm/>
      </FormProvider>
    </>
  );
};

export default MultiFormProviderVacancy;