import React, { ChangeEvent } from "react";

interface Props {
  name?: string;
  value?: string | number;
  ischecked?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: React.ReactNode;
  customStyle?: React.CSSProperties;
  id?: string;
  className?: string;
  containerClassName?: string;
  disable?: boolean;
}

const RadioField: React.FC<Props> = ({
  name = "",
  value = "",
  ischecked = false,
  handleChange,
  label,
  customStyle = {},
  id = "",
  className,
  containerClassName = "d-inline-block w-auto", //default
  disable = false,
}) => {
  return (
    <>
      <div className={containerClassName}>
        <label style={{ cursor: "pointer" }} className={className || ""}>
          <input
            style={{ ...customStyle, ...{ marginRight: "0.5vw" } }}
            type="radio"
            name={name}
            checked={ischecked}
            onChange={disable ? undefined : handleChange}
            id={id}
            value={value}
            // disabled={disable}
            className="form-check-input shadow-none cursor-pointer mt-0"
          />
          <span>{label}</span>
        </label>
      </div>
    </>
  );
};

export default RadioField;
