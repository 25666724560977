import React from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { SET_FIELD_ERROR_GENERAL_TAB, SET_FIELD_GENERAL_TAB } from "../context/Constants";
import Button from "components/atoms/Button";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";

const FunctionWithCapacity: React.FC<any> = (props: any) => {
  const { state, dispatch } = useFormContext();
  const { options, general } = state;
  const { PcFunctions } = options;
  const { selected, tableData, isEdit } = general?.data?.functions ?? {};
  const { functions: functionErrors, capacity: capacityError } = general?.errors ?? {};
  const capacityRegex = /^[0-9]*$/;

  const validateField = (field: string, value: any) => {
    const error = (!value || value?.label?.toLowerCase() === "select") ? t("Field is required") : "";
    dispatch({ type: SET_FIELD_ERROR_GENERAL_TAB, fieldName: field, error });
    return !error;
  };

  const updateFunctionsData = (newData: any) => 
    dispatch({ type: SET_FIELD_GENERAL_TAB, field: "functions", value: newData });

  const handleChange = (value: any, field: string) => {
    if (field === "capacity" && !capacityRegex.test(value)) return;
    const updatedSelected = field === "capacity"
      ? { ...selected, capacity: value }
      : { ...value, capacity: selected?.capacity || 0 };
    updateFunctionsData({ ...general.data.functions, selected: updatedSelected });
    validateField(field, value);
  };

  const addOrUpdateRecord = () => {
    const isValidFunction = validateField("functions", selected?.value);
    const isValidCapacity = validateField("capacity", selected?.capacity);
    if (isValidFunction && isValidCapacity) {
      const existingIndex = tableData?.findIndex((item: any) => item.value === selected?.value);
      const updatedTableData = existingIndex > -1
        ? tableData?.map((item: any, idx: number) => idx === existingIndex ? selected : item)
        : [...tableData ?? [], selected];
      updateFunctionsData({ isEdit: false, tableData: updatedTableData, selected: { value: "", label: "Select", capacity: 0 } });
      props?.getCompetencesOnFunction && props?.getCompetencesOnFunction(updatedTableData?.map((item: any) => item?.value));
    }
  };

  const editRecord = (id: string) => {
    const itemToEdit = tableData?.find((item: any) => item.value === id);
    updateFunctionsData({ ...general.data.functions, selected: itemToEdit, isEdit: true });
  };

  const deleteRecord = (id: string) => {
    const updatedTableData = tableData?.filter((item: any) => item.value !== id);
    updateFunctionsData({ ...general.data.functions, tableData: updatedTableData });
    props?.getCompetencesOnFunction && props?.getCompetencesOnFunction(updatedTableData?.map((item: any) => item?.value));
  };

  // Filter options to exclude already added table data entries
  const filteredOptions = PcFunctions?.filter(
    (option: any) => !tableData?.some((item: any) => item.value === option.value)
  );

  return (
    <form>
      <div className="form-border" style={{ paddingTop: "3vw", paddingBottom: "0.5vw" }}>
        <div className="row">
          <div className="col-sm-5 col-md-5">
            <SelectWithSearch
              search
              onChange={(value) => handleChange(value, "functions")}
              title={t("Functions")}
              placeHolder={t("Select")}
              options={filteredOptions}
              value={selected}
              isMandatory
              error={functionErrors}
              name="functions"
              id="functions"
            />
          </div>
          <div className="col-sm-5 col-md-5">
            <LabelWithInputField
              type="text"
              handleChange={(e) => handleChange(e.target.value, "capacity")}
              label={t("Capacity")}
              value={selected?.capacity}
              error={capacityError}
              isMandatory
              name="capacity"
              id="capacity"
            />
          </div>
          <div className="col-2 searchBtnWrapper">
            <Button
              title={isEdit ? t("Save") : t("Add")}
              className="form-button"
              handleClick={addOrUpdateRecord}
            />
          </div>
        </div>
        {tableData?.length > 0 && (
          <table className="table" style={{ marginBottom: "1.5vw" }}>
            <tbody>
              {tableData?.map((func: any) => (
                <tr key={func.value}>
                  <td className="align-middle" style={{ width: "45%" }}>{func.label}</td>
                  <td className="align-middle" style={{ width: "38%" }}>{func.capacity}</td>
                  <td>
                    <span
                      className="cursor-pointer table-action-btn me-2"
                      onClick={() => editRecord(func.value)}
                    >
                      <EditIcon />
                    </span>
                    <span
                      className="cursor-pointer table-action-btn"
                      onClick={() => deleteRecord(func.value)}
                    >
                      <DeleteIcon />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </form>
  );
};

export default FunctionWithCapacity;
