import React, { useEffect, useState } from "react";
import { useFormContext } from "./context/Context";
import CommonComponent from "./components/Index";
import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import { t } from "pages/microservices/masterData/translation/Translation";
import { getNewTabIndex } from "./components/common/TabService";
import {
  NEXT_TAB,
  PREVIOUS_TAB,
  SET_FORM_ELEMENTS,
  SWITCH_TAB,
  UPDATE_DEPENDENT_TAB,
  UPDATE_FIELD_DATA,
  UPDATE_SALARY_BENEFIT,
  UPDATE_TAB_FIELD,
} from "./context/Constants";
import {
  deletePreviousTableData,
  formateSubmitData,
  getForm,
  savePreviousTableData,
  submitData,
} from "./components/common/FormService";
import { fieldValidator } from "./components/validator/Index";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { updateStateValues } from "./components/common/UpdateService";
import CustomNotify from "components/atoms/CustomNotify";
import { error } from "console";
import Popup from "components/molecules/Popup";
import { PATH_EMPLOYEE_MANAGE, PATH_WAGE_PROPOSAL } from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import GetQueryParams from "services/util/GetQueryParams";
import TitleFieldMolecule from "components/molecules/TitleField";

type MultiTabFormProps = {
  backToManage: string;
  formName: string;
  dataSubmissionEndPoint: string;
  microservice: string;
  dataFetchEndPoint?: string;
  entryId?: string;
  customFn: any;
  formTitle: any;
  dataSubmissionForTableStructure?: any;
  deleteDataOfTableStructure?: any;
};
export const MultiTabForm: React.FC<MultiTabFormProps> = ({
  backToManage,
  formName,
  dataSubmissionEndPoint,
  microservice,
  entryId,
  customFn,
  dataFetchEndPoint,
  formTitle,
  dataSubmissionForTableStructure,
  deleteDataOfTableStructure,
}) => {
  const location = useLocation();
  const user = useSelector(selectAuth);
  const { state, dispatch } = useFormContext();
  const [showModel, setShowModel] = useState(false);
  const [employeeId, setEmployeeId] = useState();
  const [isDraft, setDraftValue] = useState(0);
  const [isBack, setIsBack] = useState(false);

  const tabs = state?.tabs;
  const form = state?.form;
  const currentTab = state?.currentTab;
  const navigate = useNavigate();
  const queryParams = GetQueryParams();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function handleTabSwitch(index: Number) {
    updateTab(index);
  }
  const handlePreviousStep = () => {
    updateTab(getNewTabIndex(tabs, currentTab, tabs.length, PREVIOUS_TAB));
  };



  const handleNextStep = () => {
    // const validationPromises: any = [];
    // form?.forEach((items: any, tabIndex: number) => {
    //   if (tabIndex == currentTab) {
    //     items?.forEach((fields: any, mainIndex: number) => {
    //       if (fields?.create == 'form' && entryId !== undefined) {
    //         return;
    //       }
    //       if (fields?.dynamic == true || fields?.dynamic == false) {
    //         const filteredForms = fields.form?.map(
    //           (innerForm: any, innerFormIndex: number) => {
    //             const innerFormsToValidate = innerForm.filter(
    //               (field: any) =>
    //                 field?.validationRules?.length > 0 && field?.display !== false
    //             );
    //             validationPromises.push(
    //               validateField(
    //                 innerFormsToValidate,
    //                 tabIndex,
    //                 true,
    //                 mainIndex,
    //                 innerFormIndex
    //               )
    //             );
    //           }
    //         );
    //       } else {
    //         const filteredFields = items.filter(
    //           (field: any) =>
    //             field?.validationRules?.length > 0 && field?.display !== false
    //         );
    //         validationPromises.push(validateField(filteredFields, tabIndex));
    //       }
    //     });
    //   }
    // });
    // if (!validationPromises.flat(1).includes(true)) {
    // updateTab(getNewTabIndex(tabs, currentTab, tabs.length, NEXT_TAB));
    // }
    updateTab(getNewTabIndex(tabs, currentTab, tabs.length, NEXT_TAB));
  };

  const updateTab = (index: Number) => {
    dispatch({ type: SWITCH_TAB, field: "currentTab", value: index });
  };

  //On saving previous data in table structure
  const handleSavePreviousData = async (formData: any) => {
    const validationPromises: any = [];
    [[formData]]?.forEach((items: any, mainIndex: number) => {
      items?.forEach((fields: any, subIndex: number) => {
        if (fields?.dynamic == false || fields?.create == 'form') {
          const filteredForms = fields?.form?.map(
            (innerForm: any, innerFormIndex: number) => {
              const innerFormsToValidate = innerForm.filter(
                (field: any) =>
                  field?.validationRules?.length > 0 &&
                  field?.display !== false
              );
              validationPromises.push(
                validateField(
                  innerFormsToValidate,
                  currentTab,
                  true,
                  0,
                  0
                )
              );
            }
          );
        } else {
          const filteredFields = items.filter(
            (field: any) =>
              field?.validationRules?.length > 0 &&
              field?.display !== false
          );
          const results = validateField(filteredFields, currentTab)
          validationPromises.push(results);
        }
      });
    })
    const validationRes = await Promise.all(validationPromises);
    const flatRes = validationRes.flat(Infinity);
    if (!flatRes.includes(true)) {
      const response = await savePreviousTableData(dataSubmissionForTableStructure,
        { data: formData, tab: tabs[currentTab].id, id: entryId },
        microservice, user?.userId);
      if (response?.status == 200) {
        return response;
      }
      else if (response?.status == 400) {
        CustomNotify({ type: "error", message: response?.message });

        const validationPromises: any = [];
        [[response?.data?.data]]?.forEach((items: any, mainIndex: number) => {
          items?.forEach((fields: any, subIndex: number) => {
            if (fields?.dynamic == false || fields?.create == 'form') {
              const filteredForms = fields?.form?.map(
                (innerForm: any, innerFormIndex: number) => {
                  const innerFormsToValidate = innerForm.filter(
                    (field: any) =>{
                      console.log(field);
                      return (field?.validationRules?.length > 0 &&
                        field?.display !== false) || field?.error.length > 0
                });
                  validationPromises.push(
                    validateField(
                      innerFormsToValidate,
                      currentTab,
                      true,
                      0,
                      0
                    )
                  );
                }
              );
            } else {
              const filteredFields = items.filter(
                (field: any) =>
                  (field?.validationRules?.length > 0 &&
                    field?.display !== false) || field?.error.length > 0
              );
              const results = validateField(filteredFields, currentTab)
              validationPromises.push(results);
            }
          });
        })
      }
    }
  }

  //On deleting previous data in table structure
  const handleDeletePreviousData = async (deleteData: any, data: any) => {
    const response = await deletePreviousTableData(deleteDataOfTableStructure,
      { data: deleteData['id'], currentData: data, tab: tabs[currentTab].id, id: entryId },
      microservice);
    if (response?.status == 200) {
      return response;
    }
  }

  const handleSubmit = async () => {
    let data = formateSubmitData(state, "submit", entryId);

    const validationPromises: any = [];
    form?.forEach((items: any, tabIndex: number) => {
      items?.forEach((fields: any, mainIndex: number) => {
        if (fields?.create == 'form' && entryId !== undefined) {
          return;
        }
        if (fields?.dynamic == true || fields?.dynamic == false) {
          const filteredForms = fields.form?.map(
            (innerForm: any, innerFormIndex: number) => {
              const innerFormsToValidate = innerForm.filter(
                (field: any) =>
                  field?.validationRules?.length > 0 && field?.display !== false
              );
              validationPromises.push(
                validateField(
                  innerFormsToValidate,
                  tabIndex,
                  true,
                  mainIndex,
                  innerFormIndex
                )
              );
            }
          );
        } else {
          const filteredFields = items.filter(
            (field: any) =>
              field?.validationRules?.length > 0 && field?.display !== false
          );
          validationPromises.push(validateField(filteredFields, tabIndex));
        }
      });
    });

    const validationRes = await Promise.all(validationPromises);
    const flatRes = validationRes.flat(Infinity);
    if (!flatRes.includes(true)) {
      let data = formateSubmitData(state, "submit", entryId);

      if (
        location.pathname.includes("/employees/create") ||
        location.pathname.includes("/vacancy/create") ||
        location.pathname.includes("/vacancy/edit")
      ) {
        data = {
          ...data,
          loginUserId: user.userId,
        };
      }
      if (
        location.pathname.includes("/employee/edit") &&
        location.search.includes("candidateId")
      ) {
        const candidateId: string = queryParams.get("candidateId") ?? "";
        data = { ...data, candidateId: candidateId };
      }
      let response: any = await submitData(
        1,
        data,
        dataSubmissionEndPoint,
        microservice,
        user?.userId,
      );
      if (response?.status === 200) {
        if (response?.data) {
          setShowModel(true);
          CustomNotify({ type: "success", message: response?.message });
          setEmployeeId(response?.data[1]);
        } else {
          CustomNotify({ type: "success", message: response?.message });
          navigate(backToManage);
        }
      } else if (response?.status == 400) {
        CustomNotify({ type: "warning", message: response?.message });
        const { id, ...updatedData } = response?.data;
        [...Object.values(updatedData)]?.forEach(
          (items: any, tabIndex: number) => {
            Object.values(items)?.forEach((fields: any, mainIndex: number) => {
              if (fields?.dynamic == true || fields?.dynamic == false) {
                const filteredForms = fields.form?.map(
                  (innerForm: any, innerFormIndex: number) => {
                    const innerFormsToValidate = innerForm.filter(
                      (field: any) =>
                      ((field?.validationRules?.length > 0 &&
                        field?.display !== false) || field?.error?.length > 0)
                    );
                    validationPromises.push(
                      validateField(
                        innerFormsToValidate,
                        tabIndex,
                        true,
                        mainIndex,
                        innerFormIndex
                      )
                    );
                  }
                );
              } else {
                const filteredFields = items.filter(
                  (field: any) =>
                  ((field?.validationRules?.length > 0 &&
                    field?.display !== false) || field?.error?.length > 0)
                );
                validationPromises.push(
                  validateField(filteredFields, tabIndex)
                );
              }
            });
          }
        );
      } else {
        console.log("error");
      }
    }
  };

  const handleDraft = async () => {
    const validationPromises: any = [];
    form?.forEach((items: any, tabIndex: number) => {
      items?.forEach((fields: any, mainIndex: number) => {
        if (fields?.create == 'form' && entryId !== undefined) {
          return;
        }
        if (fields?.dynamic == true || fields?.dynamic == false) {
          const filteredForms = fields?.form?.map(
            (innerForm: any, innerFormIndex: number) => {
              const innerFormsToValidate = innerForm.filter(
                (field: any) =>
                  field?.draftValidation === true &&
                  field?.validationRules?.length > 0 &&
                  field?.display !== false
              );
              validationPromises.push(
                validateField(
                  innerFormsToValidate,
                  tabIndex,
                  true,
                  mainIndex,
                  innerFormIndex
                )
              );
            }
          );
        } else {
          const filteredFields = items.filter(
            (field: any) =>
              field?.draftValidation === true &&
              field?.validationRules?.length > 0 &&
              field?.display !== false
          );

          validationPromises.push(validateField(filteredFields, tabIndex));
        }
      });
    });
    const validationRes = await Promise.all(validationPromises);
    const flatRes = validationRes.flat(Infinity);
    if (!flatRes.includes(true)) {
      let data = formateSubmitData(state, "submit", entryId);
      if (
        location.pathname.includes("/employees/create") ||
        location.pathname.includes("/vacancy/create")
      ) {
        data = {
          ...data,
          loginUserId: user.userId,
        };
      }
      let response: any = await submitData(
        0,
        data,
        dataSubmissionEndPoint,
        microservice,
        user.userId
      );
      if (response?.status === 200) {
        navigate(backToManage);
        CustomNotify({ type: "success", message: response?.message });
      }

      else if (response?.status == 400) {
        CustomNotify({ type: "warning", message: response?.message });
        const { id, ...updatedData } = response?.data;
        [...Object.values(updatedData)]?.forEach(
          (items: any, tabIndex: number) => {
            Object.values(items)?.forEach((fields: any, mainIndex: number) => {
              if (fields?.dynamic) {
                const filteredForms = fields.form?.map(
                  (innerForm: any, innerFormIndex: number) => {
                    const innerFormsToValidate = innerForm.filter(
                      (field: any) =>
                      ((field?.validationRules?.length > 0 &&
                        field?.display !== false) || field?.error?.length > 0)
                    );
                    validationPromises.push(
                      validateField(
                        innerFormsToValidate,
                        tabIndex,
                        true,
                        mainIndex,
                        innerFormIndex
                      )
                    );
                  }
                );
              } else {
                const filteredFields = items.filter(
                  (field: any) =>
                    field?.draftValidation === true &&
                    field?.validationRules?.length > 0 &&
                    field?.display !== false
                );
                validationPromises.push(
                  validateField(filteredFields, tabIndex)
                );
              }
            });
          }
        );
      }
      else {
        console.log("error");
      }
    }
  };
  const validateField = (
    filteredFields: any,
    tabIndex: number,
    isDynamic?: boolean,
    mainIndex?: number,
    innerFormIndex?: number
  ) => {
    var err: any = [];
    filteredFields.forEach((field: any) => {
      let validation = fieldValidator(field);
      updateState(
        field.name,
        field?.error !== "" ? field.error : validation?.[field.name],
        "error",
        tabIndex,
        mainIndex,
        isDynamic,
        innerFormIndex
      );
      const error =
        (field?.error !== undefined && field?.error !== "") ||
          validation?.[field.name]
          ? true
          : false;
      // updateTabError(tabIndex, error);
      err.push(error);
    });
    if (err.includes(true)) {
      updateTabError(tabIndex, true);
    }
    return err;
  };

  const updateTabError = (index: number, value: boolean = false) => {
    dispatch({
      type: UPDATE_TAB_FIELD,
      payload: {
        index: index,
        value: value,
      },
    });
  };

  const updateState = (
    field: any,
    value: any,
    valueField: string = "value",
    index: number,
    mainIndex?: number,
    dynamic?: boolean,
    formIndex?: number
  ) => {
    dispatch({
      type: UPDATE_FIELD_DATA,
      payload: {
        field: field,
        value: value,
        index: index,
        mainIndex: mainIndex,
        valueField: valueField,
        dynamic: dynamic,
        formIndex: formIndex,
      },
    });
  };
  const cancel = () => {
    setShowModel(false);
    navigate(PATH_EMPLOYEE_MANAGE);
  };
  const sendLetar = () => {
    navigate(PATH_EMPLOYEE_MANAGE);
  };
  const sendNow = () => {
    navigate(`${PATH_WAGE_PROPOSAL}/${employeeId}`);
  };
  const approveNow = () => {
    navigate(`${PATH_WAGE_PROPOSAL}/${employeeId}?approve=${true}`);
  };
  /**
   * render the multi-tab form
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { tabs, form } = await getForm(formName);
        dispatch({ type: SET_FORM_ELEMENTS, field: "tabs", value: tabs });
        dispatch({ type: SET_FORM_ELEMENTS, field: "form", value: form });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (entryId) {
      let editId = { id: entryId };
      if (
        location.pathname.includes("/employee/edit") &&
        location.search.includes("candidateId")
      ) {
        const candidateId: string = queryParams.get("candidateId") ?? "";
        Object.assign(editId, { candidateId: candidateId });
      }
      const fetchDataForEditing = async (editId: { id: string | number }) => {
        try {
          const response = await ApiCall.service(
            dataFetchEndPoint,
            "POST",
            editId,
            false,
            M_MASTER_DATA
          );
          if (response.status === 200) {
            const data = response.data;
            tabs.forEach(async (tab) => {
              let tabElements: any = state.form[tab.index];
              const tabData = await updateStateValues(
                data[tab.id],
                tabElements,
                tab.index,
                customFn
              );
              dispatch({
                type: UPDATE_DEPENDENT_TAB,
                payload: {
                  value: tabData,
                  index: tab.index,
                },
              });
            });
            setDraftValue(data?.isDrafted);

            const pcDetailsTab = tabs.find((tab) => tab.id === "pcDetails");
            if (pcDetailsTab) {
              const tabElements: any = state.form[4][0];
              const value: any = tabElements.value;
              const payload: any = {
                type: "api-call",
                fieldData: {
                  field: "salary",
                  value,
                },
              };
              const tabData = await customFn(payload);
              dispatch({
                type: UPDATE_SALARY_BENEFIT,
                payload: {
                  value: tabData,
                  index: 4,
                },
              });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchDataForEditing(editId);
    }
  }, [entryId, tabs?.[0]?.index]);

  return (
    <>
      <div className="position-relative">
        <div
          className="row header-sticky position-sticky"
          style={{ marginBottom: "2.3vw", height: "auto" }}
        >
          <div className="col-12">
            <h1
              className="page-title mb-0 d-flex align-items-center"
              style={{ paddingBottom: "0.7vw" }}
            >
              {t(`${formTitle}`)}
            </h1>
          </div>
        </div>
        <nav
          className={`company-step-tabs createFormTabs projectTabs ${isSticky
            ? "createTabsSticky position-sticky"
            : "company-step-tabs-absolute position-absolute w-100"
            }`}
        >
          <div
            className="d-flex border-0 w-100 m-auto gap-3"
            id="nav-tab"
            role="tablist"
          >
            <div
              className={`d-flex border-0 m-auto gap-3 align-items-center justify-content-center jjefejkjnkj`}
            >
              {tabs?.map((item: any, key: any) => (
                <div className={`flex-1 text-center`} key={item?.id}>
                  <div
                    className={`w-100 createStepBtn h-100 cursor-pointer  ${currentTab === item?.index ? "active" : "inactive"
                      } ${item?.error === true ? "error" : ""
                      }`}
                    onClick={() => handleTabSwitch(item?.index)}
                  >
                    {t(item?.title)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </nav>

        <React.Fragment key={state.currentTab}>
          <CommonComponent
            customFn={customFn}
            handleSavePreviousData={handleSavePreviousData}
            handleDeletePreviousData={handleDeletePreviousData}
          />
        </React.Fragment>
        <FormNavigationOrganism
          isFirstTab={currentTab === tabs?.[0]?.index}
          isSubmitTab={currentTab === tabs[state?.tabs?.length - 1]?.index}
          formExitBackTo={backToManage}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          handleSubmit={handleSubmit}
          {...(formName !== 'ParitairComitee' && isDraft == 0 ? { handleDraft } : {})}
        />
        {showModel && (
          <Popup
            body={t("Send proposal")}
            nowSendText={t("Send now")}
            letarSendText={t("Send later")}
            approveNowText={t("Approve now")}
            cancel={cancel}
            sendLetar={sendLetar}
            sendNow={sendNow}
            approveNow={approveNow}
            footerclass="justify-content-between"
          />
        )}
      </div>
    </>
  );
};
