import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";
import CheckBoxField from "components/atoms/CheckBoxField";

interface PremiumMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dependency?: any;
}

const PremiumMolecule: React.FC<PremiumMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
  dependency,
}) => {
  const renderField = () => {
    switch (field.field_name) {
      case "Cold premium":
        return (
          <>
            {dependency?.field_value == "1" ? (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
                unit={t("%")}
              />
            ) : (
              <></>
            )}
          </>
        );
      case "On call duty ($/day)":
        return (
          <>
            {dependency?.field_value == "1" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t("On call duty (€/hour)")}
                value={value}
                handleChange={handleChange}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
                unit={t("€")}
              />
            )}
          </>
        );
      case "Interventiepremie ($/day)":
        return (
          <>
            {dependency?.field_value == "1" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t("Interventiepremie (€/hour)")}
                value={value}
                handleChange={handleChange}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
                unit={t("€")}
              />
            )}
          </>
        );
      case "Unhealthy & hazardous work":
        return (
          <>
            {dependency?.field_value == "1" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
                unit={t("%")}
              />
            )}
          </>
        );
      default:
        return (
          <div className="row" style={{ paddingBottom: "0.5vw" }}>
            <div className="col-12">
              <CheckBoxField
                label={t(field?.field_name)}
                name={field?.field_name}
                isChecked={field?.field_value == "1"}
                onChangeHandler={handleChange}
                disable={disabled}
                id={field?.field_name}
                lineHeight="1.5vw"
              />
            </div>
          </div>
        );
    }
  };

  return <>{renderField()}</>;
};

export default PremiumMolecule;
