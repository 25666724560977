import React from "react";

const DownloadWageIcon = () => {
  return (
    <span title="Download proposal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="25"
        viewBox="0 0 92 95"
        fill="none"
      >
        <path
          d="M88.7616 23.1L68.0616 2.4C66.4616 0.8 64.4616 0 62.2616 0H28.3616C22.0616 0 16.9616 5.2 16.9616 11.4V34.9H23.4616V11.4C23.4616 8.7 25.6616 6.5 28.3616 6.5H58.3616V27C58.3616 30.3 61.0616 33 64.2616 33H84.7616V80.9C84.7616 83.6 82.5616 85.8 79.8616 85.8H36.9616L31.5616 92.3H79.6616C85.9616 92.3 91.0616 87.2 91.0616 80.9V29C91.1616 26.7 90.3616 24.7 88.7616 23.1Z"
          fill="currentColor"
        />
        <path
          d="M75.8616 74.2C75.8616 76 74.3616 77.4 72.5616 77.4H43.9616C45.4616 75.5 46.1616 73.2 45.9616 70.9H72.5616C74.3616 70.9 75.8616 72.4 75.8616 74.2Z"
          fill="currentColor"
        />
        <path
          d="M75.8616 59.3C75.8616 61.1 74.3616 62.6 72.5616 62.6H35.5616V56.1H72.5616C74.3616 56.1 75.8616 57.6 75.8616 59.3Z"
          fill="currentColor"
        />
        <path
          d="M75.8616 44.5C75.8616 46.3 74.3616 47.8 72.5616 47.8H35.5616V44.1C35.5616 43.2 35.4616 42.2 35.1616 41.4C35.2616 41.4 35.3616 41.4 35.4616 41.4H72.5616C74.3616 41.3 75.8616 42.7 75.8616 44.5Z"
          fill="currentColor"
        />
        <path
          d="M36.8616 69H29.2616V44.1C29.2616 42.6 28.0616 41.3 26.4616 41.3H13.0616C11.5616 41.3 10.2616 42.5 10.2616 44.1V69H2.76157C0.361575 69 -0.838425 71.7 0.661575 73.5L17.7616 94C18.8616 95.3 20.8616 95.3 22.0616 94L38.9616 73.5C40.4616 71.7 39.2616 69 36.8616 69Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default DownloadWageIcon;
