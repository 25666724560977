import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import LabelField from "components/atoms/LabelField";
import ModalPopup from "components/atoms/ModalPopup";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import { ADD_CERTIFICATE, REMOVE_CERTIFICATE } from "pages/microservices/CandidateCreation/annotations/CandidateConstants";
import { certificates } from "pages/microservices/CandidateCreation/annotations/CandidateInterface";
import { useFormContext } from "pages/microservices/CandidateCreation/context/Context";
import Calender from "pages/microservices/CandidateCreation/helpers/Calender";
import { formatDate } from "pages/microservices/CandidateCreation/helpers/CandidateHelperFunctions";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useState } from "react";
import { validateDate, validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService";
import DeleteIcon from "static/images/DeleteIcon";
import EditIcon from "static/images/EditIcon";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { ValidationRules } from "utils/TypeAnnotations";


const Certificates: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [formData, setFormData] = useState<certificates>({
    id: null,
    attestType: null,
    group: null,
    documentNumber: '',
    uploadCertificate: {
      accessUrl: '',  // Initialize with an empty string
      fileName: '',   // Initialize with an empty string
    },
    validFrom: null,
    validUntil: null,
    info: '',
    active: 0,
  });

  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      attestType: [validateSelectField],
      group: [validateSelectField],
      documentNumber: [validateRequired],
      validFrom: [validateDate],
      validUntil: [validateDate]
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: REMOVE_CERTIFICATE, indexToRemove });
  };

  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const addData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };

  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
    const certificateToEdit =
      state.certificates[index]; // Get the certificate to edit
    if (certificateToEdit) {
      // Populate the  data in the modal fields
      setFormData({
        id: certificateToEdit.id,
        attestType: certificateToEdit.attestType,
        group: certificateToEdit.group,
        documentNumber: certificateToEdit.documentNumber,
        uploadCertificate: {
          accessUrl: certificateToEdit.uploadCertificate?.accessUrl || '', // Ensure the accessUrl is set correctly
          fileName: certificateToEdit.uploadCertificate?.fileName || '',   // Ensure the fileName is set correctly
        },
        validFrom: certificateToEdit.validFrom,
        validUntil: certificateToEdit.validUntil,
        info: certificateToEdit.info,
        active: certificateToEdit.active,
      });
    }
  };

  const resetFields = () => {
    setFormData({
      id: null,
      attestType: null,
      group: null,
      documentNumber: '',
      uploadCertificate: {
        accessUrl: '',  // Initialize with an empty string
        fileName: '',   // Initialize with an empty string
      }, validFrom: null,
      validUntil: null,
      info: '',
      active: 0,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    const { name, value, type, checked } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: ADD_CERTIFICATE,
        data: formData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };

  const handleFileChange = async (event: any) => {

    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
    }
    const fileName = event.name;
    const index = fileName.lastIndexOf(".");
    const name = index !== -1 ? fileName.slice(0, index - 1) : fileName;
    const extension = index !== -1 ? fileName.slice(index) : "";
    setFormData((prevData: any) => ({
      ...prevData,
      uploadCertificate: fileData,
    }));
    validation("uploadCertificate", fileData, true);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
    validation(name, date, true);
  };

  return (
    <>
      <div>
        <ModalPopup
          show={modal}
          onHide={closeModal}
          title={t("Certficates")}
          body={
            <>
              <div className="row">
                <div className="col">
                  <SelectWithSearch
                    title={t("Attest type")}
                    isMandatory={true}
                    search={true}
                    options={state.dropDownData.attestTypeList}
                    onChange={(e) => handleSelectChange(e, "attestType")}
                    isMulti={false}
                    name="attestType"
                    value={formData.attestType}
                    error={errors.attestType}
                    placeHolder="Select"
                  />
                </div>
                <div className="col">
                  <SelectWithSearch
                    title={t("Group")}
                    isMandatory={true}
                    search={true}
                    options={state.dropDownData.groupList}
                    onChange={(e) => handleSelectChange(e, "group")}
                    isMulti={false}
                    name="group"
                    value={formData.group}
                    error={errors.group}
                    placeHolder="Select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <LabelWithInputField
                    isMandatory={true}
                    name="documentNumber"
                    handleChange={handleFieldChange}
                    id="documentNumber"
                    label="Document number"
                    type="text"
                    value={formData.documentNumber}
                    error={errors.documentNumber}
                  />
                </div>
                <div className="col fileUploadFullWidth">
                  <MaxiconFileUpload
                    label={t('Upload certificate')}
                    name='uploadCertificate'
                    id={t('uploadCertificate')}
                    isMandatory={false}
                    edit={false}
                    fileId={null}
                    fileObj={JSON.stringify(formData.uploadCertificate)}
                    multiple={false}
                    uploadPath="candidate-certificate"
                    formats={'value.formats'}
                    handleChange={handleFileChange}
                    maxFileSize={10000}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Calender
                    onChange={(date) => handleDateChange(date, "validFrom")}
                    label={t('Valid from')}
                    isMandatory={true}
                    name={'validFrom'}
                    error={errors.validFrom}
                    selectedDate={formData.validFrom}
                    maxDate={formData.validUntil}
                  ></Calender>
                </div>
                <div className="col">
                  <Calender
                    onChange={(date) => handleDateChange(date, "validUntil")}
                    label={t('Valid until')}
                    isMandatory={true}
                    name={'validUntil'}
                    error={errors.validUntil}
                    selectedDate={formData.validUntil}
                    minDate={formData.validFrom}
                  ></Calender>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaMolecule
                    label={t('Info')}
                    name={t('info')}
                    id={t('info')}
                    placeholder={"info"}
                    handleChange={handleFieldChange}
                    isMandatory={false}
                    value={formData.info}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <CheckBoxField
                    label={t("Active")}
                    name="active"
                    onChangeHandler={handleFieldChange}
                    isChecked={formData.active === 1}
                    className="document-checkbox"
                    id="active"
                    lineHeight="1.5vw"
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end">
                <Button
                  title={t('Add')}
                  handleClick={onOkButtonClick}
                  className="form-button"
                />
              </div>
            </>
          }
          className="modal-lg contractPerfCodeModal"
        />
      </div >
      <div>
        <div className="row">
          <div className="col-12">
            <LabelField title={t("Certificates")} className="tab-subtitle" />
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader bg-white">
                    <th className="border-0 align-middle col">
                      {t("Attest type")}
                    </th>
                    <th className="border-0 align-middle col">
                      {t("Group")}
                    </th>
                    <th className="border-0 align-middle col">
                      {t("Document number")}
                    </th>
                    <th className="border-0 align-middle col">
                      {t("Valid")}
                    </th>
                    <th className="border-0 align-middle col">
                      {t("Valid until")}
                    </th>
                    <th className="border-0 align-middle col">
                      {t("Status")}
                    </th>
                    <th
                      className="border-0 align-middle col">
                      {t("Action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.certificates && state.certificates.length > 0 && state.certificates.map(
                    (certificate, index) => (
                      <tr key={index}>
                        <td
                          className="align-middle text-break"
                          data-label={t("Attest type")}
                        >
                          {certificate.attestType?.label}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Group")}
                        >
                          {certificate.group?.label}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Document number")}
                        >
                          {certificate.documentNumber}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Document number")}
                        >
                          {formatDate(certificate.validFrom)}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Document number")}
                        >
                          {formatDate(certificate.validUntil)}
                        </td>
                        <td className="align-middle text-break">
                          <span
                            className={`d-flex align-items-center rounded-circle status-icon ${certificate.active === 0
                              ? "bg-danger"
                              : "bg-success"
                              }`}
                            data-toggle="tooltip"
                            title={t(`${certificate.active ? "Active" : "Inactive"}`)}
                          ></span>
                        </td>
                        <td className="align-middle text-break table-action-icons">
                          <span
                            title={t("Edit")}
                            onClick={() => editData(index)}
                            className="table-action-btn cursor-pointer shadow-none marginRightPoint5"
                          >
                            <EditIcon />
                          </span>
                          <span
                            title={t("Delete")}
                            onClick={() => removeFieldSet(index)}
                            className="table-action-btn cursor-pointer shadow-none"
                          >
                            <DeleteIcon />
                          </span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-12">
            <Button
              title={"+ " + t("Add")}
              handleClick={() =>
                addData(state.certificates.length)
              }
              className="form-button float-end"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificates;