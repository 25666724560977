import React from 'react'
import { dateUtils } from '../utils/dateUtils';

// <PlanningBox data={"a"} bg_color={"red"} />

const PlanningBox = ({ width, date, employee_id, bg_color, color, handleWeeklyClickEventOnPlannedUnit, planid='' }) => {
  const isWeekend = dateUtils.checkIsWeekend(date);
  return (
        <span className="d-flex align-items-center justify-content-center" 
          style={{ 
          width: `${width}`,
          height: "2vw", 
          background: isWeekend ? "#a2a2a270" : (bg_color ? "#a2a2a23d" : "#ebebeb"), 
          color: `${color ? color : "white"}`,
          borderRadius:"0"
        }}
          onClick={(e) => handleWeeklyClickEventOnPlannedUnit(e, employee_id, date, planid)}
          date={date}
          employee_id={employee_id}
          > 
        </span>
    );
}

export default PlanningBox
