import React, { useState, useEffect } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import { ApiCall } from "services/ApiServices";
import "static/css/email-template.css";
import { GET_TEMPLATE_DATA } from "routes/ApiEndpoints";
import { t } from "../translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_MASTER_DATA,
  PATH_TEMPLATE_EDIT,
  PATH_TEMPLATE_VIEW,
} from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/atoms/Button";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

const ITEMS_PER_PAGE = 10;
interface Template {
  id: number;
  title: string;
}

const ManageTemplatesPage: React.FC = () => {
  const [searchData, setSearchData] = useState({
    name: "",
  });
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const fetchAllTemplates = async () => {
    try {
      const response = await ApiCall.getService(
        GET_TEMPLATE_DATA,
        "POST",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setTemplates(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    setCurrentPage(1);
  };

  const filteredTemplates = templates.filter((template) =>
    template.title.toLowerCase().includes(searchData.name.toLowerCase())
  );

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = filteredTemplates.slice(startIndex, endIndex);
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Templates",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="search-bar">
            <Title title={t("Manage templates")} />
            <div className="row">
              <div className="col-8">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Search")}
                  name="name"
                  value={searchData.name}
                />
              </div>
            </div>
          </div>
          <div className="position-relative tableMainWrapper mt-3">
            <div className="table-responsive tableSection">
              <div className="table-responsive manage-email-template">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th className="ps-lg-4">{t("Template name")}</th>
                      <th className="table_actions">
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Templates",
                              update: true,
                              read: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                          override={userData.isSuperAdmin}
                        >
                          {t("Action")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((template, index) => (
                        <tr
                          key={template.id}
                          className="mb-3 box-shadow align-middle"
                        >
                          <td
                            className="text-break ps-lg-4 col-md-8"
                            data-label="Name"
                          >
                            {template.title}
                          </td>
                          <td className="table-action-icons px-2">
                            <div className="d-none d-md-none d-lg-block">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Templates",
                                    update: true,
                                  },
                                ]}
                                override={userData.isSuperAdmin}
                              >
                                <Button
                                  title={<EditIcon />}
                                  className={
                                    "table-action-btn border-0 p-0 me-2"
                                  }
                                  handleClick={() =>
                                    navigate(
                                      `${
                                        PATH_TEMPLATE_EDIT + "/" + template.id
                                      }`
                                    )
                                  }
                                  tooltip={t("Edit")}
                                />
                              </AccessControl>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Templates",
                                    read: true,
                                  },
                                ]}
                                override={userData.isSuperAdmin}
                              >
                                <Button
                                  title={<ViewIcon />}
                                  className={
                                    "table-action-btn border-0 p-0 me-2"
                                  }
                                  handleClick={() =>
                                    navigate(
                                      `${PATH_TEMPLATE_VIEW}/${template.id}`
                                    )
                                  }
                                  tooltip={t("View")}
                                />
                              </AccessControl>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={4} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">
                            {t("No records")}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(
                    filteredTemplates.length / ITEMS_PER_PAGE
                  )}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>

          <div
            className="col-md-6 align-self-center"
            style={{ padding: "1vw 0" }}
          >
            <BackButton />
          </div>
        </div>
      </>
    </AccessControl>
  );
};

export default ManageTemplatesPage;
