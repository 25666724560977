import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import Button from "components/atoms/Button";
import {
  validateAlphaNumeric,
  validateEmail,
  validateMaxLength,
  validateMultiSelectField,
  validatePhoneNumber,
  validateSelectField,
  validateTextFieldAlpha,
  validateMinLength,
  validateRequired,
  scrollToTop,
  validateDate,
  validateVatNumber,
  validateNoSpaces,
} from "services/validation/ValidationService";
import LoadingIcon from "utils/LoadingIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import BackButton from "components/atoms/BackButton";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
  formVisible?: boolean;
}
export const Navigation: React.FC<NavProps> = ({
  validStatus,
  isLoading = false,
  formVisible,
}) => {
  const navigate = useNavigate();
  const isViewMode = window.location.search.includes("mode=view");
  const { state, dispatch } = useFormContext();
  const validateMandatoryFields = (type: string) => {
    const activeTab = state.tabDetails.find((tab) => tab.showStatus);
    const activeTabId = activeTab?.id;

    const mandatoryFieldMapping: { [key: string]: string[] } =
      type === "draft"
        ? {
            basic: ["companyName", "vat"],
          }
        : {
            basic: [
              "companyName",
              "website",
              "officialName",
              "pc",
              "vat",
              "naceCodes",
              "companyType",
              ...(state.basic.isBussinessUnit ? ["bussinessUnit"] : []),
            ],
            address: [
              "street",
              "number",
              "box",
              "zipCode",
              "country",
              "city",
              "biStreet",
              "biNumber",
              "biBox",
              "biZipCode",
              "biCountry",
              "biCity",
            ],
            companyLocation: [
              "locationName",
              "locationPhoneNumber",
              "locationStreet",
              "locationNumber",
              //  "locationBox",
              "locationZipCode",
              "locationCountry",
              "locationCity",
            ],
            contacts: ["fName", "lName", "email", "mobileNumber"],
          };

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string } } =
      {};
    state.tabDetails
      .slice(0, state.tabDetails.findIndex((tab) => tab.id === activeTabId) + 1)
      .forEach((tab) => {
        const tabId = tab.id;
        let tabFields = {};

        if (tabId === "basic") {
          tabFields = state.basic;
        } else if (tabId === "address") {
          tabFields = state.address;
        } else if (tabId === "companyLocation") {
          tabFields = state.companyLocation;
        } else if (tabId === "contacts") {
          tabFields = state.contacts;
        }

        const validationRules: {
          [fieldName: string]: ((value: any) => string | null | undefined)[];
        } = {
          vat: [
            validateRequired,
            (value: string) => validateVatNumber(value),
            // validateAlphaNumeric,
             (value: string) => validateMaxLength(value, 15),
            // (value: string) => validateMinLength(value, 11),
            validateNoSpaces,
          ],
          companyName: [validateRequired],
          officialName: [validateRequired],
          // pc: [validateMultiSelectField],
          // naceCodes:[validateMultiSelectField],
          // location: [validateMultiSelectField],
          // website: [validateRequired],
          companyType: [validateSelectField],
          bussinessUnit: [validateSelectField],
          street: [validateRequired],
          number: [validateRequired],
          zipCode: [validateRequired],
          city: [validateRequired],
          country: [validateSelectField],
          biStreet: [validateRequired],
          biZipCode: [validateRequired],
          biNumber: [validateRequired],
          biCity: [validateRequired],
          biCountry: [validateSelectField],
          fName: [validateRequired, validateTextFieldAlpha],
          lName: [validateRequired, validateTextFieldAlpha],
          email: [validateRequired, validateEmail],
          mobileNumber: [validatePhoneNumber],
          locationName: [validateRequired],
          locationStreet: [validateRequired],
          locationNumber: [validateRequired],
          //locationBox: string;
          locationZipCode: [validateRequired],
          locationCountry: [validateSelectField],
          locationCity: [validateRequired],
          locationPhoneNumber: [validateRequired],
          //locationInfo: string,
          //linkedIn: [validateRequired],
        };

        const mandatoryFields = (mandatoryFieldMapping[tabId] ||
          []) as string[];

        const errorsInTab: { [fieldName: string]: string } = {};
        for (const fieldName of mandatoryFields) {
          const fieldValidationRules = validationRules[fieldName];

          if (
            tabId === "contacts" &&
            state.companyContactPersonTable.length > 0
          ) {
            const contact = state.contacts;
            // Check if all fields in the contact are empty
            const allFieldsEmpty = Object.values(contact).every(
              (value) =>
                value === "" ||
                value === null ||
                value === undefined ||
                (Array.isArray(value) && value.length === 0)
            );
            if (!allFieldsEmpty) {
              // Proceed with validation if not all fields are empty
              const fieldValue = contact[fieldName as keyof typeof contact];
              const contactErrors: string[] = [];

              if (fieldValidationRules) {
                for (const rule of fieldValidationRules) {
                  const validationError = rule(fieldValue as any);

                  if (validationError) {
                    contactErrors.push(validationError);
                    errorsInTab[fieldName] = validationError;

                    dispatch({
                      type: "UPDATE_CONTACTS_FIELD_ERROR",
                      field: fieldName,
                      error: validationError,
                    });
                    break;
                  } else {
                    dispatch({
                      type: "UPDATE_CONTACTS_FIELD_ERROR",
                      field: fieldName,
                      error: "",
                    });
                  }
                }
              }
            }
          } else if (
            tabId === "companyLocation" &&
            state.companyLocationTable.length > 0
          ) {
            const companyLocation = state.companyLocation;

            // Check if all fields in the contact are empty
            const allLocationFieldsEmpty = Object.values(companyLocation).every(
              (value) =>
                value === "" ||
                value === null ||
                value === undefined ||
                (Array.isArray(value) && value.length === 0)
            );

            if (!allLocationFieldsEmpty) {
              // Proceed with validation if not all fields are empty
              const fieldValue =
                companyLocation[fieldName as keyof typeof companyLocation];
              const companyLocationErrors: string[] = [];

              if (fieldValidationRules) {
                for (const rule of fieldValidationRules) {
                  const validationError = rule(fieldValue as any);

                  if (validationError) {
                    companyLocationErrors.push(validationError);
                    errorsInTab[fieldName] = validationError;

                    dispatch({
                      type: "UPDATE_COMPANY_LOCATION_FIELD_ERROR",
                      field: fieldName,
                      error: validationError,
                    });
                    break;
                  } else {
                    dispatch({
                      type: "UPDATE_COMPANY_LOCATION_FIELD_ERROR",
                      field: fieldName,
                      error: "",
                    });
                  }
                }
              }
            }
          } else {
            const fieldValue = tabFields[fieldName as keyof typeof tabFields];

            if (fieldValidationRules) {
              for (const rule of fieldValidationRules) {
                const validationError = rule(fieldValue);

                if (validationError) {
                  errorsInTab[fieldName] = validationError;
                  dispatch({
                    type: "UPDATE_FIELD_ERROR",
                    fieldName: fieldName,
                    error: validationError,
                  });
                  break;
                } else {
                  dispatch({
                    type: "UPDATE_FIELD_ERROR",
                    fieldName: fieldName,
                    error: "",
                  });
                }
              }
            }
          }
        }
        if (Object.keys(errorsInTab).length > 0) {
          errorsByTab[tabId] = errorsInTab;
          return false;
        }
      });
    updateTabsStatus(errorsByTab);
    if (Object.keys(errorsByTab).length > 0) {
      return false;
    }

    return true;
  };

  // Function to find an object by its 'id' property
  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab) => tab.id === idToFind);
  };

  const updateTabsStatus = (errors: object) => {
    const keysToGet = ["basic", "address", "companyLocation", "contacts"];
    const selectKeys = keysToGet
      .map((key) => {
        if (errors.hasOwnProperty(key)) {
          const tabIndex = findTabObjectById(key);
          if (tabIndex !== undefined && tabIndex !== null) {
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex, error: true });
          }
          return tabIndex;
        }
        return null;
      })
      .filter((key) => key !== null);
    const tabIndexes = [0, 1, 2];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: index, error: false });
      }
    });
  };

  const handleNextStep = () => {
    dispatch({ type: "NEXT_STEP" });
  };

  const handlePreviousStep = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: true,
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: update });
    const valid = validateMandatoryFields("draft");
    if (!valid) {
      validStatus({ isValid: false, type: "draft" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "draft" });
    }
  };
  //This will be invoked on final submit and provides overall validation
  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: update });
    const valid = validateMandatoryFields("submit");
    if (!valid) {
      validStatus({ isValid: false, type: "submit" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "submit" });
    }
  };

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isBasicTab = currentTab && currentTab.id === "basic";
  const isContactTab = currentTab && currentTab.id === "contacts";
  const isLocationTab = currentTab && currentTab.id === "companyLocation";
  const companyStatus = state.basic.companyStatus;

  return (
    <>
      <div className="row" style={{ padding: "1vw 0" }}>
        <div className="col-md-6 align-self-center">
          {!isBasicTab ? (
            <Button
              type="button"
              title={t("Back")}
              handleClick={handlePreviousStep}
              className="btn backBtn p-0 text-decoration-underline text-uppercase border-0"
            />
          ) : (
            <BackButton />
          )}
        </div>

        {!isViewMode && (
          <div className="col-md-6">
            {!isLoading ? (
              <Button
                type="submit"
                title={isContactTab ? t("Submit") : t("Next")}
                handleClick={isContactTab ? handleSubmit : handleNextStep}
                className="form-button float-end"
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#00a5ce"
                className="ms-auto mb-3"
                height="3vw"
                width="3vw"
              />
            )}
            {/* {companyStatus !== 1 && !isContactTab && (
              <Button
                type="submit"
                title={isContactTab ? t("Submit") : t("Next")}
                handleClick={isContactTab ? handleSubmit : handleNextStep}
                className="form-button float-end"
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#00a5ce"
                className="ms-auto mb-3"
                height="3vw"
                width="3vw"
              />
            )}
            {/* {companyStatus !== 1 && !isContactTab && (
                <Button
                  type="submit"
                  title="save as draft"
                  handleClick={handleDraft}
                  className="btn float-end form-button px-3 shadow-none  text-uppercase"
                />
              )} */}
          </div>
        )}
      </div>
    </>
  );
};
