import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Option } from "react-multi-select-component";

export interface DateInfoProps {
  type: string;
  value: number;
  label: string;
  year: number;
}
interface AuthSate {
  token: string;
  email: string;
  isAuthenticated: boolean;
  refreshToken: string | undefined;
  userId: number | null;
  role: string[];
  name: string;
  userPermissions: any;
  isSuperAdmin: boolean;
  user_language: any;
  languageOptions: any,
  employeeId:number|null,
  dateInfo: DateInfoProps,
  businessUnit: any,
}
const initialDate = new Date();
const initialState: AuthSate = {
  token: "",
  email: "",
  isAuthenticated: false,
  refreshToken: "",
  userId: null,
  employeeId:null,
  role: [],
  name: "",
  userPermissions: [],
  isSuperAdmin: false,
  user_language: null,
  languageOptions: [],
  businessUnit: [],
  dateInfo: { 
    type: 'month', value: initialDate.getMonth() + 1,
    label: initialDate.toLocaleString('default', { month: 'long' })+" "+initialDate.getFullYear(),
     year: initialDate.getFullYear() 
  },
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUserDetails: (state, action: PayloadAction<object>) => {
      return { ...state, ...action.payload };
    },
    removeUserDetails: (state, action: PayloadAction<void>) => {
      return initialState;
    },
    addUserProfilePic: (state, action: PayloadAction<string>) => { },
  },
});

export const { addUserDetails, removeUserDetails } = AuthSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default AuthSlice.reducer;
