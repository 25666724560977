import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import Button from "components/atoms/Button";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate } from "react-router-dom";
import {
  PATH_ADD_CONTRACT_TEMPLATE,
  PATH_CLONE_CONTRACT_TEMPLATE,
  PATH_MASTER_DATA,
  PATH_VIEW_CONTRACT_TEMPLATE,
} from "constants/Paths";
import { Option } from "utils/TypeAnnotations";
import {
  DELETE_CONTRACT_TEMPLATE,
  GET_CONTRACT_TEMPLATES_DATA,
  GET_DROPDOWN_DATA,
} from "routes/ApiEndpoints";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { ManageContractTemplateHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

interface DropdownDataProps {
  contractType: Option[] | null;
  projectType: Option[] | null;
  documentType: Option[] | null;
}
interface SearchData {
  category: any;
  type: any;
  documentType: any;
}

const ManageContractTemplate = ({}) => {
  const userData = useSelector(selectAuth);

  const [dropdownData, setDropdownData] = useState<DropdownDataProps>({
    contractType: null,
    projectType: null,
    documentType: null,
  });

  const [manageData, setManageData] = useState({});
  const [submit, setSubmit] = useState(true);
  const [selectedOption, setSelectedOption] = useState({
    category: null,
    type: null,
    documentType: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    category: "",
    type: "",
    documentType: "",
  });
  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    ApiCall.getService(GET_DROPDOWN_DATA, "GET", M_MASTER_DATA).then(
      (response) => {
        setDropdownData((prevData) => ({
          ...prevData,
          contractType: response.data.contractTypes,
          projectType: response.data.projectTemplate,
          documentType: response.data.documentTypes,
        }));
      }
    );
  }, []);

  useEffect(() => {
    const postData = {
      search: search,
      page: currentPage,
    };
    ApiCall.service(
      GET_CONTRACT_TEMPLATES_DATA,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    ).then((manageResponse) => {
      if (manageResponse && manageResponse.status === 200) {
        setManageData(manageResponse?.data);
        setTotalPages(manageResponse?.totalPages);
      }
    });
  }, [submit, currentPage]);
  const createQueryParams = (data: SearchData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };
  // Calculate the total number of pages
  const [totalPages, setTotalPages] = useState<number>(0);
  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({
        category: "",
        type: "",
        documentType: "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }
    setSubmit((prevSubmit) => !prevSubmit);
  };

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSearch((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "category") {
      setSearch((prevData) => ({
        ...prevData,
        type: "",
        documentType: "",
        // category: "",
      }));
    }
  };
  const handleDelete = (entities: any) => {
    setShowDeleteModal(true);
    setDeleteId(entities.id);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleSaveChanges = async () => {
    setShowDeleteModal(false);
    if (deleteId) {
      const id = {
        id: deleteId,
      };
      const response = await ApiCall.service(
        DELETE_CONTRACT_TEMPLATE,
        "POST",
        id,
        false,
        M_MASTER_DATA
      );
      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: response?.message,
        });
      } else {
        CustomNotify({ type: "success", message: "unableto delete" });
      }
      setSubmit(!submit);
    }
  };

  //Handle page Changes
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //Handle reset & search buttons
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };
  const handleView = (item: any) => {
    navigate(`${PATH_VIEW_CONTRACT_TEMPLATE}/${item.id}`);
  };

  const handleEdit = (item: any) => {
    navigate(`${PATH_ADD_CONTRACT_TEMPLATE}/${item.id}`);
  };

  const handleClone = (item: any) => {
    navigate(`${PATH_CLONE_CONTRACT_TEMPLATE}/${item.id}`);
  };
  const getTypeOptions = (): Option[] => {
    if (search.category === "1") {
      return dropdownData.projectType ?? [];
    } else if (search.category === "2") {
      return dropdownData.contractType ?? [];
    } else {
      return [];
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Contract template",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage contract templates")} />
          <div className="row align-items-end">
            <div className="col-3">
              <select
                className="form-select field-shadow form-control"
                name="category"
                id="category"
                onChange={handleDropdownChange}
                value={search?.category}
              >
                <option value="">{t("Select category")}</option>
                {[
                  { value: 1, label: "Employee" },
                  // { value: 3, label: "Temp Agency" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="col-3">
              <select
                className="form-select field-shadow form-control"
                name="type"
                id="type"
                onChange={handleDropdownChange}
                value={search.type}
              >
                {getTypeOptions() && getTypeOptions().length > 0 ? (
                  <>
                    <option value="">{t("Select  type")}</option>
                    {getTypeOptions().map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">{t("No contract type available")}</option>
                )}
              </select>
            </div> */}
            {search.category === "1" && (
              <div className="col-3">
                <select
                  className="form-select field-shadow"
                  name="documentType"
                  id="documentType"
                  onChange={handleDropdownChange}
                  value={search.documentType}
                >
                  {dropdownData.documentType &&
                  dropdownData.documentType.length > 0 ? (
                    <>
                      <option value="">{t("Select document type")}</option>
                      {dropdownData.documentType.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option value="">{t("No contract type available")}</option>
                  )}
                </select>
              </div>
            )}
            <div className="col-3">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Contract template",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink={PATH_ADD_CONTRACT_TEMPLATE}
                  title={t("Add contract template")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive tableSection">
            <div className="TableHeight">
              <DynamicTableStructure
                isAction
                headers={ManageContractTemplateHeader}
                data={manageData}
                handleView={handleView}
                handleEdit={handleEdit}
                handleClone={handleClone}
                handleDelete={handleDelete}
                permission={"Contract template"}
              />
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showDeleteModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
        {/* Back Button */}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ padding: "1vw 0" }}
        >
          <BackButton />
        </div>
      </>
    </AccessControl>
  );
};
export default ManageContractTemplate;
