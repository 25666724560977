import React from "react";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_APPLICATION_CONFIGURATION } from "constants/Paths";
import { useLocation, useParams } from "react-router-dom";
import {
  GET_CONFIG_DATA,
  GET_ENTITY,
  MASTER_DATA_CONFIG_FORM_ELEMENT,
  SAVE_CONFIG_DATA,
} from "routes/ApiEndpoints";
import { AppConfigsValidation } from "services/validation/ValidationRules";
import ConfigFormBuilder from "services/form/ConfigFormBuilder";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface FormProps {
  id?: number | string;
}
const EditAppConfig: React.FC<FormProps> = () => {
  const parms = useParams();
  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Application configuration",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <ConfigFormBuilder
          actionType={"edit"} // action type create or edit
          entryId={parms.id} // required only if actionType is edit
          getDataAPI={GET_CONFIG_DATA} //required only if actionType is edit
          getFormUrl={MASTER_DATA_CONFIG_FORM_ELEMENT} //Post's data to this endpoint
          SaveDataAPI={SAVE_CONFIG_DATA} //Post's data to this endpoint
          formName={parms.element} //Form name
          title={"Edit"} // Form title
          redirect={PATH_APPLICATION_CONFIGURATION} // After submit redirect to this screen/route
          microserviceName={M_MASTER_DATA} //micro service
          validationRules={AppConfigsValidation} //Validation rules to validate form on submit
        />
      </>
    </AccessControl>
  );
};

export default EditAppConfig;
