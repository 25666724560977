import React from "react";

const addedProjectIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M0.875 0H13.125C13.3571 0 13.5796 0.112279 13.7437 0.312137C13.9078 0.511996 14 0.783062 14 1.0657V21.4665C14.0001 21.5618 13.9792 21.6553 13.9395 21.7374C13.8999 21.8196 13.8428 21.8872 13.7744 21.9333C13.7059 21.9795 13.6286 22.0024 13.5504 21.9998C13.4722 21.9972 13.396 21.969 13.3297 21.9183L7 17.0832L0.67025 21.9173C0.604072 21.9679 0.527964 21.996 0.449839 21.9987C0.371714 22.0014 0.294425 21.9786 0.226008 21.9325C0.157591 21.8865 0.100543 21.819 0.0607975 21.7371C0.0210514 21.6551 5.8162e-05 21.5617 0 21.4665V1.0657C0 0.783062 0.0921874 0.511996 0.256282 0.312137C0.420376 0.112279 0.642936 0 0.875 0Z"
          fill="black"
        />
      </svg>
    </span>
  );
};
export default addedProjectIcon;
