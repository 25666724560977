import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import SideMenuBar from "../atoms/SideMenuBar";
import { addUserDetails, selectAuth } from "../../features/auth/AuthSlice";
import { ApiCall } from "services/ApiServices";
import { GET_USER_PERMISSIONS } from "routes/ApiEndpoints";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import { useDispatch } from "react-redux";


export const NewRootLayout = () => {


  const navigate = useNavigate();
  const authData = useSelector(selectAuth);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!authData.isAuthenticated) {
      navigate('/login')
      // navigate("/")
    }
  }, [navigate, authData]);


  useEffect(() => {
    getUserPermissions();
  }, [])

  const getUserPermissions = async () => {
    const userId = { id: authData.userId };
    const api_call = await ApiCall.service(
      GET_USER_PERMISSIONS,
      "POST",
      userId,
      false,
      M_IDENTITY_MANAGER
    ).then((response) => {
      if (response && response.status === 200) {
        const userPermissions = {
          userPermissions: response.data.permission,
          isSuperAdmin: response.data.isSuperAdmin
        }
        dispatch(addUserDetails(userPermissions));
      }
      else {
        console.error(response.message);
      }
    })
  }

  return (
    <>
  <div className="container-fluid">
        <div className="d-flex">
          <SideMenuBar />
          <div className="page-width">
            <div
              className="page-header position-sticky"
              style={{ paddingLeft: "0.35vw", paddingRight: "0.25vw" }}
            >
              <Header />
            </div>
            <div className="body-height bg-white">
              <div
                style={{ paddingLeft: "0.35vw", paddingRight: "0.25vw" }}
                className="mainWrapper"
              >
                <Outlet /> 
                {/* {/ This is where nested routes are rendered /} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  );
};
export default NewRootLayout
