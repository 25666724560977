import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { ADD_TO_PROJECT, GET_MATCHING_OPTIONS, REMOVE_FROM_PROJECT,} from "routes/ApiEndpoints";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
} from "services/validation/ValidationService";
import CustomNotify from "components/atoms/CustomNotify";

interface AddProjectModalProps {
  handleClose: () => void;
  url?: any;
  removeStatus?: any;
  handleRefresh?: () => void;
}
interface ValidationRules {
  [key: string]: Function[];
}
const AddProjectModal: React.FC<AddProjectModalProps> = ({
  handleClose,
  url,
  removeStatus,
  handleRefresh,
}) => {
  const urlParts = url.split("/");
  let employeeId = 0;
  let projectId = 0;
  let companyId = 0;
  employeeId = parseInt(urlParts[urlParts.length - 1]);
  if (url.includes("matching")) {
    projectId = parseInt(urlParts[urlParts.length - 3]);
    companyId = parseInt(urlParts[urlParts.length - 2]);
  }
  const [selectedOptions, setSelectedOptions] = useState({
    company: [],
    project: [],
  });
  const [options, setOptions] = useState({
    company: [],
    project: [],
  });
  const [error, setError] = useState({
    company: null,
    project: null,
  });

  const validateInput = (selectedOption: any, fieldName: string): boolean => {
    const validationRules: ValidationRules = {}; // Initialize an empty object
    if (url.includes('matching')) {
      validationRules.company = [validateRequired];
      validationRules.project = [validateRequired];
    } else {
      validationRules.company = [validateMultiSelectField];
      validationRules.project = [validateMultiSelectField];
    }
    const formData = { ...selectedOptions, [fieldName]: selectedOption };
    const validationErrors = validateForm(formData, validationRules);
    setError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validationErrors[fieldName],
    }));
    return !validationErrors[fieldName];
  };

  const handleAddClick = () => {
    const isCompanySelected = validateInput(selectedOptions.company, "company");
    const isProjectSelected = validateInput(selectedOptions.project, "project");
    if (isCompanySelected && isProjectSelected) {
      const postData: any = {
        employeeId: employeeId,
        companyId: url?.includes("matching") ? selectedOptions?.company?.[0] : selectedOptions?.company,
        projectId: url?.includes("matching") ? selectedOptions?.project?.[0] : selectedOptions?.project,
        category: "Project",
      };
      if (removeStatus) {
        ApiCall.service(REMOVE_FROM_PROJECT, "POST", postData, true, M_MASTER_DATA)
          .then((response) => {
            if (response?.status === 200) {
              CustomNotify({
                type: "success",
                message: response.message,
              });
            }
        });
      } else {
        ApiCall.service(ADD_TO_PROJECT, "POST", postData, true, M_MASTER_DATA)
          .then((response) => {
            if (response?.status === 200) {
              CustomNotify({
                type: "success",
                message: response.message,
              });
            }
        });
      }
      handleRefresh && handleRefresh();
      handleClose();
    }
  };

  const handleSelectChange = async (selectedOption: any, fieldName: string) => {
    let value = selectedOption?.map((item: any) => item?.value);
    let selected = { ...selectedOptions, [fieldName]: value };
    switch (fieldName) {
      case "company":
        let postData = {
          companyId: value,
          addtoproject: true,
          matching: url?.includes("matching")
        }
        let response: any = await fetchCompanyAndProjectOption(postData, true);
        setOptions((prevOptions: any) => ({
          ...prevOptions,
          project: response?.projects ?? [],
        }));
        break;
      default:
        break;
    };
    setSelectedOptions(selected);
    validateInput(value, fieldName)
  };

  useEffect(() => {
    const postData = {
      employeeId,
      companyId: companyId ? [companyId] : [],
      projectId: projectId ? [projectId] : [],
      addtoproject: true, initialCall: true,
      matching: url?.includes("matching")
    };
    fetchCompanyAndProjectOption(postData);
  }, [companyId, employeeId]);

  const fetchCompanyAndProjectOption = async (postData: any, onChange = false) => {
    try {
      const response = await ApiCall.service(GET_MATCHING_OPTIONS, "POST", postData, false, M_MASTER_DATA);
      if (response?.status === 200) {
        if (onChange) return response?.data;
        setOptions((prevOptions: any) => ({
          ...prevOptions,
          company: response?.data?.companies ?? [],
          project: response?.data?.projects ?? [],
        }));
        if (postData?.initialCall) {
          setSelectedOptions((prevOptions: any) => ({
            ...prevOptions,
            company: response?.data?.companies?.filter((item: any) => item?.status === true)?.map((item: any) => item?.value) ?? [],
            project: response?.data?.projects.filter((item: any) => item?.status === true)?.map((item: any) => item?.value) ?? [],
          }));
        }
      }
    } catch (error) {
      return [];
    }
  };

  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="bitter-italic-normal-medium-24">
          {t("Add Project")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <SelectWithSearch
            title={t("Company")}
            name="company"
            isMandatory={true}
            search={true}
            options={options?.company}
            placeHolder="Select"
            value={selectedOptions?.company ?? []}
            onChange={(e) => handleSelectChange(e, "company")}
            isMulti={true}
            className="select-field"
            error={error?.company || ""}
            isDisabled={url.includes("matching")}
          />
        </div>
        <div className="">
          <SelectWithSearch
            title={t("Project")}
            name="project"
            isMandatory={true}
            search={true}
            options={options?.project}
            placeHolder="Select"
            value={selectedOptions?.project ?? []}
            onChange={(e) => handleSelectChange(e, "project")}
            isMulti={true}
            className="select-field"
            error={error?.project || ""}
            isDisabled={url.includes("matching")}
          />
        </div>
        <div className="row  align-items-center">
          <div className="col-6 ">
            <p
              className=" text-uppercase  mb-0 cursor-pointer"
              onClick={handleClose}
            >
              {t("Cancel")}
            </p>
          </div>
          <div className="col-6 ">
            <Button
              title={removeStatus ? t("Remove") : t("Add")}
              handleClick={handleAddClick}
              className="btn form-button float-end text-uppercase rounded-0 shadow-none"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProjectModal;
