import React, { useEffect, useState, Suspense } from "react";
import { ApiCall } from "services/ApiServices";
import { MANAGE_COMPANY } from "routes/ApiEndpoints";
import ManageCompaniesPage from "./ManageCompaniesPage";
import { useNavigate } from "react-router-dom";
import { M_COMPANY_CREATION } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";

export interface CompanyData {
  id?: number;
  name: string;
  status: any;
  cs: any;
  type: any;
  officialName?: string;
  vatNumber?: string;
  businessUnits?: any;
  get_bussiness_unit?: any;
  location?: any;
  city?: any;
  email?: any;
  phoneNumber?: any;
  companyType?: any;
  hotlist?: boolean;
  isHotlist?: boolean;
  active?: boolean;
  zipCode?: any;
  country?: any;
  buIds?:any;
}


const ManageCompanies: React.FC = () => {
  const userData = useSelector(selectAuth);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [options, setOptions] = useState({
    businessUnits: [], countries: []
  });
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: false,
    initialCall: true,
  });

  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: {
        companyType: history?.filters?.companyType ?? "",
        businessUnits: history?.filters?.businessUnits ?? [],
        name: history?.filters?.name ?? "",
        vatNumber: history?.filters?.vatNumber ?? "",
        zipCode: history?.filters?.zipCode ?? "",
        city: history?.filters?.city ?? "",
        country: history?.filters?.country ?? "",
        email: history?.filters?.email ?? "",
        phoneNumber: history?.filters?.phoneNumber ?? "",
        cs: history?.filters?.cs ?? "",
        orderBy: history?.filters?.orderBy ?? "id",
        sortDirection: history?.filters?.sortDirection ?? "desc",
      },
      initialCall: pageData?.initialCall,
    };
    fetchCompaniesDetails(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchCompaniesDetails = async (data: any) => {
    const response = await ApiCall.service(MANAGE_COMPANY, "POST", data, false, M_COMPANY_CREATION);
    if (response?.status === 200) {
      setCompanies(response?.data);
      if (pageData?.initialCall) {
        setOptions((prev: any) => ({ ...prev, ...response?.options}));
      }
      setPageData((prev: any) => ({ ...prev, totalPages: response.totalPages, totalRecords: response?.totalRecords, initialCall: false}));
    }
  };

  const handleDeleteData = (deleteId: number) => {
    // setCompanies((prevdata) =>
    //   prevdata.filter((company) => company.id !== deleteId)
    // );
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
  };

  const handelAddOrRemoveHotlist = (companyId: number, type: boolean) => {
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <Suspense
          fallback={<div className="text-center text-danger"></div>}
        >
          <ManageCompaniesPage
            pageData={pageData}
            compObj={companies}
            handleRefresh={handleRefresh}
            dispatch={dispatch}
            history={history}
            deleteFilter={handleDeleteData}
            options={options}
            addOrRemoveHotlist={handelAddOrRemoveHotlist}
          />
        </Suspense>
      </>
    </AccessControl>
  );
};
export default ManageCompanies;
