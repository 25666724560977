import React, { useEffect, useState } from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PaginationButton from "components/atoms/PaginationButton";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";

interface PaginationProps {
    handleRefresh?: () => void;
    maxVisiblePages?: number;
    itemsPerPageOptions?: number[];
    dispatch: any;
    history: any;
    pageData: any;
}

const PaginationWithPerPage: React.FC<PaginationProps> = ({
    handleRefresh,
    maxVisiblePages = 3,
    itemsPerPageOptions = [10, 20, 50, 100],
    dispatch,
    history,
    pageData,
}) => {        
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history?.filters?.currentPage]);

    // Update itemsPerPage state and call the provided function to lift state up
    const handleChange = (event: any, perPage?: boolean) => {
        let filters = {};
        if (perPage) {
            const newItemsPerPage = parseInt(event.target.value);
            filters = { itemsPerPage: newItemsPerPage?.toString(), currentPage: "1" }
            dispatch(updatePageFilters({filters: filters}));
        } else {
            filters = { currentPage: event?.toString() }
            dispatch(updatePageFilters({filters: filters}));
        }        
        handleRefresh && handleRefresh();
    };

    const pageNumbers = Array.from({ length: (Number(pageData?.totalPages ?? 0)) }, (_, index) => index + 1);

    const renderPageNumbers = () => {
        if (pageNumbers.length <= maxVisiblePages) {
            return pageNumbers.map((pageNumber) => (
                <PaginationButton
                    title={pageNumber}
                    key={pageNumber}
                    handleClick={() => handleChange(pageNumber)}
                    className={`btn border-0 page-num ${
                        Number(history?.filters?.currentPage ?? 1) === pageNumber ? "page-active" : ""
                    }`}
                />
            ));
        } else {
            const firstPage = Math.max(1, Number(history?.filters?.currentPage) ?? 1 - Math.floor(maxVisiblePages / 2));
            const lastPage = Math.min(pageNumbers.length, firstPage + maxVisiblePages - 1);
            const pages = [];
            if (firstPage > 1) {
                pages.push(
                    <PaginationButton
                        title={1}
                        key={1}
                        handleClick={() => handleChange(1)}
                        className={`btn border-0 page-num`}
                    />
                );
                if (firstPage > 2) {
                    pages.push(
                        <span key="ellipsis1" className="ellipsis">
                            ...
                        </span>
                    );
                }
            }

            for (let i = firstPage; i <= lastPage; i++) {
                pages.push(
                    <PaginationButton
                        title={i}
                        key={i}
                        handleClick={() => handleChange(i)}
                        className={`btn border-0 page-num ${Number(history?.filters?.currentPage ?? 1) === i ? "page-active" : ""}`}
                    />
                );
            }

            if (lastPage < pageNumbers.length) {
                if (lastPage < pageNumbers.length - 1) {
                    pages.push(
                        <span key="ellipsis2" className="ellipsis">
                            ...
                        </span>
                    );
                }
                pages.push(
                    <PaginationButton
                        title={pageNumbers.length}
                        key={pageNumbers.length}
                        handleClick={() => handleChange(pageNumbers.length)}
                        className={`btn border-0 page-num`}
                    />
                );
            }
            return pages;
        }
    };
    const startItem = (Number(pageData?.totalRecords ?? 0)) > 0 ? ((Number(history?.filters?.currentPage ?? 1)) - 1) * Number(history?.filters?.itemsPerPage ?? 10) + 1 : 0;
    const endItem = Math.min(startItem + (Number(history?.filters?.itemsPerPage ?? 10)) - 1, (Number(pageData?.totalRecords ?? 0)));
    return (
        <>
            <div className="row pagination">
                <div className="col-4 align-self-center">
                    <div className="items-per-page d-flex align-items-center">
                        <label htmlFor="itemsPerPage">Show </label>
                        <div style={{ width: "4vw" }} className="ms-2">
                            <select
                                id="itemsPerPage"
                                value={(Number(history?.filters?.itemsPerPage ?? 10))}
                                onChange={(e) => handleChange(e, true)}
                                aria-label="Items per page"
                                className="form-control form-select field-shadow paginationSelect"
                            >
                                {itemsPerPageOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <span className="ms-2">entries</span>
                    </div>
                </div>
                <div className="col-4 align-self-center">
                    <div className="d-flex align-items-center justify-content-center">
                        {(Number(pageData?.totalPages ?? 0)) > 1 && (
                            <>
                                <PaginationButton
                                    title=""
                                    handleClick={() => {
                                        if ((Number(history?.filters?.currentPage ?? 1)) > 1) { handleChange((Number(history?.filters?.currentPage ?? 1)) - 1); }
                                    }}
                                    className={`btn previous-btn ${(Number(history?.filters?.currentPage ?? 1)) === 1 ? "disabled" : ""}`}
                                    icon={faArrowLeft}
                                    aria-label="Previous page"
                                />
                                {renderPageNumbers()}
                                <PaginationButton
                                    title=""
                                    handleClick={() => {
                                        if (Number(history?.filters?.currentPage ?? 1) < (Number(pageData?.totalPages ?? 0))) { handleChange(Number(history?.filters?.currentPage ?? 1) + 1); }
                                    }}
                                    className={`btn next-btn ${
                                        Number(history?.filters?.currentPage ?? 1) === (Number(pageData?.totalPages ?? 0)) ? "disabled" : ""
                                    }`}
                                    icon={faArrowRight}
                                    aria-label="Next page"
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="col-4 align-self-center text-end">
                    <div>
                        <span className="item-range">
                            <span className="fw-bold">{`${startItem}${" "}-${" "}${endItem}`}</span>
                            <span className="mx-2">of</span>
                            <span className="fw-bold">{`${(Number(pageData?.totalRecords ?? 0))}`}</span>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaginationWithPerPage;
