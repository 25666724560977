import TableStructureComponent from "components/molecules/TableStructureComponent";

export const EditElements = (props: any) => {
    const { data,
        fieldHandler,
        customFn,
        handleButtonClick,
        handleSavePreviousData,
        handleDeletePreviousData } = props;
    switch (data.edit) {
        case "table-structure":
            return <TableStructureComponent
                tableData={props}
                fieldHandler={fieldHandler}
                handleButtonClick={handleButtonClick}
                customFn={customFn}
                handleSavePreviousData={handleSavePreviousData}
                handleDeletePreviousData={handleDeletePreviousData}
            />
        default:
            return <></>
    }
}

