import React, { useEffect, useState } from "react";
import PlanningBox from "../atoms/planningBox";
import { useEmployee } from "../planning-redux/context/EmployeeContext/EmployeeContext";
import { usePlanning } from "../planning-redux/context/PlanningContext/PlanningContext";
import { PLANNING_MODAL_ACTIONS } from "../planning-redux/actions/PlanningModalActions";
import { getRandomInt, renderPlannedEvents } from "../utils/utils";
import { usePlanningsModalDispatch } from "../planning-redux/context/PlanningModalContext/PlanningModalContext";
import { WEEK_KEYWORD } from "../constants/planningConstants";
import { SALES_VIEW_EXCEPTION_LIST } from "../constants/planningConstants";

const PlanningWeekly = ({ weeklyDates, openPlanningModal, type, status }) => {
  const { initialEmployeeState } = useEmployee();
  const { planningState } = usePlanning();
  const { dispatchPlanningModal } = usePlanningsModalDispatch();
  const [params, setParams] = useState({
    employee: "",
    project: "",
  });
  useEffect(() => {
    // console.log(weeklyDates);
    // weeklyDates.map((date, index) => {

    // });
  })
  const handleWeeklyClickEventOnPlannedUnit = (
    e,
    employee_id,
    date,
    planid = ""
  ) => {
    if (SALES_VIEW_EXCEPTION_LIST.includes(employee_id)) return;
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
      payload: {
        employee_id: employee_id,
        start_date: date,
        frequency: "weekly",
      },
    });
    if (planid != "") {
      dispatchPlanningModal({
        type: PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODEL_FIELD,
        payload: {
          field: "id",
          value: planid,
        },
      });
    }
    openPlanningModal(e, planid);
  };

  const setWeeklyPlanningUnit = () => {
    if (
      initialEmployeeState.employees &&
      initialEmployeeState.employees.length > 0
    ) {
      return initialEmployeeState.employees.map((employee) => (
        <tr key={getRandomInt(1, 1000)}>
          {/* <td></td> */}
          {weeklyDates.map((date) => (
            <td
              key={getRandomInt(1, 1000)}
              style={{
                position: "relative",
              }}
            >
              <PlanningBox
                date={date["value"]}
                employee_id={employee.id}
                bg_color={"#ebebeb"}
                handleWeeklyClickEventOnPlannedUnit={
                  handleWeeklyClickEventOnPlannedUnit
                }
              />
              {renderPlannedEvents(
                planningState,
                employee,
                date,
                handleWeeklyClickEventOnPlannedUnit,
                WEEK_KEYWORD
              )}
            </td>
          ))}
          <td></td>
        </tr>
      ));
    }
  };

  return (
    <table className="table border border-white table-bordered weeklyPlaniingTable mb-0">
      <tbody>{setWeeklyPlanningUnit()}</tbody>
    </table>
  );
};

export default PlanningWeekly;
