import CustomNotify from "components/atoms/CustomNotify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import Reset from "static/images/ResetIcon";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Pagination from "components/atoms/Pagination";
import { Spinner } from "react-bootstrap";
import EditIcon from "static/images/EditIcon";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { GET_COEFFICIENT_DEVIATION } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { IconData } from "services/IconData";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";

interface manageData {
  name: string;
  code: string;
  id: number;
}

interface formDataProps {
  code: number | null;
  coeffDevname: string;
}

const ManageCoeffDeviation: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formDataProps>({
    code: null,
    coeffDevname: "",
  });

  const navigate = useNavigate();

  //state for to store the functions linked countries
  const [data, setData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const userData = useSelector(selectAuth);

  useEffect(() => {
    fetchDataBySearch();
  }, []);

  //API call for to fetch Employee types
  const fetchDataBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "coeffDeviation",
    };

    const response = await ApiCall.service(
      GET_COEFFICIENT_DEVIATION,
      "POST",
      requestData,
      false,
      M_MASTER_DATA
    );

    if (response.status === 200) {
      console.log(response.data);
      setData(response.data);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.coeffDevname.length === 0 && formData.code === null) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchDataBySearch();
    } else {
      fetchDataBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      code: null,
      coeffDevname: "",
    });
    setCurrentPage(1);
    fetchDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataBySearch(formData, newPage);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Coefficient deviation",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Coefficient deviations")} />
        <div className="row">
          <div className="col-3">
            <LabelWithInputField
              handleChange={handleFieldChange}
              name="code"
              value={formData.code ?? ""}
              placeholder={t("Coefficient code")}
              type="number"
              errorclass="d-none"
            />
          </div>
          <div className="col-3">
            <LabelWithInputField
              handleChange={handleFieldChange}
              name="coeffDevname"
              value={formData.coeffDevname ?? ""}
              placeholder={t("Coefficient name")}
              errorclass="d-none"
            />
          </div>

          <div className="col-3">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={handleReset} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="table-responsive  tableSection">
          <div className="tableHeightWithoutBtn">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-lg-4" style={{ width: "10%" }}>
                    {t("Code")}
                  </th>
                  <th style={{ width: "60%" }}>{t("Name")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Coefficient deviation",
                        update: true,
                        delete: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                    override={userData.isSuperAdmin}
                  >
                    <th>{t("Actions")}</th>
                  </AccessControl>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="border-0">
                    <td
                      className="text-center border-0 spinner-wrapper"
                      colSpan={3}
                    >
                      <Spinner size="sm" className="me-2" /> {t("Loading")}
                    </td>
                  </tr>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      data.map((item) => (
                        <tr
                          key={item.id}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          {/* {/ <td className="ps-lg-4">{pageIndex++}</td> /} */}
                          <td className="ps-lg-4" data-label={t("Code")}>
                            {item.code}
                          </td>
                          <td data-label={t("Name")}>{t(`${item.name}`)}</td>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Coefficient deviation",
                                update: true,
                                delete: true,
                              },
                            ]}
                            override={userData.isSuperAdmin}
                            actions={true}
                            strict={false}
                          >
                            <td className="table-action-icons px-2">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Coefficient deviation",
                                    update: true,
                                  },
                                ]}
                                override={userData.isSuperAdmin}
                                actions={true}
                                strict={false}
                              >
                                <Button
                                  title={<EditIcon />}
                                  className={"table-action-btn border-0  p-0"}
                                  handleClick={() =>
                                    navigate(`/edit/coeff-deviation/${item.id}`)
                                  }
                                  tooltip={t("Edit")}
                                />
                              </AccessControl>
                            </td>
                          </AccessControl>
                        </tr>
                      ))
                    ) : (
                      <tr className="border-bottom rounded-3">
                        <td
                          colSpan={7}
                          className="border-0 text-center py-3 no-records"
                        >
                          <span className="text-danger w-100 d-block text-center">
                            {t("No records")}
                          </span>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <BackButton />
      </div>
    </AccessControl>
  );
};

export default ManageCoeffDeviation;
