import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { t } from "pages/microservices/masterData/translation/Translation";

const AccessDenied: React.FC = () => {
  const userAuth = useSelector(selectAuth);

  return (
    <div
      className="row d-flex align-items-center justify-content-center welcome-page"
      style={{ minHeight: "calc(100vh - 6.5vw)" }}
    >
      <div className="col-xxl-10 col-xl-11 col-md-12 mx-auto text-center h-100 d-flex align-items-center justify-content-center flex-column">
        <div>
          <div className="access-denied-code pb-4">403</div>
          <div className="access-denied-title pb-xxl-3">
            {t("Access denied")}
          </div>
          <div className="access-denied-content mb-5">
            {t("Oops! You can't access the page you are looking for")}
          </div>
          <Link to="/" className="form-button page-not-found-back-link">
            {t("Go to home page")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
