import React from "react";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import ManageTimesheetOverviewOrganism from "./organisms/ManageTimesheetOverviewOrganism";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export default function ManageTimesheetOverview() {
    const userData = useSelector(selectAuth);
    return (
        <AccessControl
            requiredPermissions={[{
                permission: "Timesheets",
                read: true,
            }]}
            actions={true}
            strict={false}
            override={userData.isSuperAdmin}
        >
            <ManageTimesheetOverviewOrganism />
        </AccessControl>
    );
}
