import Button from 'components/atoms/Button';
import CustomNotify from 'components/atoms/CustomNotify';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import TitleAtom from 'components/atoms/Title';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import LabelWithTextAreaField from 'components/molecules/LabelWithTextAreaField';
import TitleFieldMolecule from 'components/molecules/TitleField';
import { M_MASTER_DATA } from 'constants/Constants';
import { selectAuth } from 'features/auth/AuthSlice';
import Calender from 'pages/microservices/CandidateCreation/helpers/Calender';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CREATE_CONTRACT_PROPOSAL, GET_CONTRACT_PROPOSAL_DATA} from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { validateDate, validateForm,validateRequired, validateSelectField } from 'services/validation/ValidationService';
import { Option, ValidationRules } from 'utils/TypeAnnotations';
import { EmployeeContractProps, initalCreatContractErrors, initialContract, initialDropdownData } from './ContractInterfaces';

const CreateContractProposal: React.FC = () => {
    const queryParam = useParams();
    const userId = queryParam.candidateId;
    const contractId = queryParam.contractId;
    const loginUserDetails = useSelector(selectAuth);
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialContract);
    const [dropdownData, setDropdownData] = useState(initialDropdownData);
    const [errors, setErrors] = useState<{ [key: string]: string }>(initalCreatContractErrors);

    useEffect(() => {
        ApiCall.service(GET_CONTRACT_PROPOSAL_DATA, "POST", { id: contractId }, true, M_MASTER_DATA).then((response) => {
            setDropdownData(response?.dropdownData);
            if (contractId) {
                setFormData(response.data?.data);
            }
        });
    }, []);

    const validation = (
        name: any = null,
        value: any = null,
        isSingleFieldValidation = false
    ) => {
        let validationRules: ValidationRules = {
            startDate: [validateDate],
            employeeContractType: [validateSelectField],
            company: [validateSelectField],
            businessUnit: [validateSelectField],
            paritairComitee: [validateSelectField],
            function: [validateSelectField],
            salary: [validateRequired],
            salaryType: [validateSelectField],
            employmentType: [validateSelectField],
            scheduleType: [validateSelectField],
            shift: [validateSelectField],
            salaryCategory: [validateSelectField],
        };

        const validationErrors = validateForm(
            {
                ...formData,
                [name]: value,
            },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        if (isSingleFieldValidation) {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };

    const handleSelectChange = (selectedOption: Option | null, fieldName: string) => {
        setFormData((prevData: EmployeeContractProps) => ({
            ...prevData,
            [fieldName]: selectedOption,
        }));
        validation(fieldName, selectedOption, true)
    }

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: EmployeeContractProps) => ({
            ...prevData,
            [name]: value
        }));
        validation(name, value, true);
    }

    const handleSave = async (e: React.FormEvent) => {
        const { name, value } = e.target as HTMLInputElement;

        const postData: any = {
            ...formData,
            id: contractId ?? null,
            userId: userId,
            loginUserId: loginUserDetails.userId
        }
        if (validation(name, value)) {
            const response = await ApiCall.service(CREATE_CONTRACT_PROPOSAL, 'POST', postData, false, M_MASTER_DATA);
            if (response.status === 200) {
                CustomNotify({ type: 'success', message: response.message });
                navigate(-1);
            }
        }
    }

    const handleDateChange = (date: Date | null, fieldName: string) => {
        setFormData((prevData: EmployeeContractProps) => ({
            ...prevData,
            [fieldName]: date
        }));
        validation(fieldName, date, true);
    }

    return (
        <>
            <div className="search-bar">
                <TitleFieldMolecule title={contractId ? t("Edit contract proposal") : t("Create contract proposal")} classname='d-block m-auto' />
            </div>
            <div className="form-border" style={{ paddingTop: "3vw" }}>
                <div className="row">
                    <div className="col">
                        <SelectWithSearch
                            title={t("Employee contract type")}
                            isMandatory={true}
                            name="employeeContractType"
                            search={true}
                            options={dropdownData.employeeContractType}
                            onChange={(e) => handleSelectChange(e, "employeeContractType")}
                            isMulti={false}
                            value={formData.employeeContractType}
                            error={errors.employeeContractType}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col">
                        <SelectWithSearch
                            title={t("Business unit")}
                            isMandatory={true}
                            name="businessUnit"
                            search={true}
                            options={dropdownData.businessUnit}
                            onChange={(e) => handleSelectChange(e, "businessUnit")}
                            isMulti={false}
                            value={formData.businessUnit}
                            error={errors.businessUnit}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Company")}
                            isMandatory={true}
                            name="company"
                            search={true}
                            options={dropdownData.company}
                            onChange={(e) => handleSelectChange(e, "company")}
                            isMulti={false}
                            value={formData.company}
                            error={errors.company}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Paritair comitee")}
                            isMandatory={true}
                            name="paritairComitee"
                            search={true}
                            options={dropdownData.paritairComitee}
                            onChange={(e) => handleSelectChange(e, "paritairComitee")}
                            isMulti={false}
                            value={formData.paritairComitee}
                            error={errors.paritairComitee}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Function")}
                            isMandatory={true}
                            name="function"
                            search={true}
                            options={dropdownData.function}
                            onChange={(e) => handleSelectChange(e, "function")}
                            isMulti={false}
                            value={formData.function}
                            error={errors.function}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <h4 className='fw-bold'>Contract term</h4>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Employment type")}
                            isMandatory={true}
                            name="employmentType"
                            search={true}
                            options={dropdownData.employmentType}
                            onChange={(e) => handleSelectChange(e, "employmentType")}
                            isMulti={false}
                            value={formData.employmentType}
                            error={errors.employmentType}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Schedule type")}
                            isMandatory={true}
                            name="scheduleType"
                            search={true}
                            options={dropdownData.scheduleType}
                            onChange={(e) => handleSelectChange(e, "scheduleType")}
                            isMulti={false}
                            value={formData.scheduleType}
                            error={errors.scheduleType}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div className="row">
                    {formData.scheduleType && formData.scheduleType?.label?.toLowerCase() === 'fixed' &&
                        <div className="col-6">
                            <LabelWithInputField
                                isMandatory={false}
                                name="numberOfMonths"
                                handleChange={(e) => handleFieldChange(e)}
                                value={formData.numberOfMonths}
                                id="numberOfMonths"
                                label={t("Number of months")}
                                type="text"
                            />
                        </div>
                    }
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Shift")}
                            isMandatory={true}
                            name="shift"
                            search={true}
                            options={dropdownData.shifts}
                            onChange={(e) => handleSelectChange(e, "shift")}
                            isMulti={false}
                            value={formData.shift}
                            error={errors.shift}
                            placeHolder="Select"
                        />
                    </div>
                    <div className="col-3">
                        <Calender
                            onChange={(date) => handleDateChange(date, "startDate")}
                            label={t('Start date')}
                            isMandatory={true}
                            name={'startDate'}
                            selectedDate={contractId && formData.startDate ? new Date(formData.startDate) : formData.startDate}
                            maxDate={contractId && formData.endDate ? new Date(formData.endDate) : formData.endDate}
                            error={errors.startDate}
                        ></Calender>
                    </div>
                    <div className="col-3">
                        <Calender
                            onChange={(date) => handleDateChange(date, "endDate")}
                            label={t('End date')}
                            isMandatory={false}
                            name={'endDate'}
                            selectedDate={contractId && formData.endDate ? new Date(formData.endDate) : formData.endDate}
                            minDate={contractId && formData.startDate ? new Date(formData.startDate) : formData.startDate}
                        ></Calender>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            title={t("Salary category")}
                            isMandatory={true}
                            name="salaryCategory"
                            search={true}
                            options={dropdownData.salaryCategory}
                            onChange={(e) => handleSelectChange(e, "salaryCategory")}
                            isMulti={false}
                            value={formData.salaryCategory}
                            placeHolder="Select"
                            error={errors.salaryCategory}
                        />
                    </div>
                    <div className="col-4">
                        <LabelWithInputField
                            isMandatory={true}
                            name="salary"
                            handleChange={(e) => handleFieldChange(e)}
                            value={formData.salary}
                            id="salary"
                            label={t("Salary")}
                            type="text"
                            error={errors.salary}
                            placeholder="Salary"
                        />
                    </div>
                    <div className="col-2">
                        <SelectWithSearch
                            title='Salary type'
                            isMandatory={true}
                            name="salaryType"
                            search={true}
                            options={dropdownData.salaryType}
                            onChange={(e) => handleSelectChange(e, "salaryType")}
                            isMulti={false}
                            value={formData.salaryType}
                            error={errors.salaryType}
                            placeHolder="Select"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <LabelWithTextAreaField
                            label={t("Info")}
                            name="info"
                            value={formData.info}
                            placeholder={t("info")}
                            isDisabled={false}
                            isMandatory={false}
                            handleChange={(e) => handleFieldChange(e)}
                        />
                    </div>
                </div>

            </div>
            <div className="row" style={{ padding: "1vw 0" }}>
                <div className="col-md-4 align-self-center">
                    <Link
                        to=""
                        className="back-btn text-decoration-underline"
                        onClick={() => navigate(-1)}
                    >
                        {t("Back")}
                    </Link>
                </div>
                <div className="col-8">
                    <Button
                        type="submit"
                        title={t("Save")}
                        handleClick={handleSave}
                        className="float-end form-button px-3 shadow-none"
                    />
                </div>
            </div>


        </>
    );
}

export default CreateContractProposal;