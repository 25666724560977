import React, { ChangeEvent, useEffect, useState } from "react";
import { manageState, tableHeaders } from "./State";
import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { FETCH_TRANSLATIONS, SAVE_TRANSLATION } from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "./Translation";
import Popup from "components/molecules/Popup";
import ManageFilters from "./ManageFilters";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import Pagination from "components/atoms/Pagination";
import TranslationEditPopup from "./TranslationEditPopup";
import { M_MASTER_DATA } from "constants/Constants";
import TitleFieldMolecule from "components/molecules/TitleField";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

const ManageTranslationsOrganism: React.FC = () => {
  const [state, setState] = useState(manageState);
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);

  useEffect(() => {
    fetchData();
  }, [state?.current_page]);

  const fetchData = async (search?: any) => {
    let postData = {
      limit: state.limit,
      currentPage: search ? 1 : state?.current_page,
      initialCall: state?.initialCall,
      manage: state?.manage,
      language_id: search?.language_id ?? state?.filters?.language_id,
      search: search?.search ?? state?.filters?.search,
    };
    const response = await ApiCall.service(
      FETCH_TRANSLATIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response.status === 200) {
      let data: any = {
        data: response?.data?.data,
        totalPages: response?.data?.pagination?.totalPages,
        offset: response?.data?.pagination?.offset,
        totalCount: response?.pagination?.totalCount,
        initialCall: false,
        current_page: search ? 1 : state?.current_page,
      };
      if (response?.data?.options) {
        data = {
          ...data,
          options: response?.data?.options,
        };
      }
      setState((prevState: any) => ({ ...prevState, ...data }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    let value = e.target ?? e;
    setState((prevState: any) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: value?.value ?? value?.value ?? value,
      },
    }));
  };

  const handlePaginationClick = (pageNumber: number) => {
    setState((prevState: any) => ({
      ...prevState,
      current_page: pageNumber,
    }));
  };

  const handleClear = () => {
    setState((prevState: any) => ({
      ...prevState,
      filters: {
        language_id: userData?.user_language,
        search: "",
      },
    }));
    fetchData({ language_id: userData?.user_language, search: "" });
  };

  const handleEditPopUp = (value: any) => {
    handlePopUpState({
      string_id: value?.string_id,
      showpopup: true,
      newstring: value?.translated_string,
    });
  };

  const handlePopUpState = (values: any, newstring?: any) => {
    setState((prevState: any) => ({
      ...prevState,
      popup: {
        ...prevState.popup,
        editpopup: {
          ...prevState.popup.editpopup,
          string_id: values?.string_id,
          showpopup: values?.showpopup,
          newstring: values?.newstring,
        },
      },
      data: newstring
        ? state?.data?.map((item: any) => {
            if (item?.string_id == state?.popup?.editpopup?.string_id) {
              return {
                ...item,
                translated_string: newstring,
                table: {
                  ...item.table,
                  translated_string: newstring,
                },
              };
            } else {
              return item;
            }
          })
        : state?.data,
    }));
  };

  const handleSave = async (newstring: string) => {
    const postdata = {
      manualUpdate: true,
      string: newstring,
      string_id: state?.popup?.editpopup?.string_id,
      language_id: state?.filters?.language_id,
    };
    try {
      const response = await ApiCall.service(
        SAVE_TRANSLATION,
        "POST",
        postdata,
        false,
        M_MASTER_DATA
      );
      CustomNotify({
        type: response.status === 200 ? "success" : "error",
        message: response.message,
      });
      if (response.status === 200) {
        handlePopUpState(
          { value: null, showpopup: false, status: null },
          newstring
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="search-bar" style={{ paddingBottom: "1vw" }}>
        <Title title={t("Manage translations")} />
        <div className="row">
          <ManageFilters
            handleChange={handleChange}
            options={state?.options}
            data={state?.filters}
            handleSubmit={() => fetchData(true)}
            handleClear={handleClear}
          />
        </div>
      </div>
      <div className="position-relative tableMainWrapper mt-0">
        <div className="tableSection manage-translations">
          <div className="manageTranslation">
            <DynamicTableStructure
              isAction
              data={state.data}
              headers={tableHeaders}
              handleEdit={handleEditPopUp}
              permission={"Translations"}
            />
          </div>
          {state?.totalPages > 1 && (
            <div className="pagination justify-content-center align-items-center">
              <Pagination
                currentPage={state?.current_page}
                totalPages={state?.totalPages}
                onPageChange={handlePaginationClick}
              />
            </div>
          )}
        </div>
      </div>
      {state?.popup?.editpopup?.showpopup && (
        <Popup
          title={t("Edit string")}
          body={
            <TranslationEditPopup
              oldstring={state?.popup?.editpopup?.newstring}
              handleSave={handleSave}
              handleCancel={() =>
                handlePopUpState({
                  value: null,
                  showpopup: false,
                  status: null,
                })
              }
            />
          }
          close={() =>
            handlePopUpState({ value: null, showpopup: false, status: null })
          }
        />
      )}
      <div className="col-md-6 align-self-center my-4">
        <BackButton />
      </div>
    </div>
  );
};

export default ManageTranslationsOrganism;
