import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import { truncateSync } from "fs";
import { t } from "pages/microservices/masterData/translation/Translation";
import { Modal } from "react-bootstrap"
import { renderFields } from "utils/multiTabForm/components/common/DynamicFormService";
import { EDIT_PREVIOUS_DATA_IN_TABLE, UPDATE_PREVIOUS_DATA, UPDATE_STATE_WITH_EMPTY_VALUES } from "utils/multiTabForm/context/Constants";
import { useFormContext } from "utils/multiTabForm/context/Context";

const DynamicPopup = (props: any) => {
    const { form,
        data,
        pageData,
        fieldHandler,
        handleButtonClick,
        customFn,
        handleSavePreviousData,
        handleDeletePreviousData,
        popUpClassName, // Add a default empty string for popUpClassName if not passed
    } = props;
    const { state, dispatch } = useFormContext();

    const currentTab = state?.currentTab;

    const handleHidePopup = () => {
        dispatch({
            type: EDIT_PREVIOUS_DATA_IN_TABLE,
            payload: {
                index: state?.currentTab,
                currentData: [],
                clearPopup: true
            }
        })

        dispatch({
            type: UPDATE_STATE_WITH_EMPTY_VALUES,
            payload: {
                index: currentTab
            }
        });
    }

    const handleSavePopup = async () => {
        try {
            const response = await handleSavePreviousData(pageData);
            if (response?.status == 200) {
                dispatch({
                    type: UPDATE_PREVIOUS_DATA,
                    payload: {
                        data: response.data,
                        index: currentTab,
                    }
                });
                dispatch({
                    type: UPDATE_STATE_WITH_EMPTY_VALUES,
                    payload: {
                        index: currentTab
                    }
                });
                CustomNotify({ type: "success", message: response?.message });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal
            size="lg"
            show={true}
            onHide={handleHidePopup}
            backdrop="static"
            keyboard={false}
            centered
            className={`${popUpClassName} dynamicPopupClass`}
        >
            <Modal.Header closeButton>
                <Modal.Title className="bitter-italic-normal-medium-24">
                    {t(pageData?.title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="d-grid">
                        {(pageData?.editModal == true) ? pageData?.form[0].map((eachItem: any, index: number) => {
                            return <>{renderFields(0, index, eachItem, fieldHandler, handleButtonClick, true, 0)}</>
                        }) : (<></>)}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Button type="button" title={t("Cancel")} className="close-button" handleClick={handleHidePopup} />
                        </div>
                        <div className="col-6 ">
                            <Button type="button" title={t("Save")} className="form-button float-end" handleClick={handleSavePopup} />
                        </div>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    )
}
export default DynamicPopup