import React, { useEffect } from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import { ApiCall } from "services/ApiServices";
import { GET_PARTICULAR_TEMPLATE_DETAILS } from "routes/ApiEndpoints";
import FormSection from "pages/microservices/masterData/templates/FormSection";
import { M_MASTER_DATA } from "constants/Constants";
import { UPDATE_FETCH_DATA, UPDATE_PARAMETER_FORM, UPDATE_PARAMETERS_ERRORS } from "../context/Constants";
import { useParams } from "react-router-dom";
import { projectParametersValidations } from "../Utils";
import { validateForm } from "services/validation/ValidationService";

type EditTemplateProps = {
  templateId?: string;
  fetchStatus?: boolean;
  setFetchStatus?: any;
};

const ProjectParameters: React.FC<EditTemplateProps> = ({
  templateId,
  fetchStatus,
  setFetchStatus,
}) => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const { fetchData } = state.projectParameters.data;

  useEffect(() => {
    // if (!id) {
    //   fetchMasterDataTemplate();
    //   dispatch({
    //     type: UPDATE_FETCH_DATA,
    //     status: true,
    //   })
    //   // setFetchStatus(true);
    // }
    // if (fetchData) {
    //   fetchMasterDataTemplate();
    // }
    if (fetchData) {
      fetchMasterDataTemplate();
    }
  }, [templateId]);

  const fetchMasterDataTemplate = async () => {
    const fetchData = async () => {
      if (templateId) {
        const response = await ApiCall.service(
          GET_PARTICULAR_TEMPLATE_DETAILS,
          "POST",
          { id: templateId },
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          const data: any[] = response.data;
          let sectionsList: { [key: string]: { [key: number]: any } } = {};
          data?.forEach((field: any) => {
            if (!sectionsList[field.form_section_title]) {
              sectionsList[field.form_section_title] = {};
            }
            sectionsList[field.form_section_title][field.field_id] = field;
          });
          dispatch({
            type: "UPDATE_PROJECT_PARAMETERS",
            payload: sectionsList,
          });
          dispatch({
            type: UPDATE_FETCH_DATA,
            status: false,
          });
        }
      }
    };
    fetchData();
  };

  const groupedFormData = state.projectParameters.data || {};

  const handleInputChange = (
    section: any,
    fieldId: any,
    value: any,
    type: any
  ) => {
    let updatedValue = value;
    const fieldData = groupedFormData.sections[section][fieldId];
    let currentValue = fieldData.field_value;

    if (type === "radio") {
      updatedValue = currentValue === "0" ? "1" : "0";
      dispatch({
        type: UPDATE_PARAMETER_FORM,
        section,
        fieldId,
        updatedValue,
      });
      dispatch({
        type: UPDATE_PARAMETER_FORM,
        section,
        fieldId: fieldId,
        updatedValue: currentValue === "1" ? "0" : "1",
      });
      dispatch({
        type: UPDATE_PARAMETER_FORM,
        section,
        fieldId: fieldId === 6 ? 7 : 6,
        updatedValue: currentValue === "1" ? "1" : "0",
      });
      dispatch({
        type: UPDATE_PARAMETER_FORM,
        section,
        fieldId: fieldId === 6 ? 43 : 6,
        updatedValue: currentValue === "1" ? "1" : "0",
      });
    } else if (type === "checkbox") {
      updatedValue = currentValue === "0" ? "1" : "0";
      dispatch({
        type: UPDATE_PARAMETER_FORM,
        section,
        fieldId,
        updatedValue,
      });
    } else {
      dispatch({
        type: UPDATE_PARAMETER_FORM,
        section,
        fieldId,
        updatedValue,
      });
      const validationResult = projectParametersValidations(parseInt(fieldId));
      const validationErrors = validateForm({ [fieldId]: updatedValue }, validationResult);

      dispatch({
        type: UPDATE_PARAMETERS_ERRORS,
        section,
        fieldId,
        value: validationErrors,
      });
    }
  };

  return (
    <>
      {Object.keys(groupedFormData.sections).map(
        (sectionKey: any, key: number) => (
          <FormSection
            key={sectionKey}
            section={sectionKey}
            fields={groupedFormData.sections?.[sectionKey]}
            handleInputChange={handleInputChange}
            templateTitle={groupedFormData.templates.label}
            type={"project"}
          />
        )
      )}
    </>
  );
};

export default ProjectParameters;
