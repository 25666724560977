import {
  validateAmount,
  validateMaxTimeFormat,
  validateMultiSelectField,
  validateNumber,
  validatePercentage,
  validatePhoneNumber,
  validatePhoneNumberIfNotRequired,
  validateRequired,
  validateSelectField,
  validateTimeFormat,
  validateVAT,

} from "services/validation/ValidationService";

export const getRequiredRulesForFieldType = (field: any) => {
  switch (field.type) {
    case "multi-select":
    case "select":
    case "drop-down":
      if (field?.isMultiSelect === true) {
        return validateMultiSelectField;
      } else {
        return validateSelectField;
      }
    case "mobile-number":
      if (field?.required) {
        return validatePhoneNumber;
      } else {      
        return validatePhoneNumberIfNotRequired;
      }
    default:
      return validateRequired;
  }
};

export const getRequiredRuleForVat=(field:any)=>{
  return validateVAT;
}

export const getRequiredRuleForNumber = (field: any) => {
  return validateAmount;
}

export const getRequiredRuleForTime = (field: any) => {
  return validateTimeFormat;
}

export const getRequiredRuleForWeekTime = (field: any) => {
  return validateMaxTimeFormat;
}

export const getRequiredRuleForPercentage=(field:any)=>{
   return validatePercentage;
}