import React, { useEffect, useState } from 'react';
import { ApiCall } from 'services/ApiServices';
import { t } from '../translation/Translation';
import TitleFieldMolecule from 'components/molecules/TitleField';
import { M_MASTER_DATA } from 'constants/Constants';
import EditIcon from 'static/images/EditIcon';
import LinkTo from 'components/atoms/LinkTo';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/atoms/Button';
import CustomNotify from 'components/atoms/CustomNotify';
import ModalPopup from 'components/atoms/ModalPopup';
import ArchieveIcon from 'static/images/ArchiveIcon';
import { useSelector } from 'react-redux';
import { selectPageHistory, updatePageFilters } from 'store/pageHistory/pageHistorySlice';
import PaginationWithPerPage from 'components/molecules/PaginationWithPerPage';
import { useDispatch } from 'react-redux';
import FilterOrganism from 'components/organism/FilterOrganism';
import { ARCHIVE_SALARY_BENEFITS, GET_SALARY_BENEFITS } from 'routes/ApiEndpoints';
import { PATH_SALARY_BENEFITS_CREATE } from 'constants/Paths';
import { ManageSalaryBenefitProps, initialManageSalaryBenefitData, initialPageDetails, occurenceFieldOptions, salaryBenefitTypes, statusOptions } from './salaryBenefitConstants';

const ManageSalaryBenefits = () => {
    const navigate = useNavigate();
    const [manageData, setManageData] = useState<ManageSalaryBenefitProps[]>([initialManageSalaryBenefitData]);
    const [archievePopup, setArchievePopup] = useState(false);
    const [userName, setUserName] = useState('');
    const dispatch = useDispatch();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
    const [pageData, setPageData] = useState(initialPageDetails);
    const [archieveId, setarchieveId] = useState<number | undefined>();

    const getSearchData = () => {
        return {
            name: history?.filters?.name ?? '',
            salaryBenefitType: history?.filters?.salaryBenefitType ?? '',
            wageCode: history?.filters?.wageCode ?? '',
            benefitValue: history?.filters?.benefitValue ?? '',
            occurence: history?.filters?.occurence ?? '',
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
            status: history?.filters?.status ?? "",
        };
    };

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall,
        };
        ApiCall.service(GET_SALARY_BENEFITS, "POST", postData, true, M_MASTER_DATA).then((response) => {
            if (response?.status === 200) {
                const name = response?.userDetails?.first_name + " " + response?.userDetails?.last_name;
                setUserName(name);
                setManageData(response?.data);
                setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }))
            }
        });
    }, [pageData?.refresh, pageData?.initialCall]);

    const filters = [
        { name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
        { name: 'salaryBenefitType', fieldType: "singleSelect", options: salaryBenefitTypes, placeholder: "Salary Benefit Type", filterType: 'search' },
        { name: 'wageCode', fieldType: "text", placeholder: "Wage Code", filterType: 'search' },
        { name: 'benefitValue', fieldType: "text", placeholder: "Benefit Value", filterType: 'search' },
        { name: 'occurence', fieldType: "singleSelect", options: occurenceFieldOptions, placeholder: "Occurrence", filterType: 'search' },
        { name: 'status', fieldType: "singleSelect", options: statusOptions, placeholder: "Status", filterType: 'search' },
    ];

    const handleArcheive = async (id: number | undefined, apiCall?: boolean) => {
        if (apiCall) {
            const url = `${ARCHIVE_SALARY_BENEFITS}/${id}`;
            const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
            if (response?.status === 200) {
                setArchievePopup(false);
                handleRefresh();
                CustomNotify({ type: 'success', message: response?.message });
            }
        } else {
            setArchievePopup(true);
            setarchieveId(id);
        }
    };

    const handleArcheivePopup = () => {
        setArchievePopup(false);
    };

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <>
            <div className="search-bar">
                <TitleFieldMolecule title={t("Manage salary benefits")} />
            </div>

            <div className="position-relative tableMainWrapper mt-5">
                <div className="row">
                    <div className="ManageCreateBtn">
                        <LinkTo
                            pagelink={`${PATH_SALARY_BENEFITS_CREATE}`}
                            title={t("Create salary benefit")}
                            icon={faPlus}
                        />
                    </div>
                </div>
                <div className="table-responsive Manage-salary-benefit-proposal tableSection">
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        handleRefresh={handleRefresh}
                    />
                    <table className="table table-hover">
                        <thead>
                            <tr className="TableHeader">
                                <th>Name</th>
                                <th>Salary Benefit Type</th>
                                <th>Wage Code</th>
                                <th>Benefit Value</th>
                                <th>Occurrence</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {manageData && manageData.length > 0 ? manageData.map((data: any, index: number) => (
                                <tr key={index}>
                                    <td>{data?.name}</td>
                                    <td>{data?.salaryBenefitType}</td>
                                    <td>{data?.wageCode}</td>
                                    <td>{data?.benefitValue}</td>
                                    <td>{data?.occurence}</td>
                                    <td>
                                        <Link
                                            to={`${PATH_SALARY_BENEFITS_CREATE}/${data.id}`}
                                            className="back-btn text-decoration-underline"
                                        >
                                            <EditIcon />
                                        </Link>
                                        {data?.status !== 2 && <Button
                                            title={<ArchieveIcon />}
                                            className={"table-action-btn border-0  p-0 me-2"}
                                            handleClick={() => handleArcheive(data?.id)}
                                            tooltip={t("Archive")}
                                        />}
                                    </td>
                                </tr>
                            )) : (
                                <tr className="border">
                                    <td colSpan={6} className="border-0 text-center py-3">
                                        <span className="text-danger fw-bold">No records</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <PaginationWithPerPage
                        handleRefresh={handleRefresh}
                        dispatch={dispatch}
                        history={history}
                        pageData={pageData}
                    />
                </div>
            </div>
            {archievePopup && (
                <ModalPopup
                    show={archievePopup}
                    onHide={handleArcheivePopup}
                    title={t("Archive confirmation")}
                    body={t("Are you sure want to archive?")}
                    confirmTitle={t("Yes")}
                    closeTitle={t("No")}
                    onConfirmButtonClick={() => handleArcheive(archieveId, true)}
                    onCloseButtonClick={handleArcheivePopup}
                />
            )}
            <div className="row my-3">
                <div className="col-md-6 align-self-center">
                    <Link
                        to=""
                        className="back-btn text-decoration-underline"
                        onClick={() => navigate(-1)}
                    >
                        {t("Back")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ManageSalaryBenefits;
