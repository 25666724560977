import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "../../static/css/siidebar.css";
import HeaderMenus from "./HeaderMenu";

const SideMenuBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <nav
      className={`main-menu ${isExpanded ? "expanded" : ""}`}
      style={{ paddingTop: "0.8vw" }}
    >
      <div className="scrollbar" id="style-1">
        <div className="sidebarLogo">
          <a href="/dashboard">
            {isExpanded ? (
              <img
                src="/static/images/logo.svg"
                alt="logo"
                className="img-fluid expanded-sidebar-logo"
              />
            ) : (
              <img
                src="/static/images/favicon.ico"
                alt="logo"
                className="img-fluid sidebar-logo"
              />
            )}
          </a>
        </div>
        {/* {/ Pass isExpanded as a prop /} */}
        <HeaderMenus isExpanded={isExpanded} />
        <ul className="list-unstyled position-absolute sidebarIcon mb-0">
          <li className="pb-4">
            <button
              onClick={handleToggleMenu}
              className="sidebar-icon d-flex align-items-center justify-content-center ms-auto me-3"
            >
              <span className="d-flex align-items-center">
                {isExpanded ? (
                  <FontAwesomeIcon icon={faAngleLeft} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </span>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SideMenuBar;
