import { PATH_APPLICANT_CREATE } from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GET_USER, UPDATE_USER, REGISTER } from "routes/ApiEndpoints";
import AccessControl from "services/AccessControl";
import FormBuilder from "services/form/FormBuilder";
import { CandidateValidation } from "services/validation/ValidationRules";
import {validateSelectField} from "services/validation/ValidationService";

interface MUserCreationFormProps {
  id?: number | string;
}

const CreateApplicant: React.FC<MUserCreationFormProps> = ({ id }) => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();
  const permission = path === PATH_APPLICANT_CREATE ? "create" : "view";
  const permissionObject: any = {
    permission: "Candidates",
  };
  permissionObject[permission] = true;
  const CandidateData = useSelector(selectAuth);
  const viewMode = location.pathname.includes("view");
  
  const customFunction = (formInputs: any, selectedOption: any) => {
    const selectedValue = selectedOption.label ? (selectedOption?.label).toLowerCase() === "temporary" ?? selectedOption.dependencyValue: false;
    if (selectedValue) {
      formInputs.map((record: any) => {
        return Object.keys(record).map((key: string) => {          
          if (key === "show") {
            record.type = "multi-select";
            Object.assign(CandidateValidation, { tempAgency: [validateSelectField] })
          }
        });
      })
    } else {
      formInputs.map((record: any) => {
        return Object.keys(record).map((key: string) => {
          if (key === "show") {
            record.type = "text-hidden";
            record.value = "";
            const keyToCheck = "tempAgency";
            if (keyToCheck in CandidateValidation) {
              // Remove tempAgency key
              delete CandidateValidation?.tempAgency;
            }            
          }
        });
      })
    }
  }
  
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={CandidateData.isSuperAdmin}
    >
      <FormBuilder
        actionType={path === PATH_APPLICANT_CREATE ? "create" : "view"} // action type create or view
        entryId={id || routeId} // required only if actionType is view
        getDataAPI={GET_USER} //required only if actionType is view
        SaveDataAPI={path === PATH_APPLICANT_CREATE ? REGISTER : UPDATE_USER} //Post's data to this endpoint
        formName={"Applicant"} //Form name
        formType={path === PATH_APPLICANT_CREATE ? "create" : "view"}
        title={
          path === PATH_APPLICANT_CREATE ? "Create applicant" : "View applicant"
        } // Form title
        redirect={"/manage-applicants"} // After submit redirect to this screen/route
        validationRules={CandidateValidation} //Validation rules to validate form on submit
        mode={viewMode}
        customFunction={customFunction}
      />
    </AccessControl>
  );
};

export default CreateApplicant;
