import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  APPROVE_WAGE_PROPOSAL,
  GENERATE_WAGE_PROPOSAL,
  REJECT_WAGE_PROPOSAL,
  SEND_WAGE_PROPOSAL_MAIL,
} from "routes/ApiEndpoints";
import { MANAGE_WAGE_PROPOSAL, PATH_ACCESS_DENIED } from "constants/Paths";
import LoadingIcon from "utils/LoadingIcon";
import { t } from "../translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import EncryptDecryptService from "services/EncryptDecryptService";
import EmployerAuthenticModal from "pages/microservices/project/Proposals/EmployerAuthenticModal";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import BackButton from "components/atoms/BackButton";

interface WageProposalData {
  businessUnit: any;
  userId: any;
  wageId: any;
  approve: any;
  reject?: any;
}

const WageProposal: React.FC = () => {
  const url = new URL(window.location.href);
  const dataParam = url.searchParams.get("data");

  const [parsedData, setParsedData] = useState<WageProposalData | null>(null);
  const [loading, setLoading] = useState(false);
  const [htmlPath, setHtmlPath] = useState("");
  const [checked, setChecked] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showCheckBox, setShowCheckbox] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const userAuth = useSelector(selectAuth);

  const navigate = useNavigate();

  // Decode or decrypt the data parameter from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const dataParam = queryParams.get("data");

    if (dataParam) {
      try {
        let decodedData;

        // Try to Base64 decode first
        try {
          decodedData = base64Decode(dataParam);
          const parsedData = JSON.parse(decodedData);
          if (parsedData.reject) {
            if (parsedData.userId !== userAuth.userId) {
              navigate(PATH_ACCESS_DENIED);
            }
          }
          setParsedData(parsedData);
        } catch (base64Error) {
          // If Base64 fails, attempt decryption
          decodedData = decodeURIComponent(dataParam);
          const decryptedData = EncryptDecryptService.decryptData(decodedData);
          const parsedData = JSON.parse(decryptedData);
          setParsedData(parsedData);
        }
      } catch (error) {
        console.error("Error processing data:", error);
        CustomNotify({ type: "error", message: "Invalid or corrupted data." });
      }
    }
  }, []);

  // Base64 decoding helper function
  const base64Decode = (data: string) => {
    return decodeURIComponent(escape(atob(data)));
  };

  useEffect(() => {
    if (parsedData) {
      generateWageProposal();
    }
  }, [parsedData]);

  // Generate the wage proposal document
  const generateWageProposal = async () => {
    try {
      setShowApproveButton(parsedData?.approve);
      setShowRejectButton(parsedData?.reject);
      const response = await ApiCall.service(
        GENERATE_WAGE_PROPOSAL,
        "POST",
        parsedData,
        true,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        setHtmlPath(response.data.html_file_path);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({
        type: "error",
        message: "An error occurred while generating the proposal.",
      });
    }
  };

  // Send the wage proposal email
  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await ApiCall.service(
        SEND_WAGE_PROPOSAL_MAIL,
        "POST",
        parsedData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(MANAGE_WAGE_PROPOSAL);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({
        type: "error",
        message: "An error occurred while sending the wage proposal.",
      });
    }
    setLoading(false);
  };

  const handleApprove = async () => {
    try {
      const response = await ApiCall.service(
        APPROVE_WAGE_PROPOSAL,
        "POST",
        parsedData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${MANAGE_WAGE_PROPOSAL}/${response.data}`);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({
        type: "error",
        message: "An error occurred while approving the proposal.",
      });
    }
  };
  const handleReject = async () => {
    try {
      const response = await ApiCall.service(
        REJECT_WAGE_PROPOSAL,
        "POST",
        parsedData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${MANAGE_WAGE_PROPOSAL}/${response.data}`);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({
        type: "error",
        message: "An error occurred while approving the proposal.",
      });
    }
  };

  const handleBack = () => {
    navigate(MANAGE_WAGE_PROPOSAL);
  };

  const handleCheckbox = (checked: boolean) => {
    setChecked(checked);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setChecked(false);
  };

  const handleAuthenticationSuccess = (success: boolean) => {
    if (success) {
      setShowApproveButton(true);
      setShowCheckbox(false);
      handleModalClose();
    } else {
      setShowApproveButton(false);
      setShowCheckbox(true);
    }
    setChecked(false);
  };

  const handleApproveAsCandidate = () => {
    setShowModal(true);
  };
  if (!htmlPath) {
    return null; // Prevent the rest of the component from rendering if htmlPath is not set
  }
  return (
    <WindowHeightComponent>
      <div className="d-flex h-100 overflow-auto flex-column">
        <div
          className="flex-1 overflow-auto"
          style={{ height: "calc(100vh - 10vw)" }}
        >
          {htmlPath && (
            <iframe
              src={`${process.env.REACT_APP_CONTRACT_URL}/${htmlPath}`}
              height="99%"
              width="100%"
            />
          )}
        </div>
        {showCheckBox && !showApproveButton && (
          <div className="d-flex align-items-center">
            <CheckBoxField
              name="checkbox"
              onChangeHandler={(e) => handleCheckbox(e.target.checked)}
              isChecked={checked}
              label={t(
                "I hereby declare that I have reviewed the document and confirming that I agree with all details"
              )}
              id="confirmCheckbox"
            />
          </div>
        )}

        <div className="d-flex align-items-center justify-content-end">
          {showApproveButton && (
            <Button
              type="button"
              title={t("Approve")}
              handleClick={handleApprove}
              className="form-button shadow-none text-uppercase marginRightPoint5"
            />
          )}
          {showRejectButton && (
            <Button
              type="button"
              title={t("Reject")}
              handleClick={handleReject}
              className="form-button shadow-none text-uppercase"
            />
          )}

          {/* <Button
            type="button"
            title={t("Back")}
            handleClick={handleBack}
            className="btn form-button shadow-none text-uppercase"
          /> */}
          {checked && (
            <>
              <Button
                type="button"
                title={t("Approve as candidate")}
                handleClick={handleApproveAsCandidate}
                className="form-button shadow-none text-uppercase"
              />
              {!loading ? (
                <Button
                  type="button"
                  title={t("Send to candidate")}
                  handleClick={handleSend}
                  className="form-button ms-3 shadow-none text-uppercase"
                />
              ) : (
                <LoadingIcon
                  className="ms-3 h-100"
                  iconType="bars"
                  color="#00a5ce"
                />
              )}
            </>
          )}
          {showModal && parsedData?.userId && (
            <EmployerAuthenticModal
              handleClose={handleModalClose}
              onAuthenticationSuccess={handleAuthenticationSuccess}
              users={[parsedData.userId]}
            />
          )}
        </div>
        <div>
          <BackButton />
        </div>
      </div>
    </WindowHeightComponent>
  );
};

export default WageProposal;
