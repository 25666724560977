import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MANAGE_CHECKLIST } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import EditIcon from 'static/images/EditIcon';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import ViewIcon from 'static/images/ViewIcon';
import { OptionProps, Option } from 'utils/TypeAnnotations';
import { mapToSelect } from 'utils/MapToSelect';
import Button from 'components/atoms/Button';
import Pagination from 'components/atoms/Pagination';
import './checklist.css';
import CustomNotify from 'components/atoms/CustomNotify';
import { M_MASTER_DATA } from '../../../../../constants/Constants';
import BackButton from 'components/atoms/BackButton';

interface ChecklistProps {
  name: string;
  id: number;
}

interface ChecklistDropDwn {
  checklist: Option | null;
}

const ManageChecklist: React.FC = () => {
  const [checklist, setChecklist] = useState([] as ChecklistProps[]);
  const [checkDrp, setCheckDrp] = useState({
    checklist: [] as OptionProps[],
  });
  const [searchData, setSearchData] = useState<ChecklistDropDwn>({
    checklist: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchChecklistDetails();
  }, []);

  const fetchChecklistDetails = async (searchData = {}, page = 1) => {
    const data = {
      ...searchData,
      page: page,
    };

    const response = await ApiCall.service(
      MANAGE_CHECKLIST,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );
    if (response.status === 200) {
      const checklistData = response.data.checklist;
      const checkOptions = Array.isArray(response.data.checkFlowCat)
        ? mapToSelect(response.data.checkFlowCat) : [];
      setChecklist(checklistData);
      setCheckDrp({
        checklist: checkOptions,
      });
      setTotalPages(response.totalPages);
    }
  };

  let pageIndex = (currentPage - 1) * 20 + 1;

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchData.checklist === null || searchData.checklist?.label === "Select") {
      CustomNotify({ type: "warning", message: "Search input not provided." });
    }
    fetchChecklistDetails(searchData, currentPage);
  }

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      checklist: null
    });
    setCurrentPage(1);
    fetchChecklistDetails();
  }

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchChecklistDetails(searchData, newPage);
  }

  return (
    <div>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">Manage checklist</h1>
          </div>
        </div>
        <form action="">
          <div className="row checklist-search-bar">
            <div className="col-sm-12 col-md-12 col-lg-6 checklist-search">
              <SelectWithSearch
                search={true}
                options={checkDrp.checklist}
                value={searchData.checklist}
                onChange={(e) => handleSelectChange(e, "checklist")}
                placeHolder='Select checklist '
                name='checklist'
                title='Checklist'
                id='checklist'
                isMandatory={false}
                className="select-field"
                isMulti={false}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className=" col-md-12 mb-lg-0 d-flex justify-content-lg-end justify-content-md-end">
                  <Button
                    title="Reset"
                    type="reset"
                    handleClick={handleReset}
                    className="btn delete-btn rounded-0 shadow-none text-uppercase me-3 search-btns"
                  />
                  <Button
                    title="Search"
                    type="submit"
                    handleClick={handleSearch}
                    className="btn form-button rounded-0 shadow-none text-uppercase search-btns"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                <th className="ps-lg-4">#</th>
                <th>Checklist</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {checklist && checklist.length > 0 ? (
                checklist.map((item, index) => (
                  <tr
                    key={index}
                    className="border mb-3 box-shadow align-middle"
                  >
                    <td className='ps-lg-4'>{pageIndex++}</td>
                    <td>{item.name}</td>
                    <td className="table-action-icons px-2">
                      <div className="d-none d-md-none d-lg-block">
                        <Link
                          to={`/checklist/edit/${item.id}`}
                          className="btn p-0 border-0 me-2"
                          title="Edit"
                        >
                          <EditIcon />
                        </Link>
                        <Link
                          to={`/checklist/view/${item.id}`}
                          className="btn p-0 border-0 me-2"
                          title="View"
                        >
                          <ViewIcon />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border">
                  <td colSpan={3} className="border-0 text-center py-3">
                    <span className="text-danger fw-bold">No records</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-6 align-self-center mb-3">
        <BackButton />
      </div>
      <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(newPage) => handlePageChange(newPage)}
        />
      </div>
    </div>
  );
};

export default ManageChecklist;
