import React, { ChangeEvent, ChangeEventHandler } from "react";
import LabelField from "../atoms/LabelField";
import InputTextfield from "../atoms/InputTextField";
import TextArea from "components/atoms/TextArea";

interface TextAreaMoleculeProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  label?: string;
  value?: string | number;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  textAreaWrapper?:string;
}

export const TextAreaMolecule: React.FC<TextAreaMoleculeProps> = ({
  className = "form-control field-shadow",
  placeholder,
  handleChange,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  readOnly,
  textAreaWrapper
}) => {
  return (
    <>
      <div className={`col-md-12 ${textAreaWrapper}`}>
        <LabelField
          title={label}
          className={labelClassName}
          isMandatory={isMandatory}
          htmlfor={id}
        />
        <TextArea
          id={id}
          className={className}
          value={value}
          isDisabled={isDisabled}
          placeholder={placeholder}
          customStyle={customStyle}
          handleChange={handleChange}
          name={name}
          error={error}
          readOnly={readOnly}
          rows={4}
          cols={40}
        />
      </div>
    </>
  );
};
