import React, { useState, useEffect } from "react";
import { LocationData } from "./ManageLocation";
import { Link, useNavigate, useParams } from "react-router-dom";
import Search from "components/atoms/Search";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { ARCHIVE_LOCATION } from "routes/ApiEndpoints";
import Pagination from "components/atoms/Pagination";
import "./location.css";
import {
  LOCATION,
  MANAGE_COMPANY,
  M_COMPANY_CREATION,
} from "../../../../constants/Constants";
import TableStructure from "components/atoms/TableStructure";
import {
  ExcludeLocationCompanyData,
  LocationColumn,
  LocationTableHeader,
} from "TableHeader";
import { t } from "pages/microservices/masterData/translation/Translation";
import Popup from "components/molecules/Popup";
import FormatData from "components/molecules/FormatData";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManageLocationPageProps {
  locationObject: any;
  pageData: any;
  history: any;
  deleteFilter: (deleteId: number) => void;
  dispatch: any;
  handleRefresh: () => void;
}

const ManageLocationPage: React.FC<ManageLocationPageProps> = ({
  locationObject,
  pageData,
  history,
  deleteFilter,
  dispatch,
  handleRefresh,
}) => {
  //Modal popup for company details
  const [popup, setPopup] = useState({
    isModelOpen: false,
  });
  //set company details in modal
  const [modalData, setModalData] = useState<any>(null);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  let currentItems = locationObject;
  //Show comapny details on click
  currentItems = currentItems.map((item: any) => {
    item.company = (
      <span onClick={(e: any) => viewCompanyDetails(e, item)}>
        {item.company}
      </span>
    );
    return item;
  });

  const deleteLocation = (locationData: LocationData | any) => {
    setShowModal(true);
    setDeleteId(locationData.id);
  };

  const handleEdit = (locationData: LocationData | any) => {
    if (locationData.id) {
      navigate(`${LOCATION}/${locationData.id}`);
    }
  };
  const viewCompanyDetails = (
    event: React.MouseEvent<HTMLAnchorElement>,
    value: string
  ) => {
    event.preventDefault();
    setModalData(value);
    setPopup((prev) => ({ ...prev, isModelOpen: true }));
  };

  const closeModal = () => {
    setPopup((prev) => ({ ...prev, isModelOpen: false }));
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const response = await ApiCall.getService(
        `${ARCHIVE_LOCATION}/${deleteID}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const userData = useSelector(selectAuth);
  const filters = [
    {name: 'location', fieldType: "text", placeholder: "Location", filterType: 'search' },
    {name: 'company', fieldType: "text", placeholder: "Company", filterType: 'search' },
  ];
  return (
    <>
      <div className="search-bar">
        <Title title={t("Manage location")} />
      </div>
      <div className="position-relative tableMainWrapper">
        <AccessControl
          requiredPermissions={[
            {
              permission: "Location",
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink="/location"
                title={t("Create location")}
                icon={faPlus}
              />
            </div>
          </div>
        </AccessControl>
        <div className="table-responsive manage-location tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
          <div className="TableHeight">
            <TableStructure
              isAction
              headers={LocationTableHeader}
              data={currentItems}
              values={LocationColumn}
              handleEdit={handleEdit}
              handleArchive={deleteLocation}
              handleRefresh={handleRefresh}
              history={history}
              permissionType={"Location"}
            />
          </div>
          <div className="">
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>

      <div className="col-md-4 align-self-center mt-3">
        <BackButton />
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Archive confirmation"
        body="Are you sure want to archive?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
      {popup.isModelOpen && (
        <Popup
          title={t("Company Details")}
          body={
            <FormatData
              data={modalData}
              excludeData={ExcludeLocationCompanyData}
            />
          }
          cancel={closeModal}
          modalSize="lg"
          notext="Close"
          cancelButtonClass="shadow-none float-end d-flex justify-content-center"
        />
      )}
    </>
  );
};
export default ManageLocationPage;
