import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA, M_WEB_CONNECTOR } from "constants/Constants";
import { COMPANY_VAT_VALIDATION, VERIFY_SUPPLIER_CLIENT_VAT_NUMBER } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";

export const activeFunction = (payload: any, dependentFields: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    return item
  })
  return newState

}

export const verifyVatNumber = async (payload: any) => {

  const { state, fieldData } = payload;
  const { field, dynamic, mainIndex, subIndex } = fieldData;

  const vatData = {
    vat_number: field?.value,
    key: `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`,
  };
  const vatExistence = await ApiCall.service(
    VERIFY_SUPPLIER_CLIENT_VAT_NUMBER,
    "POST",
    vatData,
    false,
    M_MASTER_DATA
  );
  if (vatExistence?.status === 400) {
    CustomNotify({ type: "warning", 'message': vatExistence?.message });
    return [...state];
  }
  else {
    const url = COMPANY_VAT_VALIDATION;
    const dependentFields: any = {
      d_street: 'street',
      d_number: 'number',
      d_box: 'box',
      d_postalCode: 'postal',
      d_city: 'city',
      name: 'name',
      d_country: 'country',
    };
    const response = await ApiCall.service(
      url,
      "POST",
      vatData,
      true,
      M_WEB_CONNECTOR,
      false
    );
    if (response?.status !== 200) {
      CustomNotify({ type: "error", message: response?.error });
      const newState = [...state].map((eachData: any) => {
        if (Object.keys(dependentFields).includes(eachData?.name)) {
          return { ...eachData, value: '', error: '' };
        }
        return eachData;
      });
      // dispatch({
      //   type: "UPDATE_BASIC_FIELD",
      //   field: "companyName",
      //   value: "",
      // });
      // dispatch({
      //   type: "UPDATE_BASIC_FIELD",
      //   field: "officialName",
      //   value: "",
      // });
      //handleAddressUpdate("", "", "", "", "");
      //dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      //state.fieldError.vat = response?.error;
      return newState;
    } else {
      const value = response?.data?.name;
      const addressData = {
        name: value?.toUpperCase(),
        ...response?.data?.address,
        country: 29,
      }

      //update errors to empty string for dependency fields

      const newState = [...state].map((eachData: any) => {
        if (Object.keys(dependentFields).includes(eachData?.name)) {
          return { ...eachData, value: addressData[dependentFields[eachData?.name]], error: '' };
        }
        return eachData;
      });
      CustomNotify({ type: "success", message: "VAT number is verified" });
      return newState;
    }
  }

};
