import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'static/css/common.css';
import 'static/css/variables.css';
import 'static/css/responsive.css';
import 'static/css/input.css';
import "static/css/header.css";
import "static/css/table.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'react-phone-number-input/style.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);
const port = process.env.REACT_APP_PORT || 3000;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
