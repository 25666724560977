import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { ApiCall } from "services/ApiServices";
import {
  COMPETENCE_CREATE,
  COMPETNCE_EDIT,
  GET_CATOGRY_BY_ID,
  GET_COMPETENCE_OPTIONS,
  GET_FUNCTION_BY_CATEGORY_ID,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_COMPETENCE_MANAGE } from "constants/Paths";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import CloseFile from "static/images/CloseFile";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import TitleAtom from "components/atoms/Title";
import AddPlusIcon from "static/images/AddPlusIcon";
import BackButton from "components/atoms/BackButton";
import { competenceRange } from "utils/multiTabForm/components/common/ReducerService";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";

interface ValidationRules {
  [key: string]: Function[];
}
interface Payload {
  id: string | number | any;
  pcId?: string | number;
}

function CreateCompetence() {
  const [formData, setFormData] = useState<any>({
    competenceName: "",
    competenceType: [{ value: "", label: "" }],
    paritairComitee: null,
    competenceRange: null,
    category: null,
    info: "",
    function: [""],
  });
  const [pcOption, setPcOptions] = useState<Option[]>();
  const [categoryOption, setCategoryOption] = useState<Option[]>();
  const [functionOption, setFunctionOption] = useState<Option[]>();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({
    competenceName: "",
    competenceType: [""],
    competenceRange: "",
    paritairComitee: "",
    function: "",
    info: "",
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(GET_COMPETENCE_OPTIONS, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        setPcOptions(response?.data?.pc);
        setCategoryOption(response?.data?.category);
        setFunctionOption(response?.data.function);
        if (id) {
          fetchData(response?.data);
        }
      }
    } catch (error) { }
  };

  const rangeOptions = [
    {value: 4, label: "1-4"},
    {value: 6, label: "1-6"}
  ];

  const fetchData = async (optiondata: any) => {
    if (id) {
      try {
        const editData = { id: id };
        const response = await ApiCall.service(COMPETNCE_EDIT, "POST", editData, false, M_MASTER_DATA);
        if (response?.status === 200) {
          const {
            competenceName, competenceType, paritairComitee, category,
            range, info, function: functions,
          } = response.data;
          setFormData({
            id, competenceName, competenceRange: range, info,
            competenceType: competenceType.map((type: any) => ({
              value: type.id,
              label: type.competence_type,
            })),
            paritairComitee: paritairComitee || null,
            category: category || null, function: functions || null,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const changeHandler = (e: ChangeEvent<HTMLInputElement>, index?: number, type?: string) => {
    const { name, value } = e.target;
    const updatedFormData: any = { ...formData };
    if (type === 'competenceType' && index !== null && index !== undefined) {
      updatedFormData.competenceType[index].label = value; 
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
    validateInput(index === 0 || index ? "competenceType" : name, value, index);
  };

  const handleSelectChange = async (selectedOption: Option, name: string) => {
    const id = selectedOption.value;
    // Initialize payload with selected option id
    const payload: Payload = {
      id: id,
    };
    if (name === "paritairComitee") {
      setFormData((prevData: any) => ({ ...prevData, ["category"]: null }));
      setFormData((prevData: any) => ({ ...prevData, ["function"]: null }));
      const response = await ApiCall.service(GET_CATOGRY_BY_ID, "POST", payload, false, M_MASTER_DATA);
      if (response?.status === 200) {
        setCategoryOption(response?.data?.categories);
        setFunctionOption(response?.data?.functions);
      }
    }
    if (name === "category") {
      setFormData((prevData: any) => ({ ...prevData, ["function"]: null }));
      const paritairComiteeId = formData.paritairComitee?.value;
      if (paritairComiteeId) {
        payload.pcId = paritairComiteeId;
      }
      const response = await ApiCall.service(GET_FUNCTION_BY_CATEGORY_ID, "POST", payload, false, M_MASTER_DATA);
      if (response?.status === 200) {
        setFunctionOption(response?.data);
      }
    }
    setFormData((prevData: any) => ({ ...prevData, [name]: selectedOption }));
    validateInput(name, selectedOption);
  };

  const validateInput = (name: string, value: any, index?: number) => {
    if (name === "competenceType" && (index === 0 || index)) {
      const errorsArray: any = [...errors.competenceType];
      console.log(value, name, index);
      
      errorsArray[index] = value?.trim() !== "" ? "" : "This field is required";
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: errorsArray,
      }));
      return value.trim() !== "";
    }
    const validationRules: ValidationRules = { [name]: [validateRequired] };
    const validationErrors = validateForm({ ...formData, [name]: value }, validationRules );
    setErrors((prevErrors: any) => ({...prevErrors, [name]: validationErrors[name]}));
    return Object.keys(validationErrors).length === 0;
  };

  const handleAddType = (indexToAdd: number) => {
    const lastFieldIndex = formData.competenceType.length - 1;
    const lastFieldValue = formData.competenceType[lastFieldIndex]?.label;
    const isValid = validateInput("competenceType", lastFieldValue, lastFieldIndex);
    if (isValid) {
      setFormData((prevState: any) => ({
        ...prevState,
        competenceType: [...prevState.competenceType, { value: "", label: "" }],
      }));
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: [...prevErrors.competenceType, ""],
      }));
    }
  };

  const handleRemoveType = (indexToRemove: number) => {
    setFormData((prevState: any) => ({
      ...prevState,
      competenceType: prevState.competenceType.filter((item: any, index: number) => index !== indexToRemove),
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      competenceType: prevErrors.competenceType.filter((item: any, index: number) => index !== indexToRemove)}));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;
    Object.keys(formData).map((key) => {
      if (key === "category") return;
      if (key !== "competenceType") {
        const result = validateInput(key, formData[key]);
        isValid = isValid && result;
      } else {
        const competenceError = validateInput(key, formData[key][0].label, 0);
        isValid = isValid && competenceError;
      }
    });

    if (isValid) {
      try {
        const response = await ApiCall.service(
          COMPETENCE_CREATE,
          "POST",
          formData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: response.message,
          });
          navigate(PATH_COMPETENCE_MANAGE);
        } else {
          // Handle error scenario
          console.error("Error occurred while submitting the form");
        }
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
      }
    }
  };


  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Competence",
  };

  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <TitleAtom
            title={id ? t("Edit competence") : t("Create competence")}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-border">
            <div className="row">
              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Paritair comitee")}
                  name="paritairComitee"
                  id="paritairComitee"
                  isMandatory={true}
                  search={true}
                  options={pcOption}
                  placeHolder="Select"
                  value={formData.paritairComitee}
                  onChange={(e) => handleSelectChange(e, "paritairComitee")}
                  isMulti={false}
                  className="select-field"
                  error={errors.paritairComitee}
                />
              </div>

              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Category")}
                  name="category"
                  id="category"
                  isMandatory={false}
                  search={true}
                  options={categoryOption}
                  placeHolder="Select"
                  value={formData.category}
                  onChange={(e) => handleSelectChange(e, "category")}
                  isMulti={false}
                  className="select-field"
                  error={errors.category}
                />
              </div>
              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Function")}
                  name="function"
                  id="function"
                  isMandatory={true}
                  search={true}
                  options={functionOption}
                  placeHolder="Select"
                  value={formData.function}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti={true}
                  className="select-field"
                  error={errors.function}
                />
              </div>

              <div className="form-group col-md-3">
                <SelectWithSearch
                  isMandatory={true}
                  name="competenceRange"
                  onChange={(e) => handleSelectChange(e, "competenceRange")}
                  value={formData.competenceRange}
                  id="competenceRange"
                  title={t("Competence range")}
                  placeHolder={t("Select")}
                  search={true}
                  options={rangeOptions}
                  error={errors.competenceRange}
                />
              </div>

              <div className="form-group col-md-3">
                <LabelWithInputField
                  isMandatory={true}
                  name="competenceName"
                  handleChange={(e) => changeHandler(e)}
                  value={formData.competenceName}
                  id="competenceName"
                  label={t("Name of competences")}
                  placeholder={t("Name of competences")}
                  type="text"
                  error={errors.competenceName}
                />
              </div>
              <div className="col-lg-12">
                <LabelWithTextAreaField
                  name="info"
                  handleChange={(event) => changeHandler(event)}
                  value={formData.info}
                  label={t("Info")}
                  isMandatory={true}
                  error={errors.info}
                />
              </div>
            </div>

            <div className="row d-flex align-items-center">
              {formData?.competenceType?.map((type: any, index: number) => (
                  <>
                    <div className="form-group col-md-6" key={`competenceType-${index}`}>
                      <LabelWithInputField
                        isMandatory={true}
                        name={`competenceType-${index}`}
                        handleChange={(e) => changeHandler(e, index, 'competenceType')}
                        value={type?.label}
                        id={`competenceType${index}`}
                        label={t("Type of competences")}
                        placeholder={t("Type of competences")}
                        type="text"
                        error={errors?.competenceType?.[index]}
                      />
                    </div>
                    <div className="col-3 table-action-icons">
                      {formData?.competenceType?.length !== 1 && (
                        <span
                          title={t("Delete")}
                          onClick={() => handleRemoveType(index)}
                          className="table-action-btn cursor-pointer me-2"
                        >
                          <CloseFile />
                        </span>
                      )}
                      {index === formData?.competenceType?.length - 1 && (
                        <span
                          onClick={() => handleAddType(index)}
                          className="table-action-btn cursor-pointer"
                          title={t("Add another competences")}
                        >
                          <AddPlusIcon />
                        </span>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <div className="">
              <div className="">
                <BackButton />
              </div>
            </div>
            <div className="">
              <Button
                title={t("Save")}
                handleClick={handleSubmit}
                className="form-button float-end shadow-none"
              />
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
}
export default CreateCompetence;
