import React, { useState } from "react";
import { CompanyData } from "./ManageCompanies";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import ArchiveIcon from "../../../../../static/images/ArchiveIcon";
import EditIcon from "../../../../../static/images/EditIcon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  ADD_COMPANY_TO_HOTLIST,
  ARCHIVE_COMPANY,
  REMOVE_COMPANY_FROM_HOTLIST,
} from "routes/ApiEndpoints";
import Pagination from "components/atoms/Pagination";
import Cooperation from "static/images/Cooperation";
import { M_COMPANY_CREATION } from "constants/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableActions from "components/organism/Actions/TableAction";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import FollowUpIcon from "static/images/FollowUpIcon";
import ViewIcon from "static/images/ViewIcon";
import { IconData } from "services/IconData";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import SendMailIcon from "static/images/sendMailIcon";
import PhoneCallIcon from "static/images/phoneIcon";
import ProjectLinkedIcon from "static/images/projectLikedIcon";
import WhiteHeartIcon from "static/images/whiteHeartIcon";
import BlackHeartIcon from "static/images/BlackHeartICon";
import CheckBoxField from "components/atoms/CheckBoxField";
import { PATH_PROJECTS_MANAGE } from "constants/Paths";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import FilterOrganism from "components/organism/FilterOrganism";
import {
  addPageToHistory,
  updatePageFilters,
} from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { convertArrayToOptions } from "services/util/UtilService";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { CompaniesTableHeader } from "TableHeader";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import ActionIconWrapper from "components/atoms/ActionIconWrapper";
import TextEllipsis from "components/atoms/TextEllipsis";

interface ManageCompanyPageProps {
  compObj: CompanyData[];
  deleteFilter: (deleteId: number) => void;
  history: any;
  dispatch: any;
  options: any;
  handleRefresh: () => void;
  pageData: any;
  // fetchCompaniesDetails: (data: any) => Promise<void>;
  addOrRemoveHotlist: (companyId: number, type: boolean) => void;
}

export const ManageCompaniesPage: React.FC<ManageCompanyPageProps> = ({
  compObj,
  history,
  dispatch,
  deleteFilter,
  options,
  addOrRemoveHotlist,
  pageData,
  handleRefresh,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const AgreementStatus = ["Not completed", "Completed"];
  const CompanyStatus = [
    "Drafted company ",
    "Registered company",
    "Inactive company",
  ];
  const CompanyActionStatus = [
    "Drafted company ",
    "Registered company",
    "Inactive company",
    "Prospect company",
  ];
  const Type = ["Prospect", "Customer"];
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [sideBarWidth, setSidebarWidth] = useState("");

  const deleteCompany = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const response = await ApiCall.getService(
        `${ARCHIVE_COMPANY}/${deleteID}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };
  const addToHotlist = async (companyId: number | undefined, type: boolean) => {
    if (companyId) {
      const response = await ApiCall.service(
        ADD_COMPANY_TO_HOTLIST,
        "POST",
        { companyId: companyId, type: type },
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        addOrRemoveHotlist(companyId, type);
      }
    }
  };

  const handleFollowup = (
    event: any,
    id: string | number | null,
    businessUnit: string | number | null
  ) => {
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  const userData = useSelector(selectAuth);
  function handleClick(company: any): void {
    const companyId = company.id;
    navigate(`/company/${companyId}?mode=view`);
  }

  const handleStatusFilter = (
    comType: string,
    e: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    e.preventDefault();
    dispatch(updatePageFilters({ filters: { companyType: comType } }));
    handleRefresh();
  };

  const handeSendMail = (email: any) => {
    window.location.href = `mailto:${email}`;
  };
  const handePhoneCall = (companyId: any) => {
    // need to implemtn
  };
  const filters = [
    {
      name: "businessUnits",
      fieldType: "multiSelect",
      options: options?.businessUnits,
      placeholder: "Business unit",
      filterType: "search",
    },
    {
      name: "name",
      fieldType: "text",
      placeholder: "Company name",
      filterType: "search",
    },
    {
      name: "vatNumber",
      fieldType: "text",
      placeholder: "Vat number",
      filterType: "search",
    },
    {
      name: "zipCode",
      fieldType: "text",
      placeholder: "Zip code",
      filterType: "search",
    },
    {
      name: "city",
      fieldType: "text",
      placeholder: "City",
      filterType: "search",
    },
    {
      name: "country",
      fieldType: "singleSelect",
      options: options?.countries,
      placeholder: "Country",
      filterType: "search",
    },
    {
      name: "email",
      fieldType: "text",
      placeholder: "Email",
      filterType: "search",
    },
    {
      name: "phoneNumber",
      fieldType: "text",
      placeholder: "Mobile number",
      filterType: "search",
    },
    {
      name: "cs",
      fieldType: "singleSelect",
      options: convertArrayToOptions(CompanyStatus),
      placeholder: "Status",
      filterType: "search",
    },
  ];
  const statusParam = history?.filters?.companyType ?? null;

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <TitleAtom title={t("Manage companies")} />
            <div className="col-md-12 createNavTabs">
              <button
                className={`link ${
                  statusParam === null || statusParam === "0"
                    ? "active nav-subtab"
                    : "nav-subtab"
                } marginRightPoint5 border-0 bg-transparent p-0 btn`}
                onClick={(e) => handleStatusFilter("0", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("All")}
              </button>
              <button
                className={`link ${
                  statusParam === "1" ? "active nav-subtab" : "nav-subtab"
                } btn marginRightPoint5 border-0 bg-transparent p-0`}
                onClick={(e) => handleStatusFilter("1", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("Client")}
              </button>
              <button
                className={`link ${
                  statusParam === "2" ? "active nav-subtab" : "nav-subtab"
                } btn marginRightPoint5 border-0 bg-transparent p-0`}
                onClick={(e) => handleStatusFilter("2", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("ExCilent")}
              </button>
              <button
                className={`link ${
                  statusParam === "3" ? "active nav-subtab" : "nav-subtab"
                } btn marginRightPoint5 border-0 bg-transparent p-0`}
                onClick={(e) => handleStatusFilter("3", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("Prospect")}
              </button>
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <div className="row">
            <div className="ManageCreateBtn">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Company",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to="/company" className="form-button marginRightPoint5">
                  + {t("Create company")}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Location",
                    read: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link
                  to="/manage-locations"
                  className="form-button marginRightPoint5"
                >
                  {t("Manage Location")}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Contact person",
                    read: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to="/manage-contact/person" className="form-button">
                  {t("Manage Contact Person")}
                </Link>
              </AccessControl>
            </div>
          </div>
          <div className="table-responsive manage-companies tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
            <div className="tableWithSearchBar tablePadding">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {CompaniesTableHeader?.map((value: any) => (
                      <th key={value.name} className="border-bottom-0">
                        {value.name}{" "}
                        {value?.issort && (
                          <SortAtomForManagePage
                            value={{ alias: "businessUnits" }}
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            handleRefresh={handleRefresh}
                          />
                        )}
                      </th>
                    ))}
                    <th className="actions table_actions">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Company",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {compObj && compObj.length > 0 ? (
                    compObj.map((company, index) => {
                      let color_cs =
                        company.cs === 0
                          ? "bg-warning"
                          : company.cs === 1
                          ? "bg-success"
                          : company.cs === 3
                          ? "bg-black"
                          : "bg-danger";

                      return (
                        <tr
                          key={company.id}
                          className="border mb-3 box-shadow align-middle"
                        >
                          <td className="text-break" data-label="Type">
                            <TextEllipsis
                              title={company.businessUnits}
                              label={company.businessUnits}
                              width="5vw"
                            />
                          </td>
                          <td
                            onClick={() => {
                              handleClick(company);
                            }}
                            className="cursor-pointer nameHoverClass"
                          >
                            <TextEllipsis
                              title={company.name}
                              label={company.name}
                              width="9vw"
                            />
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.vatNumber}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.zipCode}
                          </td>
                          <td className="text-break" data-label="Type">
                            <TextEllipsis
                              title={company.city}
                              label={company.city}
                              width="6vw"
                            />
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.country}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.email}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.phoneNumber}
                          </td>
                          <td className="text-break ps-3">
                            <span
                              className={`d-inline-block rounded-circle status-icon ${color_cs}`}
                              data-toggle="tooltip"
                              title={CompanyActionStatus[company.cs ?? 0]}
                            ></span>
                          </td>
                          <td className="table-action-icons px-2">
                            <ActionIconWrapper initialVisibleCount={4}>
                              {company.active && company.cs === 0 && (
                                <>
                                  {/* {/ Case 1: cs = 0, active = true /} */}
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("Edit")}
                                  />
                                </>
                              )}
                              {company.active && company.cs === 0 && (
                                <>
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("View")}
                                  />
                                </>
                              )}
                              {company.active && company.cs === 0 && (
                                <>
                                  <Button
                                    title={<ArchiveIcon />}
                                    handleClick={() =>
                                      deleteCompany(company.id)
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("Archive")}
                                  />
                                </>
                              )}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    {/* {
                                      / Case 2: cs = 1, active = true (Show all actions) /
                                    } */}
                                    <Button
                                      title={<SendMailIcon />}
                                      handleClick={() =>
                                        handeSendMail(company.email)
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Send mail")}
                                    />
                                  </>
                                )}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    <Button
                                      title={<PhoneCallIcon />}
                                      handleClick={() =>
                                        handePhoneCall(company.id)
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Phone call")}
                                    />
                                  </>
                                )}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    <Button
                                      title={<EditIcon />}
                                      handleClick={() =>
                                        navigate(`/company/${company.id}`)
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Edit")}
                                    />
                                  </>
                                )}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    <Button
                                      title={<ViewIcon />}
                                      handleClick={() =>
                                        navigate(
                                          `/company/${company.id}?mode=view`
                                        )
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("View")}
                                    />
                                  </>
                                )}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    <Button
                                      title={<ProjectLinkedIcon />}
                                      handleClick={() => {
                                        dispatch(
                                          addPageToHistory({
                                            pageName: PAGENAMES.MANAGE_PROJECT,
                                            route: window.location.pathname,
                                            filters: { company: company.name },
                                          })
                                        );
                                        dispatch(
                                          addPageToHistory({
                                            pageName: PAGENAMES.MANAGE_PROJECT,
                                            route: window.location.pathname,
                                            filters: { company: company.name,businessUnits:company.buIds },
                                          })
                                        );
                                         navigate(PATH_PROJECTS_MANAGE);
                                      }}
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Projects")}
                                    />
                                  </>
                                )}
                              {/* {company.isHotlist ? (
                                    <Button
                                      title={<BlackHeartIcon />}
                                      handleClick={() =>
                                        addToHotlist(company.id, false)
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Remove from hotlist")}
                                    />
                                  ) : (
                                    <Button
                                      title={<WhiteHeartIcon />}
                                      handleClick={() =>
                                        addToHotlist(company.id, true)
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Add to hotlist")}
                                    />
                                  )} */}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    <Button
                                      title={<ArchiveIcon />}
                                      handleClick={() =>
                                        deleteCompany(company.id)
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Archive")}
                                    />
                                  </>
                                )}
                              {company.active &&
                                (company.cs === 1 || company.cs === 3) && (
                                  <>
                                    <Button
                                      title={<FollowUpIcon />}
                                      handleClick={(e) =>
                                        handleFollowup(
                                          e,
                                          company.id ? company.id : 0,
                                          company?.get_bussiness_unit?.id
                                        )
                                      }
                                      className={
                                        "table-action-btn border-0 p-0"
                                      }
                                      tooltip={t("Followup")}
                                    />
                                  </>
                                )}
                              {!company.active && company.cs === 0 && (
                                <>
                                  {/* {/ Case 3: cs = 0, active = false /} */}
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("Edit")}
                                  />
                                </>
                              )}
                              {!company.active && company.cs === 0 && (
                                <>
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("View")}
                                  />
                                </>
                              )}
                              {!company.active && company.cs === 0 && (
                                <>
                                  <Button
                                    title={<ArchiveIcon />}
                                    handleClick={() =>
                                      deleteCompany(company.id)
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("Archive")}
                                  />
                                </>
                              )}
                              {!company.active && company.cs === 2 && (
                                <>
                                  {/* {/ Case 4: cs = 2, active = false /} */}
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("Edit")}
                                  />
                                </>
                              )}
                              {!company.active && company.cs === 2 && (
                                <>
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 p-0"}
                                    tooltip={t("View")}
                                  />
                                </>
                              )}
                            </ActionIconWrapper>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border">
                      <td
                        colSpan={10}
                        className="border-0 text-center py-3 px-2"
                      >
                        <span className="text-danger fw-bold">No records</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="pagination d-block"
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        {/* 
        <div className="align-self-center" style={{ padding: "0.5vw 0" }}>
          <BackButton />
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title="Archive confirmation"
          body="Are you sure want to archive?"
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {followupSideBar && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"company"}
            businessUnit={entity.businessUnit}
            entityId={entity.entityId}
            onClose={handleCloseSidebar}
            getWidth={getWidth}
            recordsLimit={10}
            className="right-sidebar p-3"
            titleClassName="mt-4"
            destination="/manage-companies"
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageCompaniesPage;
