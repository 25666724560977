import TitleAtom from "components/atoms/Title";
import React from "react";
import TimesheetsFilters from "./molecules/timesheetsFilters";

const PageLayout = ({ children }) => {
  return (
    <>
      <div className="search-bar" style={{top:"0px"}}>
        <TitleAtom title={("Timesheets")} />
        <TimesheetsFilters />
      </div>
      <div className="content flex-1 overflow-auto">{children}</div>
    </>
  );
};

export default PageLayout;
