import React, { useState, useEffect, useRef } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { renderFormElements } from "./FormElements";
import { validateForm } from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate } from "react-router-dom";
import { GET_ENTITY, GET_FORM_DATA } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "../../constants/Constants";
import SignatureCanvas from "react-signature-canvas";
import SignatureModal from "pages/microservices/project/Proposals/SignatureModel";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import Popup from "components/molecules/Popup";
import CommonServices from "services/CommonService";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface ValidationRules {
  [key: string]: Function[];
}
interface FormBuilderProps {
  entryId?: number | string | null;
  actionType?: string;
  getDataAPI?: any;
  SaveDataAPI?: any;
  getFormUrl?: any;
  formName: string;
  title?: string;
  formType?: string;
  redirect: string;
  validationRules: ValidationRules;
  loadingforapi?: boolean;
  microserviceName?: string;
  handleDateChange?: any;
  handleAddSignature?: any;
  mode?: any;
  customFunction?: any;
  ignoredKeys?: any;
  isEditDependent?: boolean;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  entryId,
  actionType,
  getDataAPI,
  SaveDataAPI,
  getFormUrl = GET_FORM_DATA,
  formName,
  title,
  formType,
  redirect,
  validationRules,
  microserviceName = "m-identitymanager",
  loadingforapi = false,
  mode,
  customFunction,
  ignoredKeys = [],
  isEditDependent = false,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [formInputs, setFormInputs] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [warning, setWarning] = useState({
    popup: false,
    popupdata: [],
  });
  const [showSignatureModal, setSignatureModal] = useState(false);
  const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
  const [signatureData, setSignatureData] = useState<any>(null);
  const [updateFormInput, setUpdateFormInput] = useState(false);

  useEffect(() => {
    fetchData(formName);
  }, []);

  const fetchData = async (formName: string) => {
    try {
      const postData = {
        method: "POST",
        form: formName,
      };
      const response = await ApiCall.service(
        getFormUrl,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );
      setFormInputs(response.data);
      Object.values(response.data).forEach((value: any) => {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [value.name]: value.value,
        }));
      });
    } catch (error) {
      console.log(error);
    }
  };



  const userData = useSelector(selectAuth);

  const setFormInputsWithPreviousData: any = async (formFields: any, data: any, proceesedArray: any, index: number) => {
    if (index == formInputs?.length) {
      return formFields;
    }
    const record = formFields[index];
    if ((record?.dependencyAction) && (!proceesedArray.has(record?.dependencyAction))) {
      proceesedArray.add(record?.dependencyAction);
      const { formInputs, dependencyValues, isFormInputChange } = await customFunction(formFields, data?.[record?.name], record?.dependencyAction, record?.dependencyArray);
      formFields = formInputs;
    }

    return setFormInputsWithPreviousData(formFields, data, proceesedArray, index + 1);

  }


  useEffect(() => {
    if (entryId && formInputs.length > 0) {
      const editId = { id: entryId };
      const fetchDataForEditing = async (editId: { id: string | number }) => {
        try {
          const response = await ApiCall.service(
            getDataAPI,
            "POST",
            editId,
            loadingforapi,
            microserviceName
          );
          if (response.status === 200) {
            const data = response?.data;
            let formData: any = [];
            //updating input fields
            let processedArray: any = new Set();
            let index = 0;
            let formElements = null;
            if (updateFormInput == false) {
              if (isEditDependent) {
                const resultant = await setFormInputsWithPreviousData([...formInputs], data, processedArray, index);
                if (JSON.stringify(resultant) !== JSON.stringify(formInputs)) {
                  setFormInputs(resultant);
                  setUpdateFormInput(true);
                }
                formElements = resultant;
              }
              else {
                formElements = formInputs;
              }

              Object.values(formElements).forEach((value: any) => {
                if (actionType === "clone") {
                  if (value.type !== "multi-select") {
                    formData[value.name] = data[value.alias];
                  }
                } else {
                  formData[value?.name] =
                    "phone_number" === value?.name
                      ? "+" + data[value?.name]
                      : data[value?.name];
                }
              });
              setFormData((prev: any) => ({ ...prev, ...formData }));
              if (response?.data?.signature) {
                setSignatureData(response.data.signature);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchDataForEditing(editId);
    }
  }, [entryId, formInputs]);

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    if (entryId && formData[name as keyof FormBuilderProps] === value) {
      return true;
    }

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name as keyof FormBuilderProps],
      }));
    } else {
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const fileChangeHandler = async (event: any, field: any) => {
    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "employee");
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [field.name]: fileData,
    }));
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handleDateChange = (date: any, fieldName: string) => {
    if (date != null) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      setFormData((prevData: any) => ({
        ...prevData,
        [fieldName]: formattedDate,
      }));
    }
  };

  const handleAddSignature = () => {
    setSignatureModal(true);
  };

  const handleModalClose = () => {
    setSignatureModal(false);
  };

  const handleClearSignature = () => {
    signatureData ? setSignatureData(null) : signatureRef.current?.clear();
  };

  const handleSaveSignature = () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL(); //stores image url into a variable
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        signature: signatureImage,
      }));
      setSignatureData(signatureImage);
      handleModalClose();
      setSignatureErrorMessage("");
    } else {
      setSignatureErrorMessage(t("Please add signature before saving."));
    }
  };

  const handleSelectChange = async (
    selectedOption: any,
    name: string,
    dependency: any,
    dependencyArray: any,
  ) => {

    let dependencyValues: any = {};

    if (dependency) {
      const resultant = await customFunction([...formInputs], selectedOption, dependency, dependencyArray)
      console.log(resultant);

      if (resultant?.isFormInputChange) {
        setFormInputs((prevFormInputs) =>
          JSON.stringify(prevFormInputs) !== JSON.stringify(resultant) ? resultant?.formInputs : prevFormInputs
        );
      }

      dependencyValues = resultant?.dependencyValues;
    }
    validation(name, selectedOption, true);
    // setErrors((prevErrors: any) => ({ ...prevErrors, [name]: errorMessage }));

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: selectedOption,
      ...dependencyValues,
    }));
  };

  const handlePhoneNumberChange = (e: string) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, mobNumber: e }));
    validation("mobNumber", e, true);
    // setErrors((prevErrors: any) => ({
    //   ...prevErrors,
    //   mobNumber: "This field is invalid",
    // }));
  };

  const handleLocationChange = (event: any, value: any) => {
    let data = { lat: event.detail.latLng.lat, lng: event.detail.latLng.lng };
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [value.name]: data,
    }));
  };

  //handle editor data
  const setValues = (value: any, data: any) => {
    if (value.name === "template") {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [value.name]: data,
      }));
      validation(value.name, data, true);
      // setErrors((prevErrors: any) => ({
      //   ...prevErrors,
      //   [value.name]: errorMessage,
      // }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e && e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { id: entryId, ...formData };

    if (warning?.popup && warning?.popupdata?.length) {
      data["ignore"] = warning?.popupdata;
    }
    if (validation(name, value)) {
      let response = await ApiCall.service(
        SaveDataAPI,
        "POST",
        { ...data, userId: userData?.userId },
        loadingforapi,
        microserviceName
      );
      if (response?.status === 200) {
        navigate(redirect);
        CustomNotify({ type: "success", message: response.message });
      } else if (response?.status === 400) {
        let errors: any = [];
        let warningdata: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
          if (ignoredKeys?.includes(key)) {
            warningdata?.push(key);
          }
        }
        setErrors({ ...errors });
        // Check if there are other errors besides the ignored ones
        const hasNonIgnoredErrors = Object.keys(errors).some(
          (key) => !ignoredKeys?.includes(key)
        );
        if (!hasNonIgnoredErrors && warningdata.length > 0) {
          setWarning((prev) => ({
            ...prev,
            popup: true,
            popupdata: warningdata,
          }));
        } else {
          setWarning((prev) => ({ ...prev, popup: false, popupdata: [] }));
        }
        // if (response.message) {
        CustomNotify({ type: "warning", message: response?.message ?? "Please check some errors are there" });
        // }
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div className="search-bar">
        <TitleAtom title={title} />
      </div>
      <div>
        <div className="form-border" style={{ paddingBottom: "1vw" }}>
          <div className="row">
            {Object.entries(formInputs).map(([key, value]) => {
              return (
                <React.Fragment key={key}>
                  {renderFormElements(
                    { type: value.type, name: key, value },
                    value.type === "mobile-number"
                      ? handlePhoneNumberChange
                      : value.type === "multi-select"
                        ? (
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLSelectElement
                          >
                        ) =>
                          handleSelectChange(e, value.name, value?.dependencyAction, value?.dependencyArray)
                        : value.type === "file"
                          ? (e: any) => fileChangeHandler(e, value)
                          : value.type === "editor"
                            ? (event: any, editor: any) =>
                              setValues(value, editor.getData())
                            : value.type === "location"
                              ? (
                                e: React.ChangeEvent<
                                  HTMLInputElement | HTMLSelectElement
                                >
                              ) => handleLocationChange(e, value)
                              : changeHandler,
                    formData,
                    errors,
                    formName,
                    formType,
                    handleDateChange,
                    handleAddSignature,
                    mode,
                    actionType
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="row" style={{ padding: "1vw 0" }}>
          <div className="col-md-4 align-self-center">
            <BackButton />
          </div>
          <div className="col-md-8">
            {!mode ? (
              !loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button float-end"
                  handleClick={handleSubmit}
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#00a5ce"
                  className="float-end"
                  width={"2.5vw"}
                  height={"2.5vw"}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
        {/* {/ Signature /} */}
        <div>
          {showSignatureModal && (
            <>
              <SignatureModal
                handleClose={handleModalClose}
                handleClearSignature={handleClearSignature}
                handleSaveSignature={handleSaveSignature}
                signatureRef={signatureRef}
                signatureErrorMessage={signatureErrorMessage}
                signatureData={signatureData}
              />
            </>
          )}
        </div>
        {/* {/ Warning popup /} */}
        <div>
          {warning?.popup && (
            <>
              <Popup
                title={t("Warning")}
                body={
                  <>
                    <span>
                      {warning.popupdata?.map((item: any, index: number) => (
                        <b key={index}>
                          {CommonServices.capitalizeLabel(item ?? "")}
                          {index < warning.popupdata.length - 1 ? ", " : ""}
                        </b>
                      ))}
                    </span>
                    <span>
                      {t(
                        " field value(s) already exist, do you still want to submit"
                      ) + "?"}
                    </span>
                  </>
                }
                yestext={t("Yes")}
                notext={t("No")}
                submit={(e) => handleSubmit(e)}
                cancel={() =>
                  setWarning((prev) => ({
                    ...prev,
                    popup: false,
                    popupdata: [],
                  }))
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
