import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import CardTitle from "components/atoms/CardTitle";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { CLOSE_FOLLOWUP, GET_FOLLOWUP_DETAILS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import FollowUpTable from "./FollowUpTable";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import AccessControl from "services/AccessControl";
export interface FollowUpData {
  id: string;
  todoId: string;
  date: string;
  status: number;
  businessUnit: string;
  followUpFor: string;
  taskType: string;
}

const TodoDashboard: React.FC = () => {
  const userDetails = useSelector(selectAuth);
  const navigate = useNavigate();
  const [followUps, setFollowUps] = useState<FollowUpData[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [followUpCloseId, setFollowUpCloseId] = useState<string | undefined>()
  const [key, setKey] = useState("followups"); // Initialize 'key' with a default value

  const fetchFollowupDetails = () => {
    const postData = {
      userId: userDetails.userId
    }
    ApiCall.service(
      GET_FOLLOWUP_DETAILS,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    ).then((response) => {
      setFollowUps(response.data);
    }).catch((error) => {
      console.log(error);
    })

  }
  useEffect(() => {
    fetchFollowupDetails();
  }, []);

  const handleFollowUpCloseId = (id: string) => {
    setFollowUpCloseId(id);
    setShowModal(true);
  }

  const handleFollowUpClose = () => {
    if (followUpCloseId) {
      ApiCall.getService(
        `${CLOSE_FOLLOWUP}/${followUpCloseId}`,
        'GET',
        M_MASTER_DATA,
        true
      ).then((response) => {
        if (response.status === 200) {
          CustomNotify({ type: "success", message: response.message });
          fetchFollowupDetails();
        }
      }).catch((error) => {
        console.log(error);
      })
      setShowModal(false);
    }
  }

  const handleModalClose = () => {
    setShowModal(!showModal);
  }

  const handleAddFeedback = () => {
    navigate(`/todo/create/${followUpCloseId}?destination=/dashboard`);
  }

  return (
    <>
      {/* <DashboardImage /> */}
      <div className="h-100 tasks-meeting-section">
        <div className="card p-4 position-relative h-100 rounded-3">
          <div className="mb-2 pb-xxl-3">
            <CardTitle
              title={t("Time to work some magic!")}
              className={`ms-2 me-4 recruitment-card-num`}
            />
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key || "home"}
            onSelect={(k) => {
              if (k) {
                setKey(k);
              }
            }}
            className="mb-3 dashboard-tasks-meeting-tabs border--bottom-tabs"
          >
            {/* <Tab
              eventKey="tasks"
              title={
                <span className="position-relative recruitment-card-title">
                  {t("Tasks")}
                </span>
              }
            >
              <AccessControl
                key={"todo"}
                requiredPermissions={[{ permission: "Todo", read: true }]}
                override={userDetails.isSuperAdmin}
              >
                <FollowUpTable
                  followUps={followUps}
                  handleFollowUpClose={handleFollowUpCloseId}
                />
              </AccessControl>
            </Tab> */}
            <Tab
              eventKey="followups"
              title={
                <span className="position-relative recruitment-card-title">
                  {t("Follow-ups")}
                </span>
              }
            >
              <AccessControl
                key={"todo"}
                requiredPermissions={[{ permission: "Todo", read: true }]}
                override={userDetails.isSuperAdmin}
              >
                <FollowUpTable
                  followUps={followUps}
                  handleFollowUpClose={handleFollowUpCloseId}
                />
              </AccessControl>
            </Tab>
          </Tabs>
        </div>
      </div>
  
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Feedback")}
          body={t("Do you want to add feedback") + "?"}
          onCloseButtonClick={handleFollowUpClose}
          onConfirmButtonClick={handleAddFeedback}
          className="modal-lg"
          confirmTitle={t("Yes")}
          closeTitle={t("No")}
        />
    
    </>
  );
};

export default TodoDashboard;
