import React, { useState } from "react";
import { useFormContext } from "../context/Context";
import {
  ADD_DYNAMIC_FORM,
  MANAGE_INDEX,
  REMOVE_DYNAMIC_FORM,
  UPDATE_AFTER_BUTTON_CLICK,
  UPDATE_DEPENDENT_TAB,
  UPDATE_FIELD_DATA,
  UPDATE_SALARY_BENEFIT,
} from "../context/Constants";
import { getValue } from "./common/FormService";
import { fieldValidator } from "./validator/Index";
import {
  RenderDynamicComponent,
  renderFields,
  RenderTableComponent,
} from "./common/DynamicFormService";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import TreeComponent from "components/molecules/Tree";
import { getUniqueNames } from "utils/HelperFunction";
import SalaryBenefits from "pages/microservices/masterData/paritair-comitee/create/SalaryBenefit";
import { useParams } from "react-router-dom";
import { EditElements } from "./formElementRender/EditElements";

interface CommonComponentProps {
  customFn: any;
  handleSavePreviousData: any;
  handleDeletePreviousData: any;
}

interface FunctionState {
  [categoryName: string]: string[];
}

const CommonComponent: React.FC<CommonComponentProps> = ({ customFn, handleSavePreviousData, handleDeletePreviousData }) => {
  const [treeComponentVisible, setTreeComponentVisible] = useState(false);
  const { state, dispatch } = useFormContext();
  const index: number = state.currentTab;
  const formElements = state.form[index];
  let salaryBenefitForms: any[] = [];

  if (Array.isArray(formElements)) {
    formElements.forEach((ele: any) => {
      if (
        ele.alias === "salary" &&
        ele.form !== undefined &&
        ele.form !== null
      ) {
        salaryBenefitForms.push(ele.form);
      }
    });
  }

  const { id: routeId } = useParams();

  const [functionData, setFunctionData] = useState<FunctionState>({
    categoryName: [],
  });
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    editor: any,
    field: any,
    dynamic?: boolean,
    mainIndex?: number | string,
    subIndex?: number | string
  ) => {
    if (field.type === "date") {
      return handleDateChange(
        event,
        field.name,
        dynamic,
        mainIndex,
        subIndex
      );
    } else if (field.type === "file") {
      return handleFileChange(event, field, dynamic, mainIndex, subIndex);
    } else {
      return handleFieldChange(
        event,
        field,
        dynamic,
        mainIndex,
        subIndex,
        editor
      );
    }
    // return handleFileChange(event, field, dynamic, mainIndex, subIndex);
  };

  const handleFileChange = async (
    event: any,
    field: any,
    dynamic?: boolean,
    mainIndex?: number | string,
    subIndex?: any
  ) => {
    let fileData = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "employee");
    }

    updateState(
      field.name,
      JSON.stringify(fileData),
      index,
      "value",
      dynamic,
      mainIndex,
      subIndex
    );
    // updateState(field.name, event?.error, index);
  };

  const handleDateChange = (
    date: any,
    fieldName: string,
    dynamic?: boolean,
    mainIndex?: number | string,
    subIndex?: any
  ) => {
    if (date != null) {


      const inputDate = `${date.getFullYear()}-${date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
        }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

      const dateParts = inputDate.split("-");
      let year = dateParts[0];
      // Ensure year has at most 4 digits
      if (year.length > 4) {
        year = year.slice(0, 4);
        // Updating date input field with the modified value
        dateParts[0] = year;
        date = dateParts.join("-");
      }
      updateState(
        fieldName,
        date,
        index,
        "value",
        dynamic,
        mainIndex,
        subIndex
      );
      // Remove error when date is updated
      updateState(
        fieldName,
        "", // Clear error
        index,
        "error",
        dynamic,
        mainIndex,
        subIndex
      );
    }
  };
  const handleFieldChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: any,
    dynamic?: boolean,
    mainIndex?: number | string,
    subIndex?: any,
    editor?: any
  ) => {
    const value = getValue(field.type, event, editor, dispatch, state);
    const error = fieldValidator(field, { value })?.[field.name];

    // state.tabs.forEach((tab: any) => {
    //   if (tab.id === "pcDetails") {
    //     customFn(state.form);
    //   }
    // });

    if (field?.formBinding) {
      updateState(
        field.name,
        value,
        index,
        "value",
        dynamic,
        mainIndex,
        subIndex
      );
      let payload: any = {
        type: field.formBinding,
        state: formElements,
        fieldData: {
          field: field.name,
          value,
          error,
          index,
          dynamic,
          mainIndex,
          subIndex,
        },
      };
      const tabData = await customFn(payload);
      dispatch({
        type: UPDATE_SALARY_BENEFIT,
        payload: {
          value: tabData,
          index: index,
        },
      });
    }

    if (field?.dependencyAction) {
      let payload: any = {
        type: field.dependencyAction,
        state: formElements,
        fieldData: {
          field: field.name,
          value,
          error,
          index,
          dynamic,
          mainIndex,
          subIndex,
        },
      };
      const tabData = await customFn(payload);
      dispatch({
        type: UPDATE_DEPENDENT_TAB,
        payload: {
          value: tabData,
          index: index,
        },
      });
    } else {
      updateState(
        field.name,
        value,
        index,
        "value",
        dynamic,
        mainIndex,
        subIndex
      );
      updateState(
        field.name,
        error,
        index,
        "error",
        dynamic,
        mainIndex,
        subIndex
      );
    }
  };

  const handleButtonClick = async (
    field: any,
    dynamic?: boolean,
    mainIndex?: number | string,
    subIndex?: any,) => {
    if (field?.dependencyActionOnClick) {

      let isVerify = fieldValidator(field, { value: field?.value })?.[field.name];

      //verify if the value is filled or not if filled then go below

      if (isVerify == null) {
        let payload: any = {
          type: field.dependencyActionOnClick,
          state: formElements,
          fieldData: {
            field,
            dynamic,
            mainIndex,
            subIndex,
          }
        }
        const resultantData = await customFn(payload);
        dispatch({
          type: UPDATE_AFTER_BUTTON_CLICK,
          payload: {
            currentTab: index,
            data: resultantData
          }
        });
      }
      else {
        updateState(
          field.name,
          isVerify,
          index,
          "error",
          dynamic,
          mainIndex,
          subIndex
        );
      }
    }
  }

  const updateState = (
    field: any,
    value: any,
    index: any,
    valueField: string = "value",
    dynamic?: boolean,
    mainIndex?: number | string,
    formIndex?: number

  ) => {
    dispatch({
      type: UPDATE_FIELD_DATA,
      payload: {
        field: field,
        value: value,
        index: index,
        valueField: valueField,
        dynamic: dynamic,
        mainIndex: mainIndex,
        formIndex: formIndex,
      },
    });
  };

  const addDynamicForm = (
    e: any,
    mainIndex: number,
    subIndex?: string | number
  ) => {
    dispatch({
      type: ADD_DYNAMIC_FORM,
      payload: {
        index: index,
        mainIndex: mainIndex,
        subIndex: subIndex,
      },
    });
  };

  const addDynamicTable = (
    e: any,
    mainIndex: number,
    subIndex?: string | number
  ) => {
    dispatch({
      type: ADD_DYNAMIC_FORM,
      payload: {
        index: index,
        mainIndex: mainIndex,
        subIndex: subIndex,
      },
    });
  };

  const manageIndex = (
    index?: string | number,
    action?: string,
    dynamicIndex?: any
  ) => {
    dispatch({
      type: MANAGE_INDEX,
      payload: {
        index: index,
        action: action,
        dynamicIndex: dynamicIndex,
      },
    });
  };

  const addTreeData = (data: any, e: any, mainIndex: number) => {
    // const categoryFunction: any = getUniqueNames(
    //   data,
    //   "categoryName",
    //   "functionName"
    // );
    //setFunctionData(categoryFunction);
  };

  const removeDynamicForm = (mainIndex: number, formIndex: any) => {
    dispatch({
      type: REMOVE_DYNAMIC_FORM,
      payload: { tabIndex: index, mainIndex: mainIndex, formIndex: formIndex },
    });
  };

  return (
    <>
      <div className="form-border" style={{ paddingTop: "3vw" }}>
        {treeComponentVisible && (
          <div>
            <TreeComponent functionName={functionData} />
          </div>
        )}
        <div className="row">
          {formElements &&
            Object.entries(formElements).map(([key, value]) => {
              if (value?.create == 'form' && routeId !== undefined) {     //(value?.dynamic == false || value?.dynamic == true) && (!value?.dynamicTable) && 

                return (<>
                  <EditElements
                    data={value}
                    fieldHandler={changeHandler}
                    handleButtonClick={handleButtonClick}
                    customFn={customFn}
                    handleSavePreviousData={handleSavePreviousData}
                    handleDeletePreviousData={handleDeletePreviousData}
                  /></>)
              }
              if (value?.dynamic == true && !value?.dynamicTable) {
                return RenderDynamicComponent(
                  value,
                  key,
                  changeHandler,
                  handleButtonClick,
                  addDynamicForm,
                  removeDynamicForm,
                  addTreeData,
                  true
                );
              }
              if (value?.dynamic === false && !value?.dynamicTable) {
                return RenderDynamicComponent(
                  value,
                  key,
                  changeHandler,
                  handleButtonClick,
                  addDynamicForm,
                  removeDynamicForm,
                  addTreeData,
                  false,
                );
              }
              if (value?.dynamicTable === true && value?.dynamicTable) {
                return RenderTableComponent(
                  value.form,
                  key,
                  changeHandler,
                  handleButtonClick,
                  addDynamicTable,
                  removeDynamicForm,
                  manageIndex,
                  true
                );
              } else {
                return renderFields(key, key, value, changeHandler, handleButtonClick, false);
              }
            })}
        </div>
      </div>
      <div>
        {salaryBenefitForms.length !== 0 && (
          <div>
            <SalaryBenefits />
          </div>
        )}
      </div>
    </>
  );
};

export default CommonComponent;
