import React, { useEffect, useState } from "react";
import {
  HotlistEmployeeTableHeader,
  HotlistProjectTableHeader,
} from "TableHeader";
import SearchModel from "components/atoms/SearchModel";
import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import {
  DELETE_HOTLIST_DATA,
  GET_HOTLIST_DATA,
  SAVE_HOTLIST_DATA,
} from "routes/ApiEndpoints";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import Button from "components/atoms/Button";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddEmployeeModal from "../matching/addEmployeeModel";
import AddProjectModal from "../matching/addProjectModel";
import { PATH_MASTER_DATA, PATH_HR_PLANNING } from "constants/Paths";
import TableStructure from "components/atoms/TableStructure";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import BackButton from "components/atoms/BackButton";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

export interface Hotlist {
  id?: number;
  value: string;
}
interface HotlistProps {
  onChange?: <React, changeHandler>() => void;
  handleSubmitAction?: (clear: boolean) => void;
}
interface HotlistData {
  id: number;
  name: string;
  location: string;
  contract_type: string;
  companyName: string;
  function: string;
  from: string;
  to: string;
  contract_sign: string;
}

const Hotlist: React.FC<HotlistProps> = ({ }) => {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const userData = useSelector(selectAuth);
  const [hotlistData, setHotlistData] = useState<HotlistData[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const navigate = useNavigate();
  const options = [
    { label: "Employee", value: "employee" },
    { label: "Project", value: "project" },
  ];
  const [employeeId, setEmployeeId] = useState<number>(0);
  const [id, setId] = useState({
    projectId: null,
    companyId: null,
  });
  var url = useLocation();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
    options: [],
  })
  const employeeColumns = [
    "name",
    "location",
    "contractType",
    "function",
    "startDate",
    "endDate",
    "status",
  ];
  const projectColumns = [
    "name",
    "companyName",
    "location",
    "startDate",
    "endDate",
    //"projectStatus",
  ];

  const getSearchData = () => {
    return {
      category: history?.filters?.category ?? 'employee',
      name: history?.filters?.name ?? "",
      // location: history?.filters?.location ?? "",
      // startDate: history?.filters?.startDate ?? "",
      // endDate: history?.filters?.endDate ?? "",
      // orderBy: history?.filters?.orderBy ?? "id",
      // sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  }

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
    };
    fetchData(postData);
  }, [pageData.refresh, pageData.initialCall]);

  const fetchData = async (postData: any) => {
    try {
      const response = await ApiCall.service(GET_HOTLIST_DATA, "POST", postData, false, M_MASTER_DATA);
      if (response?.status === 200) {
        setHotlistData(response.data);
        setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false}));
      }
    } catch (error) { }
  };

  const handleDelete = (entities: any) => {
    setShowDeleteModal(true);
    setDeleteId(entities.id);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleSaveChanges = async () => {
    if (deleteId) {
      const id = {
        id: deleteId,
      };
      const response = await ApiCall.service(DELETE_HOTLIST_DATA, "POST", id, false, M_MASTER_DATA);
      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: "Deleted  successfully from  hotlist",
        });
        setShowDeleteModal(false);
        handleRefresh();
      } else {
        CustomNotify({ type: "success", message: "unableto delete" });
      }
    }
  };

  function handleAddPlanning(value: any): void {
    let navigateValue = history?.filters?.category === "project" ? `project=${value?.projectId}` : `employee=${value.user_id}`
    navigate(`${PATH_HR_PLANNING}?${navigateValue}`);
  }

  const handleClose = () => {
    setId({...id, projectId: null, companyId: null});
    setEmployeeId(0);
    setShowAddModal(false);
    setSelectedProject("");
  }

  //Handle add to project
  function handleAddProject(entities: any): void {
    setId({
      ...id,
      projectId: entities.projectId,
      companyId: entities.companyId,
    });
    setEmployeeId(entities.user_id);
    setShowAddModal(true);
    setSelectedProject(entities?.name);
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
  
  const filters = [
    { name: "category", fieldType: "singleSelect", options: options, placeholder: "Select category", filterType: "search", default: 'employee'},
    { name: "name", fieldType: "text", options: options, placeholder: "Search", filterType: "search"},
  ];

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Hotlist",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage hotlist")} />
        <FilterOrganism
          dispatch={dispatch}
          history={history}
          updatePageFilters={updatePageFilters}
          filters={filters}
          handleRefresh={handleRefresh}
        />
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="table-responsive  tableSection">
          <TableStructure
            isAction
            headers={history?.filters?.category === "project" ? HotlistProjectTableHeader : HotlistEmployeeTableHeader}
            data={hotlistData}
            values={history?.filters?.category === "project" ? projectColumns : employeeColumns}
            handleDelete={handleDelete}
            handleAddProject={handleAddProject} //add to project icon
            handleAddPlanning={handleAddPlanning}
            permissionType={"Hotlist"}
          />

          <div className="" style={{ paddingTop: "0.25vw" }} >
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>
      <ModalPopup
        show={showDeleteModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
      {showAddModal ? (
        history?.filters?.category === "project" ? (
          <AddEmployeeModal
            handleClose={handleClose}
            projectName={selectedProject}
            url={url.pathname + "/" + id.companyId + "/" + id.projectId}
            handleRefresh={handleRefresh}
          />
        ) : (
          <AddProjectModal
            handleClose={handleClose}
            url={url.pathname + "/" + employeeId}
            handleRefresh={handleRefresh}
          />
        )
      ) : (
        ""
      )}
      {/* Back Button */}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "1vw 0" }}
      >
        <BackButton />
      </div>
    </AccessControl>
  );
};
export default Hotlist;
