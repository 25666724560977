import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import { useFormContext } from "../../context/Context";
import { ADD_STUDY_FIELDS_SET, REMOVE_STUDY_FIELDS_SET, UPDATE_ERROR_FIELD, UPDATE_STUDIES_ERROR, UPDATE_STUDY_FIELD } from "../../annotations/CandidateConstants";
import Close from "static/images/Close";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import LabelField from "components/atoms/LabelField";
import RadioField from "components/atoms/RadioField";
import Calender from "../../helpers/Calender";
import { ValidationRules } from "utils/TypeAnnotations";
import { validateForm, validateSelectField } from "services/validation/ValidationService";

const StudiesTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const currectDate = new Date();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const validation = (
    name: string,
    value: any,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      educationLevel: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.studies, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: UPDATE_STUDIES_ERROR,
      field: name,
      error: validationErrors[name],
      index
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;
    if (isFieldValid) {
      dispatch({
        type: UPDATE_STUDIES_ERROR,
        field: name,
        error: '',
        index,
      });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string, index: number) => {
    dispatch({
      type: UPDATE_STUDY_FIELD,
      index: index,
      field: fieldName,
      value: selectedOption
    });
    validation(fieldName, selectedOption, index, true);
  }

  const handleFieldChange = (e: any, index: number) => {
    const { name, value, type } = e.target;
    let updatedValue: any = value;
    let updatedName: string = name;
    if (type === 'radio') {
      updatedValue = parseInt(value);
      updatedName = "completed";
      if (updatedValue === 1) {
        const toDate = state.studies[index].to;
        const checkDate = toDate !== null ? new Date(toDate) > currectDate : false;
        if (checkDate) {
          dispatch({
            type: UPDATE_STUDIES_ERROR,
            field: updatedName,
            error: "Please make sure to date should not exceed current date",
            index,
          });
        }
      } else {
        dispatch({
          type: UPDATE_STUDIES_ERROR,
          field: updatedName,
          error: "",
          index,
        });
      }
    }
    dispatch({
      type: UPDATE_STUDY_FIELD,
      index: index,
      field: updatedName,
      value: updatedValue
    });
  }

  const handleDateChange = (date: Date | null, fieldName: string, index: number) => {
    dispatch({
      type: UPDATE_STUDY_FIELD,
      index: index,
      field: fieldName,
      value: date
    });
    if (fieldName === "to") {
      const checkDate = date !== null ? new Date(date) < currectDate : false;
      if (checkDate) {
        dispatch({
          type: UPDATE_STUDIES_ERROR,
          field: 'completed',
          error: "",
          index,
        });
      } else {
        if (state.studies[index].completed === 1) {
          dispatch({
            type: UPDATE_STUDIES_ERROR,
            field: 'completed',
            error: "Please make sure to date should not exceed current date",
            index,
          });
        }
      }

    }
  }

  const handleAddClick = () => {
    dispatch({
      type: ADD_STUDY_FIELDS_SET
    });
  }

  const handleFileChange = async (event: any, field: any, index: number) => {
    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "studies");
    }
    dispatch({
      type: UPDATE_STUDY_FIELD,
      index: index,
      field: field,
      value: fileData
    });
  };

  const handleRemove = (index: number) => {
    dispatch({
      type: REMOVE_STUDY_FIELDS_SET,
      indexToRemove: index
    });
  }

  return <>
    <div className="form-border" style={{ paddingTop: "3vw" }}>
      {state.studies && state.studies.length > 0 && state.studies.map((studyFields: any, index) => (
        <div key={index} className={`${index === 0 ? "" : "internalContentWrapper"} `} style={{marginBottom: index === 0 ? "1vw" : ""}}>
          {index != 0 && <div className="text-end" onClick={() => handleRemove(index)}><span className="table-action-btn cursor-pointer" title={t("Close")}>
            <Close />
          </span></div>}
          <div className="row">
            <div className="col-4">
              <SelectWithSearch
                title={t("Education level")}
                isMandatory={true}
                search={true}
                options={state.dropDownData.educationLevelList}
                onChange={(e) => handleSelectChange(e, "educationLevel", index)}
                isMulti={false}
                name="educationLevel"
                value={studyFields.educationLevel}
                error={state.studyErrors[index]?.educationLevel}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Diploma")}
                isMandatory={false}
                search={true}
                options={state.dropDownData.diplomaList}
                onChange={(e) => handleSelectChange(e, "diploma", index)}
                isMulti={false}
                name="diploma"
                value={studyFields.diploma}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={false}
                name="fieldOfStudy"
                handleChange={(e) => handleFieldChange(e, index)}
                id="fieldOfStudy"
                label="Field of study"
                type="text"
                value={studyFields.fieldOfStudy}
                error=""
              />
            </div>
            <div className="col-3">
              <LabelWithInputField
                isMandatory={false}
                name="school"
                handleChange={(e) => handleFieldChange(e, index)}
                id="school"
                label="School"
                type="text"
                value={studyFields.school}
                error=""
              />
            </div>
            <div className="col-2">
              <Calender
                onChange={(date) => handleDateChange(date, "from", index)}
                label={t('From')}
                isMandatory={false}
                name={'from'}
                selectedDate={studyFields.from}
                maxDate={studyFields.to}
              ></Calender>
            </div>
            <div className="col-2">
              <Calender
                onChange={(date) => handleDateChange(date, "to", index)}
                label={t('To')}
                isMandatory={false}
                name={'to'}
                selectedDate={studyFields.to}
                minDate={studyFields.from}
              ></Calender>
            </div>
            <div className="col-2">
              <div>
                <LabelField
                  title={t("Completed")}
                  isMandatory={false}
                  key="completed"
                />
              </div>
              <div style={{ paddingTop: "0.8vw" }}>
                <div className="d-inline-block marginRight1">
                  <RadioField
                    name={`completed-${index}`}
                    value={1}
                    ischecked={studyFields.completed == 1}
                    handleChange={(e) => handleFieldChange(e, index)}
                    label={t('Yes')}
                  />
                </div>
                <div className="d-inline-block">
                  <RadioField
                    name={`completed-${index}`}
                    value={0}
                    ischecked={studyFields.completed == 0}
                    handleChange={(e) => handleFieldChange(e, index)}
                    label={t('No')}
                  />
                </div>
              </div>
              <span className="text-danger">{state.studyErrors[index]?.completed}</span>
            </div>
            <div className="col-3 fileUploadFullWidth">
              <MaxiconFileUpload
                label={t('Upload certificate')}
                name={'uploadCertificate'}
                id={String(index)}
                isMandatory={false}
                edit={false}
                fileId={null}
                fileObj={JSON.stringify(studyFields.uploadCertificate)}
                multiple={false}
                uploadPath="candidate-certificate"
                formats={'value.formats'}
                handleChange={(e: any) => handleFileChange(e, "uploadCertificate", index)}
                maxFileSize={10000}

              />
            </div>
            <TextAreaMolecule
              label={t('Info')}
              name={t('info')}
              id={t('info')}
              placeholder={"info"}
              handleChange={(e) => handleFieldChange(e, index)}
              isMandatory={false}
              value={studyFields.info}
              error={''}
              textAreaWrapper="hideHeight"
            />
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-12">
          <Button
            title={"+ " + t("Add another")}
            handleClick={handleAddClick}
            className="form-button float-end px-3"
          />
        </div>
      </div>

    </div>
    <div style={{ padding: "1vw 0" }}>
      <Navigation validStatus={validStatus} />
    </div>
  </>
}
export default StudiesTab;