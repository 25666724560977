import React, { useState, useEffect } from "react";
import SearchModel from "components/atoms/SearchModel";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { ARCHIVE_TAG } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { RoleTableHeader } from "TableHeader";
import { GET_TAGS } from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import { PATH_CREATE_TAGS, PATH_MASTER_DATA } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import BackButton from "components/atoms/BackButton";

interface Tags {
  id?: number;
  name?: string;
}
const ManageTagPage: React.FC = ({ }) => {
  const [tags, setTags] = useState([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          GET_TAGS,
          "GET",
          M_MASTER_DATA
        );
        setTags(response.data);
      } catch (error) {
        console.error(ERROR, error);
      }
    };

    fetchData();
  }, []);

  // Filter the data based on the search query
  const filteredData = tags.filter((item: Tags) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  // Handle delete change
  const deleteTag = (Tag: Tags | any) => {
    setShowModal(true);
    setDeleteId(Tag.id);
  };
  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };
  // Handle edit change
  const EditTag = (Tag: Tags | any) => {
    if (Tag.id) {
      navigate(`${PATH_CREATE_TAGS}${Tag.id}`);
    }
  };
  //Handle page Cahnges
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  //handle close model
  const handleModalClose = () => {
    setShowModal(!showModal);
  };
  //handle archive changes
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${ARCHIVE_TAG}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 403) {
        setShowModal(true);
      } else if (response?.status === 200) {
        const newtagsObj = tags.filter((item: Tags) => item.id !== deleteID);
        setTags(newtagsObj);
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "error", message: response.msg });
      }
    }
  };
  const columns = ["name"];
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Tags",
        read: true
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="form-height-dashboard">
            <div className="row header-sticky position-sticky">
              <div className="col-md-12">
                <h1 className="py-4 page-title mb-0">{t("Manage tags")}</h1>
              </div>
            </div>
            <div className="row pb-3 search-bar">
              <div className="col-8">
                <SearchModel
                  className="form-control"
                  placeHolder={t("Search by tag name")}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col pb-3">
                <AccessControl
                  requiredPermissions={[{
                    permission: "Tags",
                    create: true,
                  }]}
                  override={userData.isSuperAdmin}
                >
                  <Link
                    to="/create/tags"
                    className="form-button float-end d-flex align-items-center text-decoration-none text-uppercase"
                  >
                    + {t("Create Tag")}
                  </Link>
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive managetags">
              <TableStructure
                isAction
                headers={RoleTableHeader}
                data={currentItems}
                values={columns}
                handleEdit={EditTag}
                handleArchive={deleteTag}
                permissionType={"Tags"}
              />
            </div>
          </div>

          <div className="pagination justify-content-center align-items-center my-3">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>

          <div className="mb-3">
            <BackButton />
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageTagPage;
