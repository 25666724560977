import {
  EDIT_STUDY_FIELD,
  EUROPEAN_CITIZEN,
  GENERAL,
  JOB_PROFILE,
  OTHERS,
  PROFILE_CARD,
  SET_DROPDOWN_DATA,
  UPDATE_TAB_DATA,
} from "../../annotations/CandidateConstants";
import { SetJobProfileData } from "./SetJobProfileData";

const processGeneralDateFields = (general: any) => {
  if (general.dob && typeof general.dob === "string") {
    general.dob = new Date(general.dob);
  }
};
const processEuropeanDateFields = (europeanCitizen: any) => {
  if (europeanCitizen.from && typeof europeanCitizen.from === "string") {
    europeanCitizen.from = new Date(europeanCitizen.from);
  }
  if (europeanCitizen.to && typeof europeanCitizen.to === "string") {
    europeanCitizen.to = new Date(europeanCitizen.to);
  }
};

// Helper function to dispatch tab data if valid
export const dispatchTabData = (dispatch: any, tab: string, tabData: any) => {
  if (Object.keys(tabData).length > 0) {
    dispatch({
      type: UPDATE_TAB_DATA,
      tab,
      data: tabData,
    });
  }
};

const dispatchFieldUpdates = (
  dispatch: any,
  data: any,
  tab: string,
  dateFields: string[] = []
) => {
  const list = data[tab];

  if (list && list.length > 0) {
    list.forEach((item: Record<string, any>, index: number) => {
      Object.entries(item).forEach(([field, value]) => {
        // Convert specified date fields to Date object if necessary
        if (dateFields.includes(field) && value && typeof value === "string") {
          value = new Date(value);
        }
        if (tab === "studies") {
          dispatch({
            type: EDIT_STUDY_FIELD,
            index,
            field,
            value,
          });
        }
        // Dispatch the update with the common "EDIT_FIELD" action
        dispatch({
          type: "EDIT_FIELD",
          field,
          value,
          index,
          tab,
        });
      });
    });
  }
};

const SetCandidateData = (
  data: any,
  dispatch: any,
  id: string | null | undefined = null,
  state: any
) => {
  const dropdownData = data?.dropDownDetails;
  // Dispatch the action to update the dropdown data
  dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownData });
  // dispatch({ type: UPDATE_FIELD, template: COMPETENCES, field: "competence", value: data?.competence });

  if (id) {
    const candidateData = data?.candidateData;
    //profile card details
    const profileCardDetails = candidateData?.profileCard;
    dispatchTabData(dispatch, PROFILE_CARD, profileCardDetails || []);
    //general tab
    const generalTabData = candidateData?.general || [];
    processGeneralDateFields(generalTabData); // Convert `from` and `to` fields to Date objects
    dispatchTabData(dispatch, GENERAL, generalTabData || []);
    //other details
    dispatchTabData(dispatch, OTHERS, candidateData?.others || []);
    //job profile details
    dispatchTabData(dispatch, JOB_PROFILE, candidateData?.jobProfile || []);
    //european citizen details
    const europeanCitizenTabData = candidateData?.europeanCitizen || [];
    processEuropeanDateFields(europeanCitizenTabData);
    dispatchTabData(dispatch, EUROPEAN_CITIZEN, europeanCitizenTabData);
    // Emergency Contact Details
    dispatchFieldUpdates(dispatch, candidateData, "emergencyContactDetails");
    // Study Details with date fields "from" and "to"
    dispatchFieldUpdates(dispatch, candidateData, "studies", ["from", "to"]);
    // Work Experience Details with date fields "from" and "to"
    dispatchFieldUpdates(dispatch, candidateData, "workExperience", [
      "from",
      "to",
    ]);
    // Certificate Details with date fields "validFrom" and "validUntill"
    dispatchFieldUpdates(dispatch, candidateData, "certificates", [
      "validFrom",
      "validUntil",
    ]);
    // Document Details with date fields "validFrom" and "validUntil"
    dispatchFieldUpdates(dispatch, candidateData, "documents", [
      "validFrom",
      "validUntil",
    ]);

    if (candidateData?.jobProfile) {
      //Pc
      const pcId = candidateData.jobProfile.desiredPartiarComitte;
      SetJobProfileData(dispatch, pcId, "desiredPartiarComitte");
      //Category
      const categoryId = candidateData.jobProfile.desiredCategory;
      SetJobProfileData(dispatch, categoryId, "desiredCategory");
    }
  }
};

export default SetCandidateData;
