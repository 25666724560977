import React from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputFieldAndUnit } from "../../templates/atoms/LabelWithInputFieldAndUnit";
import { useFormContext } from "utils/multiTabForm/context/Context";
import { UPDATE_SALARY_BENEFIT_FIELD_DATA } from "utils/multiTabForm/context/Constants";
import { occurenceFieldOptions } from "../../salary-benefits/salaryBenefitConstants";

const SalaryBenefits = () => {
  const { state, dispatch } = useFormContext();
  const currentTab = state.currentTab;
  const salaryBenefit: any = state.form[currentTab];
  let formData: any = salaryBenefit[0].form;

  const changeHandler = (
    event: any,
    key: any,
    type?: string,
    fieldName?: any
  ) => {
    let field: any = fieldName;
    let values: any;

    switch (type) {
      case "text":
        field = event.target.name;
        values = event.target.value;
        break;
      case "select":
        values = event.value;
        break;
      case "date":
        values = event;
        break;
      case "checkbox":
        values = event.target.checked;
        break;
      default:
        break;
    }

    dispatch({
      type: UPDATE_SALARY_BENEFIT_FIELD_DATA,
      payload: {
        field,
        value: values,
        index: currentTab,
        valueField: "value",
        key,
      },
    });
  };

  return (
    <>
      {Array.isArray(formData) &&
        formData.map((formItem: any, idx: any) => (
          <form key={idx}>
            <div className="form-border">
              <div className="row">
                <div className="form-group col-12">
                  <LabelWithInputField
                    isMandatory={true}
                    name="name"
                    handleChange={(e) => changeHandler(e, idx, "text")}
                    value={formItem.name || ""}
                    id="name"
                    label="Name"
                    placeholder="Name"
                    type="text"
                    readOnly={true}
                    error={formItem.errors?.name}
                  />
                </div>

                <div className="row">
                  {formItem.salary_benefit_type === 1 && (
                    <div className="form-group col-md-6">
                      <LabelWithInputFieldAndUnit
                        label="Salary benefit value"
                        name="benefit_value"
                        id="benefit_value"
                        handleChange={(e) => changeHandler(e, idx, "text")}
                        value={formItem.benefit_value || ""}
                        placeholder="Select"
                        type="text"
                        error={formItem.errors?.benefit_value}
                        unit={formItem.salary_benefit_value === 2 ? "%" : "€"}
                        containerClassName="col-12 mb-4 position-relative"
                      />
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <SelectWithSearch
                    title="Occurence"
                    name="occurence"
                    id="occurence"
                    isMandatory={true}
                    search={true}
                    options={occurenceFieldOptions}
                    placeHolder="Select"
                    value={formItem.occurence || ""}
                    onChange={(e) =>
                      changeHandler(e, idx, "select", "occurence")
                    }
                    isMulti={false}
                    className="select-field"
                    error={formItem.errors?.occurence}
                  />
                </div>

                {/* <div className="form-group col-md-6">
                  <Calender
                    isMandatory={true}
                    name="start_date"
                    onChange={(date) =>
                      changeHandler(date, idx, "date", "start_date")
                    }
                    label="Start date"
                    minDate={new Date()}
                    error={formItem.errors?.start_date}
                    selectedDate={formItem.start_date || new Date()}
                  />
                </div> */}
                {/* <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Start date")}
                  name="start_date"
                  id="start_date"
                  isMandatory={true}
                  search={true}
                  options={DATE_OPTIONS}
                  placeHolder="Select"
                  value={formItem.start_date}
                  onChange={(e) => changeHandler(e, idx, "select", "start_date")}
                  isMulti={false}
                  className="select-field"
                  error={formItem.errors?.start_date}
                />
              </div> */}

                <div className="row">
                  <div className="form-group col-md-6">
                    <CheckBoxField
                      name="is_mandatory"
                      label="Is this mandatory?"
                      isChecked={formItem.is_mandatory || false}
                      id="is_mandatory"
                      onChangeHandler={(e) =>
                        changeHandler(e, idx, "checkbox", "is_mandatory")
                      }
                      lineHeight="1.5vw"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <CheckBoxField
                      name="is_benefit_granted"
                      label="Is the benefit granted in case of absence of the employee?"
                      isChecked={formItem.is_benefit_granted || false}
                      id="is_benefit_granted"
                      onChangeHandler={(e) =>
                        changeHandler(e, idx, "checkbox", "is_benefit_granted")
                      }
                      lineHeight="1.5vw"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row my-3"></div> */}
          </form>
        ))}
    </>
  );
};

export default SalaryBenefits;
