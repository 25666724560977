import { SET_DROPDOWN_DATA } from "../../annotations/CandidateConstants";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";

export const SetJobProfileData = async (
  dispatch: any,
  selectedOption: any,
  fieldName: string,
  state?: any
) => {
  let response = null;
  if (fieldName === "desiredPartiarComitte") {
    response = await ApiCall.service(
      "getPcCategoriesforMulPcs",
      "POST",
      selectedOption,
      true,
      M_MASTER_DATA
    );
  } else if (fieldName === "desiredCategory") {
    response = await ApiCall.service(
      "getPcFunctionsforMulCategories",
      "POST",
      selectedOption,
      true,
      M_MASTER_DATA
    );
  }

  if (response !== null) {
    dispatch({
      type: SET_DROPDOWN_DATA,
      payload: response?.data,
    });
  }
};
