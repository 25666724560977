import React from "react";
import ShiftColumn from "components/organism/projects/shiftOrganism/ShiftColumn";
import { calculateTotalTime } from "services/util/TotalTimeCalculator";
import { DISABLED_DAYS, TIMETABLE_WEEK_DAYS } from "constants/Constants";
import { isValidTime } from "services/validation/ValidationService";

const DaysOfWeekTable: React.FC<{
  selectedShift: any;
  tableIndex: number | string | undefined;
  setShifts: any;
  shifts: any;
  validate?: (day: any, value: any) => void;
  error?: any;
}> = ({ tableIndex, setShifts, shifts, validate, error }) => {
  const index: any = tableIndex ?? 1;

  const handleInputChange = (day: string, field: string, value: string) => {
    setShifts((prevShifts: any) => ({
      ...prevShifts,
      [index]: {
        ...prevShifts[index],
        [day]: {
          ...prevShifts[index]?.[day],
          [field]: value,
          total: "",
        },
      },
    }));
    validate && validate(day, { field, value });
    updateTotal(day);
  };

  const updateTotal = (day: string) => {
    setShifts((prevShifts: any) => {
      const shift = prevShifts[index]?.[day];
      const from = shift?.from;
      const till = shift?.till;
      let pause;
      if (
        !isValidTime(from) ||
        !isValidTime(till) ||
        (from === "00:00" && till === "00:00")
      ) {
        pause = "00:00";
      } else {
        pause = shift?.pause;
      }
      const totalValue = calculateTotalTime(from, till, pause);
      if (totalValue && day !== undefined && day.trim() !== "") {
        return {
          ...prevShifts,
          [index]: {
            ...prevShifts[index],
            [day]: {
              from,
              till,
              pause,
              total: totalValue,
            },
          },
        };
      }
      return prevShifts;
    });
  };

  const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
  };

  return (
    <div key={`${tableIndex}-projects-shift`} style={{ marginBottom: "0.5vw" }}>
      <div className="d-flex text-center">
        <table style={tableStyle} className="table">
          <thead>
            <tr className="TableHeader">
              <th className="border-end opacity-0 py-1">header</th>
            </tr>
          </thead>
          <tbody>
            {["From", "Till", "Pause", "Total"].map((field) => (
              <tr key={`${tableIndex}-${field}`} className="border-end">
                <td className="align-middle">{field}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {TIMETABLE_WEEK_DAYS.map((day) => (
          <table
            style={tableStyle}
            key={`${tableIndex}-${day}-table`}
            className="table"
          >
            <thead>
              <tr className="TableHeader border-end">
                <th className="py-1">{day}</th>
              </tr>
            </thead>
            <ShiftColumn
              key={`${tableIndex}-${day}`}
              day={day}
              error={day && error?.[day]}
              disabled={day && DISABLED_DAYS?.includes(day)}
              shiftData={shifts[index]?.[day] || {}}
              onInputChange={handleInputChange}
            />
          </table>
        ))}
      </div>
    </div>
  );
};

export default DaysOfWeekTable;
