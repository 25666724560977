import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "../translation/Translation";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { CONFIG_HEADERS } from "TableHeader";
import TableActions from "components/organism/Actions/TableAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PATH_CONFIG_ELEMENTS_CREATE, PATH_MASTER_DATA } from "constants/Paths";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  GET_MASTER_DATA_CONFIG_ELEMENT_LIST,
  MASTER_DATA_CONFIG_ELEMENT_ARCHIVE,
  MASTER_DATA_CONFIG_ELEMENT_DATA,
  MASTER_DATA_CONFIG_ELEMENT_UNARCHIVE,
} from "routes/ApiEndpoints";
import { Option } from "react-multi-select-component";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import HelpInfoIcon from "static/images/HelpIcon";
import CustomNotify from "components/atoms/CustomNotify";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

const ManageMasterDataElements: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [configElements, setConfigElements] = useState<Option[]>([]);
  const [message, setMessage] = useState("");
  const [selectedElement, setSelectedElement] = useState<any>(
    location.state?.element ?? "gender"
  );
  const [search, setSearch] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    currentPage: number;
    totalPages: number;
  }>({ currentPage: 1, totalPages: 0 });
  const [tableHeader, setTableHeaders] = useState<any>([]);

  const handleCategoryChange = (e: any) => {
    setSearch({});
    if (e?.label !== "Select") {
      setPagination({ currentPage: 1, totalPages: 0 });
      setSelectedElement(e.value);
    }
  };

  useEffect(() => {
    console.log("chcek");

    setTableHeaders(CONFIG_HEADERS);
    const getData = async () => {
      try {
        let postData = {
          configElement: selectedElement,
          search: search?.name,
          page: pagination.currentPage,
        };
        let response = await fetchData(
          MASTER_DATA_CONFIG_ELEMENT_DATA,
          postData
        );
        setData(response?.data);
        setMessage(response?.message);
        setPagination({ ...pagination, totalPages: response.totalPages });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [selectedElement, pagination.currentPage]);

  const handleSearchChange = (e: any) => {
    const { name, value } = e.target;
    setSearch((pre: any) => ({ ...pre, [name]: value }));
  };

  const handleSearchSubmit = async (e: any, clear: boolean = false) => {
    e.preventDefault();
    let searchData = search?.name;
    if (clear) {
      setSearch({});
      searchData = "";
    }
    let postData = {
      configElement: selectedElement,
      search: searchData,
    };
    let response = await fetchData(MASTER_DATA_CONFIG_ELEMENT_DATA, postData);
    if (response?.status == 200) {
      setData(response?.data);
      setPagination({ currentPage: 1, totalPages: response?.totalPages });
    } else {
      console.log("issue while fetching data");
    }
  };

  const handleCreate = () => {
    navigate(`${PATH_CONFIG_ELEMENTS_CREATE}/?element=${selectedElement}`);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let postData = {};
        let response = await fetchData(
          GET_MASTER_DATA_CONFIG_ELEMENT_LIST,
          postData
        );
        setConfigElements(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, []);

  const fetchData = async (url: string, postData: any) => {
    return await ApiCall.service(url, "POST", postData, true, M_MASTER_DATA);
  };

  const handleEdit = (e: any) => {
    navigate(
      `${PATH_CONFIG_ELEMENTS_CREATE}/?id=${e.id}&element=${selectedElement}`
    );
  };

  const handleArchive = async (e: any) => {
    let postData = {
      id: e?.id,
      configElement: selectedElement,
      status: e?.status ? "false" : "true",
    };
    let response = await fetchData(
      MASTER_DATA_CONFIG_ELEMENT_ARCHIVE,
      postData
    );
    if (response?.status === 200) {
      setData((pre: any) =>
        pre.map((item: any) => {
          return item.id === e.id ? { ...item, status: !e?.status } : item;
        })
      );
      CustomNotify({
        type: "success",
        message: `Element ${
          e?.status ? "archived" : "unarchived"
        } successfully`,
      });
    }
  };

  const onPageChange = (number: number) => {
    setPagination({ ...pagination, currentPage: number });
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleSaveChanges = () => {};

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Config elements",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage master data configuration")} />
        <div className="row">
          <div className="col-5">
            <SelectWithSearch
              search={true}
              onChange={handleCategoryChange}
              title={t("Select element type")}
              options={configElements}
              placeHolder={t("Select")}
              isMulti={false}
              name={"configCat"}
              isMandatory={false}
              value={selectedElement}
              error={""}
              containerClassName="w-100"
              id={"config-cat"}
            />
          </div>
          <div className="col-1 ps-0 searchBtnWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Config elements",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <Button
                title={t("Create")}
                handleClick={handleCreate}
                className="form-button"
                tooltip={t("Create")}
                disabled={selectedElement ? false : true}
              />
            </AccessControl>
          </div>
          <div className="col-3 searchBtnWrapper ps-0">
            <SearchWithLabel
              label={t("Project name")}
              isMandatory={false}
              showLabel={false}
              name={"name"}
              type={"text"}
              value={search?.name}
              placeholder={t("Search by name...")}
              isDisabled={false}
              handleChange={handleSearchChange}
              className="col-12"
            />
          </div>
          <div className="col-3 searchBtnWrapper">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={(e) => handleSearchSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSearchSubmit} />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="col-6 d-flex align-items-center"
          style={{ marginTop: "0.5vw", marginBottom: "1vw" }}
        >
          <span>
            <HelpInfoIcon />
          </span>
          <span className="ps-2"> {message}</span>
        </div>

        <div className="position-relative">
          <div className="table-responsive tableSection">
            <DynamicTableStructure
              isAction
              headers={tableHeader}
              data={data}
              handleEdit={handleEdit}
              handleArchive={handleArchive}
              name={"config_elements"}
              permission={"Config elements"}
            />

            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={pagination?.currentPage}
                totalPages={pagination?.totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "1vw 0" }}>
          <BackButton />
        </div>

        <div>
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={t("Archive confirmation")}
            body={t("Are you sure want to archive?")}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            closeTitle={t("No")}
            confirmTitle={t("Yes")}
          />
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageMasterDataElements;
