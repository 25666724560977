import { useParams } from "react-router-dom";
import { useState } from "react";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  scrollToTop,
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { ADD_LOCATION } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import {
  COMPETENCES,
  GENERAL,
  OFFER,
  SET_OPTIONS,
  UPDATE_FIELD_ERROR,
  UPDATE_FIELDS,
} from "../../annotation/VacancyConstants";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelField from "components/atoms/LabelField";
import RadioField from "components/atoms/RadioField";
import CheckBoxField from "components/atoms/CheckBoxField";
import Navigation from "../form-navigation/Navigation";
import ModalPopup from "components/atoms/ModalPopup";
import AddPlusIcon from "static/images/AddPlusIcon";
import LocationFormOrganism from "components/organism/location/LocationFormOrganism";
import { FormMode } from "components/common/CommonEnums";
import { useFormContext } from "../../context/Context";
import { M_COMPANY_CREATION } from "constants/Constants";
import {
  getCompanyOptions,
  getOnChangeProjects,
  getPcFunctionOptions,
} from "../../utils/Utils";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const VacancyGeneralDetails = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const authData = useSelector(selectAuth);
  const [modalState, setModalState] = useState<any>({
    showModal: false,
    modalData: { location: "", phone: "", street: "", number: "", zipcode: "", city: "", country: null, capacity: "", },
    errors: [],
  });

  const addLocation = () => {
    if (!state.general.company) {
      CustomNotify({ type: "error", message: t("Please select company") });
    } else {
      setModalState((prev: any) => ({ ...prev, showModal: true }));
    }
  };

  const handleModalClose = () => {
    setModalState((prev: any) => ({
      ...prev,
      errors: [],
      modalData: [],
      showModal: false,
    }));
  };

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: any = null
  ) => {
    const { name, value, type } = event?.target;
    if (type === "radio") {
      dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: name, value: value });
    } else if (name === "capacity") {
      const newValue = value.replace(/[^0-9,]/g, "");
      dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: name, value: newValue });
      validation(name, value, true);
    } else if (name === "aliasName") {
      dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: name, value: value });
      validation(name, value, true);
    } else if (name === "postjobto") {
      const checked = (event.target as HTMLInputElement).checked;
      let val: any = checked ? 1 : 0;
      val = { ...state?.general?.postjobto, [field?.value]: val };
      dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: name, value: val });
      if (name === "postjobto" && field?.label === "VDAB") {
        const fieldsToUpdate = ["vdabTemplate", "vdabCompetences"];
        fieldsToUpdate?.forEach((field: string) => {
          dispatch({ type: UPDATE_FIELD_ERROR, field: field, error: "" });
        });
      }
    } else {
      setModalState((prev: any) => ({
        ...prev,
        modalData: {
          ...prev?.modalData,
          [name]: value,
        },
      }));
      modalValidation(name, value, true);
    }
  };

  const handleSelectChange = async (selectedOption: any, name: string) => {
    let fieldsToReset: string[] = [];
    let optionPayload: any = [];
    let competenceValue: any = [];
    let data: any = [];
    let value = Array.isArray(selectedOption)
      ? selectedOption?.map((item: any) => item?.value)
      : selectedOption?.value;
    if (name === "country") {
      setModalState((prev: any) => ({
        ...prev,
        modalData: {
          ...prev?.modalData,
          [name]: selectedOption,
        },
      }));
      modalValidation(name, selectedOption, true);
      return;
    } else if (name === "company") {
      fieldsToReset = ["location", "domain", "contactPerson", "function", "location", "office"];
      optionPayload = { companyId: value };
      data = await getCompanyOptions(state, dispatch, optionPayload);
      let pc = [...data?.ParitairComitee?.blueCollar ?? [], ...data?.ParitairComitee?.whiteCollar ?? []]?.map((item: any) => item?.pc);
      dispatch({
        type: UPDATE_FIELDS, template: GENERAL, field: 'pc',
        value: pc?.map((item: any) => item?.value)
      });
      dispatch({
        type: UPDATE_FIELDS, template: OFFER, field: 'payType',
        value: pc?.[0]?.categoryId === 1
          ? state?.options?.PayType?.find((item: any) => item?.label?.toLowerCase()?.includes('month')) || null
          : pc?.[0]?.categoryId === 2
            ? state?.options?.PayType?.find((item: any) => item?.label?.toLowerCase()?.includes('hour')) || null
            : null
      });
    } else if (name === "pc") {
      fieldsToReset = ["domain", "function"];
      optionPayload = { pc_id: value };
      if (selectedOption?.length > 0) {
        dispatch({
          type: UPDATE_FIELDS, template: OFFER, field: 'payType',
          value: selectedOption?.[0]?.categoryId === 1
            ? state?.options?.PayType?.find((item: any) => item?.label?.toLowerCase()?.includes('month')) || null
            : selectedOption?.[0]?.categoryId === 2
              ? state?.options?.PayType?.find((item: any) => item?.label?.toLowerCase()?.includes('hour')) || null
              : null
        });
        dispatch({
          type: SET_OPTIONS,
          payload: {
            PcCategories: selectedOption.flatMap((item: any) => item?.pcCategories ?? []),
            PcFunctions: selectedOption.flatMap((item: any) => item?.pcFunctions ?? []),
            EmployeeType: selectedOption.flatMap((item: any) => item?.employeeType ?? []),
          },
        });
      } else {
        dispatch({ type: UPDATE_FIELDS, template: OFFER, field: 'payType', value: null });
        dispatch({
          type: SET_OPTIONS,
          payload: { ParitairComitee: { whiteCollar: state?.options?.ParitairComitee } },
        });
      }
      data = await getPcFunctionOptions(state, dispatch, optionPayload);
    } else if (["domain", "function"]?.includes?.(name)) {
      optionPayload = {
        pc_id: state?.general?.pc,
        function_id: name === "function" ? value : name === "domain" ? [] : state?.general?.function,
        category_id: name === "domain" ? value : state?.general?.domain,
      };
      data = await getPcFunctionOptions(state, dispatch, optionPayload);
      if (name === "function") {
        fieldsToReset = ["competence"];
        competenceValue = state?.competences?.competence?.filter((item: any) => {
          return data?.Competence?.some((optionItem: any) => optionItem?.id === item?.competence_id)
        });
      } else if (name === "domain") {
        fieldsToReset = ["function"];
      }
    } else if (name === "project") {
      fieldsToReset = ["location", "contactPerson", "office", "competence"];
      optionPayload = { project_id: value, companyId: state?.general?.company };
      data = await getOnChangeProjects(state, dispatch, optionPayload);
      competenceValue = data?.ProjectCompetence ?? [];
    }
    if (fieldsToReset?.includes("competence")) {
      dispatch({ type: UPDATE_FIELDS, template: COMPETENCES, field: "competence", value: competenceValue, });
    }
    fieldsToReset?.forEach((field) => {
      field != "competence" && dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: field, value: null });
    });
    dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: name, value: value });
    validation(name, selectedOption, true);
  };

  const validation = (name: string, value: any, isSingleFieldValidation: boolean = false) => {
    const validationRules: ValidationRules = {
      company: [validateSelectField],
      location: [validateSelectField],
      function: [validateMultiSelectField],
      office: [validateSelectField],
      language: [validateSelectField],
      capacity: [validateRequired],
      targetGroup: [validateMultiSelectField],
      consultant: [validateSelectField],
    };
    const validationErrors = validateForm({ ...state.general, [name]: value }, validationRules, isSingleFieldValidation ? name : undefined);
    dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: validationErrors[name] });
    const isFieldValid = Object.keys(validationErrors).length === 0;
    if (isFieldValid) {
      dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  const modalValidation = (name: string, value: any, isSingleFieldValidation: boolean = false) => {
    const validationRules: ValidationRules = {
      location: [validateRequired],
      phone: [validateRequired],
      street: [validateRequired],
      number: [validateRequired],
      zipcode: [validateRequired],
      city: [validateRequired],
      country: [validateSelectField],
    };
    const validationErrors = validateForm({ ...modalState.modalData, [name]: value }, validationRules, isSingleFieldValidation ? name : undefined);
    if (isSingleFieldValidation && Object.keys(modalState?.errors).length > 0) {
      setModalState((prev: any) => ({
        ...prev,
        errors: {
          ...prev.errors,
          [name]: validationErrors?.[name] ?? "",
        },
      }));
    } else {
      setModalState((prev: any) => ({ ...prev, errors: validationErrors }));
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmitLocation = async (e: React.FormEvent) => {
    e.preventDefault();
    if (modalValidation('phone', modalState?.modalData?.phone)) {
      const response = await ApiCall.service(
        ADD_LOCATION,
        "POST",
        {
          ...modalState?.modalData,
          company: state?.options?.Company?.find(
            (item: any) => item?.value == state?.general?.company
          ),
          project: state?.general?.project ?? null,
        },
        false,
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        setModalState((prev: any) => ({
          ...prev,
          showModal: false,
          modalData: [],
          errors: [],
        }));
        dispatch({
          type: SET_OPTIONS,
          payload: [
            ...state?.options?.Location,
            { value: response?.data?.id, label: response?.data?.name, name: response?.data?.name },
          ],
          field: "Location",
        });
        handleSelectChange({ value: response?.data?.id, label: response?.data?.name, name: response?.data?.name }, "location");
        CustomNotify({ type: "success", message: t(response.msg) });
      } else if (response.status === 409) {
        CustomNotify({ type: "error", message: t(response.msg) });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="vacancyGeneralTab">
          <div className="form-border" style={{ paddingTop: "3vw" }}>
            <div className="row pt-lg-3 pt-xxl-0">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Company")}
                  name="company"
                  placeHolder={t("Select company")}
                  isMandatory
                  search
                  options={state.options.Company}
                  value={state.general.company}
                  onChange={(e) => handleSelectChange(e, "company")}
                  className="select-field"
                  error={state.errors.company}
                  id="company"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Project")}
                  name="project"
                  placeHolder={t("Select project")}
                  search
                  options={state.options.Project}
                  value={state.general.project}
                  onChange={(e) => handleSelectChange(e, "project")}
                  className="select-field"
                  error={state.errors.project}
                  id="project"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  title={t("Contact person")}
                  name="contactPerson"
                  id="contactPerson"
                  placeHolder={t("Select contact Person")}
                  search
                  options={state.options.ContactPersons}
                  value={state.general.contactPerson}
                  onChange={(e) => handleSelectChange(e, "contactPerson")}
                  className="select-field"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <SelectWithSearch
                  title={t("Location")}
                  name="location"
                  id="location"
                  placeHolder={t("Select location")}
                  isMandatory
                  search
                  options={state.options.Location}
                  value={state.general.location}
                  onChange={(e) => handleSelectChange(e, "location")}
                  className="select-field"
                  error={state.errors.location}
                />
              </div>
              <div className="col-md-6 col-lg-1 table-action-icons addBtnMargin" style={{ paddingTop: "0.35vw" }}>
                <Button
                  title={<AddPlusIcon />}
                  handleClick={addLocation}
                  tooltip={t("Add location")}
                  className="btn border-0 table-action-btn p-0"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-xxl-4 col-lg-6">
                <SelectWithSearch
                  title={t("Consultant")}
                  name="consultant"
                  id="consultant"
                  placeHolder={t("Select consultant")}
                  isDisabled={!authData?.role?.some((item) => ['ADMIN', "SUPER_ADMIN"]?.includes(item))}
                  search
                  isMandatory
                  options={state.options.Consultant}
                  value={state.general.consultant}
                  onChange={(e) => handleSelectChange(e, "consultant")}
                  className="select-field"
                  error={state.errors.consultant}
                />
              </div>
              <div className="col-sm-12  col-md-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Business unit")}
                  name="office"
                  id="office"
                  placeHolder={t("Business unit")}
                  isMandatory
                  search
                  options={state.options.BusinessUnit}
                  value={state.general.office}
                  onChange={(e) => handleSelectChange(e, "office")}
                  className="select-field"
                  error={state.errors.office}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Language")}
                  name="language"
                  id="language"
                  placeHolder={t("Select language")}
                  isMandatory
                  search
                  options={state.options.Language}
                  value={state.general.language}
                  onChange={(e) => handleSelectChange(e, "language")}
                  className="select-field"
                  error={state.errors.language}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Paritair committee")}
                  name="pc"
                  placeHolder={t("Select paritair comite")}
                  search
                  options={state.options.ParitairComitee}
                  value={state.general.pc}
                  onChange={(e) => handleSelectChange(e, "pc")}
                  isMulti
                  className="select-field"
                  error={state.errors.pc}
                  id="pc"
                />
              </div>
              {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                  <SelectWithSearch
                    title={t("Sector")}
                    name="sector"
                    id="sector"
                    placeHolder={t("Select sector")}
                    search
                    options={state.options.Sector}
                    value={state.general.sector}
                    onChange={(e) => handleSelectChange(e, "sector")}
                    className="select-field"
                  />
                </div> */}
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Category")}
                  name="domain"
                  id="domain"
                  placeHolder={t("Select category")}
                  search
                  options={state.options.PcCategories}
                  value={state.general.domain}
                  onChange={(e) => handleSelectChange(e, "domain")}
                  className="select-field"
                  error={state.errors.domain}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Function")}
                  name="function"
                  id="function"
                  placeHolder={t("Select function")}
                  isMandatory
                  search
                  options={state.options.PcFunctions}
                  value={state.general.function}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti
                  className="select-field"
                  error={state.errors.function}
                  isMenuPlacement
                />
              </div>
              {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                  <LabelWithInputField
                    isMandatory
                    name="aliasName"
                    handleChange={handleFieldChange}
                    value={state.general.aliasName}
                    id="aliasName"
                    label={t("Function alias name")}
                    type="text"
                    placeholder={t("Function alias name")}
                    error={state.errors.aliasName}
                  />
                </div> */}
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <LabelWithInputField
                  isMandatory
                  name="capacity"
                  handleChange={handleFieldChange}
                  value={state.general.capacity}
                  id="capacity"
                  label={t("Number of employees")}
                  type="text"
                  placeholder={t("Number of employees")}
                  error={state.errors.capacity}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Employee type")}
                  name="empType"
                  id="empType"
                  placeHolder={t("Select employee type")}
                  search
                  options={state.options.EmployeeType}
                  value={state.general.empType}
                  onChange={(e) => handleSelectChange(e, "empType")}
                  isMulti
                  className="select-field"
                  isMenuPlacement
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4">
                <SelectWithSearch
                  title={t("Vacancy type")}
                  name="vacancyType"
                  id="vacancyType"
                  placeHolder={t("Select vacancy type")}
                  search
                  options={state.options.VacancyType}
                  value={state.general.vacancyType}
                  onChange={(e) => handleSelectChange(e, "vacancyType")}
                  isMulti={false}
                  className="select-field"
                  isMenuPlacement
                />
              </div>
              <div className="col-sm-12 col-md-6 col-xxl-4 col-lg-6">
                <SelectWithSearch
                  title={t("Target group")}
                  name="target_group"
                  id="target_group"
                  placeHolder={t("Target group")}
                  isMandatory
                  search
                  options={state.options.TargetGroup}
                  value={state.general.targetGroup}
                  onChange={(e) => handleSelectChange(e, "targetGroup")}
                  isMulti
                  className="select-field"
                  error={state.errors.targetGroup}
                />
              </div>
              <div className="col-sm-12 col-xxl-4 mb-4 mb-xl-0 col-md-6 mt-1">
                <LabelField title={t("Vacancy status")} isMandatory={false} />
                <br />
                <div className="d-flex flex-column flex-xl-row mt-1">
                  <RadioField
                    name="isActive"
                    value={"open"}
                    ischecked={state.general?.isActive === "open"}
                    handleChange={handleFieldChange}
                    label={t("open")}
                  />
                  <RadioField
                    name="isActive"
                    value={"closed"}
                    ischecked={state.general?.isActive === "closed"}
                    handleChange={handleFieldChange}
                    label={t("Closed")}
                    className="ms-xl-3 mt-2 mt-xl-0"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-1 col-lg-6 col-xxl-4 work-experience-checkbox">
                {/* <div className="d-inline-block me-5">
                    <CheckBoxField
                      label={t("Push to website")}
                      name="pushToWebsite"
                      onChangeHandler={handleFieldChange}
                      isChecked={state.general.pushToWebsite === 1}
                      id="pushToWebsite"
                      // lineHeight="25px"
                    />
                  </div> */}
                {state?.options?.PostMediums?.map((item: any) => (
                  <CheckBoxField
                    label={t("Push to ") + item?.label}
                    name={"postjobto"}
                    onChangeHandler={(e) => handleFieldChange(e, item)}
                    isChecked={state.general.postjobto?.[item?.value] === 1}
                    id={"postjobto" + item?.label}
                    lineHeight="1.5vw"
                    className="me-3"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
      <ModalPopup
        show={modalState?.showModal}
        onHide={() => handleModalClose()}
        title={t("Add location")}
        body={
          <form onSubmit={handleSubmitLocation}>
            <LocationFormOrganism
              formMode={FormMode?.CREATE}
              formOptions={{
                companyOptions: state?.options?.Company,
                countryOptions: state?.options?.Country,
              }}
              formData={modalState?.modalData}
              formErrors={modalState?.errors}
              tab="general"
              company={state?.general?.company}
              handleChange={handleFieldChange}
              handleSelectChange={handleSelectChange}
            />
            <div className="d-flex align-items-center justify-content-end">
              <Button
                title={"Cancel"}
                className="close-button me-3 px-2"
                handleClick={() => handleModalClose()}
              />
              <Button
                title={"Save"}
                type="submit"
                className="form-button"
              />
            </div>
          </form>
        }
        className="modal-lg"
      />
    </>
  );
};

export default VacancyGeneralDetails;
