import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { MouseEventHandler } from "react";
import Button from "./Button";

interface props {
  handleSearchClick?: MouseEventHandler<HTMLButtonElement>;
}
const SearchBtn:React.FC<props> = ({
  handleSearchClick
}) => {
  return (
    <Button
      title={t("Search")}
      type="button"
      handleClick={handleSearchClick}
      icon={faMagnifyingGlass}
      className="form-button d-flex align-self-center justify-content-between"
      style={{minWidth:"5vw", paddingLeft:"1.25vw", paddingRight:"1.25vw"}}
    />
  );
};

export default SearchBtn;