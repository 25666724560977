import Targets from "./Targets";
import "../../src/static/css/welcome-dashboard.css";
import { useSelector } from "react-redux";
import * as CONST from "constants/Constants";
import Budget from "./Budget";
import { selectAuth } from "features/auth/AuthSlice";
import TodoDashboard from "./todoDashboard/TodoDashboard";
import ImageField from "components/atoms/ImageField";
import { Link } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import Count from "components/atoms/Count";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import CardTitle from "components/atoms/CardTitle";
import HomePage from "pages/dashboards/HomePage";
import AccessControl from "services/AccessControl";

const WelcomeDashboard: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const commonParams = {
    //common params needed for all components
    userAuth: userAuth,
    CONST: CONST,
    roletype: userAuth.role?.includes("HR") ? "HR" : "SALES",
  };

  return (
    <>
      {!userAuth?.role?.includes("HR") && !userAuth?.role?.includes("SALES") ? (
        <HomePage />
      ) : (
        <div className="row mb-4">
          <div className="col-md-12 d-flex justify-content-center flex-column">
            <div className="row equal-cols">
              <div className="col-12">
                <div>
                  {/* {userAuth.role && !userAuth.role?.includes("ADMIN") && (userAuth.role?.includes("SALES") || userAuth.role?.includes("HR")) && ( */}
                  <AccessControl
                    key={"welcome_message"}
                    requiredPermissions={[
                      { permission: "Welcome message", read: true },
                    ]}
                    override={userAuth.isSuperAdmin}
                  >
                    <div className="d-flex align-items-center">
                      <span className="welcome-text me-3 color-dark-purple">
                        <strong>{t("Hi") + ","}</strong>
                      </span>
                      <div className="position-relative">
                        <span className="name-text color-dark-pink ">
                          {userAuth.name}.
                        </span>
                      </div>
                    </div>
                    <div className="welcome-message color-dark-purple">
                      {t("Welcome back to")}
                      <br />
                      <div className="position-relative">
                        {t("your dashboard")}
                      </div>
                    </div>
                  </AccessControl>
                  {/* )} */}
                </div>
              </div>
              <div className="col-8">
                <Targets params={commonParams} />
              </div>
              {/* Render Budget only if the user role is not EMPLOYER, ADMIN, or THREECX */}
              {/* {!userAuth.role?.includes("ADMIN") && (userAuth.role?.includes("SALES") || userAuth.role?.includes("HR")) && ( */}
              <AccessControl
                key={"my_budget"}
                requiredPermissions={[{ permission: "My budget", read: true }]}
                override={userAuth.isSuperAdmin}
              >
                <Budget params={commonParams} />
              </AccessControl>
              {/* )} */}
              <div className="row pb-4 mt-5 pe-0">
                <div className="col-5">
                  <AccessControl
                    key={"image"}
                    requiredPermissions={[{ permission: "Image", read: true }]}
                    override={userAuth.isSuperAdmin}
                  >
                    <ImageField
                      altText="Banner"
                      className="img-fluid object-fit-cover rounded-3"
                      src="/static/images/loginImage.jpg"
                    />
                  </AccessControl>
                  <div className="new-vacancies-cadidates row mt-3">
                    <AccessControl
                      key={"new_vacancies"}
                      requiredPermissions={[
                        { permission: "New vacancies", read: true },
                      ]}
                      override={userAuth.isSuperAdmin}
                    >
                      <div className="col-6 recruiter-action-tabs">
                        <Link to={"/vacancies"}>
                          <RecruitmentCard
                            className={`card rounded-3 border-0 card-shadow justify-content-center new-vacancies-cadidates-card `}
                          >
                            <div className="row">
                              <div className="col-md-12 align-self-center">
                                <CardTitle
                                  title={t("New vacancies")}
                                  className={`mb-3 recruitment-card-title`}
                                />
                                <Count
                                  value={0}
                                  totalValue={t("go")}
                                  value_punctuation={t("to")}
                                  width={"0.2vw"}
                                  height={"1.8vw"}
                                  backgroundColor="var(--color-light-skyblue)"
                                  className="recruitment-card-num ms-2"
                                ></Count>
                              </div>
                            </div>
                          </RecruitmentCard>
                        </Link>
                      </div>
                    </AccessControl>
                    <AccessControl
                      key={"new_candidates"}
                      requiredPermissions={[
                        { permission: "New candidates", read: true },
                      ]}
                      override={userAuth.isSuperAdmin}
                    >
                      <div className="col-6 recruiter-action-tabs">
                        <Link to={"/manage-candidates"}>
                          <RecruitmentCard
                            className={`card rounded-3 border-0 card-shadow justify-content-center new-vacancies-cadidates-card `}
                          >
                            <div className="row">
                              <div className="col-md-12 align-self-center">
                                <CardTitle
                                  title={t("New recruitments")}
                                  className={`recruitment-card-title`}
                                />
                                <Count
                                  value={0}
                                  totalValue={t("go")}
                                  value_punctuation={t("to")}
                                  width={"0.2vw"}
                                  height={"1.8vw"}
                                  backgroundColor="var(--color-light-skyblue)"
                                  className="recruitment-card-num ms-2"
                                ></Count>
                              </div>
                            </div>
                          </RecruitmentCard>
                        </Link>
                      </div>
                    </AccessControl>
                  </div>
                </div>
                <AccessControl
                  key={"todo_dashboard"}
                  requiredPermissions={[
                    { permission: "Todo dashboard", read: true },
                  ]}
                  override={userAuth.isSuperAdmin}
                >
                  <div className="col-7 pe-0">
                    <TodoDashboard />
                  </div>
                </AccessControl>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeDashboard;
