import {
  validateForm,
  validateRequired,
  validateSelectField,
  validateTextFiledSpace,
} from "services/validation/ValidationService";
import { useEffect, useState } from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "../translation/Translation";
import TitleFieldMolecule from "components/molecules/TitleField";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import { MANAGE_EMPLOYER_LEAVES } from "constants/Paths";
import CommonComponent from "./CommonComponent";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import {
  EDIT_APPLIED_LEAVE,
  GET_BUSINESS_UNIT_AND_EMPLOYEE,
  GET_EMPLOYEE_LEAVE_BALANCE,
  SAVE_APPLY_FOR_LEAVE,
} from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import BackButton from "components/atoms/BackButton";
interface HolidayData {
  label: any;
  count: any;
  holiday_code_contract: any;
}

interface Options {
  businessUnits: any[];
  employesData: any[];
  holidayCode: any[];
  requestTo: any[];
}
const AddLeave = () => {
  const [leaveData, setLeaveData] = useState({
    employee: "",
    business_unit: "",
    leave_type: "",
    reason: "",
    request_to: "",
    from_date: "",
    to_date: "",
    leave_date: "",
    leave_period: "Morning",
    no_of_hours: "",
    is_full_day: "Full day",
  });

  const [leaveDataError, setLeaveDataError] = useState({
    employee: "",
    business_unit: "",
    leave_type: "",
    reason: "",
    request_to: "",
    from_date: "",
    to_date: "",
    leave_date: "",
    leave_period: "",
    no_of_hours: "",
    is_full_day: "",
  });
  const [leaveBalanceData, setLeaveBalanceData] = useState<HolidayData[]>([]);
  const userId = useSelector(selectAuth).userId;
  const location = useLocation();
  const fileMode = location.pathname.split("/")[3];
  const { id: id } = useParams();
  const [option, setOptions] = useState<Options>({
    businessUnits: [],
    employesData: [],
    holidayCode: [],
    requestTo: [],
  });
  const navigate = useNavigate();
  const handleSelectChange = (event: any, category: string) => {
    if (category == "employee") {
      fetchLeaveBalance(event.value);
    }
    if (category === "leave_type") {
      setLeaveData((prevState) => ({
        ...prevState,
        count_type: event.count_type,
        is_full_day: event.count_type == 1 ? "Hours" : "Full day",
        from_date: "",
        to_date: "",
        no_of_hours: event.count_type == 1 ? "" : "",
      }));
    }
    setLeaveData((prevState) => ({ ...prevState, [category]: event.value }));
    formValidationFunction(category, event, true);
  };
  useEffect(() => {
    fetchOptions();
    if (id) {
      fetchData();
      fetchLeaveBalance(leaveData?.employee);
    }
  }, [leaveData?.employee]);
  const fetchData = async () => {
    if (id) {
      try {
        const response = await ApiCall.service(
          EDIT_APPLIED_LEAVE,
          "POST",
          { id: id },
          false,
          M_MASTER_DATA
        );
        if (response?.status == 200) {
          setLeaveData(response?.data);
        }
      } catch (error) {
        console.error("Error");
      }
    }
  };
  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_BUSINESS_UNIT_AND_EMPLOYEE,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        setOptions((prevOptions) => ({
          ...prevOptions,
          businessUnits: response.businessUnit || [],
          employesData: response.employee || [],
          requestTo: response.hr || [],
        }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error("Error occured ", error);
    }
  };

  const handleInputChange = (event: any) => {
    const eventObject = event.target;
    let newState = {};
    let inputValue = null;
    if (eventObject.name === "is_full_day") {
      newState =
        eventObject.id === "Full day"
          ? { is_full_day: "Full day", from_date: "", to_date: "" }
          : eventObject.id === "Half day"
          ? {
              leave_period: "Morning",
              is_full_day: "Half day",
              leave_date: "",
              no_of_hours: "",
            }
          : {
              from_date: "",
              to_date: "",
              is_full_day: "Hours",
              no_of_hours: "",
            };
      inputValue = eventObject.id;
    } else if (eventObject.name === "leave_period") {
      newState = { leave_period: eventObject.id };
      inputValue = eventObject.id;
    } else {
      inputValue = eventObject.value;
      newState = { [eventObject.name]: eventObject.value };
      if (eventObject.name !== "reason") {
        formValidationFunction(eventObject.name, eventObject.value, true);
      }
    }

    if (
      eventObject.name === "is_full_day" ||
      eventObject.name === "leave_period"
    ) {
      setLeaveDataError((prevState: any) => {
        const {
          from_date,
          to_date,
          leave_date,
          no_of_hours,
          ...remainingErrors
        } = prevState;
        return { ...remainingErrors };
      });
    }

    setLeaveData((prevState) => ({ ...prevState, ...newState }));
    // formValidationFunction(eventObject.name, inputValue, true);
  };

  const formValidationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      employee: [validateSelectField],
      business_unit: [validateSelectField],
      leave_type: [validateSelectField],
      request_to: [validateRequired],
      from_date: [validateRequired],
      leave_date: [validateRequired],
      no_of_hours: [validateRequired],
    };

    let validationErrors: any = validateForm(
      leaveData,
      isSingleFieldValidation === true ? validationRules[name] : validationRules
    );

    if (isSingleFieldValidation) {
      setLeaveDataError((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: errorValue, ...remains } = prevErrors;
        return { ...remains };
      });
    } else if (isSingleFieldValidation === false) {
      let finalErrors = null;
      const dateErrorValidation =
        new Date(leaveData.from_date) <=
        new Date(
          leaveData.to_date === "" ? leaveData.from_date : leaveData.to_date
        );

      if (!dateErrorValidation) {
        validationErrors["to_date"] =
          "End date should be greater than or equal to from date";
      } else {
        const { to_date, ...remainingErrors } = validationErrors;
        validationErrors = remainingErrors;
      }

      if (leaveData.is_full_day === "Full day") {
        const { no_of_hours, leave_date, ...remaining } = validationErrors;
        finalErrors = remaining;
      } else if (leaveData.is_full_day === "Half day") {
        const { from_date, to_date, no_of_hours, ...remaining } =
          validationErrors;
        finalErrors = remaining;
      } else if (leaveData.is_full_day === "Hours") {
        const { leave_date, ...remains } = validationErrors;
        finalErrors = remains;
      }
      setLeaveDataError({ ...finalErrors });
      if (Object.keys(finalErrors).length > 0) {
        return false;
      } else {
        return true;
      }
    }
  };
  const fetchLeaveBalance = async (employeeId: any) => {
    try {
      const response = await ApiCall.service(
        GET_EMPLOYEE_LEAVE_BALANCE,
        "POST",
        { employeeId: employeeId },
        false,
        M_MASTER_DATA
      );
      if (response?.status == 200) {
        setOptions((prevOptions) => ({
          ...prevOptions,
          holidayCode: response.data.map(
            (item: { countType: any; value: any; label: any; name: any }) => ({
              value: item.value,
              label: item.label,
              name: item.name,
              count_type: item.countType,
            })
          ),
        }));
        setLeaveBalanceData(response?.data);
      }
    } catch (error) {
      console.error("Error");
    }
  };

  const handleDateChange = (event: any, categoryName: string) => {
    if (event) {
      const date = `${event.getFullYear()}-${
        event.getMonth() + 1 < 10
          ? "0" + (event.getMonth() + 1)
          : event.getMonth() + 1
      }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
      setLeaveData((prevState: any) => ({
        ...prevState,
        [categoryName]: date,
      }));
      if (categoryName !== "to_date") {
        formValidationFunction(categoryName, date, true);
      }
    }
  };

  const radioButtonState = [{ label: "Morning" }, { label: "Afternoon" }];

  const checkboxState = [
    { label: "Full day", name: "full_day" },
    { label: "Half day", name: "half_day" },
    { label: "Hours", name: "hours" },
  ];

  const handleOnSubmit = async () => {
    if (formValidationFunction()) {
      let finalLeaveData: any = {
        id: id || "",
        userId: userId,
        employeerAddLeave: true,
        code_type: leaveData["leave_type"],
        business_unit: leaveData["business_unit"],
        employee_id: leaveData["employee"],
        reason: leaveData["reason"],
        request_to: leaveData["request_to"],
      };
      if (leaveData["is_full_day"] == "Full day") {
        finalLeaveData["from_date"] = leaveData["from_date"];
        finalLeaveData["to_date"] = leaveData["to_date"];
        finalLeaveData["leave_type"] = "Full day";
      } else if (leaveData["is_full_day"] == "Half day") {
        finalLeaveData["leave_period"] = leaveData["leave_period"];
        finalLeaveData["leave_date"] = leaveData["leave_date"];
        finalLeaveData["leave_type"] = leaveData["is_full_day"];
      } else {
        finalLeaveData["from_date"] = leaveData["from_date"];
        finalLeaveData["to_date"] = leaveData["to_date"];
        finalLeaveData["no_of_hours"] = leaveData["no_of_hours"];
        finalLeaveData["leave_type"] = leaveData["is_full_day"];
      }

      try {
        const response = await ApiCall.service(
          SAVE_APPLY_FOR_LEAVE,
          "POST",
          finalLeaveData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({ type: "success", message: response.message });
          navigate(MANAGE_EMPLOYER_LEAVES);
        } else {
          CustomNotify({ type: "error", message: response.message });
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };
  return (
    <>
      <TitleFieldMolecule
        title={t(
          fileMode == "view"
            ? "View leave"
            : fileMode == "edit"
            ? "Edit leave"
            : "Add leave"
        )}
      />
      <div className="form-height">
        <div className="form-border p-5 mb-4">
          <div className="row">
            <div className="col-6">
              <SelectWithSearch
                onChange={(event) => {
                  handleSelectChange(event, "business_unit");
                }}
                title={t("Select business unit")}
                placeHolder={t("Select")}
                search={true}
                options={option.businessUnits}
                value={leaveData.business_unit}
                isDisabled={fileMode === "view"}
                isMandatory={true}
                error={leaveDataError.business_unit}
                name={"business_unit"}
                id={"business_unit"}
              />
            </div>
            <div className="col-6">
              <SelectWithSearch
                onChange={(event) => {
                  handleSelectChange(event, "employee");
                }}
                title={t("Select employee")}
                placeHolder={t("Select")}
                search={true}
                options={option.employesData}
                value={leaveData.employee}
                isDisabled={fileMode === "view"}
                isMandatory={true}
                error={leaveDataError.employee}
                name={"employee"}
                id={"employee"}
              />
            </div>
            <CommonComponent
              handleSelectChange={handleSelectChange}
              handleInputChange={handleInputChange}
              handleDateChange={handleDateChange}
              handleOnSubmit={handleOnSubmit}
              leavesType={option.holidayCode}
              leaveBalance={leaveBalanceData}
              hrOptions={option.requestTo}
              radioButtonState={radioButtonState}
              checkboxState={checkboxState}
              leaveData={leaveData}
              leaveDataError={leaveDataError}
              fileMode={fileMode}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between my-3 align-items-center">
          <div className="">
            <BackButton />
          </div>
          {fileMode != "view" ? (
            <div className="">
              <Button
                title={t("Submit")}
                type={"submit"}
                handleClick={handleOnSubmit}
                className={
                  "btn form-button  text-uppercase rounded-0 shadow-none"
                }
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default AddLeave;
