import { PC_CREATE, PC_EDIT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_PC_MANAGE } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { fetchSalaryBenefit, prefillSalaryBasedOnCategory, updateCategoryFunctions } from "./CustomFunctions";

export type CustomFnType = (payload: any) => any;

interface Category {
  value: string;
  label: string;
}

const CreateParitairComitee = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = async (
    payload: any,
    dataObj: any = null
  ) => {
    if (payload.type === "api-call") {
      const result = await fetchSalaryBenefit(payload);
      return result;
    } else if (payload.type == "functions") {
      const pcFunctions = await updateCategoryFunctions(
        payload,
        routeId,
        dataObj
      );
      return pcFunctions;
    } else if (payload.type == "employeeType") {
      const { fieldData, state } = payload;
      const fieldValue =
        fieldData?.value?.value !== undefined
          ? fieldData?.value?.value
          : fieldData?.value;

      let updatedOPtions: any = null;
      if (!state[1].list) {
        updatedOPtions = state[1].options.filter(
          (eachItem: { categoty: any }) =>
            eachItem.categoty == fieldData.value.value
        );
        state[1].list = state[1].options;
      } else {
        updatedOPtions = state[1].list.filter(
          (eachItem: { categoty: any }) =>
            eachItem.categoty == fieldData.value.value
        );
      }
      let newState = state?.map((item: any) => {
        if (item?.dependencyAction === "employeeType") {
          return { ...item, value: fieldData?.value }
        }
        if (item?.name === "employee_type") {
          return { ...item, value: dataObj?.['employee_type'] ?? "", options: updatedOPtions }
        }
        return item;
      });
      return newState;
    } else if (payload.type == "category") {
      return prefillSalaryBasedOnCategory(payload)
    } else {
      const stateFormArray: any[][] = payload as any[];
      let categoryList: Category[] = [];
      let pcName: string | undefined;
      let pcNumber: string | undefined;

      for (const innerDataArray of stateFormArray) {
        if (Array.isArray(innerDataArray)) {
          innerDataArray.forEach((object) => {
            switch (object.name) {
              case "pc_name":
                pcName = object.value;
                break;
              case "pc_number":
                pcNumber = object.value;
                break;
              case "pcNumber":
                if (object.type === "table" && Array.isArray(object.data)) {
                  object.data.forEach((dataElement: any) => {
                    dataElement.number = pcNumber;
                    dataElement.name = pcName;
                  });
                }
                break;
              default:
                if (object.dynamic === true) {
                  object.form.forEach((formArray: any) => {
                    formArray.forEach((formItem: any) => {
                      if (formItem.name === "categoryName") {
                        const categoryObject: Category = {
                          value: (categoryList.length + 1).toString(),
                          label: formItem.value,
                        };
                        categoryList.push(categoryObject);
                      }

                      if (
                        formItem.name === "category" &&
                        categoryList.length > 0
                      ) {
                        formItem.options = categoryList;
                      }
                    });
                  });
                }
                break;
            }
          });
        }
      }
    }
  };

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Paritair commitee",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <FormProviderWrapper
          backToManage={PATH_PC_MANAGE}
          formName={"ParitairComitee"}
          dataSubmissionEndPoint={PC_CREATE}
          microservice={M_MASTER_DATA}
          dataFetchEndPoint={PC_EDIT}
          entryId={routeId}
          customFn={customActions}
          formTitle={routeId ? "Edit paritair comitee" : "Create paritair comitee"}
        />
      </>
    </AccessControl>
  );
};
export default CreateParitairComitee;
