import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import {
  ADD_TO_PROJECT,
  FETCH_ALL_EMPLOYEES,
  GET_MATCHING_OPTIONS,
  REMOVE_FROM_PROJECT,
} from "routes/ApiEndpoints";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
} from "services/validation/ValidationService";
import { useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

interface Option {
  label: string;
  value: string;
}

interface AddProjectModalProps {
  handleClose: () => void;
  projectName: string;
  url?: any;
  removeStatus?: any;
  handleRefresh?: () => void;
}

const AddEmployeeModal: React.FC<AddProjectModalProps> = ({ handleClose, projectName, url, removeStatus, handleRefresh}) => {
  const urlParts = url.split("/");
  const project = urlParts[urlParts.length - 1];
  const company = urlParts[urlParts.length - 2];
  let projectId = 0;
  let companyId = 0;
  if (!isNaN(Number(project)) && !isNaN(Number(company))) {
    projectId = parseInt(project);
    companyId = parseInt(company);
  }
  const { id } = useParams();
  const [selectedOptions, setSelectedOptions] = useState({
    employee: [],
  });
  const [options, setOptions] = useState({
    employee: [],
  });
  const [error, setError] = useState({
    employee: null,
  });

  const validateInput = (selectedOption: any, fieldName: string): boolean => {
    const validationRules: any = {}; // Initialize an empty object
    if (url.includes('matching')) {
      validationRules[fieldName] = [validateRequired];
    } else {
      validationRules[fieldName] = [validateMultiSelectField];
    }
    const formData = { ...selectedOptions, [fieldName]: selectedOption };
    const validationErrors = validateForm(formData, validationRules);
    console.log(validationRules, validationErrors, selectedOption);
    
    setError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validationErrors[fieldName],
    }));
    return !validationErrors[fieldName];
  };
  const handleAddClick = (remove?: boolean) => {
    const isEmployeeSelected = validateInput(selectedOptions.employee, "employee");
    if (isEmployeeSelected) {
      const postData: any = {
        employeeId: selectedOptions.employee,
        companyId: companyId,
        projectId: projectId,
        category: "Employee",
      };
      if (removeStatus || remove) {
        ApiCall.service(REMOVE_FROM_PROJECT, "POST", postData, true, M_MASTER_DATA)
          .then((response) => {
            if (response.status === 200) {
              CustomNotify({
                type: "success",
                message: response.message,
              });
            }
          }
        );
      } else {
        ApiCall.service(ADD_TO_PROJECT, "POST", postData, false, M_MASTER_DATA)
          .then((response) => {
            if (response.status === 200) {
              CustomNotify({
                type: "success",
                message: response.message,
              });
            }
          }
        );
      }
      handleRefresh && handleRefresh();
      handleClose();
    } else {
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    let value = selectedOption?.map((item: any) => item?.value);
    validateInput(selectedOption, "employee")
    setSelectedOptions((prev: any) => ({ ...prev, [fieldName]: value}));
  };

  useEffect(() => {
    const postData = {
      employeeId: id,
      companyId: companyId ? [companyId] : [],
      projectId: projectId ? [projectId] : [],
      addtoemployee: true, initialCall: true,
      matching: url?.includes("matching")
    };
    fetchemployee(postData);
  }, []);

  const fetchemployee = async (postData: any, onChange = false) => {
    try {
      const response = await ApiCall.service(GET_MATCHING_OPTIONS, "POST", postData, false, M_MASTER_DATA);
      if (response?.status === 200) {
        if (onChange) return response?.data;
        setOptions((prevOptions: any) => ({
          ...prevOptions,
          employee: response?.data?.employees ?? [],
        }));
        if (postData?.initialCall) {
          setSelectedOptions((prevOptions: any) => ({
            ...prevOptions,
            employee: response?.data?.employees?.filter((item: any) => item?.status === true)?.map((item: any) => item?.value) ?? [],
          }));
        }
      }
    } catch (error) {
      return [];
    }
  };
  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="bitter-italic-normal-medium-24">
          {t(`Link employee to project : "${projectName}"`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <SelectWithSearch
            title={t("Employee")}
            name="employee"
            isMandatory={true}
            search={true}
            options={options.employee}
            placeHolder="Select employee"
            value={selectedOptions.employee ?? []}
            onChange={(e) => handleSelectChange(e, "employee")}
            isMulti={true}
            className="select-field"
            error={error?.employee || ""}
            isDisabled={url.includes("matching") ? true : false}
          />
        </div>
        <div className="row align-items-center ">
          <div className="col-6  ">
            <p
              className="text-uppercase  mb-0 cursor-pointer"
              onClick={handleClose}
            >
              {t("Cancel")}
            </p>
          </div>
          <div className="col-6">
            <Button
              title={removeStatus ? t("Remove") : t("Add")}
              handleClick={() => handleAddClick()}
              className="btn form-button float-end text-uppercase rounded-0 shadow-none"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEmployeeModal;
