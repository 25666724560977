import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CREATE_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT,
  GET_BUSINESS_UNIT,
  PROJECTS_OPTIONS,
} from "routes/ApiEndpoints";
import FormBuilder from "services/form/FormBuilder";
import { BusinessFormValidation } from "services/validation/ValidationRules";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_MANAGE_BUSINESS_UNIT,
  PATH_CREATE_BUSINESS_UNIT,
} from "constants/Paths";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { ApiCall } from "services/ApiServices";

interface BusinessUnitForm {
  id?: number | string;
}

const CreateBusinessUnit: React.FC<BusinessUnitForm> = ({ id }) => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();
  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Business unit",
  }
  permissionObject[permissionType] = true
  const userData = useSelector(selectAuth);

  // (selectedOption?.value !== null && selectedOption?.value !== undefined) ?
  //           selectedOption?.value : selectedOption

  const getCompanyAddress = async (selectedOption: any) => {
    try {
      const response = await ApiCall.service(
        PROJECTS_OPTIONS,
        'POST',
        {
          options: ["CompanyAddress"], companyId: (selectedOption?.value !== null && selectedOption?.value !== undefined) ?
            selectedOption?.value : selectedOption
        },
        false,
        M_MASTER_DATA
      );
      if (response?.status == 200) {
        return response?.data?.CompanyAddress?.[0];
      }
      else {
        return [];
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const customFunction = async (formInputs: any, selectedOption: any, dependency: any, dependencyArray: any) => {
    const dependencyValues: any = {};
    if (dependency == 'company') {
      const response = await getCompanyAddress(selectedOption);
      formInputs = formInputs.map((record: any) => {
        if (dependencyArray.includes(record.name)) {
          dependencyValues[record?.name] = response?.[record?.name];
          return { ...record, value: response?.[record?.name], };
        }
        return record;
      });
    }
    return { formInputs, dependencyValues, isFormInputChange: false };
  }

  return (
    <AccessControl
      requiredPermissions={[
        permissionObject
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <FormBuilder
        actionType={path === PATH_CREATE_BUSINESS_UNIT ? "create" : "edit"} // action type create or edit
        entryId={id || routeId} // required only if actionType is edit
        getDataAPI={GET_BUSINESS_UNIT} //required only if actionType is edit
        SaveDataAPI={
          path === PATH_CREATE_BUSINESS_UNIT
            ? CREATE_BUSINESS_UNIT
            : UPDATE_BUSINESS_UNIT
        } //Post's data to this endpoint
        formName={"BusinessUnitForm"} //Form name
        title={
          path === PATH_CREATE_BUSINESS_UNIT
            ? t("Create business unit")
            : t("Edit business unit")
        }
        // Form title
        redirect={PATH_MANAGE_BUSINESS_UNIT} // After submit redirect to this screen/route
        validationRules={BusinessFormValidation} //Validation rules to validate form on submit
        microserviceName={M_MASTER_DATA} //micro service
        ignoredKeys={["email"]}
        customFunction={customFunction}
      />
    </AccessControl>
  );
};

export default CreateBusinessUnit;
