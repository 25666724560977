import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import React, { useEffect, useState } from "react";
import {
  ARCHIVE_WAGE_PROPOSAL,
  GET_WAGE_PROPOSAL_DATA,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { t } from "../translation/Translation";
import EditIcon from "static/images/EditIcon";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import { CREATE_WAGE_PROPOSAL, PATH_WAGE_PROPOSAL } from "constants/Paths";
import ProfileCard from "utils/ProfileCard";
import ArchieveIcon from "static/images/ArchiveIcon";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import FilterOrganism from "components/organism/FilterOrganism";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPageHistory,
  updatePageFilters,
} from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import {
  ManageWageProposalProps,
  initialManageData,
  initialPageDetails,
  manageDropdownData,
  statusOptions,
} from "./WageInterfaces";
import SendMailIcon from "static/images/sendMailIcon";
import EncryptDecryptService from "services/EncryptDecryptService";
import DownloadWageIcon from "static/images/wageProposalDownloadOcon";
import Download from "static/images/downloadIcon";
import BackButton from "components/atoms/BackButton";

const ManageWageProposal = () => {
  const queryParam = useParams();
  const candidateId = queryParam.candidateId;
  const navigate = useNavigate();
  const [archievePopup, setArchievePopup] = useState(false);
  const dispatch = useDispatch();
  const [manageData, setManageData] = useState<ManageWageProposalProps[]>([
    {
      id: null,
      user_id: Number(candidateId),
      employeeContractType: "",
      businessUnit: "",
      paritairComitee: "",
      salary: "",
      status: null,
      businessUnitId: null,
      wagePropsalDownLoadPath: null,
    },
  ]);
  const pageHistory = useSelector(selectPageHistory);
  const history =
    pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const [pageData, setPageData] = useState(initialPageDetails);
  const [dropdownData, setDropdownData] =
    useState<manageDropdownData>(initialManageData);
  const [archieveId, setarchieveId] = useState<number | undefined>();

  const getSearchData = () => {
    return {
      businessUnits: history?.filters?.businessUnits ?? [],
      paritairComitee: history?.filters?.paritairComitee ?? "",
      employeeContractType: history?.filters?.employeeContractType ?? "",
      salary: history?.filters?.salary ?? "",
      status: history?.filters?.status ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  };

  useEffect(() => {
    const postData = {
      userId: candidateId,
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    ApiCall.service(
      GET_WAGE_PROPOSAL_DATA,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    ).then((response) => {
      if (response?.status === 200) {
        setDropdownData(response?.dropdownData);
        setManageData(response?.data?.data);
        setPageData((prev: any) => ({
          ...prev,
          totalPages: response?.data?.totalPages,
          totalRecords: response?.data?.totalRecords,
          initialCall: false,
        }));
      }
    });
  }, [pageData?.refresh, pageData?.initialCall]);

  const filters = [
    {
      name: "businessUnits",
      fieldType: "multiSelect",
      options: dropdownData?.businessUnit,
      placeholder: "Business unit",
      filterType: "search",
    },
    {
      name: "paritairComitee",
      fieldType: "singleSelect",
      options: dropdownData?.paritairComitee,
      placeholder: "Paritair comitee",
      filterType: "search",
    },
    {
      name: "employeeContractType",
      fieldType: "singleSelect",
      options: dropdownData?.employeeContractType,
      placeholder: "Employee contract type",
      filterType: "search",
    },
    {
      name: "salary",
      fieldType: "text",
      placeholder: "Salary",
      filterType: "search",
    },
    {
      name: "status",
      fieldType: "singleSelect",
      options: statusOptions,
      placeholder: "Status",
      filterType: "search",
    },
  ];

  const handleArcheive = async (id: number | undefined, apiCall?: string) => {
    if (apiCall) {
      const postData = {
        id: id,
      };
      const response = await ApiCall.service(
        ARCHIVE_WAGE_PROPOSAL,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setArchievePopup(false);
        handleRefresh();
        CustomNotify({ type: "success", message: response?.message });
      }
    } else {
      setArchievePopup(true);
      setarchieveId(id);
    }
  };

  const handleArcheivePopup = () => {
    setArchievePopup(false);
  };
  const handleSendWageMail = (businessUnit: any, userId: any, wageId: any) => {
    const data = {
      businessUnit: businessUnit,
      userId: userId,
      wageId: wageId,
      approve: false,
      reject:false,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_WAGE_PROPOSAL}/?data=${encodedData}`);
  };
  const handleWageDownload = (wagePdfPath: string | undefined) => {
    if (!wagePdfPath) {
      console.error("Invalid PDF path");
      return;
    }

    // Open the PDF in a new tab
    window.open(wagePdfPath, "_blank");
  };

  const handleRefresh = () =>
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <div className="search-bar">
        <TitleFieldMolecule title={t("Manage wage proposal")} />
      </div>

      <div className="row m-auto">
        <ProfileCard id={candidateId} />
      </div>

      <div className="position-relative tableMainWrapper mt-5">
        <div className="row">
          <div className="ManageCreateBtn">
            <LinkTo
              pagelink={`${CREATE_WAGE_PROPOSAL}/${candidateId}`}
              title={t("Create wage proposal")}
              icon={faPlus}
            />
          </div>
        </div>

        <div className="table-responsive Manage-wage-proposal tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
          <div className="TableHeight">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader  hchehewc">
                  <th>{t("Business unit")}</th>
                  <th>{t("Paritair comitee")}</th>
                  <th>{t("Employee contract type")}</th>
                  <th>{t("Salary")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {manageData && manageData.length > 0 ? (
                  manageData.map((data: any, index: number) => (
                    <tr key={index}>
                      <td>{data.businessUnit}</td>
                      <td>{data.paritairComitee}</td>
                      <td>{data.employeeContractType}</td>
                      <td>{data.salary}</td>
                      <td>
                        <span
                          className={`d-inline-block rounded-circle status-icon ${
                            data.status === 0
                              ? "bg-warning"
                              : data.status === 1
                              ? "bg-success"
                              : data.status === 5
                              ? "bg-danger"
                              : data.status === 2
                              ? "bg-secondary"
                              : "bg-success"
                          }`}
                          data-toggle="tooltip"
                          title={
                            data.status === 0
                              ? t("Created, not sent to candidate")
                              : data.status === 1
                              ? t("Proposal sent to candidate")
                              : data.status === 2
                              ? t("Sent and approved by candidate")
                              : data.status === 3
                              ? t("Created and approved by Maxicon")
                              : data.status === 4
                              ? t("Proposal sent, rejected by candidate")
                              : data.status === 5
                              ? t("Inactive")
                              : ""
                          }
                        ></span>
                      </td>

                      <td>
                        {![1, 2, 3, 4, 5].includes(data.status) && (
                          <Link
                            to={`${CREATE_WAGE_PROPOSAL}/${data.userId}/${data.id}`}
                            className={"table-action-btn border-0 p-0 me-2"}
                          >
                            <EditIcon />
                          </Link>
                        )}

                        {![4, 5].includes(data.status) && (
                          <Button
                            title={<ArchieveIcon />}
                            className="table-action-btn border-0 p-0 me-2"
                            handleClick={() => handleArcheive(data.id)}
                            tooltip={t("Archive")}
                          />
                        )}
                        {![2, 3, 4, 5].includes(data.status) && (
                          <Button
                            title=""
                            className="table-action-btn border-0  p-0 me-2"
                            handleClick={() =>
                              handleSendWageMail(
                                data.businessUnitId,
                                data.userId,
                                data.id
                              )
                            }
                          >
                            <SendMailIcon />
                          </Button>
                        )}
                        {data.wagePropsalDownLoadPath && (
                          <Button
                            title=""
                            className="table-action-btn border-0 p-0 me-2"
                            handleClick={() =>
                              handleWageDownload(data.wagePropsalDownLoadPath)
                            }
                          >
                            <Download />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td colSpan={7} className="border-0 text-center py-3">
                      <span className="text-danger fw-bold">
                        {t("No records")}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="">
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>
      {archievePopup && (
        <ModalPopup
          show={archievePopup}
          onHide={handleArcheivePopup}
          title={t("Archive confirmation")}
          body={t("Are you sure you want to archive wage proposal")}
          confirmTitle={t("Yes")}
          closeTitle={t("No")}
          onConfirmButtonClick={() => handleArcheive(archieveId, "apiCall")}
          onCloseButtonClick={handleArcheivePopup}
        />
      )}

      <div className="row my-3">
        <div className="col-md-6 align-self-center">
          <BackButton />
        </div>
      </div>
    </>
  );
};

export default ManageWageProposal;
