import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface OvertimeMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  data: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const OvertimeMolecule: React.FC<OvertimeMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
  data,
}) => {
  const renderField = () => {
    switch (field.field_name) {
      case "Surcharge on overtime":
        return (
          <div>
            <LabelWithInputFieldAndUnit
              isMandatory={true}
              label={t(name)}
              value={value}
              handleChange={handleChange}
              type="text"
              isDisabled={disabled}
              placeholder={t(field?.field_name)}
              error={t(error)}
              unit={t("%")}
              containerClassName="col-12"
            />
          </div>
        );
      case "Weekly limit":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value === "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Day limit":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Exception friday":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value === "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "From":
        return (
          <div className="col-4">
            {data?.[field?.field_id - 3]?.field_value == "1" && (
              <LabelWithInputFieldAndUnit
                isMandatory={false}
                label={t(field?.field_name)}
                value={value}
                handleChange={handleChange}
                type={
                  data?.[field?.field_id - 3]?.field_id == "17"
                    ? "text"
                    : "time"
                }
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
                containerClassName="w-100"
              />
            )}
          </div>
        );
      case "Exception saturday":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Exception saturday & holidays":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Exception monday":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Exception tuesday":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Exception wednesday":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
      case "Exception thursday":
        return (
          <div className="col-4">
            <CheckBoxField
              label={t(field?.field_name)}
              name={field?.field_name}
              isChecked={field.field_value == "1"}
              onChangeHandler={handleChange}
              disable={disabled}
              id={field?.field_name}
              lineHeight="1.5vw"
            />
          </div>
        );
    }
  };

  return <>{renderField()}</>;
};

export default OvertimeMolecule;
