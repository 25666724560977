import { createBrowserRouter, RouteObject } from "react-router-dom";
import LoginForm from "pages/microservices/identityManager/authentication/LoginForm";
import { NewRootLayout } from "components/layout/RootLayout";
import RegistrationForm from "pages/microservices/identityManager/candidate-registration/RegistrationForm";
import ResetPasswordForm from "pages/microservices/identityManager/authentication/ResetPasswordForm";
import { ForgotPasswordForm } from "pages/microservices/identityManager/authentication/ForgotPasswordForm";
import CreatePermission from "pages/microservices/identityManager/permissions/CreatePermission";
import ManagePermissions from "pages/microservices/identityManager/permissions/ManagePermissions";
import CreateUser from "pages/microservices/identityManager/users/CreateUser";
import RolePage from "pages/microservices/identityManager/roles/CreateRole";
import ManageRoles, { loader as rolesLoader } from "pages/microservices/identityManager/roles/ManageRoles";
import TermsAndConditions from "pages/microservices/identityManager/terms-conditions/TermsAndConditions";
import ManageUsers from "pages/microservices/identityManager/users/ManageUsers";
import CreateEmailTemplate from "pages/microservices/masterData/email-templates/CreateEmailTemplate";
import EditEmailTemplate from "pages/microservices/masterData/email-templates/EditEmailTemplate";
import ManageEmailTemplatesPage from "pages/microservices/masterData/email-templates/ManageEmailTemplates";
import { MultiFormProvider } from "pages/microservices/companyCreation/context/MultiFormProvider";
import ManageCityProfile from "pages/microservices/cooperation/configurationAndSettings/cities/ManageCityProfile";
import ManageCompanies from "pages/microservices/companyCreation/create-company/formContent/ManageCompanies";
import Location from "pages/microservices/companyCreation/Location/Location";
import ManageLocation from "pages/microservices/companyCreation/Location/ManageLocation";
import ManageFunctions from "pages/microservices/cooperation/configurationAndSettings/functionsProfile/ManageFunctions";
import AddFunctions from "pages/microservices/cooperation/configurationAndSettings/functionsProfile/AddFunctions";
import Checklist from "pages/microservices/cooperation/configurationAndSettings/checklist/Checklist";
import ManageChecklist from "pages/microservices/cooperation/configurationAndSettings/checklist/ManageChecklist";
import AddCityProfile from "pages/microservices/cooperation/configurationAndSettings/cities/AddCityProfile";
import LowCoefficientTableForm from "pages/microservices/cooperation/configurationAndSettings/coefficients/defaultLowCoefficents/LowCoefficientTableForm";
import WorkTypeExtra from "pages/microservices/cooperation/configurationAndSettings/workTypeExtra/WorkTypeExtra";
import ConfigAndSettingsPage from "pages/dashboards/ConfigAndSettingsPage";
import ManageExtras from "pages/microservices/cooperation/configurationAndSettings/extras/ManageExtras";
import { MultiFormProviderAgreement } from "pages/microservices/cooperation/agreement/context/MultiFormProvider";
import InflationCoefficientsForm from "pages/microservices/cooperation/configurationAndSettings/coefficients/inflations/InflationCoefficientsForm";
import ManageInflations from "pages/microservices/cooperation/configurationAndSettings/coefficients/inflations/ManageInflations";
import ManageLowCoefficients from "pages/microservices/cooperation/configurationAndSettings/coefficients/defaultLowCoefficents/ManageLowCofficients";
import CreateBusinessUnit from "pages/microservices/masterData/business-unit/CreateBusinessUnit";
import ManageBusinessUnit from "pages/microservices/masterData/business-unit/ManageBusinessUnit";
import ManageContactPerson from "pages/microservices/companyCreation/create-company/ContactPerson/ManageContactPerson";
import Contact from "pages/microservices/companyCreation/create-company/ContactPerson";
import CreateEmployeeType from "pages/microservices/masterData/employee-type/Create";
import ManageEmployeeType from "pages/microservices/masterData/employee-type/Manage";
import CreateTags from "pages/microservices/masterData/tags/CreateTags";
import ManageTagPage from "pages/microservices/masterData/tags/ManageTagsPage";
import CreateShift from "pages/microservices/masterData/shifts/Create";
import { ProjectFormProvider } from "pages/microservices/project/ProjectFormProvider";
import Projects from "pages/microservices/project/Projects";
import Planning from "pages/microservices/planning";
import {
      PATH_MANAGE_BUSINESS_UNIT,
      PATH_CREATE_BUSINESS_UNIT,
      PATH_EDIT_BUSINESS_UNIT,
      PATH_EMAIL_TEMPLATE_CREATE,
      PATH_EMAIL_TEMPLATE_MANAGE,
      PATH_EMAIL_TEMPLATE_EDIT,
      PATH_EMAIL_TEMPLATE_VIEW,
      PATH_MANAGE_TAGS,
      PATH_MANAGE_SHIFTS,
      PATH_CREATE_SHIFT,
      PATH_EDIT_SHIFT,
      PATH_PROJECTS_MANAGE,
      PATH_PROJECTS_CREATE,
      PATH_MANAGE_TEMPLATE,
      PATH_TEMPLATE_EDIT,
      PATH_TEMPLATE_VIEW,
      PATH_PROJECTS_EDIT,
      PATH_MANAGE_TIMETABLE,
      PATH_TIMETABLE_CREATE,
      PATH_PROPOSAL_AGREEMENT,
      PATH_EMPLOYEE_CREATE,
      PATH_EMPLOYEE_MANAGE,
      PATH_PC_MANAGE,
      PATH_PC_CREATE,
      PATH_PROPOSAL_AGREEMENT_MANAGER,
      PATH_CONFIG_ELEMENTS_MANAGE,
      PATH_CONFIG_ELEMENTS_CREATE,
      PATH_PROFILE,
      PATH_COMPETENCE_MANAGE,
      PATH_COMPETENCE_CREATE,
      PATH_COMPETENCE_EDIT,
      PATH_COMPETENCE_VIEW,
      PATH_TIMETABLE_EDIT,
      PATH_MATCHING,
      PATH_EMPLOYEE_EDIT,
      PATH_APPLICATION_CONFIGURATION,
      PATH_EDIT_APPLICATION_CONFIG,
      PATH_PC_EDIT,
      PATH_MATCHING_EMPLOYEE,
      PATH_HOTLIST,
      PATH_DOCUMENT,
      MANAGE_QUESIONS,
      ADD_QUESTION,
      PATH_HR_PLANNING,
      PATH_SALES_PLANNING,
      VIEW_QUESTION,
      CLONE_QUESTION,
      EDIT_QUESTION,
      PATH_VACANCY_CREATE,
      PATH_VACANCY_MANAGE,
      PATH_CANDIDATE_SCREENING,
      PATH_WAGE_PROPOSAL,
      PATH_CREATE_CONTRACT,
      PATH_MANAGE_APPLICANTS,
      PATH_SALARY_BENEFITS_CREATE,
      PATH_SALARY_BENEFITS_MANAGE,
      PATH_APPLICANT_CREATE,
      EDIT_HOLIDAY_CODE,
      CREATE_HOLIDAY_CODES,
      HOLIDAY_CONFIGS,
      MANAGE_PUBLIC_HOLIDAYS,
      MANAGE_HOLIDAY_CODES,
      CREATE_PUBLIC_HOLIDAY,
      EDIT_PUBLIC_HOLIDAY,
      MANAGE_HOLIDAY_PER_PC,
      LINK_HOLIDAY_PER_PC,
      EDIT_LINK_HOLIDAY_PER_PC,
      PATH_TODO_CREATE,
      PATH_TODO_MANAGE,
      PATH_TODO_VIEW,
      PATH_ADD_CONTRACT_TEMPLATE,
      PATH_MANAGE_CONTRACT_TEMPLATE,
      PATH_VIEW_CONTRACT_TEMPLATE,
      PATH_CLONE_CONTRACT_TEMPLATE,
      APPLY_LEAVE_OF_EMPLOYEE,
      VIEW_LEAVE_OF_EMPLOYEE,
      EDIT_LEAVE_OF_EMPLOYEE,
      MANAGE_EMPLOYEE_LEAVES,
      EMPLOYEE_LEAVE_COUNT,
      MANAGE_EMPLOYER_LEAVES,
      EMPLOYER_LEAVE_COUNT,
      ADD_LEAVE,
      VIEW_LEAVE_THROUGH_EMPLOYER,
      EDIT_LEAVE_THROUGH_EMPLOYER,
      PATH_MANAGE_SCREENING,
      PATH_SCREENING_ASSESSMENT,
      PATH_CREATE_MATERIAL,
      PATH_MANAGE_MATERIAL,
      PATH_FLEET_AND_MATERIAL,
      PATH_MANAGE_CAR,
      PATH_CREATE_CAR,
      PATH_MANAGE_CLOTHES,
      PATH_CREATE_CLOTHES,
      PATH_MANAGE_SUPPLIER,
      PATH_CREATE_SUPPLIER,
      PATH_MANAGE_CLIENT,
      PATH_CREATE_CLIENT,
      PATH_MANAGE_PURCHASE_ORDER,
      PATH_CREATE_PURCHASE_ORDER,
      PATH_EDIT_MATERIAL,
      PATH_EDIT_CLOTHES,
      PATH_EDIT_CAR,
      PATH_EDIT_PURCHASE_ORDER,
      PATH_CONTRACT_OVERVIEW,
      PATH_EDIT_SUPPLIER,
      PATH_EDIT_CLIENT,
      PATH_ACCESS_DENIED,
      PATH_MANAGE_TIMEHSHEETS,
      PATH_PROJECT_PROPOSAL,
      PATH_EMPLOYEE_FLEET_OVERVIEW,
      PATH_WORK_PAY_BILL_ROLL,
      PATH_MANAGE_TEMP_AGENCY,
      PATH_CREATE_TEMP_AGENCY,
      PATH_EDIT_TEMP_AGENCY,
      PATH_MANAGECOEFF_DEVIATION,
      PATH_MANAGE_WAGE_ELEMENT,
      PATH_EDIT_COEEFICIENT,
      PATH_MANAGE_TRANSLATIONS,
      PATH_VIEW_PURCHASE_ORDER,
      PATH_MARGIN_CALCULATOR,
      PATH_MANAGE_PARTENA,
      PATH_ADD_PARTENA,
      PATH_PARTENA_CALCULATION,
      PATH_TEMP_AGENCY_PROPOSAL,
      PATH_MANAGE_FLEET_LOCATION,
      PATH_CREATE_FLEET_LOCATION,
      PATH_EDIT_FLEET_LOCATION,
      CREATE_CANDIDATE,
      MANAGE_CANDIDATES,
      CREATE_WAGE_PROPOSAL,
      MANAGE_WAGE_PROPOSAL,
      MANAGE_CONTRACT_PROPOSAL,
      EDIT_CANDIDATE,
      PATH_CREATE_DOCUMENT_TYPE,
      PATH_EDIT_DOCUMENT_TYPE,
      PATH_MANAGE_DOCUMENT_TYPE,
      PATH_VACANCY_EDIT,
      CREATE_CONTRACT,
      PATH_MANAGE_TIMEHSHEETS_OVERVIEW,
      PATH_PROJECTS_VIEW,
} from "constants/Paths";
import ManageShift from "pages/microservices/masterData/shifts/Manage";
import ManageTemplatesPage from "pages/microservices/masterData/templates/Manage";
import EditTemplate from "pages/microservices/masterData/templates/Edit";
import ManageTimeTable from "pages/microservices/masterData/timetable/Manage";
import CreateTimeTable from "pages/microservices/masterData/timetable/create";
import { Proposal } from "pages/microservices/project/Proposals/proposals";
import { Employees } from "pages/microservices/employees/Index";
import { CreateEmployees } from "pages/microservices/employees/create/Index";
import CreateParitairComitee from "pages/microservices/masterData/paritair-comitee/create";
import ManageMasterDataElements from "pages/microservices/masterData/configElements/Index";
import ConfigForm from "pages/microservices/masterData/configElements/create/Index";
import Matching from "pages/microservices/masterData/matching";
import UsersProfile from "pages/microservices/identityManager/my-profile";
import CreateCompetence from "pages/microservices/masterData/competence/Create";
import ManageCompetence from "pages/microservices/masterData/competence/Manage";
import EditAppConfig from "pages/microservices/masterData/application-configuration/Create";
import AppConfiguration from "pages/microservices/masterData/application-configuration/Index";
import ManagePCPage from "pages/microservices/masterData/paritair-comitee/ManagePage";
import FileUploader from "utils/S3Bucket/FileUploader";
import MatchingEmployee from "pages/microservices/masterData/matching/MatchingEmployee";
import Vacancies from "pages/microservices/vacancies/Vacancies";
import ManageHotlist from "pages/microservices/masterData/hotlist";
import ManageDocumentPage from "pages/microservices/masterData/documents/Index";
import ManageQuestions from "pages/microservices/masterData/questions";
import AddQuestions from "pages/microservices/masterData/questions/AddQuestions";
import Hrmanage from "pages/HrManage";
import CreateHolidayCodes from "pages/microservices/masterData/holidays/createHolidayCodes";
import CreateHoliday from "pages/microservices/masterData/holidays/createHoliday";
import ManageHolidays from "pages/microservices/masterData/holidays/createHoliday/ManageHoliday";
import ManageHolidayCodes from "pages/microservices/masterData/holidays/createHolidayCodes/ManageHolidayCodes";
import ManageHolidayPerPC from "pages/microservices/masterData/holidays/holidayPerPC/ManageHolidayPerPC";
import LinkHolidayToPc from "pages/microservices/masterData/holidays/holidayPerPC";
import HolidayConfig from "pages/dashboards/HolidayConfig";
import WageProposal from "pages/microservices/masterData/wageProposal/wageProposalOverview";
import CreateContract from "pages/microservices/masterData/wageProposal/createEmployeeContract";
import WelcomeDashboard from "dashboard/WelcomeDashboard";
import CreateSalaryBenefits from "pages/microservices/masterData/salary-benefits/Create";
import ManageTodo from "pages/microservices/masterData/follow-up-todo/Manage";
// import CreateTodo from "pages/microservices/masterData/follow-up-todo/Create";
import ManageApplicants from "pages/microservices/identityManager/Applicants/ManageApplicants";
import CreateApplicant from "pages/microservices/identityManager/Applicants/CreateApplicant";
import AddContractsTemplate from "pages/microservices/masterData/contracts/AddContractTemplate";
import ManageContractTemplate from "pages/microservices/masterData/contracts/ManageContractTemplates";
import ManageLeavesOfEmployee from "pages/microservices/masterData/leaves/ManageLeavesForEmployee";
import LeaveCountOfEmployee from "pages/microservices/masterData/leaves/LeaveCountOfEmployee";
import ApplyForLeave from "pages/microservices/masterData/leaves/ApplyForLeave";
import AddLeave from "pages/microservices/masterData/leaves/AddLeave";
import LeaveCountOfEmployer from "pages/microservices/masterData/leaves/LeaveCountOfEmployer";
import ManageLeavesForEmployer from "pages/microservices/masterData/leaves/ManageLeavesForEmployers";
import CandidateCorner from "pages/microservices/identityManager/Applicants/CandidateCorner";
import ManageScreening from "pages/microservices/masterData/Screening/ManageScreening";
import ScreeningAssessment from "pages/microservices/masterData/Screening/ScreeningAssessment";
import CandidateScreening from "pages/microservices/masterData/Screening/CandidateScreening";
import Material from "pages/microservices/fleetAndMaterial/material/Material";
import AddMaterial from "pages/microservices/fleetAndMaterial/material/AddMaterial";
import FleetAndMaterial from "pages/microservices/fleetAndMaterial";
import Car from "pages/microservices/fleetAndMaterial/car/Car";
import AddCar from "pages/microservices/fleetAndMaterial/car/AddCar";
import Clothes from "pages/microservices/fleetAndMaterial/clothes/Clothes";
import AddClothes from "pages/microservices/fleetAndMaterial/clothes/AddClothes";
import Supplier from "pages/microservices/fleetAndMaterial/supplier/Supplier";
import AddSupplier from "pages/microservices/fleetAndMaterial/supplier/AddSupplier";
import Client from "pages/microservices/fleetAndMaterial/client/Client";
import AddClient from "pages/microservices/fleetAndMaterial/client/AddClient";
import PurchaseOrder from "pages/microservices/fleetAndMaterial/purchaseOrder/PurchaseOrder";
import AddPurchaseOrder from "pages/microservices/fleetAndMaterial/purchaseOrder/AddPurchaseOrder";
import ContractOverview from "pages/microservices/masterData/wageProposal/contractOverview";
import AccessDenied from "pages/AccessDenied/AccessDenied";
import Timesheets from "pages/microservices/timesheets";
import FleetOverview from "pages/microservices/fleetAndMaterial/FleetOverview";
import ProjectOverview from "pages/microservices/project/components/ProjectOverview";
import FleetEmployeeOverview from "pages/microservices/fleetAndMaterial/FleetEmployeeOverview";
import WorkPayBillRoll from "pages/microservices/work-pay-bill-roll";
import ManageTempAgency from "pages/microservices/tempAgancy/manage/ManageTempAgency";
import { TempAgencyFormProvider } from "pages/microservices/tempAgancy/TempAgencyFormProvider";
import ManageCoeffDeviation from "pages/microservices/masterData/WageCodes/ManageCoeffDeviation";
import ManageWageElements from "pages/microservices/masterData/WageElement/ManageWageElements";
import CoeffDeviation from "pages/microservices/masterData/WageCodes/CoeffDeviation";
import CreateTodo from "pages/microservices/masterData/follow-up-todo/Create";
import ManageTranslations from "pages/microservices/masterData/translation/ManageTranslation";
import MarginCalculator from "pages/microservices/marginCalculation";
import { MarginProviderWrapper } from "pages/microservices/marginCalculation/MarginWrapper";
import ManagePartena from "pages/microservices/masterData/partena/PartenaExport";
import AddPartenaCalculation from "pages/microservices/masterData/partena/AddPartenaCalculation";
import PartenaCalculation from "pages/microservices/masterData/partena/PartenaCalculation";
import TempAgencyOverview from "pages/microservices/tempAgancy/componests/TempAgencyOverview";
import MultiFormProviderVacancy from "pages/microservices/vacancies/context/MultiFormProvider";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import CreateFleetLocation from "pages/microservices/fleetAndMaterial/locations/CreateLocations";
import ManageFleetLocation from "pages/microservices/fleetAndMaterial/locations/ManageLocations";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import { MultiFormProviderCandidate } from "pages/microservices/CandidateCreation/context/MultiFormProviderCandidate";
import ManageCandidates from "pages/microservices/CandidateCreation/ManageCandidates/ManageCandidates";
import CreactWageProposal from "pages/microservices/masterData/wage-proposal/CreateWageProposal";
import Contract from "pages/microservices/masterData/contract-proposal/CreateContractProposal";
import ManageWageProposal from "pages/microservices/masterData/wage-proposal/ManageWageProposal";
import ManageContractProposal from "pages/microservices/masterData/contract-proposal/ManageContractProposal";
import CreateContractProposal from "pages/microservices/masterData/contract-proposal/CreateContractProposal";
import CreateDocumentType from "pages/microservices/masterData/documentType/Index";
import DocumentType from "pages/microservices/masterData/documentType/DocumentType";
import ManageSalaryBenefits from "pages/microservices/masterData/salary-benefits/ManageSalaryBenefits";
import ManageTimesheetOverview from "pages/microservices/timesheets/ManageTimesheetOverview";

// Create a new type that adds the optional 'name' property
export type NamedRouteObject = RouteObject & {
      name?: string;
      children?: NamedRouteObject[]
};

export const routes: NamedRouteObject[] = ([
      { path: "/login", element: <LoginForm /> },
      { path: "/register", element: <RegistrationForm /> },
      { path: "/reset-password", element: <ResetPasswordForm /> },
      { path: "/forgot-password", element: <ForgotPasswordForm /> },
      { path: "/terms-conditions", element: <TermsAndConditions /> },
      {
            path: "/",
            name: "main",
            element: <NewRootLayout />,
            children: [
                  { path: "*", element: <PageNotFound />, name: PAGENAMES?.PAGE_NOT_FOUND },
                  { path: PATH_ACCESS_DENIED, element: <AccessDenied />, name: PAGENAMES?.ACCESS_DENIED },
                  { path: "/", element: <WelcomeDashboard />, name: PAGENAMES?.HOME_PAGE },
                  { path: "/dashboard", element: <WelcomeDashboard />, name: PAGENAMES?.DASHBOARD },

                  //// routes for permissions (Manage, Create, Edit and Delete)
                  { path: "/permission/create", element: <CreatePermission />, name: PAGENAMES?.CREATE_PERMISSION },
                  { path: "/permission/edit/:id", element: <CreatePermission />, name: PAGENAMES?.EDIT_PERMISSION },
                  { path: "/manage-permissions", element: <ManagePermissions />, name: PAGENAMES?.MANAGE_PERMISSION },

                  // ///// routes for roles (Create, Edit)
                  { path: "/manage-roles", element: <ManageRoles />, loader: rolesLoader, name: PAGENAMES?.MANAGE_ROLE },
                  { path: "/role/create", element: <RolePage />, name: PAGENAMES?.CREATE_ROLE },
                  { path: "/role/edit/:id", element: <RolePage />, name: PAGENAMES?.EDIT_ROLE },
                  { path: "/role/clone/:id", element: <RolePage />, name: PAGENAMES?.CLONE_ROLE },

                  // ///// routes for creating the AJ-Users (Create, Edit, Update)
                  { path: "/user/create", element: <CreateUser />, name: PAGENAMES?.CREATE_USER },
                  { path: "/user/edit/:id", element: <CreateUser />, name: PAGENAMES?.EDIT_USER },
                  { path: "/user/view/:id", element: <CreateUser />, name: PAGENAMES?.VIEW_USER },
                  { path: "/manage-users", element: <ManageUsers />, name: PAGENAMES?.MANAGE_USER },

                  // Email templates
                  { path: `${PATH_EMAIL_TEMPLATE_CREATE}`, element: <CreateEmailTemplate />, name: PAGENAMES?.CREATE_EMAIL_TEMPLATE },
                  { path: `${PATH_EMAIL_TEMPLATE_EDIT}/:id`, element: <EditEmailTemplate />, name: PAGENAMES?.EDIT_EMAIL_TEMPLATE },
                  { path: `${PATH_EMAIL_TEMPLATE_VIEW}/:id`, element: <EditEmailTemplate />, name: PAGENAMES?.VIEW_EMAIL_TEMPLATE },
                  { path: `${PATH_EMAIL_TEMPLATE_MANAGE}`, element: <ManageEmailTemplatesPage />, name: PAGENAMES?.MANAGE_EMAIL_TEMPLATE },

                  ///for company-creation
                  { path: "/company/:companyId?", element: <MultiFormProvider />, name: PAGENAMES?.CREATE_COMPANY },
                  { path: "/manage-companies", element: <ManageCompanies />, name: PAGENAMES?.MANAGE_COMPANY },
                  { path: "/location/:id?", element: <Location />, name: PAGENAMES?.CREATE_LOCATION },
                  { path: "/manage-locations", element: <ManageLocation />, name: PAGENAMES?.MANAGE_LOCATION },
                  { path: "/manage-contact/person", element: <ManageContactPerson />, name: PAGENAMES?.MANAGE_CONTACT_PERSON },
                  { path: "/create/contact-person/:id?", element: <Contact />, name: PAGENAMES?.CREATE_CONTACT_PERSON },

                  { path: "/checklist/edit/:id?", element: <Checklist />, name: PAGENAMES?.EDIT_CHECKLIST },
                  { path: "/checklist/view/:id?", element: <Checklist />, name: PAGENAMES?.VIEW_CHECKLIST },
                  { path: "/manage-checklist/:id?", element: <ManageChecklist />, name: PAGENAMES?.MANAGE_CHECKLIST },

                  //Cooperation agreement config and settings
                  { path: "/config/settings", element: <ConfigAndSettingsPage />, name: PAGENAMES?.MASTER_DATA },

                  { path: "/manage/function-profile", element: <ManageFunctions />, name: PAGENAMES?.MANAGE_PROFILE_FUNCTION },
                  { path: "/function/add", element: <AddFunctions />, name: PAGENAMES?.ADD_PROFILE_FUNCTION },
                  { path: "/function/edit/:id", element: <AddFunctions />, name: PAGENAMES?.EDIT_PROFILE_FUNCTION },
                  { path: "/function/view/:id", element: <AddFunctions />, name: PAGENAMES?.VIEW_PROFILE_FUNCTION },

                  { path: "/edit/default/low-coefficients/:id?", element: <LowCoefficientTableForm />, name: PAGENAMES?.EDIT_LOW_COEFFICIENTS },
                  { path: "/add/default/low-coefficients", element: <LowCoefficientTableForm />, name: PAGENAMES?.ADD_LOW_COEFFICIENTS },
                  { path: "/manage/default/low-coefficients", element: <ManageLowCoefficients />, name: PAGENAMES?.MANAGE_LOW_COEFFICIENTS },
                  { path: "/add/city-profile", element: <AddCityProfile />, name: PAGENAMES?.ADD_CITY_PROFILE },
                  { path: "/edit/city-profile/:id", element: <AddCityProfile />, name: PAGENAMES?.EDIT_CITY_PROFILE },
                  { path: "/view/city-profile/:id", element: <AddCityProfile />, name: PAGENAMES?.VIEW_CITY_PROFILE },
                  { path: "/manage-city-profile", element: <ManageCityProfile />, name: PAGENAMES?.MANAGE_CITY_PROFILE },
                  { path: "/work-type/extra", element: <WorkTypeExtra />, name: PAGENAMES?.EXTRA },
                  { path: "/manage-extras", element: <ManageExtras />, name: PAGENAMES?.MANAGE_EXTRA },

                  { path: "/inflation/coefficients", element: <InflationCoefficientsForm />, name: PAGENAMES?.INFLATION_COEEFICIENTS },
                  { path: "/create/cooperation-agreement/:companyId/:modalId?", element: <MultiFormProviderAgreement />, name: PAGENAMES?.CREATE_COOPERATION_AGREEMENT },
                  { path: "/add/inflation", element: <InflationCoefficientsForm />, name: PAGENAMES?.ADD_INFLATION },
                  { path: "/edit/:id/inflation", element: <InflationCoefficientsForm />, name: PAGENAMES?.EDIT_INFLATION },
                  { path: "/view/:id/inflation", element: <InflationCoefficientsForm />, name: PAGENAMES?.VIEW_INFLATION },
                  { path: "/manage/inflations", element: <ManageInflations />, name: PAGENAMES?.MANAGE_INFLATION },

                  //for employee type
                  { path: "/manage/employee-type", element: <ManageEmployeeType />, name: PAGENAMES?.MANAGE_EMPLOYEE_TYPE },
                  { path: "/create/employee-type/:id?", element: <CreateEmployeeType />, name: PAGENAMES?.CREATE_EMPLOYEE_TYPE },

                  //For Business unit
                  { path: `${PATH_MANAGE_BUSINESS_UNIT}`, element: <ManageBusinessUnit />, name: PAGENAMES?.MANAGE_BU },
                  { path: `${PATH_CREATE_BUSINESS_UNIT}`, element: <CreateBusinessUnit />, name: PAGENAMES?.CREATE_BU },
                  { path: `${PATH_EDIT_BUSINESS_UNIT}/:id`, element: <CreateBusinessUnit />, name: PAGENAMES?.EDIT_BU },

                  //For Tags
                  { path: `${PATH_MANAGE_TAGS}`, element: <ManageTagPage />, name: PAGENAMES?.MANAGE_TAGS },
                  { path: "/create/tags/:id?", element: <CreateTags />, name: PAGENAMES?.CREATE_TAGS },

                  //For shifts
                  { path: `${PATH_MANAGE_SHIFTS}`, element: <ManageShift />, name: PAGENAMES?.MANAGE_SHIFT },
                  { path: `${PATH_CREATE_SHIFT}`, element: <CreateShift />, name: PAGENAMES?.CREATE_SHIFT },
                  { path: `${PATH_EDIT_SHIFT}/:id`, element: <CreateShift />, name: PAGENAMES?.CREATE_SHIFT },

                  // Projects
                  { path: `${PATH_PROJECTS_MANAGE}`, element: <Projects />, name: PAGENAMES?.MANAGE_PROJECT },
                  { path: `${PATH_PROJECT_PROPOSAL}`, element: <ProjectOverview />, name: PAGENAMES?.PROJECT_PROPOSAL },
                  { path: `${PATH_PROJECTS_CREATE}`, element: <ProjectFormProvider />, name: PAGENAMES?.CREATE_PROJECT },
                  { path: `${PATH_PROJECTS_EDIT}/:id`, element: <ProjectFormProvider />, name: PAGENAMES?.EDIT_PROJECT },
                  { path: `${PATH_PROJECTS_VIEW}/:id`, element: <ProjectFormProvider />, name: PAGENAMES?.VIEW_PROJECT },

                  //Template
                  { path: `${PATH_MANAGE_TEMPLATE}`, element: <ManageTemplatesPage />, name: PAGENAMES?.MANAGE_PROJECT_TEMPLATE },
                  { path: `${PATH_TEMPLATE_EDIT}/:id`, element: <EditTemplate />, name: PAGENAMES?.EDIT_PROJECT_TEMPLATE },
                  { path: `${PATH_TEMPLATE_VIEW}/:id`, element: <EditTemplate />, name: PAGENAMES?.VIEW_PROJECT_TEMPLATE },

                  // Proposal Agreement
                  { path: `${PATH_PROPOSAL_AGREEMENT}`, element: <Proposal />, name: PAGENAMES?.PROPOSAL_AGREEMENT },
                  { path: `${PATH_PROPOSAL_AGREEMENT_MANAGER}`, element: <Proposal />, name: PAGENAMES?.PROPOSAL_AGREEMENT_MANAGER },
                  { path: `${PATH_EMPLOYEE_MANAGE}`, element: <Employees />, name: PAGENAMES?.MANAGE_EMPLOYEE },
                  { path: `${PATH_EMPLOYEE_CREATE}`, element: <CreateEmployees />, name: PAGENAMES?.CREATE_EMPLOYEE },

                  //PC
                  { path: `${PATH_PC_EDIT}/:id`, element: <CreateParitairComitee />, name: PAGENAMES?.EDIT_PC },
                  { path: `${PATH_PC_CREATE}`, element: <CreateParitairComitee />, name: PAGENAMES?.CREATE_PC },
                  { path: `${PATH_PC_MANAGE}`, element: <ManagePCPage />, name: PAGENAMES?.MANAGE_PC },

                  //Salary Benefits
                  { path: `${PATH_SALARY_BENEFITS_MANAGE}`, element: <ManageSalaryBenefits />, name: PAGENAMES?.MANAGE_SALARY_BENIFITS },
                  { path: `${PATH_SALARY_BENEFITS_CREATE}/:id?`, element: <CreateSalaryBenefits />, name: PAGENAMES?.CREATE_SALARY_BENIFITS },

                  //Application configuration
                  { path: `${PATH_EDIT_APPLICATION_CONFIG}/:element/:id`, element: <EditAppConfig />, name: PAGENAMES?.EDIT_APPLICATION_CONFIGURATION },
                  { path: `${PATH_APPLICATION_CONFIGURATION}`, element: <AppConfiguration />, name: PAGENAMES?.APPLICATION_CONFIGURATION },

                  //Matching
                  { path: `${PATH_MATCHING_EMPLOYEE}/:id`, element: <MatchingEmployee />, name: PAGENAMES?.EMPLOYEE_MATCHING },
                  { path: `${PATH_MATCHING}/:element/:id`, element: <Matching />, name: PAGENAMES?.MATCHING_PROJECT },
                  // config data
                  { path: `${PATH_CONFIG_ELEMENTS_CREATE}`, element: <ConfigForm />, name: PAGENAMES?.CREATE_CONFIG_ELEMENTS },
                  { path: `${PATH_CONFIG_ELEMENTS_MANAGE}`, element: <ManageMasterDataElements />, name: PAGENAMES?.MANAGE_CONFIG_ELEMENTS },

                  //My Profile
                  { path: `${PATH_PROFILE}`, element: <UsersProfile />, name: PAGENAMES?.MY_PROFILE },

                  //competence
                  { path: `${PATH_COMPETENCE_EDIT}/:id`, element: <CreateCompetence />, name: PAGENAMES?.EDIT_COMPETENCE },
                  { path: `${PATH_COMPETENCE_VIEW}/:id`, element: <CreateCompetence />, name: PAGENAMES?.VIEW_COMPETENCE },
                  { path: `${PATH_COMPETENCE_CREATE}`, element: <CreateCompetence />, name: PAGENAMES?.CREATE_COMPETENCE },
                  { path: `${PATH_COMPETENCE_MANAGE}`, element: <ManageCompetence />, name: PAGENAMES?.MANAGE_COMPETENCE },

                  // Proposal Agreement
                  { path: `${PATH_PROPOSAL_AGREEMENT}`, element: <Proposal />, name: PAGENAMES?.PROPOSAL_AGREEMENT },
                  { path: `${PATH_EMPLOYEE_MANAGE}`, element: <Employees />, name: PAGENAMES?.MANAGE_EMPLOYEE },
                  { path: `${PATH_EMPLOYEE_CREATE}`, element: <CreateEmployees />, name: PAGENAMES?.CREATE_EMPLOYEE },
                  { path: `${PATH_EMPLOYEE_EDIT}/:id`, element: <CreateEmployees />, name: PAGENAMES?.EDIT_EMPLOYEE },

                  //Vacancy
                  { path: `${PATH_VACANCY_MANAGE}`, element: <Vacancies />, name: PAGENAMES?.MANAGE_VACANCY },
                  { path: `${PATH_VACANCY_CREATE}/:id?`, element: <MultiFormProviderVacancy />, name: PAGENAMES?.CREATE_VACANCY },

                  // Testing purpose only you can remove it before going to production
                  { path: `${"/s3service"}`, element: <FileUploader /> },

                  //Timetable
                  { path: `${PATH_TIMETABLE_EDIT}/:id`, element: <CreateTimeTable />, name: PAGENAMES?.EDIT_TIMETABLE },
                  { path: `${PATH_TIMETABLE_CREATE}`, element: <CreateTimeTable />, name: PAGENAMES?.CREATE_TIMETABLE },
                  { path: `${PATH_MANAGE_TIMETABLE}`, element: <ManageTimeTable />, name: PAGENAMES?.MANAGE_TIMETABLE },

                  //Application configuration
                  { path: `${PATH_APPLICATION_CONFIGURATION}`, element: <AppConfiguration />, name: PAGENAMES?.APPLICATION_CONFIGURATION },
                  { path: `${PATH_EDIT_APPLICATION_CONFIG}/:element/:id`, element: <EditAppConfig />, name: PAGENAMES?.EDIT_APPLICATION_CONFIGURATION },
                  //Hotlist
                  { path: `${PATH_HOTLIST}`, element: <ManageHotlist />, name: PAGENAMES?.MANAGE_HOTLIST },
                  { path: `${PATH_EMPLOYEE_MANAGE}/:projectId/:employeeId`, element: <Employees />, name: PAGENAMES?.MANAGE_EMPLOYEE },

                  //Documents
                  { path: `${PATH_DOCUMENT}`, element: <ManageDocumentPage />, name: PAGENAMES?.MANAGE_DOCUMENTS },

                  //Questions
                  { path: `${MANAGE_QUESIONS}/:category?`, element: <ManageQuestions />, name: PAGENAMES?.MANAGE_QUESTION },
                  { path: `${ADD_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.ADD_QUESTION },
                  { path: `${EDIT_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.EDIT_QUESTION },
                  { path: `${CLONE_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.CLONE_QUESTION },
                  { path: `${VIEW_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.VIEW_QUESTION },

                  //Questions
                  { path: `${MANAGE_QUESIONS}`, element: <ManageQuestions />, name: PAGENAMES?.EDIT_PROJECT },
                  { path: `${ADD_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.EDIT_PROJECT },
                  { path: `${EDIT_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.EDIT_PROJECT },
                  { path: `${CLONE_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.EDIT_PROJECT },
                  { path: `${VIEW_QUESTION}`, element: <AddQuestions />, name: PAGENAMES?.EDIT_PROJECT },
                  { path: `${PATH_PROJECTS_MANAGE}/:employeeId`, element: <Projects />, name: PAGENAMES?.MANAGE_PROJECT },

                  //Screening
                  { path: `${PATH_MANAGE_SCREENING}`, element: <ManageScreening />, name: PAGENAMES?.MANAGE_SCREENING },
                  { path: `${PATH_SCREENING_ASSESSMENT}`, element: <ScreeningAssessment />, name: PAGENAMES?.SCREENING_ASSESSMENT },
                  { path: `${PATH_CANDIDATE_SCREENING}/:id`, element: <CandidateScreening />, name: PAGENAMES?.CANDIDATE_SCREENING },
                  { path: `${PATH_MANAGE_APPLICANTS}`, element: <ManageApplicants />, name: PAGENAMES?.MANAGE_APPLICANT },

                  //Planning
                  { path: `${PATH_HR_PLANNING}`, element: <Planning type={"hr"} />, name: PAGENAMES?.HR_PLANNING },
                  { path: `${PATH_SALES_PLANNING}`, element: <Planning type={"sales"} />, name: PAGENAMES?.SALES_PLANNING },
                  { path: "/hrmanage", element: <Hrmanage />, name: PAGENAMES?.EDIT_PROJECT },

                  // PATH_WAGE_PROPOSAL
                  { path: `${PATH_WAGE_PROPOSAL}`, element: <WageProposal />, name: PAGENAMES?.WAGE_PROPOSAL },
                  { path: `${PATH_CREATE_CONTRACT}/:id?`, element: <CreateContract />, name: PAGENAMES?.CREATE_CONTRACT },
                  { path: `${PATH_CONTRACT_OVERVIEW}`, element: <ContractOverview />, name: PAGENAMES?.CONTRACT_OVERVIEW },

                  //Applicants
                  { path: `${PATH_APPLICANT_CREATE}`, element: <CreateApplicant />, name: PAGENAMES?.CREATE_APPLICANT },
                  { path: "/applicant/view/:id", element: <CreateApplicant />, name: PAGENAMES?.VIEW_APPLICANT },
                  { path: "/applicant/details/:candidateId", element: <CandidateCorner />, name: PAGENAMES?.APPLICANT_CORNER },
                  { path: "/employee/details/:candidateId", element: <CandidateCorner />, name: PAGENAMES?.CANDIDATE_CORNER },

                  //Holiday codes
                  { path: `${CREATE_HOLIDAY_CODES}`, element: <CreateHolidayCodes />, name: PAGENAMES?.CREATE_HOLIDAY_CODE },
                  { path: `${EDIT_HOLIDAY_CODE}/:id`, element: <CreateHolidayCodes />, name: PAGENAMES?.EDIT_HOLIDAY_CODE },

                  //Holiday config
                  { path: `${HOLIDAY_CONFIGS}`, element: <HolidayConfig />, name: PAGENAMES?.HOLIDAY_CONFIG },

                  //Holiday
                  { path: `${CREATE_PUBLIC_HOLIDAY}`, element: <CreateHoliday />, name: PAGENAMES?.CREATE_PUBLIC_HOLIDAY },
                  { path: `${EDIT_PUBLIC_HOLIDAY}/:id`, element: <CreateHoliday />, name: PAGENAMES?.EDIT_PUBLIC_HOLIDAY },
                  { path: `${MANAGE_PUBLIC_HOLIDAYS}`, element: <ManageHolidays />, name: PAGENAMES?.MANAGE_PUBLIC_HOLIDAYS },
                  { path: `${MANAGE_HOLIDAY_CODES}`, element: <ManageHolidayCodes />, name: PAGENAMES?.MANAGE_HOLIDAY_CODES },

                  //Holidays per PC
                  { path: `${MANAGE_HOLIDAY_PER_PC}`, element: <ManageHolidayPerPC />, name: PAGENAMES?.MANAGE_HOLIDAY_PER_PC },
                  { path: `${LINK_HOLIDAY_PER_PC}`, element: <LinkHolidayToPc />, name: PAGENAMES?.LINK_HOLIDAY_PER_PC },
                  { path: `${EDIT_LINK_HOLIDAY_PER_PC}/:id`, element: <LinkHolidayToPc />, name: PAGENAMES?.EDIT_LINK_HOLIDAY_PER_PC },

                  //Todo
                  { path: `${PATH_TODO_CREATE}/:id?`, element: <CreateTodo />, name: PAGENAMES?.CREATE_TODO },
                  { path: `${PATH_TODO_VIEW}/:id`, element: <CreateTodo />, name: PAGENAMES?.VIEW_TODO },
                  { path: `${PATH_TODO_MANAGE}`, element: <ManageTodo />, name: PAGENAMES?.MANAGE_TODO },

                  //Contract
                  { path: `${PATH_ADD_CONTRACT_TEMPLATE}/:id?`, element: <AddContractsTemplate />, name: PAGENAMES?.ADD_CONTRACT_TEMPLATE },
                  { path: `${PATH_MANAGE_CONTRACT_TEMPLATE}`, element: <ManageContractTemplate />, name: PAGENAMES?.MANAGE_CONTRACT_TEMPLATE },
                  { path: `${PATH_VIEW_CONTRACT_TEMPLATE}/:id?`, element: <AddContractsTemplate />, name: PAGENAMES?.VIEW_CONTRACT_TEMPLATE },
                  { path: `${PATH_CLONE_CONTRACT_TEMPLATE}/:id?`, element: <AddContractsTemplate />, name: PAGENAMES?.CLONE_CONTRACT_TEMPLATE },

                  //Apply for leaves
                  { path: `${APPLY_LEAVE_OF_EMPLOYEE}`, element: <ApplyForLeave />, name: PAGENAMES?.APPLY_LEAVE },
                  { path: `${VIEW_LEAVE_OF_EMPLOYEE}/:id`, element: <ApplyForLeave />, name: PAGENAMES?.VIEW_LEAVES },
                  { path: `${EDIT_LEAVE_OF_EMPLOYEE}/:id`, element: <ApplyForLeave />, name: PAGENAMES?.EDIT_LEAVES },

                  //Leave flow
                  { path: `${MANAGE_EMPLOYEE_LEAVES}`, element: <ManageLeavesOfEmployee />, name: PAGENAMES?.MANAGE_EMPLOYEE_LEAVES },
                  { path: `${EMPLOYEE_LEAVE_COUNT}`, element: <LeaveCountOfEmployee />, name: PAGENAMES?.EMPLOYEE_LEAVE_COUNT },
                  { path: `${MANAGE_EMPLOYER_LEAVES}`, element: <ManageLeavesForEmployer />, name: PAGENAMES?.MANAGE_EMPLOYER_LEAVES },
                  { path: `${EMPLOYER_LEAVE_COUNT}`, element: <LeaveCountOfEmployer />, name: PAGENAMES?.EMPLOYER_LEAVE_COUNT },
                  { path: `${ADD_LEAVE}`, element: <AddLeave />, name: PAGENAMES?.APPLY_LEAVE },
                  { path: `${VIEW_LEAVE_THROUGH_EMPLOYER}/:id`, element: <AddLeave />, name: PAGENAMES?.VIEW_LEAVES },
                  { path: `${EDIT_LEAVE_THROUGH_EMPLOYER}/:id`, element: <AddLeave />, name: PAGENAMES?.EDIT_LEAVES },

                  // timesheets
                  { path: `${PATH_MANAGE_TIMEHSHEETS}`, element: <Timesheets />, name: PAGENAMES?.MANAGE_TIMESHEET },
                  { path: `${PATH_MANAGE_TIMEHSHEETS_OVERVIEW}`, element: <ManageTimesheetOverview />, name: PAGENAMES?.MANAGE_TIMESHEET_OVERVIEW },
                  
                  // fleet and material
                  { path: PATH_CREATE_MATERIAL, element: <AddMaterial />, name: PAGENAMES?.CREATE_MATERIAL },
                  { path: `${PATH_EDIT_MATERIAL}/:id`, element: <AddMaterial />, name: PAGENAMES?.EDIT_MATERIAL },
                  { path: PATH_CREATE_CAR, element: <AddCar />, name: PAGENAMES?.CREATE_CAR },
                  { path: `${PATH_EDIT_CAR}/:id`, element: <AddCar />, name: PAGENAMES?.EDIT_CAR },
                  { path: PATH_CREATE_CLOTHES, element: <AddClothes />, name: PAGENAMES?.CREATE_CLOTHS },
                  { path: `${PATH_EDIT_CLOTHES}/:id`, element: <AddClothes />, name: PAGENAMES?.EDIT_CLOTHS },
                  { path: PATH_CREATE_SUPPLIER, element: <AddSupplier />, name: PAGENAMES?.CREATE_SUPPLIER },
                  { path: `${PATH_EDIT_SUPPLIER}/:id`, element: <AddSupplier />, name: PAGENAMES?.EDIT_SUPPLIER },
                  { path: PATH_CREATE_CLIENT, element: <AddClient />, name: PAGENAMES?.CREATE_CLIENT },
                  { path: `${PATH_EDIT_CLIENT}/:id`, element: <AddClient />, name: PAGENAMES?.EDIT_CLIENT },
                  { path: PATH_CREATE_PURCHASE_ORDER, element: <AddPurchaseOrder />, name: PAGENAMES?.CREATE_PURCHASE_ORDER },
                  { path: `${PATH_VIEW_PURCHASE_ORDER}/:id`, element: <AddPurchaseOrder />, name: PAGENAMES?.VIEW_PURCHASE_ORDER },
                  { path: `${PATH_EDIT_PURCHASE_ORDER}/:id`, element: <AddPurchaseOrder />, name: PAGENAMES?.EDIT_PURCHASE_ORDER },
                  { path: `/fleetOverview/:type?`, element: <FleetOverview />, name: PAGENAMES?.FLEET_OVERVIEW },
                  { path: `${PATH_EMPLOYEE_FLEET_OVERVIEW}/:id/:userType`, element: <FleetEmployeeOverview />, name: PAGENAMES?.EMPLOYEE_FLEET_OVERVIEW },
                  { path: PATH_CREATE_FLEET_LOCATION, element: <CreateFleetLocation />, name: PAGENAMES?.CREATE_FLEET_LOCATION },
                  { path: `${PATH_EDIT_FLEET_LOCATION}/:id`, element: <CreateFleetLocation />, name: PAGENAMES?.EDIT_FLEET_LOCATION },

                  //work-pay-bill-roll
                  { path: `${PATH_WORK_PAY_BILL_ROLL}`, element: <WorkPayBillRoll />, name: PAGENAMES?.PAY_ROLL },

                  //temp agency
                  { path: PATH_MANAGE_TEMP_AGENCY, element: <ManageTempAgency />, name: PAGENAMES?.MANAGE_TEMP_AGENCY },
                  { path: PATH_CREATE_TEMP_AGENCY, element: <TempAgencyFormProvider />, name: PAGENAMES?.CREATE_TEMP_AGENCY },
                  { path: `${PATH_EDIT_TEMP_AGENCY}/:id`, element: <TempAgencyFormProvider />, name: PAGENAMES?.EDIT_TEMP_AGENCY },
                  { path: `${PATH_TEMP_AGENCY_PROPOSAL}`, element: <TempAgencyOverview />, name: PAGENAMES?.TEMP_AGENCY_OVERVIEW },
                  { path: PATH_MANAGECOEFF_DEVIATION, element: <ManageCoeffDeviation />, name: PAGENAMES?.MANAGE_COEFF_DEVIATION },
                  { path: `${PATH_EDIT_COEEFICIENT}/:id`, element: <CoeffDeviation />, name: PAGENAMES?.EDIT_COEEFICIENT },
                  { path: PATH_MANAGE_WAGE_ELEMENT, element: <ManageWageElements />, name: PAGENAMES?.MANAGE_WAGE_ELEMENT },

                  //Translations
                  { path: PATH_MANAGE_TRANSLATIONS, element: <ManageTranslations />, name: PAGENAMES?.MANAGE_TRANSLATIONS },

                  //Margin calculation
                  { path: PATH_MARGIN_CALCULATOR, element: <MarginCalculator />, name: PAGENAMES?.MARGIN_CLALCULATOR },
                  { path: "/margin", element: <MarginProviderWrapper />, name: PAGENAMES?.MARGIN_CLALCULATOR },

                  //Partena
                  { path: PATH_MANAGE_PARTENA, element: <ManagePartena />, name: PAGENAMES?.MANAGE_PARTENA },
                  { path: PATH_ADD_PARTENA, element: <AddPartenaCalculation />, name: PAGENAMES?.ADD_PARTENA },
                  { path: `${PATH_PARTENA_CALCULATION}/:id`, element: <PartenaCalculation />, name: PAGENAMES?.PARTENA_CALCULATIONS },

                  //Candidates
                  { path: `${CREATE_CANDIDATE}/:id?`, element: <MultiFormProviderCandidate />, name: PAGENAMES?.CREATE_CANDIDATE },
                  { path: MANAGE_CANDIDATES, element: <ManageCandidates />, name: PAGENAMES?.MANAGE_CANDIDATES },
                  { path: `${EDIT_CANDIDATE}/:id`, element: <MultiFormProviderCandidate />, name: PAGENAMES?.EDIT_CANDIDATES },

                  //Wage  proposal
                  { path: `${MANAGE_WAGE_PROPOSAL}/:candidateId?`, element: <ManageWageProposal />, name: PAGENAMES?.MANAGE_WAGE_PROPOSAL },
                  { path: `${CREATE_WAGE_PROPOSAL}/:candidateId?/:wageId?`, element: <CreactWageProposal />, name: PAGENAMES?.CREATE_WAGE_PROPOSAL },

                  //Contract proposal
                  { path: `${CREATE_CONTRACT}/:candidateId?/:contractId?`, element: <CreateContractProposal />, name: PAGENAMES?.CREATE_CONTRACT_PROPOSAL },
                  { path: `${MANAGE_CONTRACT_PROPOSAL}/:candidateId?`, element: <ManageContractProposal />, name: PAGENAMES?.MANAGE_CONTRACT_PROPOSAL },

                  //Document type
                  { path: PATH_CREATE_DOCUMENT_TYPE, element: <CreateDocumentType />, name: PAGENAMES?.CREATE_DOCUMENT_TYPE },
                  { path: `${PATH_EDIT_DOCUMENT_TYPE}/:id`, element: <CreateDocumentType />, name: PAGENAMES?.EDIT_DOCUMENT_TYPE },
                  { path: PATH_MANAGE_DOCUMENT_TYPE, element: <DocumentType />, name: PAGENAMES?.MANAGE_DOCUMENT_TYPE }
            ],
      },
]);
export const Routes = createBrowserRouter(routes);
