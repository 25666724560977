import React from "react";

interface FormSubTitleProps {
  title?: string;
}

const FormSubTitle: React.FC<FormSubTitleProps> = ({ title }) => {
  return (
      <div className="tab-subtitle px-0">{title}</div>
  );
};
export default FormSubTitle;
