import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { FETCH_PLANNING, GET_PLANNING_FILTER_DATA, GET_TIMEHSHEETS } from "routes/ApiEndpoints";
import { PROJECT_PARAMETER_ACTIONS } from "../timesheets-redux/actions/ProjectParameterActions";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { EMPLOYEE_ACTIONS } from "../timesheets-redux/actions/EmployeesActions";

export const getWeekDays = (date) => {
  const days = [];
  date = new Date(date);
  const dayOfWeek = date.getDay(); // Get the day of the week (0-6)
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Sunday as first day of the week
  date.setDate(diff);
  for (let i = 0; i < 7; i++) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const dateFormat = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month starts from 0, so add 1
  const year = date.getFullYear(); // Get last two digits of the year
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
  return formattedDate;
};

export function calculateWorkedTime(startTime, endTime, pauseTime) {
  // Helper function to parse time
  function parseTime(time) {
    const parts = time.split(":").map(Number);
    if (parts.length === 2) {
      parts.push(0); // add seconds if missing
    }
    return parts;
  }

  // Parse the start, end, and pause times
  const [startHours, startMinutes, startSeconds] = parseTime(startTime);
  const [endHours, endMinutes, endSeconds] = parseTime(endTime);
  const [pauseHours, pauseMinutes, pauseSeconds] = parseTime(pauseTime);

  // Create Date objects
  const startDate = new Date();
  startDate.setHours(startHours, startMinutes, startSeconds, 0);

  const endDate = new Date();
  endDate.setHours(endHours, endMinutes, endSeconds, 0);

  // Calculate the difference in milliseconds
  let diff = endDate - startDate;

  // If end time is less than start time, assume it goes over midnight
  if (diff < 0) {
    diff += 24 * 60 * 60 * 1000; // add 24 hours
  }

  // Convert pause time to milliseconds
  const pauseTimeInMs =
    (pauseHours * 60 * 60 + pauseMinutes * 60 + pauseSeconds) * 1000;

  // Subtract pause time
  diff -= pauseTimeInMs;

  // Convert the difference to hours and minutes
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  // Format the result with leading zeros if necessary
  const formattedHours = String(diffHours).padStart(2, "0");
  const formattedMinutes = String(diffMinutes).padStart(2, "0");

  // Combine hours and minutes
  const totalHours = `${formattedHours}:${formattedMinutes}`;

  return totalHours;
}
export function addTimes(time1, time2) {
  // Function to convert time string (HH:MM) to total minutes
  function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  // Function to convert total minutes to time string (HH:MM)
  function minutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(
      remainingMinutes
    ).padStart(2, "0")}`;
  }

  const totalMinutes = timeToMinutes(time1) + timeToMinutes(time2);
  return minutesToTime(totalMinutes);
}

export const getAllDates = (date) => {
  const days = [];
  date = new Date(date);
  const dayOfWeek = date.getDay(); // Get the day of the week (0-6)
  const startDate = new Date(date); // Create a copy of the date

  // Calculate the start date of the week (previous Monday)
  startDate.setDate(
    startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
  );

  // Ensure startDate is within the same month as the provided date
  while (startDate.getMonth() !== date.getMonth()) {
    startDate.setDate(startDate.getDate() - 7); // Move back a week until within the same month
  }

  for (let i = 0; i < 7; i++) {
    const formattedDate = new Date(startDate);
    formattedDate.setDate(startDate.getDate() + i);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = formattedDate.getDate().toString().padStart(2, "0");
    days.push(`${year}-${month}-${day}`);
  }
  return days;
};

const normalizeDate = (dateString) => {
  return new Date(dateString)?.toISOString()?.split?.("T")?.[0]; // Normalize date to YYYY-MM-DD format
};

export async function fetchWeeklyTimesheetData(data, currentDate, parametersDispatch = false) {
  try {
    const planning = {
      company_id: [],
      project_id: [data.project_id],
      businessUnits: [],
      employeeTypes: [],
      startdate: data.from_date,
      enddate: data.to_date,
      employeesid: [data.employee_id],
      strict: true,
    };
    let payload = {
      timesheets: data,
      planning: planning
    }
    if (parametersDispatch) {
      payload.parameters = true
    }
    // Fetch timesheet data
    const timesheetResponse = await ApiCall.service(GET_TIMEHSHEETS,"POST", payload, false, M_MASTER_DATA);
    if (parametersDispatch) {
      parametersDispatch({ type: PROJECT_PARAMETER_ACTIONS.SET_PROJECT_PARAMETER_DATA, payload: timesheetResponse?.['parameters'] });
    }    
    const timesheetDetails = timesheetResponse?.['timesheets']?.[0]?.timesheet_details || [];
    // Fetch planning data
    const planData = timesheetResponse?.['planning']?.[0]?.plans?.map((entry) => {
      return {
        start_date_time: entry?.start_date_time,
        end_date_time: entry?.end_date_time,
        start_time: entry?.start_date_time,
        end_time: entry?.end_date_time,
        break_hours: entry?.break_hours || "00:00",
        regime: entry?.regime || "",
        holiday: entry.holiday || false,
        invoice_milage: false,
        purchase_invoice: false,
        invoice_no: "",
        worksheet_number: "",
        plan_id: entry.planid,
        plan_date: normalizeDate(entry?.plan_date),
        totalHours: "",
        pause_time: entry?.break_hours || "00:00",
        reimbursement: entry?.reimbursement ?? [],
        performance: entry?.performance ?? [],
      };
    }) || [];

    // Merge timesheet details with planning data
    const mergedData = [
      ...timesheetDetails,
      ...planData
        .filter(
          (plan) =>
            !timesheetDetails.some(
              (entry) => normalizeDate(entry.plan_date) === normalizeDate(plan.plan_date)
            )
        )
        .filter(plan => !isFutureDate(plan?.plan_date))
        .filter(plan => !(timesheetResponse?.['leaves']?.length > 0 && isDateInLeaveRange(plan?.plan_date, timesheetResponse?.['leaves'])))
    ];
    // Generate all dates for the given week and fill missing dates with dummy data
    const allDates = getAllDates(currentDate);    
    allDates.forEach((date) => {
      if (!mergedData?.some((entry) => normalizeDate(entry?.plan_date) === date)) {
        mergedData.push({
          start_date_time: `${date} 00:00:00`,
          end_date_time: `${date} 00:00:00`,
          start_time: "",
          end_time: "",
          break_hours: "",
          regime: "",
          holiday: false,
          invoice_milage: false,
          purchase_invoice: false,
          invoice_no: "",
          worksheet_number: "",
          plan_id: null,
          plan_date: `${date}`,
          totalHours: 0,
          pause_time: "",
          reimbursement: [],
          performance: [],
        });
      }
    });

    // Sort and slice the data for the week
    const sortedData = mergedData
      .sort((a, b) => new Date(a.plan_date) - new Date(b.plan_date))
      .slice(0, 7);
      
    return {
      timesheet_id: timesheetResponse?.['timesheets']?.[0]?.timesheet_id || null,
      isInvoiced: timesheetResponse?.['timesheets']?.[0]?.isInvoiced,
      timesheet_data: sortedData,
    };
  } catch (error) {
    console.error("Error fetching weekly timesheet data:", error);
    return { timesheet_id: null, timesheet_data: [] };
  }
}

const isFutureDate = (date) => {
  const currentDate = new Date();
  return new Date(date) > currentDate;
};

const isDateInLeaveRange = (planDate, leaves) => {
  return leaves?.some(leave => 
    leave?.dates?.some(dateRange => 
      normalizeDate(planDate) >= normalizeDate(dateRange?.start_date) &&
      normalizeDate(planDate) <= normalizeDate(dateRange?.end_date)
    )
  );
};

export const handleProjects = async (props) => {
  try {
    let employeeData = await ApiCall.service(FETCH_PLANNING, 'POST', { ...props?.planningPayload }, false, M_MASTER_DATA);
    if (props?.filterOptionsState?.filteredState?.employeesid?.length > 0 && employeeData?.data?.length > 0) {
      employeeData.data = employeeData?.data?.filter((item) => props?.filterOptionsState?.filteredState?.employeesid?.includes(item?.employee_number));
    }
    props.dispatchEmployee({
      type: EMPLOYEE_ACTIONS.SET_EMPLOYEE_DATA,
      payload: employeeData?.data?.map((item) => { return { value: item?.employee_number, label: item?.employee_name } }) || []
    });    
    if (employeeData?.data[0]) {
      const date = new Date(props.initialWeekAndDayPropsState.currentDate);
      let weekDays = getWeekDays(date);
      let startDate = dateFormat(weekDays[0]);
      let endDate = dateFormat(weekDays[6]);
      const updatedData = await fetchWeeklyTimesheetData({
        project_id: props?.project_id,
        employee_id: employeeData?.data[0]?.employee_number ?? null,
        from_date: startDate,
        to_date: endDate
      }, date, props.projectParameterDispatch);
      props.timesheetDispatch({ type: TIMESHEET_ACTIONS.CHANGE_WEEK, payload: { data: updatedData || [] } });
    } else {
      props.timesheetDispatch({ type: TIMESHEET_ACTIONS.CHANGE_WEEK, payload: { data: [] } });
    }
  } catch (error) {
    console.error('Failed to fetch employees:', error);
  }
};

export const getFilterOptions = async (payload) => {
  let response = [];
  try {
    response = await ApiCall.service(GET_PLANNING_FILTER_DATA, "post", payload, false, M_MASTER_DATA);
  } catch (error) {
    console.error(error);
  }
  return response;
};