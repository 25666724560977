import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useFormContext } from '../../context/Context';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import HelpInfoIcon from 'static/images/HelpIcon';
import { Option } from 'utils/TypeAnnotations';
import { RANGE_VALUE, RANGE_VALUE_FOR_GENERAL } from 'constants/Constants';
import { COMPETENCE_CHANGE } from '../../annotations/CandidateConstants';
import Navigation from '../form-navigation/Navigation';
import { t } from 'pages/microservices/masterData/translation/Translation';
import CustomRangeSlider from 'components/atoms/CustomRangeSlider';
import TextEllipsis from 'components/atoms/TextEllipsis';

interface CompetenceProps {
  competenceOptions: any;
}
const CompetenceTab: React.FC<CompetenceProps> = ({
  competenceOptions,
}) => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const data: any = state?.competence ?? [];

  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleSelect = (selectedId: number, section: string, selected: Option[]) => {
    dispatch({
      type: COMPETENCE_CHANGE, field: "SELECT",
      payload: { selection: section, selected: selected.length > 0 ? selected : [], id: selectedId },
    });
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: string,
    index: number
  ) => {
    dispatch({
      type: COMPETENCE_CHANGE,
      payload: { value: e.target.value, section: section, index: index },
    });
  };

  return (
    <>
      <div className="form-border" style={{ paddingTop: "3vw" }}>
        <Accordion defaultActiveKey={competenceOptions !== undefined && competenceOptions[0]?.key} alwaysOpen>
          {competenceOptions !== undefined && competenceOptions.length > 0 ? (competenceOptions.map((section: any) => {
            return (
              <Accordion.Item key={section?.key} eventKey={section?.key}>
                <Accordion.Header>{section?.label}</Accordion.Header>
                <Accordion.Body className='competentcesAccordionBody'>
                  <div className='hideHeight marginBottomPoint5'>
                    <SelectWithSearch
                      name={section.key}
                      isMandatory={false}
                      search={true}
                      placeHolder="Select"
                      options={section.options}
                      value={data?.filter((item: any) => item.key == section.key)?.[0]?.options?.map((item: any) => item?.value)}
                      onChange={(selected) => handleSelect(section.id, section.key, selected)}
                      isMulti={true}
                      className="select-field"
                      error={""}
                    />
                  </div>
                  <div className="row">
                    {/* {data?.length > 0 &&
                      data?.find((item: any) => item.key === section.key)?.options && (
                        <div
                          className="text-end mt-2"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title={section?.info}
                        >
                          <HelpInfoIcon />
                        </div>
                      )} */}
                    {data?.length > 0 && data?.find((item: any) => item.key === section.key)?.options?.map((option: any, index: any) => (
                      <div key={index} className="col-3" style={{ margin: "0.5vw 0" }}>
                        <div className="row">
                          <div className="col-5 pe-0">
                            <span className="fw-bolder"><TextEllipsis title={option?.label} label={option?.label} width="8vw" /> </span>
                          </div>
                          <div className="col">
                            <CustomRangeSlider
                              min={1}
                              max={section?.maxRange ?? "6"}// Adjusted max value to accommodate the range
                              step={1} // Step size should match the range
                              title={getTitleForRating(option?.range)}
                              handleChange={(e) => handleChange(e, section.key, index)}
                            />
                            <div className="w-100">
                              <div className="d-flex justify-content-between">
                                {(section?.maxRange == 4 ? RANGE_VALUE_FOR_GENERAL : RANGE_VALUE).map((value: any, index: number) => (
                                  <span key={value}>{value}</span>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })) : (
            <div className='text-center text-danger'> {t("No records found")}</div>
          )}
        </Accordion>

      </div>
      <div style={{ padding: "1vw 0" }}>
        <Navigation validStatus={validStatus} />
      </div>
    </>
  );
}

export default CompetenceTab;