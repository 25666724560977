import LabelField from "components/atoms/LabelField";
import MonthPicker from "components/atoms/MonthPicker";
import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import Button from "components/atoms/Button";
import ArrowsIcon from "static/images/Arrows";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import DatePicker from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { FormDataProps, initialAddPartenaData } from "./PartenaInterface";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps } from "utils/TypeAnnotations";

const AddPartenaCalculation = () => {
  const navigate = useNavigate();
  const user = useSelector(selectAuth);
  const [formData, setFormData] = useState<FormDataProps>(initialAddPartenaData);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [businessUnits, setBusinessUnits] = useState<OptionProps[]>();
  const [initialBUnits, setInitialBUnits] = useState<OptionProps[]>([]);

  useEffect(() => {

    fetchBusinessUnits();
  }, []);
  const fetchBusinessUnits = async () => {
    const response = await ApiCall.getService("getBusinessUnits", "get", M_MASTER_DATA, false);
    const bunits = mapToSelect(response.data);
    setInitialBUnits(bunits);
    setBusinessUnits(bunits);

  }
  const validation = (name: string, value: number | undefined) => {
  }

  const onChange = (selectedOption: OptionProps, field: string) => {
    setFormData((prevData: any) => {
      if (field === "businessUnit") {
        const exists = prevData.business_units.some((unit: OptionProps) => unit?.value === selectedOption?.value);
        const filteredBusinessUnits = businessUnits?.filter((record: object) => record !== selectedOption);
        setBusinessUnits(filteredBusinessUnits);
        if (exists) {
          const filteredAssignedBUs = prevData.business_units.filter((record: object) => record !== selectedOption);
          setFormData((prevData: FormDataProps) => ({ ...prevData, business_units: filteredAssignedBUs }));
          setBusinessUnits([...(businessUnits ?? []), selectedOption]);
          return prevData;
        } else {
          return {
            ...prevData,
            business_units: [...prevData.business_units, selectedOption]
          };
        }
      } else if (field === "month") {
        return {
          ...prevData,
          month: selectedOption?.value
        };
      }
      return prevData; // Default case if field is not recognized
    });
    validation(field, selectedOption?.value);
  };

  const handleCheckBoxChange = () => {
    const newStatus: boolean = !checkBoxStatus;
    setCheckBoxStatus(newStatus);
    const businessUnit = newStatus ? initialBUnits : [];
    if (businessUnit.length > 0) {
      setBusinessUnits([]);
    } else {
      setBusinessUnits(initialBUnits);
    }
    setFormData((prevData: FormDataProps) => ({ ...prevData, business_units: businessUnit } as FormDataProps));

  }

  const handleSubmit = async (e: React.FormEvent, reset: boolean) => {
    e.preventDefault();
    if (reset) {
      setFormData((prevData) => ({ ...prevData, business_units: [], month: "select" }))
    }
    const postData: { data: FormDataProps } = {
      data: { ...formData, created_by: user?.userId, name: user?.name }
    }

    const response = await ApiCall.service("storePartenaCalculation", "POST", postData, false, M_MASTER_DATA);

    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.message });
      navigate("/partena");
    }
  };
  const handleDateChange = (date: any, name: any) => {
    const value = date.year;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className=" row ">
      <div className="col">
        <MonthPicker
          label={t("Choose a month")}
          name="month"
          handleChange={(value) => onChange(value, "month")}
          value={formData.month}
          error=""
        />
      </div>
      <div className="col">
        <div className="row">
          <LabelField title={t("Choose a year")} />
        </div>
        <div className="row">
          <DatePicker
            inputClass="form-control field-shadow"
            name="date"
            placeholder={new Date().getFullYear().toString()}
            onChange={(e) => handleDateChange(e, "year")}
            format="YYYY"
            onlyYearPicker={true}
            minDate={new Date(2014, 0, 1)}
            zIndex={1000}
          />
        </div>

      </div>

      <div className="d-flex">
        <div className="border border-dark cursor-pointer flex-1">
          <div className="p-2 border-bottom-3 border-bottom border-secondary border-dark">Available business unit</div>
          {businessUnits && businessUnits.length > 0 &&
            businessUnits.map((record: any, index) => (
              <>
                <div className="p-2" onClick={() => onChange(record, "businessUnit")} key={index}>{record.label}</div>
              </>
            ))}
        </div>
        <div className="d-flex align-items-center mx-5">
          <ArrowsIcon />
        </div>
        <div className="border border-dark cursor-pointer flex-1">
          <div className="p-2 border-bottom-3 border-bottom border-secondary border-dark">Assigned business unit</div>

          {formData?.business_units && formData?.business_units?.length > 0 &&
            formData?.business_units.map((record: any, index) => (
              <>
                <div className="p-2" onClick={() => onChange(record, "businessUnit")} key={index}>{record.label}</div>
              </>

            ))}
        </div>

      </div>
      <div className="my-3">
        <CheckBoxField
          label="Select all"
          name="selectAll"
          isChecked={checkBoxStatus}
          onChangeHandler={() => handleCheckBoxChange()}
        />
      </div>
      <div className=" d-flex justify-content-end">
        <Button
          title={t("Cancel")}
          handleClick={(e) => handleSubmit(e, true)}
          className={"btn form-button me-3  rounded shadow-none"}
        />
        <Button
          title={t("Save")}
          handleClick={(e) => handleSubmit(e, false)}
          className={"btn form-button  rounded shadow-none"}
        />
      </div>
    </div>
  );
}

export default AddPartenaCalculation;