import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { RANGE_VALUE } from "constants/Constants";
import HelpInfoIcon from "static/images/HelpIcon";
import { useFormContext } from "../../utils/multiTabForm/context/Context";
import {
  COMPETENCE_RANGE,
  COMPETENCE_SELECTED,
} from "utils/multiTabForm/context/Constants";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceProps {
  competenceOptions: any;
}
const Competence: React.FC<CompetenceProps> = ({ competenceOptions }) => {
  const { state, dispatch } = useFormContext();
  const handleSelect = (
    selectedId: number,
    section: string,
    selected: Option[]
  ) => {
    dispatch({
      type: COMPETENCE_SELECTED,
      payload: {
        selection: section,
        selected: selected.length > 0 ? selected : [],
        id: selectedId,
      },
    });
  };
  let data = (state.form[state.currentTab][0] as unknown as { value: any })
    .value;
  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: string,
    index: number
  ) => {
    dispatch({
      type: COMPETENCE_RANGE,
      payload: { value: e.target.value, section: section, index: index },
    });
  };
  return (
    <Accordion defaultActiveKey={competenceOptions[0]?.key} alwaysOpen>
      {competenceOptions.map((section: any) => {
        return (
          <Accordion.Item key={section?.key} eventKey={section?.key}>
            <Accordion.Header>{section?.label}</Accordion.Header>
            <Accordion.Body className="pb-0">
              <SelectWithSearch
                name={section.key}
                isMandatory={false}
                search={true}
                placeHolder="Select"
                options={section.options}
                value={
                  data?.filter((item: any) => item.key == section.key)?.[0]
                    ?.options
                }
                onChange={(selected) =>
                  handleSelect(section.id, section.key, selected)
                }
                isMulti={true}
                className="select-field"
                error={""}
              />
              <div className="row">
                {data?.length > 0 &&
                  data.find((item: any) => item.key === section.key)
                    ?.options && (
                    <div className="col-12">
                      <span
                        className="float-end d-inline-block"
                        title={`A. Basic user (A1/A2: beginner)\nB. Independent user (B1/B2: advanced)\nC. Skilled user (C1/C2: advanced)`}
                      >
                        <HelpInfoIcon />
                      </span>
                    </div>
                  )}
                {data?.length > 0 &&
                  data
                    ?.find((item: any) => item.key === section.key)
                    ?.options?.map((option: any, index: any) => (
                      <div
                        key={index}
                        className="col-3"
                        style={{ marginBottom: "1vw" }}
                      >
                        <div className="row compentences">
                          <div className="col-3 align-self-center">
                            <span className="">{option?.label}</span>
                          </div>
                          <div className="col range-wrap">
                            <input
                              type="range"
                              min="1"
                              max="6"
                              title={getTitleForRating(option?.range)}
                              value={option.range}
                              className="form-range cursor-pointer range sliderPink"
                              onChange={(e) =>
                                handleChange(e, section.key, index)
                              }
                            />
                            <div className="d-flex justify-content-between">
                              {RANGE_VALUE.map((value, index) => (
                                <span key={value}>{value}</span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default Competence;
