import { ManageHolidaysPerPC } from "TableHeader";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import {
  EDIT_LINK_HOLIDAY_PER_PC,
  HOLIDAY_CONFIGS,
  LINK_HOLIDAY_PER_PC,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DELETE_HOLIDAY_PER_PC,
  GET_HOLIDAYS_AND_PC,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";
import TitleAtom from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
interface PC {
  name: string;
  id: number;
}
interface PcOptions {
  label: any;
  name: any;
  value: any;
}
const ManageHolidayPerPC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    pc_name: queryParams.get("year") ?? "",
  });
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });
  const getSearchData = () => {
    return {
      pc_name: history?.filters?.pc_name ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
};
  const [pcData, setPcData] = useState<PC[]>([]);
  const [id, setId] = useState<number | undefined>(undefined);
  const [pcOptions, setPcOptions] = useState<PcOptions[]>([]);
  //onClick edit handler
  const handleEdit = (entity: any) => {
    navigate(`${EDIT_LINK_HOLIDAY_PER_PC}/${entity.id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (entity: any) => {
    setId(entity.id);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_HOLIDAY_PER_PC,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        setPcData((prevdata) =>
          (prevdata as PC[]).filter((pcData) => pcData.id !== id)
        );
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };
  useEffect(() => {
    const data = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall
    };
    fetchPcPerHolidays(data); //To get all public holiday details
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchPcPerHolidays = async (data: any) => {
    try {
      const response = await ApiCall.service(GET_HOLIDAYS_AND_PC, "POST", data, false, M_MASTER_DATA);
      if (response?.status === 200) {
        if (pageData?.initialCall) {
          setPcOptions(response?.pcOptions);
        }
        setPcData(response?.data);        
        setPageData((prev: any) => ({ ...prev, totalPages: response.totalPages, totalRecords: response?.totalRecords, initialCall: false}));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <div className="search-btn">
        <TitleAtom title={t("Manage holiday per paritair committee")} />
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="ManageCreateBtn">
            <Link to={LINK_HOLIDAY_PER_PC} className="form-button">
              + {t("Link holiday per PC")}
            </Link>
          </div>
        </div>
        <div className="table-responsive manage-publicholiday-codes tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={[{name: 'pc_name', fieldType: "singleSelect", options: pcOptions, placeholder: "PC name", filterType: 'search' }]}
            handleRefresh={handleRefresh}
          />
          <div className="TableHeight">
            <DynamicTableStructure
              isAction={true}
              data={pcData}
              headers={ManageHolidaysPerPC}
              name={"manage_public_holidays"}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              history={history}
              handleRefresh={handleRefresh}
            />
          </div>
          <div
            className=""
            style={{ paddingTop: "0.25vw" }}
          >
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>

      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <BackButton />
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidayPerPC;
