import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as ENDPOINTS from "routes/ApiEndpoints";
import RightArrow from "static/images/RightArrow";
import AccessControl from "services/AccessControl";
import CardCircle from "components/atoms/CardCircle";
import DownloadIcon from "static/images/downloadIcon";
import { t } from "pages/microservices/masterData/translation/Translation";

export type MatchingCandidateProfileCardType = {
  candidateId?: number | null;
  firstName: string;
  lastName: string;
  functions: any;
  mobNumber?: string;
  email?: string;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  picFileName?: string;
  picFilePath?: string;
  country?: string;
  cvData?: any;
  profilePicData?: any;
  isScreening?: boolean;
  source?: string;
};

export interface MatchingProfileCardProps {
  profileCardProps: MatchingCandidateProfileCardType;
  isCandidateCreation?: boolean;
  profileData?: boolean
}

const MatchingProfileCard: React.FC<MatchingProfileCardProps> = (props) => {  
  const navigate = useNavigate();

  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address.join(", ");

  // const downloadFile = async (
  //   id: number | null,
  //   name: string | null,
  //   path: string | null,
  //   uploadPath: string | null
  // ) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_SERVICE}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
  //     );
  //     if (response.status === 200) {
  //       const fileUrl = response.data.url;
  //       const link = document.createElement("a");
  //       link.style.display = "none";
  //       link.href = fileUrl;
  //       link.target = "_blank";
  //       link.download = response.data.filename;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching download URL:", error);
  //   }
  // };

  return (
    // <AccessControl
    //     requiredPermissions={[
    //       {
    //         permission: "Candidates",
    //         read: true,
    //       },
    //     ]}
    //     renderNoAccess={true}
    //   >
    <div className={props.profileCardProps.firstName === '' &&  props.profileCardProps.lastName === '' ? " row mt-5": 'row'}>
      <div className="col-md-7">
        <div
          style={{
            marginLeft: "3.75vw",
          }}
        >
          <div
            className="card position-relative border-0"
            style={{
              backgroundColor: "var(--sideBarBgColor)",
              padding: "2.5vw"
            }}
          >
            <div className="cv-upload">
              {/* {props.profileCardProps.picFileName === "" ? (
                        <img
                          src="../../static/images/ProfileBadge.svg"
                          alt="mobile"
                          className="img-fluid ProfileBadge"
                          width={60}
                          style={{
                            position: "absolute",
                            bottom: "-115px",
                            left: "6px",
                          }}
                        />
                      ) : (
                        <img
                          src="../../static/images/crown.svg"
                          alt="mobile"
                          className="img-fluid"
                          width={40}
                          style={{
                            position: "absolute",
                            left: "-136px",
                            top: "-50px",
                            transform: "rotate(-20deg)",
                          }}
                        />
                      )} */}
              <CardCircle
                position="absolute"
                width="7.813vw"
                height="7.813vw"
                backgroundColor="var(--primaryBrandColor)"
                left="0%"
                top={"50%"}
                transform={`translate(-3.5vw, -3.9vw)`}
                className="me-5 flex-column cursor-pointer justify-content-center"
                color="var(--color-white)"
                inputClass={"mb-2"}
              >
                {props.profileCardProps.profilePicData !== '' && props?.profileCardProps?.profilePicData?.fileName !== "" ? (
                  <img
                    src={props.profileCardProps.profilePicData?.accessUrl}
                    alt={t("Uploaded")}
                    style={{
                      backgroundColor: "var(--color-white)",
                      borderRadius: "50%",
                      boxShadow: "rgb(248 216 235) 0.052vw 0vw 0.521vw 0.156vw",
                    }}
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <>
                  <img
                    src="/static/images/Person.svg"
                    alt="Profile"
                    width="100%"
                    height="100%"
                  />
                  </>
                )}
              </CardCircle>
            </div>
            <div className="row">
              <div className="col-md-10 ms-auto position-relative">
                {(props.profileCardProps.firstName ||
                  props.profileCardProps.lastName) && (
                    <div className={`color-dark-skyblue name-text text-start mb-3`}>
                      {props.profileCardProps.firstName}{" "}
                      {props.profileCardProps.lastName}
                    </div>
                  )}
                {/* <div className="job-title text-start" style={{ marginBottom: "1vw" }}>
                  <span>{t("looking for a job as")}</span>
                  <span>
                    {functionList.length > 0 && (
                      <strong> {functionList.join(", ")}</strong>
                    )}
                  </span>
                </div> */}
                <div className="candidate-person-details">
                  {props.profileCardProps.mobNumber && (
                    <div className="d-flex align-items-center" style={{ marginBottom: "0.8vw" }}>
                      <img
                      src="/static/images/mobile.png"
                        alt="mobile"
                        className="profileCardImage"
                      />
                      <span className={`ms-3`}>
                        {props.profileCardProps.mobNumber}
                      </span>
                    </div>
                  )}
                  {props.profileCardProps.email && (
                    <div className="d-flex align-items-center" style={{ marginBottom: "0.8vw" }}>
                      <a
                        href={`mailto:${props.profileCardProps.email}`}
                        target="_blank"
                        className="link-color"
                      >
                        <img
                          src="/static/images/mail.png"
                          alt="mail"
                          className="profileCardImage"
                        />
                        <span className={`ms-3`}>
                          {props.profileCardProps.email}
                        </span>
                      </a>
                    </div>
                  )}
                  {fullAddress && (
                    <div className="d-flex align-items-start">
                      <img
                        src="/static/images/home.png"
                        alt="home"
                        className="profileCardImage"
                      />
                      <span className={`ms-3`}>
                        <div className="mb-2">{fullAddress}</div>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props?.profileData ? "" :
        <div className="col-md-3 pt-1 ps-4 align-self-center candidate-cv-download">
          <div className="shout-icon d-flex align-items-center">
            <div className="d-flex">
              <img
                src="../../static/images/megaphone.svg"
                alt="shout"
                className="img-fluid"
                width={"90vw"}
              />
              <div className="available-text mb-2 fraunces-italic color-dark-pink">
                <div>{t("Available")}</div>
                <div>{t("for work!")}</div>
              </div>
            </div>
          </div>
          {props.profileCardProps?.cvData?.fileId &&
            props.profileCardProps?.cvData?.fileName &&
            props.profileCardProps?.cvData?.filePath && (
              <div className="d-flex justify-content-end">
                <span
                  // onClick={(event) =>
                  // downloadFile(
                  //   props.profileCardProps.cvData.fileId
                  //     ? props.profileCardProps.cvData.fileId
                  //     : null,
                  //   props.profileCardProps.cvData.fileName
                  //     ? props.profileCardProps.cvData.fileName
                  //     : "",
                  //   props.profileCardProps.cvData.filePath
                  //     ? props.profileCardProps.cvData.filePath
                  //     : "",
                  //   "candidate-cv"
                  // )
                  // }
                  className="table-action-icons cursor-pointer matching-btns"
                >
                  <div
                    className="candidate-download-btn px-3"
                    title={t("Download")}
                  >
                    <span className="me-4">{t("Download CV")}</span>
                    <DownloadIcon />
                  </div>
                </span>
              </div>
            )}
          {props.profileCardProps.isScreening &&
            <div className="d-flex justify-content-end mt-2">
              <Link
                className="btn px-3 form-button matching-btns d-flex align-items-center justify-content-between"
                title=""
                to={`/screening/${props.profileCardProps.candidateId}`}
                state={{ origin: props.profileCardProps.source }}
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  {t("Screening")} &nbsp;
                  <span className="me-3">
                    <RightArrow />
                  </span>
                </div>
              </Link>
            </div>
          }
        </div>}
    </div>

    // </AccessControl>
  );
};

export default MatchingProfileCard;
