import SelectWithSearch from "components/atoms/SelectWithSearch";
import React from "react";
import { t } from "../translation/Translation";
import Calender from "components/molecules/Calender";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { FollowupData, TodoData, commonData } from "./Create";
import CheckBoxField from "components/atoms/CheckBoxField";
import LabelField from "components/atoms/LabelField";

interface FollowupProps {
  options: TodoData;
  followupData: FollowupData;
  commonErrors: { [key: string]: string };
  handleSelectChange: (
    selectedOption: any,
    fieldName: string,
    type: string
  ) => void;
  handleDateChange: (date: any, fieldName: string, type: string) => void;
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    type: string
  ) => void;
  type: string;
  isEdit: boolean;
  commonData: commonData;
  handleCheckBoxChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  followupFor: number | null;
}
const Followup: React.FC<FollowupProps> = ({
  options,
  followupData,
  commonErrors,
  handleSelectChange,
  handleDateChange,
  handleChange,
  type,
  commonData,
  handleCheckBoxChange,
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-4">
          <SelectWithSearch
            title={t("To be followedup by")}
            isMandatory={false}
            search={true}
            options={options.assignedTo}
            onChange={(e) => handleSelectChange(e, "followedBy", type)}
            isMulti={false}
            name="followedBy"
            value={followupData.followedBy}
            error={commonErrors.followedBy}
            placeHolder="Select"
          />
        </div>
        <div className="position-relative col-2">
          <Calender
            onChange={(date) => handleDateChange(date, "followupDate", type)}
            label={t("Followup date")}
            isMandatory={true}
            name="followupDate"
            error={commonErrors.followupDate}
            selectedDate={followupData.followupDate}
            isDisabled={false}
            maxDate={
              followupData.followupDate ? new Date(Date.now() - 86400000) : null
            }
          ></Calender>
        </div>
        <div className="col searchBtnWrapper" style={{ paddingTop: "0.5vw" }}>
          <CheckBoxField
            label={t("Completed")}
            name="status"
            onChangeHandler={handleCheckBoxChange}
            isChecked={followupData.status}
            className="document-checkbox"
            id="completeStatus"
            lineHeight="1.5vw"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SelectWithSearch
            title={t("Entity type")}
            isMandatory={false}
            search={true}
            options={options.entityType}
            onChange={(e) => handleSelectChange(e, "entityType", type)}
            isMulti={false}
            name="entityType"
            value={commonData.entity_type}
            error={commonErrors.entityType}
            placeHolder="Select"
            isDisabled={true}
          />
        </div>
        <div className="col">
          <SelectWithSearch
            title={t("Entity name")}
            isMandatory={false}
            search={true}
            options={options.entityRelatedEntityName}
            onChange={(e) => handleSelectChange(e, "entityId", type)}
            isMulti={false}
            name="entityId"
            value={commonData.entity_id}
            error={commonErrors.entityId}
            placeHolder="Select"
            isDisabled={true}
          />
        </div>
        <div className="col">
          <SelectWithSearch
            title={t("Entity related todo type")}
            isMandatory={false}
            search={true}
            options={options.entityRelatedTodoType}
            onChange={(e) => handleSelectChange(e, "todoTypeId", type)}
            isMulti={false}
            name="todoTypeId"
            value={commonData.todo_type_id}
            error={commonErrors.todoTypeId}
            placeHolder="Select"
            isDisabled={true}
          />
        </div>
      </div>
      <div>
        <LabelWithTextAreaField
          label={t("Feedback")}
          name="feedback"
          value={followupData.feedback}
          placeholder={t("Feedback")}
          isDisabled={false}
          isMandatory={false}
          handleChange={(e) => handleChange(e, type)}
        />
      </div>
    </div>
  );
};

export default Followup;
