import React, { ChangeEvent } from "react";
import LabelField from "components/atoms/LabelField";
import InputTextfield from "components/atoms/InputTextField";

interface LabelWithInputFieldProps {
  type?: string;
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  unit?: string;
}

export const LabelWithInputFieldAndUnit: React.FC<LabelWithInputFieldProps> = ({
  type,
  className = "form-control field-shadow",
  containerClassName = "col-6",
  placeholder,
  handleChange,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  min,
  step,
  max,
  readOnly,
  unit,
}) => {
  return (
    <div className={containerClassName}>
      {!!label && (
        <LabelField
          title={label}
          className={`${labelClassName} mb-0`}
          isMandatory={isMandatory}
          htmlfor={id}
        />
      )}
      <div className="input-group">
        <InputTextfield
          id={id}
          type={type}
          className={`form-control field-shadow ${className}`}
          value={value}
          isDisabled={isDisabled}
          placeholder={placeholder}
          customStyle={customStyle}
          handleChange={handleChange}
          name={name}
          min={min}
          step={step}
          max={max}
          readOnly={readOnly}
        />
        {unit && (
          <div className="p-2 position-absolute end-0 units">
            <span>{unit}</span>
          </div>
        )}
      </div>
      <div
        className="height-20"
        style={{ marginTop: "0.25vw", marginBottom: "0.25vw" }}
      >
        {error && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );
};
