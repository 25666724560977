import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PROJECTS_OPTIONS, VACANCY_EDIT } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  COMPETENCES,
  EMPLOYMENT,
  GENERAL,
  JOB_DESCRIPTION,
  OFFER,
  REQUIREMENT,
  SET_OPTIONS,
  UPDATE_FIELDS,
  UPDATE_SINGLE_TAB_DETAILS,
  UPDATE_TAB_DETAILS,
  VACANCY_DROPDOWN_OPTIONS,
} from "../../annotation/VacancyConstants";
import VacancyGeneralDetails from "./VacancyGeneralDetails";
import VacancyEmplomentDetails from "./VacancyEmplomentDetails";
import VacancyCompetences from "./VacancyCompetences";
import VacancyOfferDetails from "./VacancyOfferDetails";
import VacancyRequirementDetails from "./VacancyRequirementDetails";
import VacancyJobDescriptionDetails from "./VacancyJobDescriptionDetails";
import AccessControl from "services/AccessControl";
import TitleAtom from "components/atoms/Title";
import { t } from "pages/microservices/masterData/translation/Translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useFormContext } from "../../context/Context";
import { fetchProjectOptions } from "../../utils/Utils";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";

const VacancyForm = () => {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const historyDispatch = useDispatch();
  const { state, dispatch } = useFormContext();
  const [isSticky, setIsSticky] = useState(false);
  const { id } = useParams<{ id: string }>();
  const authData = useSelector(selectAuth);
  useEffect(() => {
    fetchVacancyDetails();
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchVacancyDetails = async () => {
    if (id) {
      const data = { options: VACANCY_DROPDOWN_OPTIONS, id: id, user: authData?.userId };
      const response = await ApiCall.service(VACANCY_EDIT, "POST", data, true, M_MASTER_DATA);
      if (response.status === 200) {
        let data = response?.data;        
        dispatch({ type: SET_OPTIONS, payload: data?.options });
        dispatch({ type: UPDATE_FIELDS, template: GENERAL, value: data?.general });
        dispatch({ type: UPDATE_FIELDS, template: EMPLOYMENT, value: data?.employment });
        dispatch({ type: UPDATE_FIELDS, template: OFFER, value: data?.offer });
        dispatch({ type: UPDATE_FIELDS, template: REQUIREMENT, value: data?.requirement });
        dispatch({ type: UPDATE_FIELDS, template: JOB_DESCRIPTION, value: data?.jobDescription });
        dispatch({ type: UPDATE_FIELDS, template: COMPETENCES, field: "competence", value: data?.competence });
      }
    } else {
      let optionsRes = await fetchProjectOptions({ options: VACANCY_DROPDOWN_OPTIONS, user: authData?.userId }, PROJECTS_OPTIONS, true);            
      dispatch({ type: SET_OPTIONS, payload: optionsRes });
      dispatch({
        type: UPDATE_FIELDS,
        template: EMPLOYMENT,
        field: "contract",
        value: history?.filters?.contract ?? optionsRes?.ContractType?.filter((item: any) =>
            ["permanent", "freelancer", "temporary"].includes(
              item?.name?.toLowerCase()
            ))?.map((item: any) => item?.value),
      });
      history?.filters?.location && dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: 'location', value: Number(history?.filters?.location ?? null) });
      history?.filters?.contract && dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: 'contract', value: history?.filters?.contract });
      history?.filters?.function && dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: 'function', value: history?.filters?.function });
      history?.filters?.project && dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: 'project', value: Number(history?.filters?.project ?? null) });
      history?.filters?.company && dispatch({ type: UPDATE_FIELDS, template: GENERAL, field: 'company', value: Number(history?.filters?.company ?? null) });        
      dispatch({ type: UPDATE_FIELDS, template: COMPETENCES, field: "competence",
        value: Object?.entries(history?.filters?.competance || {})
          .map(([id, { options }]: any) => optionsRes?.Competence?.find((opt: any) => opt?.id === +id) && ({
            competence_id: +id, ...optionsRes?.Competence?.find((opt: any) => opt?.id === +id), options
          }))?.filter(Boolean)
      });
      historyDispatch(updatePageFilters({filters: {}}));
      if (!authData?.role?.some((item) => ['ADMIN', "SUPER_ADMIN"]?.includes(item))) {
        dispatch({type: UPDATE_FIELDS, template: GENERAL, field: "consultant", value: authData?.userId });
      }
    }
  };

  //For handling the tab navigation
  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "general":
        return <VacancyGeneralDetails />;
      case "employment":
        return <VacancyEmplomentDetails />;
      case "competences":
        return (
          <VacancyCompetences
            competenceOptions={state?.options?.Competence}
            state={state}
            dispatch={dispatch}
          />
        );
      case "offer":
        return <VacancyOfferDetails />;
      case "requirement":
        return <VacancyRequirementDetails />;
      case "jobDescription":
        return <VacancyJobDescriptionDetails />;
      default:
        return <VacancyGeneralDetails />;
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = { permission: "Vacancy" };
  permissionObject[permissionType] = true;
  return (
    <>
      <div className="position-relative">
        <AccessControl
          requiredPermissions={[permissionObject]}
          renderNoAccess={true}
          override={authData?.isSuperAdmin}
        >
          {/* {state?.tabDetails?.[0]?.error === true && (
            <div className="px-4">
              <div className="row">
                <div className="px-4 error-msg-tabs col-12">
                  <div
                    className="alert alert-danger alert-dismissible fade show "
                    role="alert"
                  >
                    <span className="me-2">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    </span>
                    {t("Please fill the mandatory fields to create a vacancy.")}
                    <button
                      type="button"
                      className="btn-close shadow-none"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div
            className="row header-sticky position-sticky"
            style={{ marginBottom: "2.3vw", height: "auto" }}
          >
            <div className="col-12">
              <h1
                className="page-title mb-0 d-flex align-items-center"
                style={{ paddingBottom: "0.7vw" }}
              >
                {t("Vacancy")}
              </h1>
            </div>
          </div>
          <nav
            className={`projectTabs company-step-tabs createFormTabs ${
              isSticky
                ? "createTabsSticky position-sticky"
                : "company-step-tabs-absolute position-absolute w-100"
            }`}
          >
            <div
              className="d-flex border-0 w-100 m-auto gap-3"
              id="nav-tab"
              role="tablist"
            >
              <div
                className={`d-flex border-0 w-75 m-auto gap-3 align-items-center justify-content-center`}
              >
                {state?.tabDetails.map((item: any, index) => (
                  <button
                    key={item?.id}
                    className={`nav-link createStepBtn me-lg-3 text-break ${
                      item?.error ? "error" : ""
                    } ${item?.showStatus ? "active" : "inactive"}`}
                    id={`${item?.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${item?.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${item?.id}`}
                    aria-selected={item.showStatus}
                    onClick={() => handleTabClick(item?.id)}
                  >
                    <div 
                      title={`${item.error && t("Please fill all the mandatory fields")}`}
                      className=" d-flex align-items-center justify-content-center"
                    >
                      {t(`${item?.title}`)}

                      {/* {item.error && (
                        <span
                          className="exclamation-icon ms-2"
                          title={`${t("Please fill all the mandatory fields")}`}
                          data-toggle="tooltip"
                        >
                          <FontAwesomeIcon icon={faTriangleExclamation} />
                        </span>
                      )} */}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </nav>

          <div className="tab-content py-0 p-4" id="nav-tabContent">
            {state?.tabDetails?.map((item: any) => (
              <div
                key={item?.id}
                className={`tab-pane fade ${item?.showStatus ? "show" : ""} ${
                  item?.showStatus ? "active" : ""
                }`}
                id={item?.id}
                role="tabpanel"
                aria-labelledby={`${item?.id}-tab`}
              >
                {ConstructTabs(item?.id, item?.showStatus)}
              </div>
            ))}
          </div>
        </AccessControl>
      </div>
    </>
  );
};

export default VacancyForm;
