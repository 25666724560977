import { useEffect, useState } from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Title from "components/atoms/Title";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { Option, OptionProps, ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateMultiSelectField,
  validateSelectField,
} from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import { t } from "../translation/Translation";
import { Accordion } from "react-bootstrap";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import LoadingIcon from "utils/LoadingIcon";
import { M_MASTER_DATA } from "constants/Constants";
import { mapToSelect } from "utils/MapToSelect";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import BackButton from "components/atoms/BackButton";
import { PATH_MANAGE_APPLICANTS, PATH_MANAGE_SCREENING } from "constants/Paths";

interface PrepareQuestnProps {
  candidates: any;
  pcNumber: Option | null;
  functions: object[];
  questions: any;
}

interface FeedbackProps {
  feedback: Option | null;
  feedbackInfo: string;
}

const CandidateScreening: React.FC = () => {
  const location = useLocation();
  const [formData, setFormData] = useState<PrepareQuestnProps>({
    functions: [],
    candidates: null,
    pcNumber: null,
    questions: [],
  });
  const [candidateName, setCandidateName] = useState("");
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const initialFeedbackErrors: { [key: string]: string } = {};
  const [feedbackErrors, setFeedbackErrors] = useState<{
    [key: string]: string;
  }>(initialFeedbackErrors);

  const [dropDownData, setDropDownData] = useState({
    candidates: [] as OptionProps[],
    pcNumber: [] as OptionProps[],
    functions: [] as OptionProps[],
  });
  const [feedBackData, setFeedBackData] = useState<FeedbackProps>({
    feedback: null,
    feedbackInfo: "",
  });

  const navigate = useNavigate();
  const id = useParams();
  const user = useSelector(selectAuth);
  const userRole: string = user.role.toString();
  const userId = user.userId;
  const [loading, setLoading] = useState(false);
  const fetchCandidatesAndFunctions = async (pcId = null) => {
    // const url =
    //   pcId == null
    //     ? `${ENDPOINTS.GET_SCREENING_DETAILS}/${id.id}`
    //     : `${ENDPOINTS.GET_SCREENING_DETAILS}/${id.id}/${pcId}`;
    let postId: any = id.id;
    if (userRole.toLowerCase() === "candidate") {
      postId = userId;
    }
    const postData = {
      id: postId,
      pcId: pcId,
    };
    // const response = await ApiCall.getService(url, "GET", M_MASTER_DATA, true);

    const response = await ApiCall.service(
      ENDPOINTS.GET_SCREENING_DETAILS,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    );
    if (response.status === 200) {
      // const selectedFunctions = response.data["selectedFunctions"] || [];
      const candidates = mapToSelect(response.data["candidates"], "user_name");
      const pcSections = mapToSelect(response.data["pc"], "pc_number");
      const functions =
        response.data["functions"] && response.data["functions"]["functions"] &&
          response.data["functions"]["functions"].length > 0
          ? mapToSelect(response.data["functions"]["functions"], "name")
          : [];

      setCandidateName(candidateName);
      setFormData((prevData) => ({
        ...prevData,
        candidates: candidates[0],
        functions: [],
      }));

      setDropDownData((prevData) => ({
        ...prevData,
        candidates: candidates,
        pcNumber: pcSections,
        functions: functions,
      }));
    }
  };
  useEffect(() => {
    fetchCandidatesAndFunctions();
  }, []);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null | Option,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      candidates: [validateSelectField],
      pcNumber: [validateSelectField],
      functions: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const validateFeedback = (
    name: string,
    value: string | boolean | Date | object[] | null | Option,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      feedback: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...feedBackData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setFeedbackErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setFeedbackErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupIndex: number,
    questionIndex: number
  ) => {
    const { name, type, value } = event.target;

    setFormData((prevData) => {
      const updatedQuestions = [...prevData.questions];

      if (type === "radio") {
        const answer = parseInt(value);
        updatedQuestions[groupIndex].questions[questionIndex].answers = [
          answer,
        ];
      } else if (type === "checkbox") {
        const answersList =
          updatedQuestions[groupIndex].questions[questionIndex].answers || [];

        if ((event.target as HTMLInputElement).checked) {
          if (!answersList.includes(parseInt(value))) {
            answersList.push(parseInt(value));
          }
        } else {
          // If the checkbox is unchecked, remove the value from the array
          const indexToRemove = answersList.indexOf(parseInt(value));
          if (indexToRemove !== -1) {
            answersList.splice(indexToRemove, 1);
          }
        }

        updatedQuestions[groupIndex].questions[questionIndex].answers = [
          ...answersList,
        ];
      } else if (name === "description") {
        updatedQuestions[groupIndex].questions[questionIndex].answers = [value];
      }

      return {
        ...prevData,
        questions: updatedQuestions,
      };
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "feedback") {
      setFeedBackData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
      validateFeedback(fieldName, selectedOption, true);
    } else if (fieldName === "pcNumber") {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
      if (selectedOption !== null) {
        fetchCandidatesAndFunctions(selectedOption.value);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const handleDisableQuestion = (groupIndex: number, questionIndex: number) => {
    const updatedQuestions = [...formData.questions];
    const currentStatus =
      updatedQuestions[groupIndex].questions[questionIndex].disabled;

    updatedQuestions[groupIndex].questions[questionIndex].answers = [];
    updatedQuestions[groupIndex].questions[questionIndex].disabled =
      !currentStatus;

    setFormData({
      ...formData,
      questions: updatedQuestions,
    });
  };

  const getQuestions = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (
      validation("pcNumber", formData.pcNumber) &&
      validation("functions", formData.functions)
    ) {
      const fetchQuestionsByFunction = async () => {
        const response = await ApiCall.service(
          `${ENDPOINTS.GET_QUESTIONS_BY_FUNCTIONS}`,
          "POST",
          formData,
          false,
          M_MASTER_DATA
        );
        if (response.status === 200) {
          setFormData((prevData) => ({
            ...prevData,
            questions: response.data,
          }));
        } else if (response.status === 400) {
          setErrors((prevData) => ({
            ...prevData,
            functions: response.data,
          }));
          CustomNotify({
            type: "error",
            message: response.data,
          });
          if (formData.questions.length > 0) {
            setFormData((prevData) => ({
              ...prevData,
              questions: [],
            }));
          }
        }
      };
      fetchQuestionsByFunction();
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;

    // if (validation(name, value) && validateFeedback(name, value)) {
    if (validation(name, value)) {
      const data = {
        userId: userId,
        // feedBackData: feedBackData,
        // pcId: pcId,//candidateId
        candidate: formData.candidates,
        pcId: formData.pcNumber,
        functionQuestions: formData.questions.map((group: any) => ({
          function: group.function,
          questions: group.questions
            .filter((question: any) => !question.disabled) // Include only questions with disabled: false
            .map((question: any) => ({
              questionId: question.questionId,
              questionType: question.questionType,
              answers: question.answers,
            })),
        })),
      };
      // Check if all question arrays are empty
      const allArraysEmpty = data.functionQuestions.every(
        (group: any) => group.questions.length === 0
      );

      // If all arrays are empty, set an error message and return
      if (allArraysEmpty) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          functions: t("No questions added for the selected functions"),
        }));
        CustomNotify({
          type: "error",
          message: t("No questions added for the selected functions"),
        });
        setLoading(false);
        return;
      }

      const response = await ApiCall.service(
        `${ENDPOINTS.STORE_SCREENING_INFO}`,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response.status === 200) {
        navigate(getBackNavigationPath(location.state?.origin));

        // if (location.state?.origin === "todos") {
        //   navigate("/todos/overview");
        // } else
        // if (location.state?.origin === "dashboard") {
        //   navigate("/dashboard");
        // }
        //  else {
        //   navigate("/manage-candidates");
        // }
        CustomNotify({ type: "success", message: response.msg });
        //Api call for updating todos details after screening
        // const todoData = {
        //   pcId: pcId,
        //   stage: 3,
        //   submitType: "accept",
        // };
        // await ApiCall
        //   .service
        //   "updateTodo",
        //   "POST",
        //   todoData,
        //   false,
        //   M_MASTER_DATA
        // ();
      }
    }
    setLoading(false);
  };

  const getBackNavigationPath = (state: string) => {
    if (userRole.toLowerCase() === "candidate") {
      state = "dashboard";
    }
    let link = "/manage-screening";
    switch (state) {
      case "dashboard":
        link = `/dashboard`;
        break;
      case "manageScreening":
        link = `/manage-screening/${id.id}`;
        break;
      default:
        link = `${PATH_MANAGE_APPLICANTS}`;
    }

    return link;
  };

  // const handleFeedBackFieldChange = (
  //   e: React.ChangeEvent<
  //     HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  //   >
  // ) => {
  //   const { name, value, type } = e.target;
  //   setFeedBackData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const feedbackOptions = [
  //   { value: 1, label: "Excellent" },
  //   { value: 2, label: "Very good" },
  //   { value: 3, label: "Good" },
  //   { value: 4, label: "Average" },
  //   { value: 5, label: "Poor" },
  // ];

  return (
    <>
      {/* <Title title={`Screening for ${candidateName}`} className="px-4" /> */}
      <Title title={t("Screening")} />

      <form onSubmit={handleSubmit} className="px-4">
        <div className="row">
          <div className="col-md-4">
            <SelectWithSearch
              title={t("Candidate")}
              isMandatory={false}
              search={false}
              options={dropDownData.candidates}
              placeHolder={t("Select candidate")}
              onChange={(e) => handleSelectChange(e, "candidates")}
              isMulti={false}
              className="select-field"
              name="candidates"
              value={id.id}
              // error={errors.candidates}
              isDisabled={true}
            />
          </div>
          <div className="col-md-4">
            <SelectWithSearch
              title={t("Paritair comitee")}
              isMandatory={true}
              search={true}
              options={dropDownData.pcNumber}
              placeHolder={t("Select pc")}
              onChange={(e) => handleSelectChange(e, "pcNumber")}
              isMulti={false}
              className="select-field"
              name="pcNumber"
              value={formData.pcNumber}
              error={errors.pcNumber}
              isDisabled={false}
            />
          </div>
          <div className="col-md-4">
            <SelectWithSearch
              title={t("Function")}
              isMandatory={true}
              search={true}
              options={dropDownData.functions}
              placeHolder={t("Select function")}
              onChange={(e) => handleSelectChange(e, "functions")}
              isMulti={true}
              className="select-field"
              name="functions"
              value={formData.functions}
              error={errors.functions}
              isDisabled={false}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <Button
              title={t("Start screening")}
              handleClick={getQuestions}
              className="btn form-button float-end  rounded-3 shadow-none"
            />
          </div>
        </div>
        {formData.questions.length > 0 && (
          <div className="screening-height">
            <div className="position-relative mb-5">
              <div className="prepare-questions-height">
                <div className="position-relative mb-5">
                  <div className="form-border p-5">
                    <div className="pb-4">
                      {formData.questions.map(
                        (questionGroup: any, groupIndex: number) => (
                          <div key={groupIndex}>
                            <div className="mb-3">
                              <Accordion>
                                <Accordion.Item eventKey={`${groupIndex}`}>
                                  <Accordion.Header className="fw-bold">
                                    {questionGroup.function.label}
                                  </Accordion.Header>
                                  <Accordion.Body className="bg-white">
                                    {questionGroup.questions.length &&
                                      questionGroup.questions.length > 0 ? (
                                      <>
                                        {questionGroup.questions.map(
                                          (question: any, index: number) => (
                                            <div
                                              key={index}
                                              className={` p-3 rounded-3 mb-3 border ${question.disabled
                                                ? " border question-not-applicable-section opacity-50 p-3 rounded-3 mb-3"
                                                : ""
                                                }`}
                                            >
                                              <div className="d-flex justify-content-between align-items-center">
                                                <h5
                                                  className="questionName"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      index +
                                                      1 +
                                                      ") " +
                                                      question.question,
                                                  }}
                                                />
                                                <Button
                                                  title=""
                                                  className="btn py-1 px-3 border-0 rounded-2 text-end not-applicable-action"
                                                  handleClick={() =>
                                                    handleDisableQuestion(
                                                      groupIndex,
                                                      index
                                                    )
                                                  }
                                                >
                                                  N/A
                                                </Button>
                                              </div>
                                              {question.questionType === 1 &&
                                                question.options.length > 0 && (
                                                  <div
                                                    className="my-1"
                                                    key={index}
                                                  >
                                                    {question.options.map(
                                                      (
                                                        option: any,
                                                        optionIndex: number
                                                      ) => (
                                                        <div
                                                          key={optionIndex}
                                                          className="screening-checkbox d-flex align-items-center mb-2"
                                                        >
                                                          <CheckBoxField
                                                            name={`answers-${index}-${optionIndex}`}
                                                            id={`answers-${index}-${optionIndex}`}
                                                            onChangeHandler={(
                                                              event
                                                            ) =>
                                                              handleFieldChange(
                                                                event,
                                                                groupIndex,
                                                                index
                                                              )
                                                            }
                                                            value={optionIndex}
                                                            disable={
                                                              question.disabled
                                                            }
                                                            isChecked={question.answers.includes(
                                                              optionIndex
                                                            )}
                                                          />

                                                          <span
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                option.replace(
                                                                  /<a/g,
                                                                  '<a target="_blank"'
                                                                ),
                                                            }}
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )}

                                              {question.questionType === 3 &&
                                                question.options.length > 0 && (
                                                  <div
                                                    className="my-1"
                                                    key={index}
                                                  >
                                                    {question.options.map(
                                                      (
                                                        option: any,
                                                        optionIndex: number
                                                      ) => (
                                                        <div
                                                          key={optionIndex}
                                                          className="d-flex screening-checkbox align-items-center mb-2"
                                                        >
                                                          <RadioField
                                                            label=""
                                                            className="ps-1"
                                                            id={`answers-${index}-${optionIndex}`}
                                                            value={optionIndex}
                                                            name={`answers-${groupIndex}-${index}`}
                                                            handleChange={(
                                                              event
                                                            ) => {
                                                              handleFieldChange(
                                                                event,
                                                                groupIndex,
                                                                index
                                                              );
                                                            }}
                                                            disable={
                                                              question.disabled
                                                            }
                                                            ischecked={question.answers.includes(
                                                              optionIndex
                                                            )}
                                                          />
                                                          <span
                                                            dangerouslySetInnerHTML={{
                                                              __html: option,
                                                            }}
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              {question.questionType === 2 && (
                                                <LabelWithTextAreaField
                                                  name="description"
                                                  handleChange={(event) =>
                                                    handleFieldChange(
                                                      event,
                                                      groupIndex,
                                                      index
                                                    )
                                                  }
                                                  label=""
                                                  value={
                                                    formData.questions[
                                                      groupIndex
                                                    ].questions[index]
                                                      .answers[0]
                                                  }
                                                  isDisabled={question.disabled}
                                                />
                                              )}
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <div className="text-danger p-2 text-center">
                                        {t(
                                          "No general and function related questions added"
                                        )}
                                      </div>
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Feedback */}
        {/* <div className="row">
          <div className="col-md-6">
            <SelectWithSearch
              title={t("Screening feedback")}
              isMandatory={true}
              search={true}
              options={feedbackOptions}
              placeHolder={t("Select")}
              onChange={(e) => handleSelectChange(e, "feedback")}
              isMulti={false}
              className="select-field"
              name="feedback"
              value={feedBackData.feedback}
              error={feedbackErrors.feedback}
              isDisabled={false}
            />
          </div>
          <div className="col-md-12">
            <LabelWithTextAreaField
              name="feedbackInfo"
              handleChange={handleFeedBackFieldChange}
              label={t("Feedback info")}
              value={feedBackData.feedbackInfo}
            />
          </div>
        </div> */}
        <div className="row py-4">
          <div className="col-6 align-self-center">
            <BackButton />
          </div>
          <div className="col-md-6 text">
            {!loading ? (
              <Button
                title={t("SAVE")}
                type="submit"
                className="btn float-end form-button rounded-3 shadow-none"
              />
            ) : (
              <LoadingIcon iconType="bars" className="ms-auto" />
            )}
          </div>
        </div>
      </form>
    </>
  );
};
export default CandidateScreening;
