import { ReactNode, createContext, useContext, useReducer } from "react";
import { Dispatch } from "./Actions";
import { candidateFormData } from "../annotations/CandidateInterface";
import { CandidateReducer } from "./CandidateReducer";
import { CandidateIntialState } from "../annotations/CandidateIntialState";

interface FormProviderProps {
    children: ReactNode;
}

const FormContext = createContext<
    | {
          state: candidateFormData;
          dispatch: Dispatch;
      }
    | undefined
>(undefined);

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(CandidateReducer, CandidateIntialState);
    
    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    );
};