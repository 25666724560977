import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { useNavigate } from "react-router-dom";
import AccomplishmentData from "./AccomplishmentData";
import { Modal } from "react-bootstrap";
import Button from "components/atoms/Button";
import { Option } from "utils/TypeAnnotations";
import ModalPopup from "components/atoms/ModalPopup";
import { AccomplishData, CalculationSearchProps, EmployeeDataProps, initialAccomplishSearchData, initialAccomplishmentData, initialCalculationSearch, initialEmployeeData } from "./PartenaInterface";
import './css/Partena.css';

const PartenaCalculation = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showPerformanceModal, setShowPerformanceModal] = useState<boolean>(false);
    const [search, setSearch] = useState<CalculationSearchProps>(initialCalculationSearch);
    const [employeeSearch, setEmployeeSearch] = useState<{ [key: string]: string }>({
        name: "",
        userSearch: "",
    })
    const [accomplishSearch, setAccomplishSearch] = useState<{ [key: string]: string | undefined }>(initialAccomplishSearchData)
    const [status, setStatus] = useState({
        employee: true,
        accomplishment: false
    })
    const [employeeData, setEmployeeData] = useState<EmployeeDataProps[]>([initialEmployeeData]);
    const [accomplishData, setAccomplishData] = useState<AccomplishData[]>([initialAccomplishmentData]);
    const [dropdownData, setDropdownData] = useState<Option[]>([]);
    useEffect(() => {
        fetchManageData();
    }, []);
    const fetchManageData = () => {
        const postData: any = {
            search: {
                month: "January",
                businessUnit: 201,
                createdBy: "John",
                creationTime: "2022-01-01"
            },
            accomplishmentSearch: {
                employeeName: "John",
                date: "2022-01-11",
                department: 1,
                all: { value: 1, label: "one" },
                wageCode: 20
            },
            employeeSearch: {
                name: "John",
                userSearch: "priyanka"
            }
        }
        // const response = ApiCall.service("getPartenaCalculation", postData, "POST", TRUE, M_MASTER_DATA);
        const response = {
            status: 200,
            employeeData: [
                {
                    id: 1,
                    name: "Priyanka",
                    exportedBy: "Sophie",
                    exportedTime: "2022-01-01",
                    exportedFile: "pathOfFile"
                },
                {
                    id: 2,
                    name: "Nagavarun",
                    exportedBy: "Sophie",
                    exportedTime: "2022-01-04",
                    exportedFile: "pathOfFile"
                },
                {
                    id: 3,
                    name: "Aman",
                    exportedBy: "Sophie",
                    exportedTime: "2022-01-03",
                    exportedFile: "pathOfFile"
                },
                {
                    id: 4,
                    name: "Fayeed",
                    exportedBy: "Sophie",
                    exportedTime: "2022-01-02",
                    exportedFile: "pathOfFile"
                }
            ],
            accomplishmentData: [
                {
                    id: 1,
                    employeeName: "Priyanka",
                    date: "2022-02-20",
                    department: 101,
                    type: "worked",
                    wageCode: 201,
                    shiftCode: 301,
                    hours: 8
                },
                {
                    id: 2,
                    employeeName: "Fayeed",
                    date: "2022-02-21",
                    department: 101,
                    type: "out performance",
                    wageCode: 201,
                    shiftCode: 301,
                    hours: 10
                },
                {
                    id: 3,
                    employeeName: "Aman",
                    date: "2022-02-20",
                    department: 101,
                    type: "worked",
                    wageCode: 201,
                    shiftCode: 301,
                    hours: 5
                }
            ],
            dropdownData: [
                { value: 1, label: "one" },
                { value: 2, label: "two" },
                { value: 3, label: "three" }
            ]
        }
        if (response.status === 200) {
            setEmployeeData(response.employeeData);
            setAccomplishData(response.accomplishmentData);
            setDropdownData(response.dropdownData);
        }
    }

    const handleSearchChange = (e: any, field: string = '') => {
        if (field === "all") {
            setAccomplishSearch(prev => ({ ...prev, [field]: e }));
            return;
        }
        const { name, value } = e.target;
        if (name in search) {
            setSearch(prev => ({ ...prev, [name]: value }));
        } else if (name in employeeSearch) {
            setEmployeeSearch(prev => ({ ...prev, [name]: value }));
        } else if (name in accomplishSearch) {
            setAccomplishSearch(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleClick = (value: string | null | EmployeeDataProps | boolean) => {
        if (typeof value === "string") {
            const employeeStatus: boolean = value.includes('Employees');
            const accomplishmentStatus: boolean = value.includes('Accomplishments');
            setStatus((prevData: any) => ({ ...prevData, employee: employeeStatus }));
            setStatus((prevData: any) => ({ ...prevData, accomplishment: accomplishmentStatus }));
        } else if (typeof value === "object") {
            setAccomplishSearch(initialAccomplishSearchData);
            setShowModal(true);
        } else if (typeof value === "boolean") {
            setAccomplishSearch(initialAccomplishSearchData);
            setShowModal(value)
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
        setAccomplishSearch(initialAccomplishSearchData);
    }

    const hadlePopupModal = () => {
        setShowPerformanceModal(true);
        setAccomplishSearch(initialAccomplishSearchData);
    }
    function handlePerformanceModalClose(): void {
        setShowPerformanceModal(false);
    }

    return (
        <>
            <div className="row d-flex flex-row">
                <div className="col">
                    <SearchWithLabel
                        label={t("Month")}
                        isMandatory={false}
                        showLabel={true}
                        name={"month"}
                        className="me-3"
                        type={"text"}
                        value={search.month}
                        placeholder={t("Search by name...")}
                        isDisabled={true}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Business unit")}
                        isMandatory={false}
                        showLabel={true}
                        name={"businessUnit"}
                        className="me-3"
                        type={"number"}
                        value={search.businessUnit}
                        placeholder={t("Search by name...")}
                        isDisabled={true}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Created by")}
                        isMandatory={false}
                        showLabel={true}
                        name={"createdBy"}
                        className="me-3"
                        type={"text"}
                        value={search.createdBy}
                        placeholder={t("Search by name...")}
                        isDisabled={true}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col">
                    <SearchWithLabel
                        label={t("Time of creation")}
                        isMandatory={false}
                        showLabel={true}
                        name={"creationTime"}
                        className="me-3"
                        type={"text"}
                        value={search.creationTime}
                        placeholder={t("Search by name...")}
                        isDisabled={true}
                        handleChange={(e) => handleSearchChange(e)}
                    />
                </div>
                <div className="col mt-4">
                    <Button
                        title={t("Calculate performance")}
                        handleClick={(e) => hadlePopupModal()}
                        className={"btn form-button me-3  rounded shadow-none"}
                    />
                </div>

            </div>
            <div className="row mt-5 text-center cursor-pointer modal-title ">
                <div className={`col p-2 ${status.employee ? "partenaTabsActive" : "partenaTabs"}`} onClick={(e) => handleClick(e.currentTarget.textContent)}>Employees</div>
                <div className={`col p-2  ${status.accomplishment ? "partenaTabsActive" : "partenaTabs"}`} onClick={(e) => handleClick(e.currentTarget.textContent)} >Accomplishments</div>
            </div>
            {status.employee && employeeData && (<>
                <div className="row mt-5">
                    <div className="col-3">
                        <SearchWithLabel
                            isMandatory={false}
                            showLabel={false}
                            name={"name"}
                            className="me-3"
                            type={"text"}
                            value={employeeSearch.name}
                            placeholder={t("Find an employee")}
                            isDisabled={true}
                            handleChange={(e) => handleSearchChange(e)}
                        />
                    </div>
                    <div className="col-3">
                        <SearchWithLabel
                            isMandatory={false}
                            showLabel={false}
                            name={"userSearch"}
                            className=" ms-0 me-3"
                            type={"text"}
                            value={employeeSearch.userSearch}
                            placeholder={t("User search")}
                            isDisabled={true}
                            handleChange={(e) => handleSearchChange(e)}
                        />
                    </div>
                </div>
                <div className="mt-4 table table-responsive">
                    <table className="table table-borderless">
                        {/* {setAccomplishments(!accomplishments)} */}
                        <thead className="TableHeader">
                            <th>Employee</th>
                            <th>Exported by</th>
                            <th>Time of export</th>
                            <th>Export file</th>
                        </thead>
                        <tbody>
                            {employeeData.length > 0 && employeeData.map((employee: EmployeeDataProps) => (
                                <tr className="cursor-pointer" onClick={() => handleClick(employee)}>
                                    <td>{employee.name}</td>
                                    <td>{employee.exportedBy}</td>
                                    <td>{employee.exportedTime}</td>
                                    <td>{employee.exportedFile}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>)}
            {showModal && accomplishData &&
                <div className="w-75">
                    <ModalPopup
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        title={t("Partena Details")}
                        body={
                            <AccomplishmentData
                                handleChange={handleSearchChange}
                                accomplishData={accomplishData}
                                accomplishSearchData={accomplishSearch}
                                dropdownData={dropdownData}
                            />
                        }
                        onCloseButtonClick={handleModalClose}
                        onConfirmButtonClick={handleModalClose}
                        closeTitle="No"
                        confirmTitle="Yes"
                        modalClassName="partena-modal-width"
                    />
                    {/* <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="partena-modal-width">
                        <div className="partena-modal-width">
                            <Modal.Header closeButton >
                                <Modal.Title>Partena Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AccomplishmentData
                                    handleChange={handleSearchChange}
                                    accomplishData={accomplishData}
                                    accomplishSearchData={accomplishSearch}
                                    dropdownData={dropdownData}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    title={t("Ok")}
                                    handleClick={() => handleClick(false)}
                                    className={"btn form-button me-3  rounded shadow-none px-5"}
                                />
                            </Modal.Footer>
                        </div>

                    </Modal> */}
                </div>

            }
            {status.accomplishment && accomplishData && accomplishData.length > 0 &&
                (
                    <AccomplishmentData
                        handleChange={handleSearchChange}
                        accomplishData={accomplishData}
                        accomplishSearchData={accomplishSearch}
                        dropdownData={dropdownData}
                    />
                )}
            {showPerformanceModal &&
                <ModalPopup
                    show={showPerformanceModal}
                    onHide={handlePerformanceModalClose}
                    title="Recalculate?"
                    body="Do you want to recalculate achievements not yet exported?"
                    onCloseButtonClick={handlePerformanceModalClose}
                    onConfirmButtonClick={handlePerformanceModalClose}
                    closeTitle="No"
                    confirmTitle="Yes"
                />
            }
        </>
    );
}

export default PartenaCalculation;