import React from "react";
import { Routes } from "./routes/Routes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store"; // Import your Redux store and persistor
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "utils/LoadingOverlay";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <LoadingOverlay display="none" /> */}
          <RouterProvider router={Routes} />
          <ToastContainer limit={1} />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
