import React, { useEffect, useState } from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import {  OptionProps } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import Pagination from "components/atoms/Pagination";
import CustomNotify from "components/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from '../../../../../constants/Constants';
import BackButton from "components/atoms/BackButton";

interface manageData {
  regionName: string;
  countryName: string;
  id: number;
}

interface dropDownsProps {
  region: OptionProps[];
  country: OptionProps[];
}

const ManageCityProfile: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    region: [],
    country: [],
  });

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    region: [] as OptionProps[],
    country: [] as OptionProps[],
  });

  //state for to store the functions linked countries
  const [countries, setCountries] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCountryBySearch();
  }, []);

  //page index based on page number
  let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch countries
  const fetchCountryBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_CITIES_DETAILS,
      "POST",
      requestData,
      false,
      M_COMPANY_CREATION
    );

    if (response.status === 200) {
      const regions = mapToSelect(response.regions);
      const countries = mapToSelect(response.countries);
      setDropDowns({
        region: regions,
        country: countries,
      });
      setCountries(response.data);
      setTotalPages(response.totalPages);
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const fetchRegionForCountryDetails = async () => {
      const response = await ApiCall.service(`${ENDPOINTS.GET_REGION_FOR_COUNTRIES}`, "POST", selectedCountryIds, false, M_COMPANY_CREATION);
      if (response.status === 200) {
        setFormData((prevData) => ({
          ...prevData,
          region:response.data
        }));
      }

    }
    fetchRegionForCountryDetails();  
  };

  const handleRegionChange = (selectedRegionIds: any) => {
    const fetchCountryDetailsForRegion = async () => {
      const response = await ApiCall.service(`${ENDPOINTS.GET_COUNTRIES_FOR_REGION}`, "POST", selectedRegionIds, false, M_COMPANY_CREATION);
      if (response.status === 200) {
        const countries = mapToSelect(response.data);
        setDropDowns((prevData) => ({
          ...prevData,
          country:countries
        }));
      }

    }
    fetchCountryDetailsForRegion();
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === 'country') {
      const selectedCountryIds = selectedOption.map((option: OptionProps) => option.value);
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === 'region') {
      const selectedRegionIds = selectedOption.map((option: OptionProps) => option.value);
      handleRegionChange(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.region.length === 0 && formData.country.length === 0) {
      CustomNotify({ type: "warning", message: "Search input not provided." });
      fetchCountryBySearch();
    } else {
      fetchCountryBySearch(formData, currentPage);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      region: [],
      country: [],
    });
    setCurrentPage(1);
    fetchCountryBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchCountryBySearch(formData, newPage);
  };

  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <div className="col-md-12">
            <h1 className="py-4 page-title mb-0">Manage city profile</h1>
          </div>
        </div>
        <div className="row search-bar">
          <div className="col-md-12 mb-3">
            <Link
              to="/add/city-profile"
              className="btn form-button float-end rounded-0  shadow-none text-decoration-none text-uppercase"
            >
              + Add city
            </Link>
          </div>
          <div className="col-md-12 manage-function-search">
            <div className="row">
              <div className="col-xxl-9 col-lg-8">
                <div className="row">
                  <div className="col-md-6">
                    <SelectWithSearch
                      search={true}
                      options={dropDowns.region}
                      placeHolder="Select region"
                      onChange={(e) => handleSelectChange(e, "region")}
                      isMulti={true}
                      className="select-field"
                      name="region"
                      value={formData.region}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectWithSearch
                      search={true}
                      options={dropDowns.country}
                      placeHolder="Select country"
                      onChange={(e) => handleSelectChange(e, "country")}
                      isMulti={true}
                      className="select-field"
                      name="country"
                      value={formData.country}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xxl-3">
                <div className="row justify-content-end">
                  <div className="col-md-6 col-lg-12 d-flex justify-content-between">
                    <Button
                      title="Reset"
                      type="reset"
                      handleClick={handleReset}
                      className="btn delete-btn rounded-0 shadow-none text-uppercase me-3 px-4 search-btns w-50"
                    />
                    <Button
                      title="Search"
                      type="submit"
                      handleClick={handleSubmit}
                      className="btn form-button rounded-0 shadow-none text-uppercase search-btns w-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display the table of city profiles */}
        <div className="manage-function-profile-table">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-lg-4">#</th>
                  <th>Region</th>
                  <th>Country</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {countries && countries.length > 0 ? (
                  countries.map((country) => (
                    <tr
                      key={country.id}
                      className="border mb-3 box-shadow align-middle"
                    >
                      <td className="ps-lg-4">{pageIndex++}</td>
                      <td>{country.regionName}</td>
                      <td>{country.countryName}</td>
                      <td className="table-action-icons px-2">
                        <div className="d-none d-md-none d-lg-block">
                          <Link
                            to={`/edit/city-profile/${country.id}`}
                            className="btn p-0 border-0 me-2"
                            title="Edit"
                          >
                            <EditIcon />
                          </Link>
                          <Link
                            to={`/view/city-profile/${country.id}`}
                            className="btn p-0 border-0 me-2"
                            title="View"
                          >
                            <ViewIcon />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td colSpan={7} className="border-0 text-center py-3">
                      <span className="text-danger fw-bold">No records</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center mb-3">
        <BackButton />
      </div>
      <div className="pagination justify-content-center align-items-center my-3 mb-4">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(newPage) => handlePageChange(newPage)}
        />
      </div>
    </>
  );
};

export default ManageCityProfile;