export const salaryBenefitTypes = [
  { name: "premium", label: "Premium", value: 1 },
  { name: "wagecode", label: "Wage code", value: 2 },
];

export const occurenceFieldOptions = [
    { label: "Hour", value: 1 },
    { label: "Day", value: 2 },
    { label: "Month", value: 3 },
    { label: "Year", value: 4 },
    { label: "Manual benefit type", value: 5 },
  ];
  
// Manage salary benefits
export interface ManageSalaryBenefitProps {
  id?: number | null;
  name: string;
  wageCode?: string;
  salaryBenefitType?: string;
  occurence?: string;
  benefitValue?: string;
}

export const initialManageSalaryBenefitData: ManageSalaryBenefitProps = {
  id: null,
  name: "",
  salaryBenefitType: "",
  wageCode: "",
  benefitValue: "",
  occurence: "",
};
export const statusOptions = [
  { value: 1, label: "Active" },
  { value: 2, label: "Archived" },
];

export const initialPageDetails = {
  totalPages: 0,
  totalRecords: 0,
  refresh: true,
  initialCall: true,
};
