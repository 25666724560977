import React from "react";

const ProjectLinkedIcon = () => {
  return (
    <>
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m16 4 3 3H5v3m3 10-3-3h14v-3m-9-2.5 2-1.5v4"
        />
      </svg>
    </>
  );
};

export default ProjectLinkedIcon;
