import MatchingProfileCard, { MatchingCandidateProfileCardType } from 'components/Matching/MatchingCandidateProfileCardType';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useEffect, useState } from 'react';
import { GET_EMPLOYEE_DETAILS_BY_ID } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';

interface ProfileCardProps {
    id: number | string | undefined;
}
const ProfileCard: React.FC<ProfileCardProps> = ({ id }) => {

    const [profileCardData, setProfileCardData] =
        useState<MatchingCandidateProfileCardType>({
            candidateId: null,
            firstName: "",
            lastName: "",
            functions: "",
            mobNumber: "",
            email: "",
            street: "",
            number: "",
            box: "",
            zipCode: "",
            city: "",
            picFileName: "",
            picFilePath: "",
            country: "",
            cvData: "",
            profilePicData: "",
        });

    useEffect(() => {
        if (id) {
            fetchEmployeeDetails(id);
        }
    }, []);
    const fetchEmployeeDetails = async (id: any) => {
        try {
            const response = await ApiCall.service(
                GET_EMPLOYEE_DETAILS_BY_ID,
                "POST",
                { employeeId: id },
                false,
                M_MASTER_DATA
            );
            if (response?.status === 200) {
            const data = response?.data;
            // Update the state with the fetched data
            setProfileCardData({
                candidateId: id,
                firstName: data?.firstName || "",
                lastName: data?.lastName || "",
                functions: "",
                mobNumber: data?.mobNumber || "",
                email: data?.email || "",
                street: data?.street || "",
                number: data?.number || "",
                box: data?.box || "",
                zipCode: data?.zipCode || "",
                city: data?.city || "",
                picFileName: data?.image_url?.fileName || "",
                picFilePath: data?.image_url?.accessUrl || "",
                country: data?.country || "",
                cvData: data?.cv || "",
                profilePicData: data?.image_url || "",
            });
            }
        } catch (error) {
            //  console.error("Error fetching data:", error);
        }
    };

    return (
        <MatchingProfileCard profileCardProps={profileCardData} profileData={true} />

    );
}

export default ProfileCard;