import { TIMETABLE_WEEK_DAYS } from "constants/Constants";
import { isValidTime, validateAmount, validateMaxTimeFormat, validateNumber, validatePercentage, validateRequired, validateTimeFormat, validateTimeRange } from "services/validation/ValidationService";

export const validate = (state: any, dispatch: any, day: any, value: any, id?: any) => {
    let error: any = "";
    let shiftdata = state?.shifts?.data?.shiftTable?.[id]?.timeTable?.[day];
    
    if (value?.field === "pause" && isValidTime(shiftdata?.from) && isValidTime(shiftdata?.till)) {
        error = validateTimeRange(shiftdata?.from, shiftdata?.till, value?.value);
    } else if (value?.field === "till" && (isValidTime(shiftdata?.from) || !isValidTime(value?.value))) {
        error = validateTimeRange(value?.value, shiftdata?.till);
    } else if (value?.field === "from" && (isValidTime(shiftdata?.till) || !isValidTime(value?.value))) {
        error = validateTimeRange(shiftdata?.from, value?.value);
    }
    dispatch({
        type: "SHIFT_UPDATE_FIELD",
        field: "shifts",
        value: {
            ...state?.shifts,
            errors: {
                ...state.shifts.errors,
                shifts: {
                    ...state.shifts.errors?.shifts,
                    [id]: {
                        ...state.shifts.errors?.shifts[id],
                        [day]: {
                            ...state.shifts.errors?.shifts[id][day],
                            [value?.field]: error ?? "",
                        }
                    }
                }
            }
        }
    });
    return error;
}

export const calculateWeeklyTotal = (shift: any) => {
    const totalMinutes = TIMETABLE_WEEK_DAYS
        .reduce((sum, day) => {
            const dayShift = shift?.['timeTable']?.[day];
            if (dayShift) {
                const [hours, minutes] = (dayShift.total || "00:00").split(":").map(Number);
                return sum + hours * 60 + minutes;
            }
            return sum;
        }, 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

export const projectParametersValidations = (name: number) => {
    let validationRules: Record<string, Array<Function>> = {};
    switch (name) {
        case 9:
        case 10:
            validationRules = {
                [name]: [validateNumber],
            };
            break;

        case 11:
        case 36:
        case 37:
        case 39:
        case 40:
            validationRules = {
                [name]: [validateRequired, validateNumber],
            };
            break;

        case 20:
            validationRules = {
                [name]: [validateRequired, validateMaxTimeFormat],
            };
            break;
        case 21:
        case 22:
        case 26:
        case 27:
        case 28:
        case 32:
        case 33:
        case 34:
            validationRules = {
                [name]: [validateRequired, validateTimeFormat],
            };
            break;

        case 13:
        case 12:
        case 14:
        case 15:
        case 16:
        case 35:
        case 38:
            validationRules = {
                [name]: [validateRequired, validatePercentage],
            };
            break;

        case 41:
        case 8:
            validationRules = {
                [name]: [validateRequired, validateAmount],
            };
            break;

        default:
            validationRules = {
                [name]: [validateRequired],
            };
            break;
    }
    return validationRules;
}