import React, { useEffect, useState } from "react";
import SearchModel from "components/atoms/SearchModel";
import Pagination from "components/atoms/Pagination";
import { ApiCall } from "services/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import "static/css/permission.css";
import Button from "components/atoms/Button";
import { GET_PERMISSIONS, DELETE_PERMISSION } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import FilterMolecule from "components/molecules/FilterMolecule";
import ResetBtn from "components/atoms/ResetBtn";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { handleClear } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
const ManagePermissions = () => {
  interface Permission {
    permission_id?: number;
    name?: string;
    classification_name?: string;
  }
  const userData = useSelector(selectAuth);
  const pageHistory = useSelector(selectPageHistory);
  const history: any  = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const fetchAllPermissions = async () => {
    const response = await ApiCall.getService(GET_PERMISSIONS, "GET");
    if (response.status === 200) {
      setPermissions(response.data);
    }
  };

  const deletePermission = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };  

  const filterOrder = permissions?.sort((a: any, b: any) => {
    // If `orderBy` is not resent, sort by `created_at`
    if (!history?.filters?.orderBy) {
      const dateA = new Date(a?.created_at).getTime();
      const dateB = new Date(b?.created_at).getTime();
      return dateB - dateA;
    } else {
      let comparison = (history?.filters?.orderBy === 'name') ? a?.name.localeCompare(b?.name) : a?.classification_name?.localeCompare(b?.classification_name);
      return ((history?.filters?.sortDirection || 'desc') === 'asc') ? comparison : -comparison;
    }
  });

  // Filter the data based on the search query
  const filteredData = filterOrder?.filter(
    (item: Permission) =>
      item?.name?.trim()?.toLowerCase()?.includes(history?.filters?.name?.trim()?.toLowerCase() ?? "") &&
      item?.classification_name?.trim()?.toLowerCase()?.includes(history?.filters?.classification_name?.trim()?.toLowerCase() ?? "")
  );

  // Get the current items to display on the current page
  const indexOfLastItem = Number(history?.filters?.currentPage ?? 1) * Number(history?.filters?.itemsPerPage ?? 10);
  const indexOfFirstItem = indexOfLastItem - Number(history?.filters?.itemsPerPage ?? 10);
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / Number(history?.filters?.itemsPerPage ?? 10));

  // Modal popup for delete confirmation
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_PERMISSION}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response.status === 200) {
        setPermissions((prevdata) =>
          prevdata.filter(
            (permissions) => permissions.permission_id !== deleteID
          )
        );
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Permission",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage permissions")} />
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Permission",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/permission/create"
                  title={t("Create permission")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="tableSection">
            <div className="table-responsive manage-permission">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={[
                  {name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
                  {name: 'classification_name', fieldType: "text", placeholder: "Classification", filterType: 'search' },
                ]}
              />
              <div className="TableHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th className="ps-lg-4">{
                        t("Name")}{" "}
                        <SortAtomForManagePage
                          value={{alias: "name"}}
                          dispatch={dispatch}
                          history={history}
                          updatePageFilters={updatePageFilters}
                        />
                      </th>
                      <th>{
                        t("Classification")}{" "}
                        <SortAtomForManagePage
                          value={{alias: "classification_name"}}
                          dispatch={dispatch}
                          history={history}
                          updatePageFilters={updatePageFilters}
                        />
                      </th>
                      <th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Permission",
                              update: true,
                              delete: true,
                            },
                          ]}
                          override={userData.isSuperAdmin}
                          actions={true}
                          strict={false}
                        >
                          {t("Actions")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems && currentItems.length > 0 ? (
                      currentItems?.map((item, index) => (
                        <tr
                          key={item.permission_id}
                          className="border-bottom box-shadow mb-3"
                        >
                          <td className="text-break ps-lg-4" data-label="Name">
                            {item.name}
                          </td>
                          <td
                            className="text-break"
                            data-label="Classification"
                          >
                            {item.classification_name}
                          </td>
                          <td className="px-2 table-action-icons">
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Permission",
                                  update: true,
                                },
                              ]}
                              override={userData.isSuperAdmin}
                            >
                              <button
                                className="table-action-btn border-0 p-0 me-2"
                                title={t("Delete")}
                                onClick={() =>
                                  navigate(
                                    `/permission/edit/${item.permission_id}`
                                  )
                                }
                              >
                                <EditIcon />
                              </button>
                            </AccessControl>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Permission",
                                  delete: true,
                                },
                              ]}
                              override={userData.isSuperAdmin}
                            >
                              <button
                                className="table-action-btn border-0 p-0 me-2"
                                title={t("Delete")}
                                onClick={() =>
                                  deletePermission(item.permission_id)
                                }
                              >
                                <DeleteIcon />
                              </button>
                            </AccessControl>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={4} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">
                            {t("No records")}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="container-fluid">
              <div
                className=""
                style={{ paddingTop: "0.25vw" }}
              >
                <PaginationWithPerPage
                  dispatch={dispatch}
                  history={history}
                  pageData={{totalPages: totalPages, totalRecords: filteredData?.length ?? 0}}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "0.5vw 0" }}
        >
          <BackButton />
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManagePermissions;
