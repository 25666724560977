import React from 'react';

interface SearchProps {
    onChange? : (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    placeHolder?: string;
    value?: string;
}

const SearchModel: React.FC<SearchProps> = ({onChange, className, placeHolder,value}) => {
    return <>
        <div>
            <input className={`${className} field-shadow`} type="text" placeholder={placeHolder} onChange={onChange} value={value}/>
        </div>
    </>
}

export default SearchModel;