import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { t } from "pages/microservices/masterData/translation/Translation";
import "./PageNotFound.css";

const PageNotFound: React.FC = () => {
  const userAuth = useSelector(selectAuth);

  return (
    <div
      className="row d-flex align-items-center justify-content-center welcome-page"
      style={{ minHeight: "calc(100vh - 6.5vw)" }}
    >
      <div className="col-xxl-10 col-xl-11 col-md-12 mx-auto text-center h-100 d-flex align-items-center justify-content-center flex-column">
        <div className="access-denied-code pb-xxl-3">404</div>
        <div className="access-denied-title pb-xxl-3">
          {t("Page not found")}
        </div>
        <div className="access-denied-content mb-4">
          {t(
            "It looks like the page you were searching for is missing or doesn't exist. Let's find a different path to explore together."
          )}
        </div>
        <Link to="/dashboard" className="form-button page-not-found-back-link">
          {t("Go to homepage")}
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
