import React, { useEffect } from "react";
import PlanningFilters from "./molecules/planningFilters";
import { usePlanningsDispatch } from "./planning-redux/context/PlanningContext/PlanningContext";
import { PLANNING_ACTIONS } from "./planning-redux/actions/PlanningActions";
import TitleAtom from "components/atoms/Title";
import { t } from "../masterData/translation/Translation";
import "./css/planning.css";

const PageLayout = ({ children, ...props }) => {
  const { dispatchPlanning } = usePlanningsDispatch();
  useEffect(() => {
    dispatchPlanning({
      type: PLANNING_ACTIONS.SET_TYPE,
      payload: props.type,
    });
  }, [props.type]);
  return (
    <>
      <div className="search-bar">
        <TitleAtom title={t("Planning")} />
        <PlanningFilters type={props.type} />
      </div>
      <div className="content">{children}</div>
    </>
  );
};

export default PageLayout;
