import { Option } from "utils/TypeAnnotations";

//Manage contract proposal
export interface ManagecontractProps {
  id: number | null;
  userId: number | null;
  startDate: Date | null;
  endDate: Date | null;
  employeeContrcatType: string;
  company: string;
  function: string;
  salary: string;
}

export interface ManageDropdownData {
  employeeContractType: Option | null;
  company: Option | null;
  function: Option | null;
}

export const ManageDropdownData = {
  employeeContractType: null,
  company: null,
  function: null,
};

export const initialPageDetails = {
  totalPages: 0,
  totalRecords: 0,
  refresh: true,
  initialCall: true,
};

//Create contract proposal
export interface EmployeeContractProps {
  employeeContractType: Option | null;
  businessUnit: Option | null;
  company: Option | null;
  paritairComitee: Option | null;
  function: Option | null;
  employmentType: Option | null;
  scheduleType: Option | null;
  numberOfMonths: number | undefined;
  shift: number | undefined;
  startDate: Date | null;
  endDate: Date | null;
  salary: number | undefined;
  salaryCategory: Option | null;
  salaryType: Option | null;
  // qBonus: string;
  // partenaNumber: number;
  status: number | null;
  isApproved: number | null;
  info: string;
}

export const initialContract: EmployeeContractProps = {
  employeeContractType: null,
  businessUnit: null,
  company: null,
  paritairComitee: null,
  function: null,
  employmentType: null,
  scheduleType: null,
  numberOfMonths: undefined,
  shift: undefined,
  startDate: null,
  endDate: null,
  salary: undefined,
  salaryCategory: null,
  salaryType: null,
  // qBonus: '',
  // partenaNumber: 0,
  status: 1,
  isApproved: 1, // can be 0-9
  info: "",
};

export const initialDropdownData = {
  employeeContractType: [],
  businessUnit: [],
  company: [],
  paritairComitee: [],
  function: [],
  employmentType: [],
  scheduleType: [],
  shifts: [],
  salaryCategory: [],
  salaryType: [],
};

export const initalCreatContractErrors = {
  startDate: "",
  employeecontractType: "",
  company: "",
  businessUnit: "",
  paritairComitee: "",
  function: "",
  salary: "",
  salaryType: "",
  employeeType: "",
  scheduleType: "",
  shift: "",
};
