import { t } from "pages/microservices/masterData/translation/Translation";
import React, { MouseEventHandler } from "react";
import Reset from "static/images/ResetIcon";
import Button from "./Button";

interface props {
  handleResetClick?: MouseEventHandler<HTMLButtonElement>;

}
const ResetBtn:React.FC<props> = ({
  handleResetClick
}) => {
  return (
    <Button
      title=""
      type="reset"
      handleClick={handleResetClick}
      className="close-button marginRight1"
      style={{minWidth:"3vw"}}
    >
      <span title={t("Reset")}>
        <Reset />
      </span>
    </Button>
  );
};

export default ResetBtn;
