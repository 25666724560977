import {
    validateEmail,
    validateMultiSelectField,
    validateNumber,
    validatePhoneNumber,
    validateRequired,
    validateSelectField,
    validateTextFieldAlpha,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";


// GeneralTab
export const GeneralTabValidationRules: ValidationRules = {
    businessUnitNumber: [validateRequired],
    projectName: [validateRequired, validateTextFieldAlpha],
    projectCode: [validateRequired, validateTextFieldAlpha],
    company: [validateRequired, validateSelectField],
    locations: [validateRequired, validateMultiSelectField],
    startDate: [validateRequired],
    endDate: [],
    isActive: [],
};

export const GeneralTabSubformLocationRules: ValidationRules = {
    company: [validateRequired, validateSelectField],
    location: [validateRequired],
    phone: [validateRequired],
    street: [validateRequired],
    number: [validateNumber],
    zipcode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
};

// ContactsTab
export const ContactsTabValidationRules: ValidationRules = {
    selectedContactPersons: [validateRequired, validateMultiSelectField],
}

export const ContactsTabSubformValidationRules: ValidationRules = {
    fName: [validateRequired, validateTextFieldAlpha],
    lName: [validateRequired, validateTextFieldAlpha],
    email: [validateRequired, validateEmail],
    company: [validateSelectField], // validateRequired
    mobNumber: [validatePhoneNumber],
    selectedContactPersons: [validateRequired, validateMultiSelectField],
};

//billing tab
export const BillingTabValidationRules: ValidationRules = {
    vatRate: [validateSelectField],
    paymentTerm: [validateSelectField],
    billingStatus: [validateSelectField],
};

export const ShiftsTabValidationRules: ValidationRules = {
    shifts: [validateMultiSelectField],
};

export const SequenceTabValidationRules: ValidationRules = {
    sequenceName: [validateRequired],
};

export const ProjectParametersTabValidationRules: ValidationRules = {
    templates: [validateSelectField],
    fromdate: [validateRequired],
};
