import React from "react";

const Download = () => {
  return (
    <span title="Download">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 16l-6-6h4V4h4v6h4l-6 6zm6-10v2H6V6H4v14h16V6h-2z" fill="currentColor"/>
        <path d="M0 0h24v24H0z" fill="none"/>
      </svg>
    </span>
  );
};

export default Download;
