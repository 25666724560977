export const SWITCH_TAB = "SWITCH_TAB";
export const SET_OPTIONS = "SET_OPTION";
export const UPDATE_CURRENT_TAB = "UPDATE_CURRENT_TAB";
export const NEXT_TAB = "NEXT_TAB";
export const PREVIOUS_TAB = "PREVIOUS_TAB";
export const SET_FORM_ELEMENTS = "SET_FORM_ELEMENTS";
export const UPDATE_FIELD_DATA = "UPDATE_FIELD_DATA";
export const ADD_DYNAMIC_FORM = "ADD_DYNAMIC_FORM";
export const REMOVE_DYNAMIC_FORM = "REMOVE_DYNAMIC_FORM";
export const UPDATE_TAB_FIELD = "UPDATE_TAB_FIELD";
export const UPDATE_DEPENDENT_TAB = "UPDATE_DEPENDENT_TAB";
export const MANAGE_INDEX = "MANAGE_INDEX";
export const COMPETENCE_SELECTED = "COMPETENCE_SELECTED";
export const COMPETENCE_RANGE = "COMPETENCE_RANGE"
export const UPDATE_SALARY_BENEFIT = "UPDATE_SALARY_BENEFIT";
export const UPDATE_SALARY_BENEFIT_FIELD_DATA = "UPDATE_SALARY_BENEFIT_FIELD_DATA";
export const EDIT_PREVIOUS_DATA_IN_TABLE = "EDIT_PREVIOUS_DATA_IN_TABLE";
export const HIDE_PREVIOUS_DATA_IN_TABLE = "HIDE_PREVIOUS_DATA_IN_TABLE";
export const UPDATE_PREVIOUS_DATA = "UPDATE_PREVIOUS_DATA";
export const UPDATE_STATE_WITH_EMPTY_VALUES = "UPDATE_STATE_WITH_EMPTY_VALUES";
export const UPDATE_DELETE_POPUP = "UPDATE_DELETE_POPUP";
export const UPDATE_AFTER_BUTTON_CLICK = "UPDATE_AFTER_BUTTON_CLICK";
