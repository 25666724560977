import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import TableActions from "components/organism/Actions/TableAction";
import { Link, useSearchParams } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import CompetenceModal from "./competenceModel";
import AddProjectModal from "./addProjectModel";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { DELETE_HOTLIST_DATA, GET_EMPLOYEES, GET_MATCHING_OPTIONS, SAVE_HOTLIST_DATA } from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import { useNavigate } from "react-router-dom";
import { PATH_HR_PLANNING, PATH_VACANCY_CREATE } from "constants/Paths";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import CommonServices from "services/CommonService";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { ProjectMacthingTableHeader } from "TableHeader";
import FilterOrganism from "components/organism/FilterOrganism";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import TitleFieldMolecule from "components/molecules/TitleField";

export const MatchingSearch: React.FC = ({ }) => {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const url = useLocation();
  const [showCompetenceModal, setShowCompetenceModal] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [option, setOptions] = useState({
    location: [],
    function: [],
    radius: [],
    competences: [],
    contractTypes: [],
  });
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
    options: [],
  })
  const getSearchData = () => {
    let startDate: any = CommonServices?.getTimezoneOffset(history?.filters?.startDate ?? null);
    let endDate: any = CommonServices?.getTimezoneOffset(history?.filters?.endDate ?? null);
    if (history?.filters?.startDate && history?.filters?.endDate) {
      startDate = CommonServices?.getTimezoneOffset(new Date());
      endDate = CommonServices?.getTimezoneOffset(new Date(new Date().setDate(new Date().getDate() + 28)));
      dispatch(updatePageFilters({
        filters: {
          startDate: startDate,
          endDate: endDate,
        },
      }));
    }
    const filteredObject = Object.fromEntries(Object.entries(history?.filters?.competance ?? {}).filter(([key, value]: any) => value?.options?.length > 0)) ?? "";
    return {
      user_id: Number(history?.filters?.user_id ?? null),
      name: history?.filters?.name ?? "",
      radius: history?.filters?.radius ?? [],
      location: history?.filters?.location ?? "",
      contractType: history?.filters?.contractType ?? "",
      function: history?.filters?.function ?? "",
      competance: filteredObject,
      projectId: projectId,
      companyId: companyId,
      startDate: startDate,
      endDate: endDate,
      // orderBy: history?.filters?.orderBy ?? "id",
      // sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  }

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
      employeeId: employeeId,
    };
    fetchEmployees(postData);
  }, [pageData.refresh, pageData.initialCall]);

  const [employeData, setEmployeeData] = useState([]);
  const params = useParams();
  const projectId = params.element;
  const companyId = params.id;
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState(0);
  const [removeStatus, setRemoveStatus] = useState<boolean>(false);

  const handleModalClose = () => {
    setShowCompetenceModal(false);
    setShowAddProjectModal(false);
  };

  const handleAddPlanning = (value: any) => {
    navigate(`${PATH_HR_PLANNING}?employee=${value.id}`);
  };

  const handleAddProject = (item: any) => {
    setEmployeeId(item?.id);
    setShowAddProjectModal(true);
    setRemoveStatus(item?.addProjectStatus ?? false);
  };

  const handleAddHotlist = async (item: any) => {
    const postData = {
      category: "employee",
      categoryValue: item?.id,
    };
    try {
      if (item.hotlistStatus && item?.id) {
        const id = {
          id: item.id,
          page: "matching",
        };
        const response = await ApiCall.service(DELETE_HOTLIST_DATA, "POST", id, false, M_MASTER_DATA);
        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: "Deleted  successfully from  hotlist",
          });
          handleRefresh();
        } else {
          CustomNotify({ type: "success", message: "Unable to delete" });
        }
      } else if (item?.id) {
        const response = await ApiCall.service(SAVE_HOTLIST_DATA, "POST", postData, false, M_MASTER_DATA);
        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: "Employee successfully added to hotlist",
          });
          handleRefresh();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOptions = async (postData: any) => {
    const respose = await ApiCall.service(GET_MATCHING_OPTIONS, "POST", postData, false, M_MASTER_DATA);
    if (respose?.status === 200) {
      return respose?.data;
    }
  }
  const fetchEmployees = async (data: any) => {
    let pgdata = {...pageData};
    try {      
      if (pageData?.initialCall) {
        const payload = {
          employeeId, companyId, projectId
        };
        const optionsData = await fetchOptions(payload);
        setOptions(optionsData);
        pgdata = {...pgdata, initialCall: false};
      }
      const response = await ApiCall.service(GET_EMPLOYEES, "POST", data, false, M_MASTER_DATA);
      if (response?.status === 200) {
        setEmployeeData(response?.data);
        pgdata = {...pgdata, totalPages: response?.totalPages, totalRecords: response?.totalRecords};
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
    setPageData(pgdata);
  };

  const onchangeFunction = async (value: any, filter: any) => {    
    dispatch(updatePageFilters({
      filters: {
        [filter?.name]: value, currentPage: "1", competance: {},
      }
    }));
    const postData = {onChangeOptions: true, function_id: value };
    const optionsData = await fetchOptions(postData);
    setOptions((prev) => ({ ...prev, competences: optionsData?.competences}));
    await handleRefresh();
  }

  const handleAddCompetence = (selectedOptions: any) => {
    dispatch(updatePageFilters({ filters: { competance: selectedOptions, currentPage: "1" } }));
    setShowCompetenceModal(false);
    handleRefresh();
  };

  const handleNavigateToVacancy = () => {
    dispatch(addPageToHistory({
      pageName: PAGENAMES?.CREATE_VACANCY,
      filters: {
        location: history?.filters?.location ?? null,
        contract: history?.filters?.contractType ?? [],
        function: history?.filters?.function ?? [],
        competance: history?.filters?.competance ?? [],
        project: projectId ?? null,
        company: companyId ?? null,
      },
      route: PATH_VACANCY_CREATE
    }));
    navigate(PATH_VACANCY_CREATE);
  }
  const handleRefresh = async () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const filters = [
    { name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
    {name: 'contractType', fieldType: "multiSelect", options: option?.contractTypes, placeholder: "Contract type", filterType: 'search' },
    {name: 'function', fieldType: "multiSelect", options: option?.function, placeholder: "Function", onchangeCall: onchangeFunction, filterType: 'search' },
    {name: 'location', fieldType: "singleSelect", options: option?.location, placeholder: "Location", filterType: 'search' },
    {name: 'radius', fieldType: "singleSelect", options: option?.radius, placeholder: "Radius", filterType: 'search' },
    // { name: 'startDate', fieldType: "date", end: history?.filters?.endDate, placeholder: "Start date", filterType: 'search' },
    // { name: 'endDate', fieldType: "date", start: history?.filters?.startDate, placeholder: "End date", filterType: 'search' },
  ];

  return (
    <>

      <div className="search-bar">
        <TitleFieldMolecule title={t("Matching project")} />
        <FilterOrganism
          dispatch={dispatch}
          history={history}
          updatePageFilters={updatePageFilters}
          filters={filters}
          handleRefresh={handleRefresh}
        />
      </div>
      <div className="position-relative tableMainWrapper">
        {/* Add Competence Button */}
        <div className="row">
          <div className="ManageCreateBtn">
            <Link
              to="#"
              title=""
              className="form-button marginRightPoint5"
              onClick={() => setShowCompetenceModal(true)}
            >
              + {t("Add Competence")}
            </Link>

            <Link
              to="#"
              title=""
              className="form-button marginRightPoint5"
              onClick={() => handleNavigateToVacancy()}
            >
              + {t("Create vacancy")}
            </Link>

          </div>
        </div>

        <div className="table-responsive Matching-project tableSection">
          <table className="table table-hover mt-2">
            <thead>
              <tr className="TableHeader">
                {ProjectMacthingTableHeader?.map((value: any) => (
                  <th key={value.alias} className="border-bottom-0" >
                    {t(value?.name)}
                    {/* {value?.issort && (
                      <SortAtomForManagePage
                        value={value}
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        handleRefresh={handleRefresh}
                      />
                    )} */}
                  </th>
                ))}
                <th className="table_actions">{t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {/* Render dummy data */}
              {employeData.map((item: any, index) => (
                <tr key={index}>
                  <td>{item?.name}</td>
                  <td>{item?.contractType}</td>
                  {/* <td>{item?.location}</td> */}
                  <td>{item?.function}</td>
                  <td>{item?.margin} &euro;</td>
                  <td>{item?.marginPercentage}%</td>
                  {/* <td>{item?.availablity ? "Yes" : "No"}</td> */}
                  {/* <td>{item?.startDate}</td>
                  <td>{item?.endDate}</td> */}
                  <td data-label={t("Score")}>
                    <div className="d-flex align-items-center">
                      <div>
                        <span className="color-dark-pink">
                          {Math.round(item?.score)}%
                        </span>
                      </div>
                      <div className="progress flex-1">
                        <div
                          className={`progress-bar`}
                          role="progressbar"
                          aria-valuenow={item?.score}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: `${Math.round(item?.score)}%` }}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <TableActions
                      isAction={true}
                      handleAddPlanning={() => handleAddPlanning(item)}
                      handleAddProject={() => { handleAddProject(item) }}
                      projectAddedToEmployee={item?.addProjectStatus}
                      handleAddHotlist={() => handleAddHotlist(item)}
                      value={item?.hotlistStatus}
                      permission={null}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="">
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>

      </div>
      {/* Back Button */}
      <div className="d-flex justify-content-between align-items-center my-4">
        <BackButton />
      </div>
      {/* Competence Modal */}
      {showCompetenceModal && (
        <CompetenceModal
          handleClose={handleModalClose}
          handleAdd={handleAddCompetence}
          selectedCompetence={history?.filters?.competance ?? {}}
          competenceData={option?.competences ?? []}
        />
      )}
      {/* Project Modal */}
      {showAddProjectModal && (
        <AddProjectModal
          removeStatus={removeStatus}
          handleClose={handleModalClose}
          url={url.pathname + "/" + employeeId}
          handleRefresh={handleRefresh}
        />
      )}
    </>
  );
};

export default MatchingSearch;
