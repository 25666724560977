import React from "react";

interface Props {
  title?: string;
  label?: string;
  width?: string;  // Allow passing a custom width as a string (e.g., '200px', '50%')
}

const TextEllipsis: React.FC<Props> = ({ title, label, width }) => {
  return (
    <div className="textEllipsis" title={title} style={{ width }}>
      {label}
    </div>
  );
};

export default TextEllipsis;
