import React, { ReactNode, useEffect, useRef, useState } from "react";
import WorkPayBillRollFilters from "./organisms/WorkPayBillRollFilters";
import { t } from "../masterData/translation/Translation";
import { MANAGE_PAYROLL_CONSTANTS } from "./constants/WorkPayBillRollConstants";
import { useReactToPrint } from "react-to-print";
import { useFilterOptions, useFilterOptionsDispatch } from "./work-pay-bill-roll-redux/context/FilterOptionsContext";
import { getDate, handleFilterChange, handleTableDataChange } from "./utils/Utils";
import { useTable, useTableDispatch } from "./work-pay-bill-roll-redux/context/TableContext";
import { getManageData } from "./utils/ApiCalls";
import TitleAtom from "components/atoms/Title";
import html2pdf from "html2pdf.js";
import BackButton from "components/atoms/BackButton";

interface PageLayoutProps {
  children: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const filterState = useFilterOptions();
  const filterDispatch = useFilterOptionsDispatch();
  const tableState = useTable();
  const tableDispatch = useTableDispatch();
  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const externalElement = document.getElementById("overlay") as HTMLDivElement | null;

  const getDocTitle = (overview?: boolean) => {
    return (tableState?.overview || overview)
      ? `${t("Payroll of")} ${tableState?.overview?.business_unit} - ${tableState?.overview?.employee} ${t("on")} ${getDate(filterState?.selected_date, { month: "short", year: "numeric"})}`
      : t(MANAGE_PAYROLL_CONSTANTS?.MANAGE_PAYROLL);
  };

  const handlePrint = async (type: any, page?: string) => {
    switch (type) {
      case "export":
        let filters: any = { filterState, tableDispatch, tableState, export: true };    
        await getManageData(filters)
          .then(async () => {
            setTimeout(() => {
              setLoading(true);
            }, 500);
          }).catch((error) => {
            setLoading(false);
            console.error("Error during data management:", error);
          });
        break;
      case "exportoverview":
        if (externalElement) externalElement.style.display = "flex";
        await handleTableDataChange(tableState, tableDispatch, "overview", page)
          .then(async () => {
            setTimeout(() => {
              setLoading(true);
            }, 500);
          }).catch((error) => {
            if (externalElement) externalElement.style.display = "none";
            setLoading(false);
            console.error("Error during data management:", error);
          });
        break;
      case "print":
        printPdf(null, () => componentRef.current);
        break;
      default:
        break;
    }
  };

  useEffect(() => {    
    const handleDownloadAndNextSteps = async () => {
      if (loading === true) {          
        // Trigger download and wait for it to complete
        await downloadPdf();
        if (tableState?.overview && tableState?.overview !== "overview" && tableState?.selected_data?.length > 0) {
          handleTableDataChange(tableState, tableDispatch, "overview", 'false');
        } else {
          handleFilterChange(filterState, filterDispatch, "submit");
        }
        setLoading(false);
        if (externalElement) externalElement.style.display = "none";
      }
    };
    handleDownloadAndNextSteps();
  }, [loading === true]);

  const downloadPdf = async () => {
    const options = {
      filename: `Export-payroll-${new Date().getDate()}-${new Date().toLocaleString('default', { month: 'short' })}-${new Date().getFullYear()}-${new Date().toLocaleTimeString().replace(/:/g, '-')}`,
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
      pagebreak: { mode: ['css', 'legacy'] },
      html2canvas: { scale: 3, useCORS: true },
    };
    if (componentRef?.current) {
      (componentRef.current as HTMLDivElement).style.padding = '40px';
      await html2pdf().from(componentRef.current).set(options).save();
      (componentRef.current as HTMLDivElement).style.padding = '0px';
    }
  };

  const printPdf = useReactToPrint({
    onBeforePrint: () => {
      console.log("before printing...");
    },
    onAfterPrint: () => {
      console.log("after printing...");
    },
    content: () => componentRef.current,
    documentTitle: getDocTitle(),
    removeAfterPrint: true,
    bodyClass: "mt-5",
    pageStyle: "",
  });

  return (
    <>
      <div className="search-bar">
        <TitleAtom title={t(MANAGE_PAYROLL_CONSTANTS?.MANAGE_PAYROLL)} />
        <WorkPayBillRollFilters handlePrint={handlePrint} />
      </div>
      <div ref={componentRef} className="content">
        {children}
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        {tableState?.overview 
          ? (<span
              onClick={() => handleTableDataChange(tableState, tableDispatch, "overview", false)}
              className="cursor-pointer text-uppercase back-btn text-decoration-underline"
            >
              {t(MANAGE_PAYROLL_CONSTANTS?.BACK)}
            </span>)
          : <BackButton />
        }
      </div>
    </>
  );
};

export default PageLayout;
