import Calender from 'components/molecules/Calender'
import React from 'react'
import { handleFilterChange } from '../utils/Utils'
import { useFilterOptions, useFilterOptionsDispatch } from '../work-pay-bill-roll-redux/context/FilterOptionsContext';
import { t } from 'pages/microservices/masterData/translation/Translation';
import Button from 'components/atoms/Button';
import { MANAGE_PAYROLL_CONSTANTS } from '../constants/WorkPayBillRollConstants';

interface ManagePayrollHeadersProps {
    handleChange: (e: any, name: any) => void;
}
const ExportPopup: React.FC<ManagePayrollHeadersProps> = ({ handleChange }) => {
    const filterState = useFilterOptions();
    return (
        <>
            <div className="d-flex ms-auto exportPopup" style={{gap:"1vw"}}>
                <Calender
                    onChange={(e, name) => handleChange(e, name)}
                    selectedDate={filterState?.popup?.export?.from}
                    maxDate={filterState?.popup?.export?.to}
                    placeHolder={t("Select from date")}
                    pickerType='month'
                    name="from"
                />
                <Calender
                    onChange={(e, name) => handleChange(e, name)}
                    selectedDate={filterState?.popup?.export?.to}
                    minDate={filterState?.popup?.export?.from}
                    placeHolder={t("Select to date")}
                    pickerType='month'
                    name="to"
                />
            </div>
        </>  
    )
}

export default ExportPopup