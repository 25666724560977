import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { PURCHASE_ORDERS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ManagePurchaseOrder from "./ManagePurchaseOrder";

// Define the prop types for the component
interface PurchaseOrderProps {
    id?: string | number;
    dispatch: any;
    history: any;
}

// Functional component using TypeScript
const PurchaseOrder: React.FC<PurchaseOrderProps> = ({history, dispatch}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const [vacaciesData, setVacanciesData] = useState([]);
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        initialCall: true,
        refresh: true,
      });
    
    const getSearchData = () => {
        return {
            ownerCompany: history?.filters?.ownerCompany ?? "",
            businessUnits: history?.filters?.businessUnits ?? "",
            brand: history?.filters?.brand ?? "",
            model: history?.filters?.model ?? "",
            status: history?.filters?.status ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
        }
    };

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
        };
        getVacanciesData(postData);
    }, [pageData?.initialCall, pageData?.refresh]);

    const getVacanciesData = async (postData: any) => {
        const response = await ApiCall.service(
            PURCHASE_ORDERS,
            "POST",
            postData,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setVacanciesData(response?.data?.data);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords }))
        } else {
            console.log("error");
        }
    };
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}...</div>
                }
            >

                <ManagePurchaseOrder
                    data={vacaciesData}
                    setMaterialData={setVacanciesData}
                    handleGetVacany={getVacanciesData}
                    dispatch={dispatch}
                    history={history}
                    handleRefresh={handleRefresh}
                    pageData={pageData}
                />
            </Suspense>
        </>
    );
};

export default PurchaseOrder;
