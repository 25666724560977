import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import EditIcon from "static/images/EditIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import { CREATE_CANDIDATE, EDIT_CANDIDATE, MANAGE_CONTRACT_PROPOSAL, MANAGE_WAGE_PROPOSAL, PATH_CANDIDATE_CORNER, PATH_MATCHING_EMPLOYEE } from "constants/Paths";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import Title from "components/atoms/Title";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import { CANDIDATE_ARCHIVE, EMPLOYEE_CONVERSION, FETCH_CANDIDATE_OVERVIEW_DATA } from "routes/ApiEndpoints";
import { OptionProps } from "react-select";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Matching from "static/images/matchingIcon";
import Button from "components/atoms/Button";
import FollowUpIcon from "static/images/FollowUpIcon";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import ArchieveIcon from "static/images/ArchiveIcon";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { useSelector, useDispatch } from "react-redux";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { convertArrayToOptions, convertObjectToOptions } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { CandidatesTableHeader } from "TableHeader";
import AddWageProposalIcon from "static/images/AddWageProposalIcon";
import AddContractIcon from "static/images/addContractIcon";
import Unarchive from "static/images/UnarchiveIcon";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import EmployeeConversionIcon from "static/images/EmployeeConversionIcon";
import { CandidateStatus } from "../helpers/CandidateHelperFunctions";
import BackButton from "components/atoms/BackButton";

export interface CandidateData {
    id?: number;
    email: string;
    status: number | null;
    function: string;
    name: string;
    mobileNumber: string;
    businessUnits: string;
    businessUnitId: number | null;
    isEmployee: boolean;
    ssn: string;
}

const ManageCandidates: React.FC = () => {
    const navigate = useNavigate();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [candidates, setCandidates] = useState<CandidateData[]>([]);
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true
    });

    const getSearchData = () => {
        return {
            businessUnits: history?.filters?.businessUnits ?? [],
            businessUnitId: history?.filters?.businessUnitId ?? "",
            name: history?.filters?.name ?? "",
            email: history?.filters?.email ?? "",
            mobileNumber: history?.filters?.mobileNumber ?? "",
            function: history?.filters?.function ?? "",
            ssn: history?.filters?.ssn ?? "",
            status: history?.filters?.status ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
            role: history?.filters?.role ?? []
        }
    };
    const [businessUnits, setBusinessUnits] = useState<OptionProps[]>([]);
    const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
    const [sideBarWidth, setSidebarWidth] = useState("");
    const [entity, setEntity] = useState<{
        entityId: string | number | null;
        businessUnit: string | number | null;
    }>({ entityId: null, businessUnit: null });
    const [archiveModal, setArchiveModal] = useState(false);
    const [candidateId, setCandidateId] = useState<number | undefined>(undefined);
    const roles = [
        { 'value': 'Candidate', 'label': 'Candidate' },
        { 'value': 'Employee', 'label': 'Employee' },
    ];

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall
        };
        fetchCandidates(postData);
    }, [pageData?.refresh, pageData?.initialCall]);

    const fetchCandidates = async (postData: any) => {
        const response = await ApiCall.service(FETCH_CANDIDATE_OVERVIEW_DATA, "POST", postData, false, M_IDENTITY_MANAGER);
        if (response?.status === 200) {
            if (pageData?.initialCall) {
                setBusinessUnits(response?.dropDownData?.businessUnitList || []);
            }
            setCandidates(response?.data || []);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }))
        }
    };

    const handleClick = (id: number | undefined) => {
        if (id) {
            navigate(`${PATH_CANDIDATE_CORNER}/${id}?source=candidate`);
        }
    }

    const handleCloseSidebar = () => {
        setFollowupSideBar(false);
    };

    const getWidth = (width: any) => {
        setSidebarWidth(width);
    };

    const handleFollowup = (
        id: number | undefined,
        businessUnit: string | number | null
    ) => {
        if (entity.entityId === id) {
            setEntity({
                entityId: null,
                businessUnit: null,
            });
            setFollowupSideBar(false);
        } else {
            setEntity({
                entityId: id ?? null,
                businessUnit: businessUnit ?? null,
            });
            setFollowupSideBar(true);
        }
    };

    const handleArchive = (id: number | undefined, status: number | null) => {
        if (status === 3) {
            setArchiveModal(false);
        } else {
            setArchiveModal(true);
        }
        setCandidateId(id);
    }
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    const handleArchiveConfirm = async () => {
        if (candidateId) {
            const response = await ApiCall.getService(`${CANDIDATE_ARCHIVE}/${candidateId}`, "GET", M_IDENTITY_MANAGER, true);
            if (response.status === 200) {
                handleRefresh();
                setArchiveModal(false);
                CustomNotify({ type: 'success', message: response.message });
            }
        }
    }

    const handleCloseArchiveModal = () => {
        setArchiveModal(false);
    }
    const filters = [
        { name: 'businessUnits', fieldType: "multiSelect", options: businessUnits, placeholder: "Business unit", filterType: 'search' },
        { name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
        { name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
        { name: 'mobileNumber', fieldType: "text", placeholder: "Mobile number", filterType: 'search' },
        { name: 'status', fieldType: "singleSelect", options: convertObjectToOptions(CandidateStatus), placeholder: "Status", filterType: 'search' },
        { name: 'role', fieldType: "singleSelect", options: roles, placeholder: "Role", filterType: 'search' },
    ];

    const handleEmployee = async (id: number | undefined) => {
        const response = await ApiCall.getService(`${EMPLOYEE_CONVERSION}/${id}`, "GET", M_IDENTITY_MANAGER, true);
        if (response.status === 200) {
            handleRefresh();
            CustomNotify({ type: 'success', message: response.message });
        }
    }
    const handleMatching = (record: any) => {
        let competence: any = {};
        record?.employeeCompetence && record?.employeeCompetence
            ?.filter((item: any) => item !== null && item?.competence_id)
            ?.map((item: any) => {
                competence[item.competence_id] = {
                    options: item?.user_competence_type?.map((type: any) => ({
                        value: type?.competence_type_id,
                        label: type?.competence_type?.competence_type ?? "",
                        range: type?.range
                    }))
                };
            });
        dispatch(addPageToHistory({
            pageName: PAGENAMES?.EMPLOYEE_MATCHING,
            filters: {
                function: record?.employeeFunctions ?? [],
                competance: competence,
                location: [record?.contractType] ?? [],
            },
            route: `${PATH_MATCHING_EMPLOYEE}/${record?.id}`
        }));
        navigate(`${PATH_MATCHING_EMPLOYEE}/${record?.id}`);
    };
    return (
        <>
            <div className="search-bar">
                <Title title={t("Manage candidates")} />
            </div>
            <div className="position-relative tableMainWrapper">
                <div className="row">
                    <div className="ManageCreateBtn">
                        <LinkTo
                            pagelink={CREATE_CANDIDATE}
                            title={t("Create candidate")}
                            icon={faPlus}
                        />
                    </div>
                </div>
                <div className="table-responsive Manage-candidates tableSection">
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        handleRefresh={handleRefresh}
                    />
                    <div className="TableHeight">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    {CandidatesTableHeader?.map((header: any) => (
                                        <th key={header?.alias}>
                                            <>
                                                {t(header.name)}
                                                {header?.issort && (
                                                    <SortAtomForManagePage
                                                        value={{ alias: header?.alias }}
                                                        dispatch={dispatch}
                                                        history={history}
                                                        updatePageFilters={updatePageFilters}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                )}
                                            </>
                                        </th>
                                    ))}
                                    <th>{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidates?.length > 0 ? (
                                    candidates?.map((candidate: CandidateData) => (
                                        <tr key={candidate.id}>
                                            <td>{candidate?.businessUnits}</td>
                                            <td style={{ cursor: 'pointer' }} onClick={() => handleClick(candidate.id)}>{candidate?.name}</td>
                                            <td>{candidate?.email}</td>
                                            <td>{candidate?.mobileNumber}</td>
                                            <td>
                                                <span
                                                    className={`d-inline-block rounded-circle status-icon bg-${candidate?.status === 1
                                                        ? "success" : candidate.status === 4 ? "warning"
                                                            : "secondary"
                                                        }`}
                                                    data-toggle="tooltip"
                                                    title={candidate.status === 4 ?
                                                        CandidateStatus[0] :
                                                        candidate?.status === 1
                                                            ? CandidateStatus[1]
                                                            : CandidateStatus[3]
                                                    }
                                                ></span>
                                            </td>
                                            <td>
                                                <div>
                                                    <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => navigate(`${EDIT_CANDIDATE}/${candidate.id}`)}
                                                    >
                                                        <EditIcon />
                                                    </Button>
                                                    {(candidate?.status == 1 && candidate?.isEmployee) && <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => handleMatching(candidate)}
                                                    >
                                                        <Matching />
                                                    </Button>}
                                                    <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => handleFollowup(candidate?.id, candidate?.businessUnitId)}
                                                    >
                                                        <FollowUpIcon />
                                                    </Button>
                                                    <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => handleArchive(candidate.id, candidate.status)}                                                >
                                                        {candidate.status === 3 ? <Unarchive /> : <ArchieveIcon />}
                                                    </Button>
                                                    <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => navigate(`${MANAGE_WAGE_PROPOSAL}/${candidate.id}`)}
                                                    >
                                                        <AddWageProposalIcon />
                                                    </Button>
                                                    <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => navigate(`${MANAGE_CONTRACT_PROPOSAL}/${candidate.id}`)}
                                                    >
                                                        <AddContractIcon />
                                                    </Button>
                                                    <Button
                                                        title=""
                                                        className="table-action-btn border-0  p-0 me-2"
                                                        handleClick={() => handleEmployee(candidate?.id)}
                                                    >
                                                        <EmployeeConversionIcon />
                                                    </Button>

                                                </div>

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="border">
                                        <td colSpan={6} className="border-0 text-center py-3">
                                            <span className="text-danger fw-bold">No records</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <PaginationWithPerPage
                            handleRefresh={handleRefresh}
                            dispatch={dispatch}
                            history={history}
                            pageData={pageData}
                        />
                    </div>

                </div>

            </div>
            <div className="col-md-6 align-self-center my-3">
                <BackButton />
            </div>
            {followupSideBar && (
                <RightSideBar
                    title={"Follow-ups"}
                    entityType={"candidate"}
                    businessUnit={entity.businessUnit}
                    entityId={entity.entityId}
                    onClose={handleCloseSidebar}
                    getWidth={getWidth}
                    recordsLimit={10}
                    className="right-sidebar p-3"
                    titleClassName="mt-4"
                    destination="/manage-candidates"
                />
            )}
            {archiveModal && (
                <ModalPopup
                    title={"Archive"}
                    show={archiveModal}
                    onHide={handleCloseArchiveModal}
                    body={t("Are you sure you want to archive this candidate ?")}
                    onConfirmButtonClick={handleArchiveConfirm}
                    onCloseButtonClick={handleCloseArchiveModal}
                    confirmTitle={t("Yes")}
                    closeTitle={t("No")}
                />
            )}
        </>
    );
};

export default ManageCandidates;
