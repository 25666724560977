import TitleFieldMolecule from "components/molecules/TitleField"
import { t } from "../masterData/translation/Translation"
import { Link, useNavigate, useParams } from "react-router-dom"
import { PATH_EMPLOYEE_MANAGE } from "constants/Paths"
import Car from "./car/Car"
import Material from "./material/Material"
import Clothes from "./clothes/Clothes"
import { useEffect, useState } from "react"
import "./css/fleetAndMaterial.css";
import BackButton from "components/atoms/BackButton"

const FleetEmployeeOverview = () => {

    const tabsData = [
        {
            id: 'car',
            tabName: 'Cars',
        },
        {
            id: 'material',
            tabName: 'Material',
        },
        {
            id: 'clothes',
            tabName: 'Clothes',
        },
    ];

    const [tabs, setTabsData] = useState(tabsData);

    const [activeTab, setActiveTabs] = useState(tabs[0]);

    // useEffect(() => {
    //     updateCurrentTab();
    // }, []);

    // const updateCurrentTab = () => {
    //     const tabType = params.type;
    //     const updatedCurrentTab = tabs.filter((eachTab: any) => {
    //         if (eachTab.id == tabType) {
    //             return eachTab;
    //         }
    //         return false;
    //     });
    //     setActiveTabs(updatedCurrentTab[0]);
    // }


    const handleChangeTab = (event: any) => {
        const { id } = event.target;
        const updatedTab = tabs.filter((eachItem: any) => {
            if (eachItem.tabName === event.target.textContent) {
                setActiveTabs(eachItem)
                return { ...eachItem };
            }
            else {
                return { ...eachItem };
            }
        });
    }
    
    const renderActiveComponent = (component: any) => {
        switch (component) {
            case "car":
                return (<Car type="fleet" />);
            case "material":
                return (<Material type="fleet" />);
            default:
                return (<Clothes type="fleet" />)
        }
    }

    return (
        <div className="fleetEmployeeOverview">
            <TitleFieldMolecule
                title={t("Fleet employee overview")}
            />
            <div className="w-50 mx-auto d-flex justify-content-center gap-3 createFormTabs">
                {tabs.map((eachItem) =>
                    <button className={`createStepBtn ${eachItem.id == activeTab.id ? 'active' : 'inactive'}`} id={eachItem.id} onClick={handleChangeTab} >{eachItem.tabName}</button>)}
            </div>
            {renderActiveComponent(activeTab.id)}
            <div className="col-md-6 align-self-center mb-3">
                <BackButton />
            </div>

        </div>
    )
}
export default FleetEmployeeOverview