export const getUpdatedForm = (state: any, action: any) => {
  const { index, field, value, valueField = "value", dynamic } = action.payload;
  if (dynamic === true) {
    return updateDynamicFieldData(state, action);
  }
  const updatedForm = state?.form?.map((tabForm: any, tabIndex: any) => {
    if (tabIndex !== index) {
      return tabForm;
    }

    const fieldIndex = tabForm.findIndex((item: any) => item.name === field);

    if (fieldIndex === -1) {
      return tabForm;
    }

    const updatedField = { ...tabForm[fieldIndex], [valueField]: value };
    return [
      ...tabForm.slice(0, fieldIndex),
      updatedField,
      ...tabForm.slice(fieldIndex + 1),
    ];
  });

  return updatedForm;
};

export const updateDynamicFieldData = (state: any, action: any) => {
  const {
    index,
    field,
    value,
    valueField = "value",
    formIndex,
    mainIndex,
  } = action.payload;

  let stateForm = JSON.parse(JSON.stringify([...state.form]));
  let currentTab = JSON.parse(JSON.stringify(stateForm[index]));


  let tabCurrentForm = currentTab[mainIndex].form[formIndex];

  const fieldIndex = tabCurrentForm.findIndex(
    (item: any) => item.name === field
  );

  if (fieldIndex !== -1) {
    const updatedField = { ...tabCurrentForm[fieldIndex], [valueField]: value };
    tabCurrentForm[fieldIndex] = updatedField;
    currentTab[mainIndex].form[formIndex] = tabCurrentForm;
    stateForm = [
      ...stateForm.slice(0, index),
      currentTab,
      ...stateForm.slice(index + 1),
    ];
  }

  return stateForm;
};

export const addDynamicForm = (state: any, action: any) => {
  const updatedForm = JSON.parse(JSON.stringify([...state.form]));

  let tabData = {
    ...updatedForm[action.payload.index][action.payload.mainIndex],
  };
  let tabDatalength = updatedForm[action.payload.index][action.payload.mainIndex].form.length;

  let tabForm = [...tabData.form];

  const finalLength = (tabDatalength == 1) ? tabDatalength + 1 : parseInt(tabForm[tabDatalength - 1][0].name.split('-')[1]) + 1;
  let newForm = tabForm[0].map((field: any) => ({
    ...field,
    name: `${field['name'].split('-')[0]}-${finalLength}`,
    fieldId: null,
    value: "",
    error: "",
  }));

  tabData = { ...tabData, form: [...tabForm, newForm] };
  updatedForm[action.payload.index][action.payload.mainIndex] = tabData;
  return { ...state, form: updatedForm };
};

export const removeDynamicForm = (state: any, action: any) => {
  const { payload } = action;

  // if (!payload || typeof payload.tabIndex === 'undefined' || typeof payload.mainIndex === 'undefined' || typeof payload.formIndex === 'undefined') {
  //   console.error("Invalid action payload. Missing necessary properties.");
  //   return state;
  // }

  const updatedForm = JSON.parse(JSON.stringify(state.form));
  const tabIndex = payload.tabIndex;
  const mainIndex = payload.mainIndex;
  const formIndex = parseInt(payload.formIndex, 10);
  // if (isNaN(formIndex) || formIndex < 0) {
  //   console.error("Invalid formIndex. Unable to remove form.");
  //   return state;
  // }

  // if (tabIndex >= updatedForm.length || mainIndex >= updatedForm[tabIndex].length) {
  //   console.error("Invalid tabIndex or mainIndex. Unable to remove form.");
  //   return state;
  // }

  const tabData = JSON.parse(JSON.stringify(updatedForm[tabIndex]));
  const dynamicForms = JSON.parse(JSON.stringify(tabData[mainIndex]));

  let formsToUpdate = [...dynamicForms.form];
  // if (formIndex >= formsToUpdate.length) {
  //   console.error("Invalid formIndex. Unable to remove form.");
  //   return state;
  // }

  formsToUpdate.splice(formIndex, 1);
  dynamicForms.form = formsToUpdate;
  tabData[mainIndex] = dynamicForms;
  updatedForm[tabIndex] = tabData;

  return { ...state, form: updatedForm };
};

export const updateTabErrors = (state: any, action: any) => {
  return {
    ...state,
    tabs: state.tabs.map((tab: any, index: any) => ({
      ...tab,
      error:
        index === action?.payload?.index ? action?.payload?.value : tab.error,
    })),
  };
};

export const updateFieldDependency = (state: any, action: any) => {
  state.form[action.payload.index] = action.payload.value;
  return { ...state, form: state.form };
};
export const competenceType = (state: any, action: any) => {
  state.form[2][0].value = action.payload.competence;
  return { ...state, form: state.form };
};

export const manageIndex = (state: any, action: any) => {
  let currentTab = state.currentTab;
  let dynamicIndex = action.payload.dynamicIndex;
  const formData = state.form?.[currentTab]?.[dynamicIndex]?.form;


  let currentFormIndex =
    state.form?.[currentTab]?.[dynamicIndex]?.currentFormIndex;
  if (action.payload.action === "delete" && formData.length > 0) {


    const indexToDelete = action.payload.index;
    const tempdata = formData.filter(
      (item: any, index: number) => index !== indexToDelete
    );


    currentFormIndex = String(tempdata.length - 1);
    if (state.form?.[currentTab]?.[dynamicIndex]?.optionsDependency) {
      const dependentField = state.form?.[currentTab]?.[dynamicIndex]?.optionsDependency;
      const dependencyIndex = state.form?.[currentTab]?.[dynamicIndex]?.dependencyIndex;
      const categories = tempdata.filter((eachItem: any) => eachItem[0].value !== "")
        .map((eachItem: any, ind: number) => ({
          value: ind + 1,
          label: eachItem[0].value
        }));
      const fields = state.form?.[currentTab]?.[dependentField]?.form;
      const forms = fields.map((eachItem: any, index: number) => {
        return eachItem.map((Item: any, key: number) => {
          if (key == dependencyIndex) {
            return { ...Item, options: categories, value: { value: '', label: 'Select' } };
          }
          return Item
        });
      });
      state.form[currentTab][dependentField].form = forms;
    }
    return {
      ...state,
      form: state.form.map((tab: any, tabIndex: number) => {
        if (tabIndex === currentTab) {
          return tab.map((item: any, index: number) => {
            if (index == dynamicIndex) {
              return {
                ...item,
                form: tempdata,
                currentFormIndex,
              };
            }
            return item;
          });
        } else {
          return tab;
        }
      }),
    };
  } else if (action.payload.action === "edit" && formData.length > 0) {
    let dynamicForm = [...state.form[currentTab][dynamicIndex].form];
    const indexToUpdate = action.payload.index;
    const lastIndex = dynamicForm.length - 1;
    dynamicForm[lastIndex] = dynamicForm[indexToUpdate];


    let currentFormIndex: any = null;
    dynamicForm?.splice(indexToUpdate, 1);
    if (!state.form[currentTab][dynamicIndex].isEdit) {
      currentFormIndex = lastIndex - 1;
    }
    else {
      dynamicForm = [state.form[currentTab][dynamicIndex].form[lastIndex], ...dynamicForm]
      currentFormIndex = dynamicForm.length - 1;
    }


    return {
      ...state,
      form: state.form.map((tab: any, tabIndex: number) => {
        if (tabIndex === currentTab) {
          return Object.values(tab).map((item: any, index: number) => {
            if (index == dynamicIndex) {
              return {
                ...item,
                form: dynamicForm,
                currentFormIndex: String(currentFormIndex),
                isEdit: true,
              };
            }
            return item;
          });
        } else {
          return tab;
        }
      }),
    };
  }


  // state.form[currentTab][dynamicIndex].isEdit = false;
  // state.form[currentTab][dynamicIndex].currentFormIndex = action.payload.index;


  const currentTabData = state.form?.[currentTab]?.[dynamicIndex]?.optionsDependency;
  const isEdit = state.form?.[currentTab]?.[dynamicIndex]?.isEdit;
  state.form[currentTab][dynamicIndex].isEdit = false;
  state.form[currentTab][dynamicIndex].currentFormIndex = action.payload.index;

  if (state.form?.[currentTab]?.[dynamicIndex]?.optionsDependency) {
    const dependentField = state.form?.[currentTab]?.[dynamicIndex]?.optionsDependency;
    const dependencyIndex = state.form?.[currentTab]?.[dynamicIndex]?.dependencyIndex;
    const categories = formData.filter((eachItem: any) => eachItem[0].value !== "")
      .map((eachItem: any, ind: number) => ({
        value: ind + 1,
        label: eachItem[0].value
      }));
    const fields = state.form?.[currentTab]?.[dependentField]?.form;
    const currentFormIndex = state.form[currentTab][dynamicIndex].currentFormIndex
    const forms = fields.map((eachItem: any, index: number) => {
      return eachItem.map((Item: any, key: number) => {
        if (key == dependencyIndex) {
          let val = null;
          if (isEdit) {
            const catValue = categories[currentFormIndex - 1];
            const currentValue = (catValue?.value == Item.value?.value) ? catValue : Item?.value;
            val = currentValue;
          }
          else {
            val = Item.value;
          }
          const activeValue = (index == fields.length - 1) ? '' : val;
          //const activeValue = (index == fields.length - 1) ? '' : Item.value;
          return { ...Item, options: categories, value: activeValue };
        }
        return Item
      });
    });
    // state.form[currentTab][dynamicIndex].isEdit = false;
    // state.form[currentTab][dynamicIndex].currentFormIndex = action.payload.index;
    state.form[currentTab][dependentField].form = forms;
  }


  return { ...state, form: state.form };
};


export const competenceSelected = (state: any, action: any) => {
  const selection = action.payload.selection;
  const selectedData = action.payload.selected;
  const selectedId = action.payload.id

  let updatedForm = [...state.form];
  if (selectedData.length === 0) {
    updatedForm[state.currentTab][0].value = updatedForm[
      state.currentTab
    ][0].value.filter((item: { key: any }) => item.key !== selection);
  } else {
    const existingSelection = updatedForm[state.currentTab][0].value.find(
      (item: { key: any }) => item.key === selection
    );
    if (existingSelection) {
      existingSelection.options = selectedData;
    } else {
      const newSelection = {
        competence_id: selectedId,
        key: selection,
        label: selection,
        options: selectedData,
      };
      updatedForm[state.currentTab][0].value.push(newSelection);
    }
  }
  const updatedState = { ...state, form: updatedForm };
  return updatedState;
};
// Reducer function to handle COMPETENCE_RANGE action
export const competenceRange = (state: any, action: any) => {
  const { value, section, index } = action.payload;
  let updatedForm = [...state.form];
  updatedForm[state.currentTab][0].value.forEach((item: any) => {
    if (item.key === section && item.options[index]) {
      item.options[index].range = value;
    }
  });
  const updatedState = { ...state, form: updatedForm };
  return updatedState;
};

export const updateSalaryBenefit = (state: any, action: any) => {
  const salaryBenefitForms = action.payload.value;

  {/*Salary benefit will be 5th tab always*/ }
  let currentTab = 4;
  const updatedFormEntry = {
    ...state.form[currentTab][0],
    form: salaryBenefitForms,
  };
  const updatedForm = [
    ...state.form.slice(0, currentTab),
    [updatedFormEntry],
    ...state.form.slice(currentTab + 1),
  ];
  let result = {
    ...state,
    form: updatedForm,
  };
  return result;
};

export const updateSalaryBenefitFields = (state: any, action: any) => {
  const { index, field, key, value } = action.payload;
  let form: any = state.form[action.payload.index];
  let salarBenefitForm = form[0].form;
  const fieldObject = salarBenefitForm[key];
  fieldObject[field] = value;
  return { ...state, form: state.form };
};

export const updateTableDataInPopUp = (state: any, action: any) => {
  const { index, currentData, clearPopup } = action.payload;

  let form = { ...state.form[index][0] };
  form.editModal = clearPopup == true ? false : true;
  form.currentData = currentData;

  if (Object.keys(currentData).length > 0) {
    const updatedForm = state.form[index][0].form[0].map((eachEle: any) => {
      return { ...eachEle, value: currentData[eachEle.alias] ?? null }
    });
    form.form = [updatedForm]
  }

  return {
    ...state,
    form: [
      ...state.form.slice(0, index),
      [form],
      ...state.form.slice(index + 1)
    ]
  };
}

export const updatePreviousTableData = (state: any, action: any) => {
  const { data, index } = action.payload;
  console.log(data);
  const updatedForm = { ...state.form[index][0] };
  updatedForm.data = data;
  updatedForm.currentData = [];
  updatedForm.editModal = false;
  updatedForm.deleteModal = false;

  return {
    ...state,
    form: [
      ...state.form.slice(0, index),
      [updatedForm],
      ...state.form.slice(index + 1)

    ]
  }
}

export const updateStateWithEmptyValues = (state: any, action: any) => {
  const { index } = action.payload;
  const updatedForm = { ...state.form[index][0] };
  updatedForm.editModal = false;
  updatedForm.form = updatedForm.form.map((eachItem: any) => {
    return eachItem.map((eachEle: any) => {
      return { ...eachEle, value: '', error: '' };
    });
  });
  return {
    ...state,
    form: [
      ...state.form.slice(0, index),
      [updatedForm],
      ...state.form.slice(index + 1)
    ]
  }
}

export const updateDeletePopUp = (state: any, action: any) => {
  const { index, currentData, deletePopUp } = action.payload;
  const updatedForm = { ...state.form[index][0] };
  updatedForm.deleteModal = deletePopUp;
  updatedForm.currentData = currentData;
  return {
    ...state,
    form: [
      ...state.form.slice(0, index),
      [updatedForm],
      ...state.form.slice(index + 1)
    ]
  }
}

export const updateStateAfterButtonClick = (state: any, action: any) => {
  const { currentTab, data } = action?.payload;

  return {
    ...state,
    form: [
      ...state.form.slice(0, currentTab),
      data,
      ...state.form.slice(currentTab + 1)
    ]
  }

}
