// import LabelField from "components/common/atoms/LabelField";
// import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Button from "components/atoms/Button";
import LabelField from "components/atoms/LabelField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import { Option } from "components/common/CommonInterfaces";
import PCTable from "./PCTable";
// import PCTable from "./PCTable";
// import { Option } from "components/common/utlis/TypeAnnotations";
// import Button from "components/common/atoms/Button";
// import { t, translate } from "components/CentralDataMangement/translation/Translation";

interface ParitairCommittee {
  pc: {
    id: number;
    label: string;
    value: Option | null;
    categoryId?: number; // Optional property for handling variations
  };
  employeeTypes: {
    id: number;
    label: string;
    value: Option | null; // Ensuring the property in the interface
    category?: number; // Optional property for handling variations
  }[];
}

interface BlueCollarSectionProps {
  optionsList: any[];
  selectedValue: Option | null;
  selectedEmpType: Option | null;
  handlePCFields: (e: React.ChangeEvent, name: string) => void;
  state: any; // Add the state type
  addParitiarComitee: (type: string) => void;
  pcChangeHandler: (id: number, type: string) => void;
  pcDeleteHandler: (id: number, type: string) => void;
  employeeTypes: any[];
  isBlueCollar: boolean;
  isWhiteCollar: boolean;
}

const BlueCollarSection: React.FC<BlueCollarSectionProps> = ({
  optionsList,
  selectedValue,
  selectedEmpType,
  handlePCFields,
  state,
  addParitiarComitee,
  pcChangeHandler,
  pcDeleteHandler,
  employeeTypes,
  isBlueCollar,
  isWhiteCollar,
}) => {
  const companyParitairCommittee: ParitairCommittee[] =
    state.companyParitairCommittee;
  const blueCollarPc = state.options.blueCollar;
  const blueEmployeeType = state.options.blueEmployeeType;

  const whiteCollarParriter: ParitairCommittee[] = [];
  const blueCollarParriter: ParitairCommittee[] = [];
  const bluePcError = state.employeeCoefficient.errors.bluePc;
  const blueEmployeeTypeError =
    state.employeeCoefficient.errors.blueEmployeeType;

  // Check if companyParitairCommittee is not empty
  if (companyParitairCommittee.length > 0) {
    companyParitairCommittee.forEach((item) => {
      if (item.pc.categoryId === 1) {
        whiteCollarParriter.push(item);
      } else if (item.pc.categoryId === 2) {
        blueCollarParriter.push(item);
      }
    });
  }
  return (
    <div
      className={`${
        isBlueCollar && isWhiteCollar && state.type !== 2
          ? " col-lg-6 col-12"
          : "col-xxl-12 col-12 mx-auto"
      }`}
    >
      <LabelField
        title={t("Paritair committee for blue collar")}
        className="tab-subtitle pb-1"
      />
      <PCTable
        data={state.employeeCoefficient.blueCollar}
        pcChangeHandler={pcChangeHandler}
        type="blueCollar"
        pcDeleteHandler={pcDeleteHandler}
      />
      {/* {true && (
        // <div className="text-danger">{state.fieldError.paritairBlue}</div>
      )} */}
      <div className="row">
        <div className={`col-6`}>
          <SelectWithSearch
            onChange={(value) => handlePCFields(value, "PC2")}
            title={t("Paritair committee (PC) 2")}
            placeHolder={t("Select")}
            search={true}
            options={optionsList}
            value={selectedValue}
            isDisabled={false}
            isMulti={false}
            isMandatory={true}
            error={bluePcError}
            name={"PC2"}
            id={"PC2"}
          ></SelectWithSearch>
        </div>
        <div className={`col-6`}>
          <SelectWithSearch
            onChange={(e) => {
              handlePCFields(e, "employeeType2");
            }}
            title={t("Selection of employee types (statuut) that can be used")}
            placeHolder={t("Select")}
            search={true}
            options={blueEmployeeType}
            value={selectedEmpType}
            isDisabled={false}
            isMulti={true}
            isMandatory={true}
            error={blueEmployeeTypeError}
            name={"employeeType2"}
            id={"employeeType2"}
          ></SelectWithSearch>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button
            title={t("Add Paritair committee for blue collar")}
            handleClick={() => addParitiarComitee("blueCollar")}
            className="form-button float-end"
          />
        </div>
      </div>
    </div>
  );
};

export default BlueCollarSection;
