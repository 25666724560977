import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import LinkTo from "components/atoms/LinkTo";
import StatusComponent from "components/atoms/ManageStatus";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import TitleAtom from "components/atoms/Title";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import FilterOrganism from "components/organism/FilterOrganism";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_TIMEHSHEETS } from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { TimesheetsTableHeader } from "TableHeader";
import Title from "components/atoms/Title";

const ManageTimesheetOverviewOrganism: React.FC = () => {
    const navigate = useNavigate();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true,
        data: [],
        options: {
            businessUnits: [],
            status: [
                {value: 1, label: "To be approved"},
                {value: 2, label: "To be invoiced"},
                {value: 3, label: "Approved"},
            ],
        },
    });

    const getSearchData = () => {
        return {
            businessUnits: history?.filters?.businessUnits ?? [],
            week: history?.filters?.week ?? "",
            month: history?.filters?.month ?? "",
            projectName: history?.filters?.projectName ?? "",
            employeeName: history?.filters?.employeeName ?? "",
            worksheet_no: history?.filters?.worksheet_no ?? "",
            invoice_no: history?.filters?.invoice_no ?? "",
            status: history?.filters?.status ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
            role: history?.filters?.role ?? []
        }
    };
    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall
        };
        fetchCandidates(postData);
    }, [pageData?.refresh, pageData?.initialCall]);

    const fetchCandidates = async (postData: any) => {
        const response = await ApiCall.service('managetimesheetOverview', "POST", postData, false, M_MASTER_DATA);
        if (response?.status === 200) {
            let data: any = {
                data: response?.data,
                totalPages: response?.totalPages,
                totalRecords: response?.totalRecords,
                initialCall: false,
                options: pageData?.options,
            }
            if (pageData?.initialCall) {
                data.options = { ...data?.options, ...(response?.options ?? []) }
            }
            setPageData(data);
        }
    };

    // const handleClick = (id: number | undefined) => {
    //     if (id) {
    //         navigate(`${PATH_CANDIDATE_CORNER}/${id}?source=candidate`);
    //     }
    // }

    const getColorClass = (item: any) => {
        if (item?.invoice_no) {
            return "";
        }
        return "table_left_side_color";
    }
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    const filters = [
        { name: 'businessUnits', fieldType: "multiSelect", options: pageData?.options?.businessUnits, placeholder: "Business unit", filterType: 'search' },
        { name: 'month', fieldType: "text", placeholder: "Month", filterType: 'search' },
        { name: 'week', fieldType: "text", placeholder: "Week", filterType: 'search' },
        { name: 'projectName', fieldType: "text", placeholder: "Project name", filterType: 'search' },
        { name: 'employeeName', fieldType: "text", placeholder: "Employee name", filterType: 'search' },
        { name: 'worksheet_no', fieldType: "text", placeholder: "Worksheet number", filterType: 'search' },
        { name: 'invoice_no', fieldType: "text", placeholder: "Invoice number", filterType: 'search' },        
        { name: 'status', fieldType: "singleSelect", options: pageData?.options?.status, placeholder: "Status", filterType: 'search' },
    ];

    return (
        <>
            <div className="search-bar">
                <Title title={t("Manage timesheets")} />
            </div>
            <div className="position-relative tableMainWrapper">
                <div className="row">
                    <div className="ManageCreateBtn">
                        <LinkTo
                            pagelink={PATH_MANAGE_TIMEHSHEETS}
                            title={t("Create timesheet")}
                            icon={faPlus}
                        />
                    </div>
                </div>
                <div className="table-responsive Manage-timesheet tableSection">
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        handleRefresh={handleRefresh}
                    />
                    <div className="TableHeight">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    {TimesheetsTableHeader?.map((header: any) => (
                                        <th key={header?.alias}>
                                            <>
                                                {t(header.name)}
                                                {header?.issort && (
                                                    <SortAtomForManagePage
                                                        value={{ alias: header?.alias }}
                                                        dispatch={dispatch}
                                                        history={history}
                                                        updatePageFilters={updatePageFilters}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                )}
                                            </>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {pageData?.data?.length > 0 ? (
                                    pageData?.data?.map((item: any) => (
                                        <tr key={item?.timesheet_id} className={ "cursor-pointer " +getColorClass(item)}>
                                            <td>{item?.businessUnits}</td>
                                            <td>{item?.month}</td>
                                            <td>{item?.week}</td>
                                            <td>{item?.projectName}</td>
                                            <td>{item?.employeeName}</td>
                                            <td>{item?.worksheet_no}</td>
                                            <td>{item?.invoice_no}</td>
                                            <td><StatusComponent initialStatus={item?.worksheet_no ? 1 : 2} tooltip={item?.worksheet_no ? item?.worksheet_no : ""} /></td>
                                            <td><StatusComponent initialStatus={item?.invoice_no ? 1 : 2} tooltip={item?.invoice_no ? item?.invoice_no : ""} /></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="border">
                                        <td colSpan={6} className="border-0 text-center py-3">
                                            <span className="text-danger fw-bold">{t("No records")}</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <PaginationWithPerPage
                            handleRefresh={handleRefresh}
                            dispatch={dispatch}
                            history={history}
                            pageData={pageData}
                        />
                    </div>

                </div>

            </div>
            <div className="col-md-6 align-self-center my-3">
                <BackButton />
            </div>
        </>
    );
};

export default ManageTimesheetOverviewOrganism;
