import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps } from "utils/TypeAnnotations";
import { useEffect, useState } from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "utils/TypeAnnotations";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import InputTextfield from "components/atoms/InputTextField";
import RadioField from "components/atoms/RadioField";
import CustomNotify from "components/atoms/CustomNotify";
import './manageExtras.css';
import { scrollToTop } from "services/validation/ValidationService";
import { M_COMPANY_CREATION } from '../../../../../constants/Constants';
import BackButton from "components/atoms/BackButton";


interface ExtraProps {
  priceOrCoeff: Option | null;
  min: string;
  desired: string;
  max: string;
  negotiability: number | null;
  inclusiveOption: number | null;
  applicableType: string;
  minStatus: boolean;
  desiredStatus: boolean;
  maxStatus: boolean;
}

const priceOptions = [
  { value: 0, label: "Amount" },
  { value: 1, label: "Coefficient" },
];

const ManageExtras: React.FC = () => {
  const [extraInfoDetails, setExtraInfoDetails] = useState({
    extraInfo: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchExtraInfoDetails = async () => {
      const url = `getExtraInfo`;

      const response = await ApiCall.getService(url, "GET", M_COMPANY_CREATION, true);

      if (response.status === 200) {
        const extraInfo = response.data.map((item: any) => { //i.e id,name from extra_info table
          const editId = item.id; //get id from extra_info table
          const editInfoDetails = response.extraInfo.find((data: any) => data.extraInfoId === editId); //match with extra_info_id from extra_info_details table

          if (editInfoDetails) { //if id found through flter then data exist in extra_info_table with editId return that form data along with id,name 
            return {
              ...item,
              priceOrCoeff: { value: editInfoDetails.priceOrCoeff, label: editInfoDetails.priceOrCoeff === 0 ? "Amount" : "Coefficient" },
              min: editInfoDetails.min === "0" ? "" : editInfoDetails.min,
              desired: editInfoDetails.desired === "0" ? "" : editInfoDetails.desired,
              max: editInfoDetails.max === "0" ? "" : editInfoDetails.max,
              negotiability: editInfoDetails.negotiability,
              inclusiveOption: editInfoDetails.inclusiveOption,
              applicableType: editInfoDetails.applicableType === "0" ? "" : editInfoDetails.applicableType,
              minStatus: false,
              desiredStatus: false,
              maxStatus: false
            };
          }

          return {
            ...item,
            priceOrCoeff: null,
            min: "",
            max: "",
            desired: "",
            negotiability: null,
            inclusiveOption: null,
            applicableType: "",
            minStatus: false,
            desiredStatus: false,
            maxStatus: false

          }// If no matching extraInfo is found, return just id,name
        });

        setExtraInfoDetails({
          extraInfo: extraInfo,
        });

        // Here, you can set the formData with the extraInfo
        setFormData(extraInfo);
      }
    };

    fetchExtraInfoDetails();
  }, []);

  const [formData, setFormData] = useState<ExtraProps[]>([
    {
      priceOrCoeff: null,
      min: "",
      max: "",
      desired: "",
      negotiability: null,
      inclusiveOption: null,
      applicableType: "",
      minStatus: false,
      desiredStatus: false,
      maxStatus: false
    },
  ]);

  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();


  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setDirty(true);
    const { name, value, type } = e.target as HTMLInputElement;
    const newFields: ExtraProps[] = [...formData];
    let updatedValue: string | number | null | Option;
    let updatedName: string;

    if (type === "radio" && name.startsWith("negotiability")) {
      updatedName = "negotiability";
      updatedValue = parseInt(value);
      newFields[index] = {
        ...newFields[index],
        [updatedName]: updatedValue,
      };
      setFormData(newFields);

    } else if (type === "radio" && name.startsWith("inclusiveOption")) {
      updatedName = "inclusiveOption";
      updatedValue = parseInt(value);
      newFields[index] = {
        ...newFields[index],
        [updatedName]: updatedValue,
      };
      setFormData(newFields);

    } else if (name === 'min' || name === 'max' || name === 'desired'|| name === 'applicableType') {
      const newValue = value.replace(/[^0-9,]/g, "");
      const regex = /^(\d{1,2})(,\d{0,4})?$/;
      let matches = regex.exec(newValue);

      if (matches || newValue === "") {
        updatedName = name;
        updatedValue = newValue;
        newFields[index] = {
          ...newFields[index],
          [updatedName]: updatedValue,
        };
        setFormData(newFields);
      }

        const currentObject = newFields[index];
        const { min, desired, max } = currentObject;
        if (
          min !== null &&
          min !== "" &&
          max !== null &&
          max !== "" &&
          parseFloat(min.replace(',', '.')) >= parseFloat(max.replace(',', '.'))
        ) {
          newFields[index]["minStatus"] = true;
        } else {
          newFields[index]["minStatus"] = false;
        }

        if (
          desired !== null &&
          min !== null &&
          max !== null &&
          desired !== "" &&
          min !== "" &&
          max !== "" &&
          (parseFloat(desired.replace(',', '.')) <= parseFloat(min.replace(',', '.')) || parseFloat(desired.replace(',', '.')) >= parseFloat(max.replace(',', '.')))
        ) {
          newFields[index]["desiredStatus"] = true;

        } else {
          newFields[index]["desiredStatus"] = false;

        }
        setError(hasTrueValue(newFields));

    }

  };

  const hasTrueValue = (formData:any) => {
    for (const item of formData) {
     
      if (item.minStatus || item.desiredStatus) {
        return true; // Return true if either minStatus or desiredStatus is true
      }
    }
    return false; // Return false if neither minStatus nor desiredStatus is true
  };
  
  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    setDirty(true);
    const newFormData: ExtraProps[] = [...formData];

    if (selectedOption.value === '' || selectedOption.value === 0 || selectedOption.value === 1) {
      newFormData[index] = {
        ...newFormData[index],
        min: "",
        max: "",
        desired: "",
        negotiability: null,
        inclusiveOption: null,
        applicableType: ""
      };

    }
    newFormData[index] = {
      ...newFormData[index],
      [fieldName]: selectedOption,
    };
    setFormData(newFormData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = formData.map((item, index) => ({
      
      ...item,
      extraInfoId: extraInfoDetails.extraInfo[index].id,
    }));
    if (!hasTrueValue(data) && dirty) {
      const response = await ApiCall.service(
        'updateExtraInfo',
        "POST",
        data,
        false,
        M_COMPANY_CREATION
      );
      if (response.status === 200) {
        navigate('/config/settings');
        CustomNotify({ type: "success", message: response.msg });
      }
    } else {
      scrollToTop();
      let msg = !dirty ? 'Please update the fields!!' : 'Please correct the errors as per error message shown';
      CustomNotify({ type: "error", message: msg, autoClose: 2000 });
    }
    setDirty(false);
  };



  return (
    <>
      <div className="row header-sticky position-sticky">
        <div className="col-md-12">
          <h1 className="py-4 page-title mb-0">
            Manage extras
          </h1>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="form-height">
        {error && (
          <div className="text-danger error-coefficients">
            <span>
            Please change the highlighted values, minimum value should be less than maximum value.
            </span>
            <br />
            <span>
            The desired value should be in between minimum and maximum values.
            </span>
          </div>
        )}
        <div className="table-responsive manage-extras">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                <th className="text-break">Title</th>
                <th className="text-break">Price / Coeff</th>
                <th className="text-break">Min</th>
                <th className="text-break">Desired</th>
                <th className="text-break">Max</th>
                <th className="text-break">Negotiable</th>
                <th className="text-break">Inclusive</th>
                <th className="text-break">Applicable amount/coefficient</th>
              </tr>
            </thead>
            <tbody>
              {extraInfoDetails.extraInfo.map((info, index) => (
                <tr key={index} className="align-middle border mb-3 box-shadow align-middle">
                  <td className="text-break" data-label="Title">{info.name}</td>
                  <td className="text-break price-coeff" data-label="Price / Coeff">
                    <SelectWithSearch
                      search={true}
                      options={priceOptions}
                      placeHolder="Select"
                      onChange={(e) => handleSelectChange(e, "priceOrCoeff", index)}
                      isMulti={false}
                      className="select-field"
                      name="price"
                      value={formData[index].priceOrCoeff}
                      isMenuPlacement = {index > 4 ? true : false}
                    />
                  </td>
                  <td className="text-break" data-label="Min">
                    <div className="input-group">
                      <InputTextfield
                        name="min"
                        handleChange={(event) => changeHandler(event, index)}
                        value={formData[index].min}
                        id={`min-${index}`}
                        type="text"
                        readOnly={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}
                        className={`form-control shadow-none rounded-0 ${formData[index].minStatus ? "extraBorder" : ""
                          }`}
                      />
                      {formData[index]?.priceOrCoeff?.value === 0 && (
                        <span className="input-group-text rounded-0" id="basic-addon1">€</span>
                      )}
                    </div>
                  </td>
                  <td className="text-break" data-label="Def">
                    <div className="input-group">
                      <InputTextfield
                        name="desired"
                        handleChange={(event) => changeHandler(event, index)}
                        value={formData[index].desired}
                        id={`desired-${index}`}
                        type="text"
                        readOnly={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}
                        className={`form-control shadow-none rounded-0 ${formData[index].desiredStatus ? "extraBorder" : ""}`}
                      />
                      {formData[index]?.priceOrCoeff?.value === 0 && (
                        <span className="input-group-text rounded-0" id="basic-addon1">€</span>
                      )}
                    </div>
                  </td>
                  <td className="text-break" data-label="Max">
                    <div className="input-group">
                      <InputTextfield
                        name="max"
                        handleChange={(event) => changeHandler(event, index)}
                        value={formData[index].max}
                        id={`max-${index}`}
                        type="text"
                        readOnly={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}
                        className="form-control shadow-none rounded-0"
                      />
                      {formData[index]?.priceOrCoeff?.value === 0 && (
                        <span className="input-group-text rounded-0" id="basic-addon1">€</span>
                      )}
                    </div>
                  </td>
                  <td className="text-break" data-label="Nego / Non neg">
                    <RadioField
                      name={`negotiability-${index}`}
                      id={`negotiability-yes-${index}`}
                      value={0}
                      ischecked={formData[index].negotiability === 0}
                      handleChange={(event) => {
                        changeHandler(event, index);
                      }}
                      label="Yes"
                      disable={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}

                    />
                    <RadioField
                      name={`negotiability-${index}`}
                      id={`negotiability-no-${index}`}
                      value={1}
                      ischecked={formData[index].negotiability === 1}
                      handleChange={(event) => {
                        changeHandler(event, index);
                      }}
                      label="No"
                      className="ms-2"
                      disable={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}

                    />
                  </td>
                  <td className="text-break" data-label="Inclu / Exclue">
                    <RadioField
                      name={`inclusiveOption-${index}`}
                      id={`inclusiveOption-yes-${index}`}
                      value={0}
                      ischecked={formData[index].inclusiveOption === 0}
                      handleChange={(event) => {
                        changeHandler(event, index);
                      }}
                      label="Yes"
                      disable={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}

                    />
                    <RadioField
                      name={`inclusiveOption-${index}`}
                      id={`inclusiveOption-no-${index}`}
                      value={1}
                      ischecked={formData[index].inclusiveOption === 1}
                      handleChange={(event) => {
                        changeHandler(event, index);
                      }}
                      label="No"
                      className="ms-2"
                      disable={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}

                    />
                  </td>
                  <td className="text-break pb-3 pb-lg-0" data-label="Applicable/amount/coefficient">
                    <div className="input-group">
                      <InputTextfield
                        name="applicableType"
                        handleChange={(event) => changeHandler(event, index)}
                        value={formData[index].applicableType}
                        id={`applicableType-${index}`}
                        type="text"
                        readOnly={formData[index]?.priceOrCoeff?.value !== 0 && formData[index]?.priceOrCoeff?.value !== 1}
                        className="form-control shadow-none rounded-0"
                      />
                      {formData[index]?.priceOrCoeff?.value === 0 && (
                        <span className="input-group-text rounded-0" id="basic-addon1">€</span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row mb-3">
          <div className="col-md-4 align-self-center">
            <BackButton />
          </div>
          <div className="col-md-8">
            <Button
              title="submit"
              type="submit"
              className="btn form-button float-end text-uppercase rounded-0 shadow-none"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ManageExtras;
