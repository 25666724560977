import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface GeneralMoleculeProps {
  unit?: any;
  field: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const GeneralMolecule: React.FC<GeneralMoleculeProps> = ({
  name,
  value,
  unit,
  disabled,
  error,
  handleChange,
  field,
}) => {

  const renderField = () => {
    switch (field?.field_name) {
      case "Template name":
        return (
          <div className="col-12">
            <div className="row">
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                unit={unit}
                type="text"
                readOnly={false}
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
              />
            </div>
          </div>
        );
      case "Total amount":
      case "Number of months":
      case "Time period":
        return (
          <>
            {field?.field_name === "Total amount" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                unit={unit}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
              />
            )}

            {field?.field_name === "Number of months" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                unit={unit}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
              />
            )}

            {field?.field_name === "Time period" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                unit={unit}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <>{renderField()}</>;
};

export default GeneralMolecule;
