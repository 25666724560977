import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

interface LinkToProps {
  pagelink: string; // Change to mandatory string type
  title?: string;
  icon?: IconProp;
  className?: string;
  state?: any; // Change type to 'any' or a specific type that matches your state data

}

const LinkTo: React.FC<LinkToProps> = ({ pagelink, title, icon, className, state }) => {
  return (
    <>
      {pagelink && (
        <Link
          to={pagelink}
          className={`${className} form-button float-end d-flex align-items-center text-decoration-none`}
          state={state}
        >
          {title}
          {icon &&
            <span className='ms-3'>
              <FontAwesomeIcon icon={icon} />
            </span>
          }
        </Link>
      )}
    </>
  );
};

export default LinkTo;