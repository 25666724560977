import React from "react";
import { SET_COMPETENCE_DATA } from "../../annotations/CandidateConstants";
import { ApiCall } from "services/ApiServices";
import { VACANCY_EDIT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";

export const SetCompetenceData = async (
  dispatch: any,
  state: any,
  data: any
) => {
  const competenceResponse = await ApiCall.service(
    VACANCY_EDIT,
    "POST",
    data,
    true,
    M_MASTER_DATA
  );
  dispatch({
    type: SET_COMPETENCE_DATA,
    payload: competenceResponse.data?.Competence,
  });
};
