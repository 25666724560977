import { float } from "aws-sdk/clients/cloudfront";
import { ChangeEvent } from "react";
import { OptionProps } from "react-select";
import { Option } from "utils/TypeAnnotations";

export interface PartenaData {
  id: number;
  month: number;
  businessUnit: number;
  noOfEmployees: number;
  numberSent: number;
  quantityNotSent: number;
  createdBy: string;
  creationTime: string;
  status: string;
}

export const initialExportSearch = {
  month: "",
  businessUnit: "",
  numberOfEmployees: "",
  numberSent: "",
  quantityNotSent: "",
  created_by: "",
};

// export interface BusinessUnitProps {
//   value: number;
//   label: string;
// }

export interface FormDataProps {
  month: string;
  year: number;
  business_units: OptionProps[];
  created_by: number|null;
  name: string;
}

export const initialAddPartenaData = {
  year: new Date().getFullYear(),
  business_units: [],
  month: "select",
  created_by: null,
  name: ""
};

export interface CalculationSearchProps {
  month: string;
  businessUnit: string;
  createdBy: string;
  creationTime: string;
}

export interface EmployeeDataProps {
  id: number | null;
  name: string;
  exportedBy: string;
  exportedTime: string;
  exportedFile: string;
}

export interface AccomplishmentDataProps {
  accomplishData: AccomplishData[];
  handleChange: (event: ChangeEvent<HTMLInputElement>, field?: string) => void;
  accomplishSearchData: { [key: string]: string | undefined };
  dropdownData: Option[];
}
export interface AccomplishData {
  id: number | null;
  employeeName: string;
  date: string;
  department: number | null;
  type: string;
  wageCode: number | null;
  shiftCode: number | null;
  hours: float | null;
}

export const initialCalculationSearch = {
  month: "",
  businessUnit: "",
  createdBy: "",
  creationTime: "",
};

export const initialEmployeeData = {
  id: null,
  name: "",
  exportedBy: "",
  exportedTime: "",
  exportedFile: "",
};

export const initialAccomplishmentData = {
  id: null,
  employeeName: "",
  date: "",
  department: null,
  type: "",
  wageCode: null,
  shiftCode: null,
  hours: null,
};

export const initialAccomplishSearchData = {
  employeeName: "",
  date: "",
  department: "",
  all: undefined,
  wageCode: "",
};
