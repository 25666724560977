import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from 'services/AccessControl';
import { sideBarItems } from 'pages/dashboards/DashboardConstants';
import { t } from 'pages/microservices/masterData/translation/Translation';

interface HeaderMenusProps {
  isExpanded: boolean;
}

const HeaderMenus: React.FC<HeaderMenusProps> = ({ isExpanded }) => {
  const [activeMenu, setActiveMenu] = useState<string>("dashboard");
  const userAuth = useSelector(selectAuth);
  const tiles = sideBarItems(userAuth);

  const handleSetActiveMenu = (menu: string) => {
    setActiveMenu(menu);
  };

  return (
    <div className='scrollbar leftMenuWrapper'>
      <ul className="list-unstyled menuWrapper">
        {tiles && tiles.map((item: any) => (
          <li
            key={item.id}
            className={`sidebar-menu mb-3 links ${activeMenu === item.id ? "active" : ""}`}
            onClick={() => handleSetActiveMenu(item.id)}
          >
            <AccessControl
              requiredPermissions={item?.requiredPermissions}
              override={userAuth?.isSuperAdmin}
            >
              <Link to={item.path} title={t(item.name)} className="d-inline-flex align-items-center">
                <div className="icon d-inline-block">
                  <div className="dashboard-icon">
                    {item.icon}
                  </div>
                </div>
                {/* {/ Show nav-text only when isExpanded is true /} */}
                {isExpanded && (
                  <span className="nav-text ms-3 d-inline-block">{t(item.name)}</span>
                )}
              </Link>
            </AccessControl>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderMenus;
