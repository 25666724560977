import React, { useState, useEffect } from "react";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { TIMESHEET_FILTERS } from "../constants/timesheetConstants";
import DatePicker from "react-multi-date-picker";
import {
  useWeekAndDay,
  useWeekAndDayDispatch,
} from "../timesheets-redux/context/WeekAndDayContext/WeekAndDayContext";
import { getFilterOptions, getWeekDays } from "../utils/utils";
import { dateFormat } from "../utils/utils";
import { WeekAndDayActions } from "../timesheets-redux/actions/WeekAndDayActions";
import {
  useProject,
  useProjectDispatch,
} from "../../timesheets/timesheets-redux/context/ProjectContext/ProjectContext";
import {
  useFilterOptions,
  useFilterOptionsDispatch,
} from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";
import { PROJECT_ACTIONS } from "../timesheets-redux/actions/ProjectActions";
import { t } from "pages/microservices/masterData/translation/Translation";
import { getList } from "pages/microservices/planning/utils/utils";
import SearchBtn from "components/atoms/SearchBtn";

const TimesheetsFilters = () => {
  const { projectDispatch } = useProjectDispatch();
  const { dispatchWeekAndDay } = useWeekAndDayDispatch();
  const { filterOptionsState } = useFilterOptions();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const { initialWeekAndDayPropsState } = useWeekAndDay();
  const [filterData, setFilterData] = useState({
    companies: [],
    projects: [],
    businessUnits: [],
    companyProjects: [],
    projectBusinessUnits: [],
    companyBusinessUnits: [],
    employees: [],
  });
  const [timsheetFilters, settimsheetFilters] = useState(TIMESHEET_FILTERS);
  const [filterDate, setFilterDate] = useState(new Date());

  useEffect(() => {
    getFilterOption();
  }, []);

  const getFilterOption = async () => {
    try {
      const response = await getFilterOptions({ employees: true });
      if (response?.status === 200) {
        let project = {
          ...response?.data,
          projects: getList(response?.data.projects, "status", 2),
          companies: getList(response?.data.companies, "status", 1),
        };
        updateBulkFilterOptions(
          project,
          TIMESHEET_FILTER_ACTIONS.BULK_UPDATE_TIMESHEET_OPTIONS_STATE
        );
        setFilterData(project);
        projectDispatch({
          type: PROJECT_ACTIONS.SET_PROJECTS,
          payload: { projects: project?.projects },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChangeForProjectAndCompany = (value, type) => {
    settimsheetFilters((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [type]: value,
      },
    }));
    switch (type) {
      case "company":
        let selectedCompanyIds = [];
        let projectlist = filterOptionsState.state.projects;
        if (value.length > 0) {
          selectedCompanyIds = value.map((company) => company.value);
          const filteredProjects = filterData.companyProjects
            .filter((project) =>
              selectedCompanyIds.includes(project.company_id)
            )
            .map((project) => project.project_id);
          projectlist = filterOptionsState.state.projects.filter((project) =>
            filteredProjects.includes(project.value)
          );
        }
        updateFilterOptions("company_id", selectedCompanyIds);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          projects: projectlist,
        }));
        break;

      case "project":
        let selectedProjectIds = [];
        let filteredBusinessUnits = [];
        if (value.length > 0) {
          selectedProjectIds = value.map((project) => project.value);
          filteredBusinessUnits = filterData.projectBusinessUnits
            .filter((bu) => selectedProjectIds.includes(bu.project_id))
            .map((bu) => bu.bu_id);
        }
        updateFilterOptions("project_id", selectedProjectIds);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          businessUnits: filterOptionsState.state.businessUnits.filter((bu) =>
            filteredBusinessUnits.includes(bu.value)
          ),
        }));
        break;

      case "business_unit":
        let selectedbuIds = [];
        let companylist = filterOptionsState.state.companies;
        if (value.length > 0) {
          selectedbuIds = value.map((bu) => bu.value);
          const companyidslist = value.map((bu) => bu.companyid);
          companylist = filterOptionsState.state.companies.filter((company) =>
            companyidslist.includes(company.value.toString())
          );
        }
        updateFilterOptions("businessUnits", selectedbuIds);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          companies: companylist,
        }));
        break;
      case "employee":
        let selectedemployees = [];
        if (value.length > 0) {
          selectedemployees = value.map((employee) => employee.value);
        }
        updateFilterOptions("employeesid", selectedemployees);
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (value, type, isMultiSelect) => {
    switch (type) {
      case "employee":
      case "company":
      case "project":
      case "business_unit":
        handleSelectChangeForProjectAndCompany(value, type);
        break;
    }
  };

  const handleSearch = async () => {
    const date = new Date(filterDate);
    dispatchWeekAndDay({
      type: WeekAndDayActions.CHANGE_YEAR,
      payload: date,
    });
    const filters = await getProjectsAndEmployees();
    const payload = {
      employees: true,
      planningPayload: {
        ...filterOptionsState?.filteredState,
        strict: true,
      },
    };
    const response = await getFilterOptions(payload);
    projectDispatch({
      type: PROJECT_ACTIONS.SET_PROJECTS,
      payload: {
        projects:
          response?.data.projects?.length > 0
            ? getList(response?.data.projects, "status", 2)
            : filters?.projects,
      },
    });
  };

  const updateFilterOptions = (fieldname, value) => {
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.UPDATE_TIMESHEET_SELECTED_FIELD,
      payload: {
        field: fieldname,
        value: value,
      },
    });
  };

  const updateBulkFilterOptions = (list, type) => {
    dispatchFilterOptions({
      type: type,
      payload: list,
    });
  };

  const getProjectsAndEmployees = async () => {
    const filters = {
      projects:
        timsheetFilters?.state?.project?.length > 0
          ? timsheetFilters?.state?.project
          : filterData?.projects,
    };
    return filters;
  };

  return (
    <div className="container-fluid ps-1 pe-3">
      <div className="row">
        <div className="col-2">
          <SelectWithSearch
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "business_unit", false)
            }
            placeHolder={t("BU")}
            options={filterData.businessUnits}
            value={timsheetFilters.state.business_unit}
            isDisabled={false}
            isMulti={true}
            name={"business_unit"}
            id={"business_unit_planning_filter_id"}
          />
        </div>
        <div className="col-2">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "company", false)}
            placeHolder={t("Company")}
            options={filterData.companies}
            value={timsheetFilters.state.company}
            isDisabled={false}
            isMulti={true}
            name={"company"}
            id={"company_planning_filter_id"}
          />
        </div>
        <div className="col-2">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "project", false)}
            placeHolder={t("Project")}
            options={filterData.projects}
            value={timsheetFilters.state.project}
            isDisabled={false}
            isMulti={true}
            name={"project"}
            id={"project_planning_filter_id"}
          />
        </div>
        <div className="col-2">
          <SelectWithSearch
            search={true}
            onChange={(value) => handleSelectChange(value, "employee", false)}
            placeHolder={t("Employees")}
            options={filterData.employees}
            value={timsheetFilters.state.employee}
            isDisabled={false}
            isMulti={true}
            name={"employee"}
            id={"employees_planning_filter_id"}
          />
        </div>
        <div className="col-2">
          <DatePicker
            inputClass="form-control field-shadow"
            name="date"
            placeholder={"mm-yyyy"}
            value={filterDate}
            onChange={(date) => {
              const parsedDate = new Date(date);
              if (!isNaN(parsedDate)) {
                setFilterDate(parsedDate);
              }
            }}
            format="MM-YYYY"
            onlyMonthPicker
            minDate={new Date(2014, 0, 1)}
            zIndex={1000}
          />
        </div>
        {/* <div className="col-1 ">
          <Calender
            onChange={(date) => handleSelectChange(date, "startdate", false)}
            selectedDate={timsheetFilters.state.startdate}
            // label={t("End date")}
            isMandatory={false}
            name={"start_date"}
            // error={filterOptionsState.filteredState.end_date}
            isDisabled={false}
          ></Calender>
        </div>
        <div className="col-1 ">
          <Calender
            onChange={(date) => handleSelectChange(date, "enddate", false)}
            selectedDate={timsheetFilters.state.enddate}
            // label={t("End date")}
            isMandatory={false}
            name={"end_date"}
            // error={filterOptionsState.filteredState.end_date}
            isDisabled={false}
          ></Calender>
        </div> */}
        <div className="col-2">
          <div className="d-flex justify-content-end">
            <SearchBtn handleSearchClick={() => handleSearch()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimesheetsFilters;
