export interface PageHistoryItem {
    pageName: string;
    route: string;
    filters: any;
}

export interface PageHistoryState {
    history: PageHistoryItem[];
}

export interface UpdateFiltersPayload {
    pageName?: string;
    filters?: any;
    reset?: boolean;
}

export interface AddPagePayload {
    pageName?: string;
    route?: string;
    filters?: any;
    isBackAction?: boolean;
}

export const initialState: PageHistoryState = {
    history: [],
};

// Helper function to find last occurrence of pageName
export const findIndexOfPage = (history: PageHistoryItem[], pageName: string) => history?.map(page => page?.pageName)?.indexOf(pageName);
