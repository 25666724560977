import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import Documents from "./document-content/Documents";
import Certificates from "./document-content/Certificates";
import { useFormContext } from "../../context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { EUROPEAN_CITIZEN, UPDATE_FIELD } from "../../annotations/CandidateConstants";
import { formatDate, resetEuropeanCitizenRelatedFields } from "../../helpers/CandidateHelperFunctions";
import { t } from "pages/microservices/masterData/translation/Translation";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import Calender from "../../helpers/Calender";
import LabelField from "components/atoms/LabelField";

const EmployeeDocumentsTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
    }
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: field,
      value: fileData
    });
    // validation(field, fileData, true);
    // validation("fileName", event?.name, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: "isEuropeanCitizen",
      value: event.target.checked ? 1 : 0
    });
    if (state.europeanCitizen.isEuropeanCitizen === 1) {
      resetEuropeanCitizenRelatedFields(dispatch);
    }
    // validation(name, updatedValue, true);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: name,
      value: date
    });
    // validation(name, date, true);
  };

  return (<>
    <div className="form-border" style={{ paddingTop: "3vw" }}>
      <Certificates />
      <div className="row">
        <div className="col-12">
          <LabelField title={t("European citizen")} className="tab-subtitle" />
        </div>
        <div className="col" style={{ marginBottom: "1vw" }}>
          <CheckBoxField
            label={t("European citizen")}
            name="isEuropeanCitizen"
            onChangeHandler={(e) => handleFieldChange(e)}
            isChecked={state.europeanCitizen.isEuropeanCitizen === 1}
            className="document-checkbox"
            id="isEuropeanCitizen"
            lineHeight="1.5vw"
          />
        </div>
      </div>
      {!state.europeanCitizen.isEuropeanCitizen && (
        <>
          <div className="row">
            <div className="col fileUploadFullWidth">
              <MaxiconFileUpload
                label={t('Work permit')}
                name={t('workPermit')}
                id={t('workPermit')}
                isMandatory={false}
                edit={false}
                fileId={null}
                fileObj={JSON.stringify(state.europeanCitizen.workPermit)}
                multiple={false}
                uploadPath="candidate-workPermit"
                formats={'value.formats'}
                handleChange={(e: any) => handleFileChange(e, "workPermit")}
                maxFileSize={10000}
              // error={errors.workPermit}

              />
            </div>
            <div className="col">
              <Calender
                onChange={(date) => handleDateChange(date, "from")}
                label={t('From')}
                isMandatory={false}
                name={'from'}
                // error={errors.from}
                selectedDate={state.europeanCitizen.from}
                maxDate={state.europeanCitizen.to}
              ></Calender>
            </div>
            <div className="col">
              <Calender
                onChange={(date) => handleDateChange(date, "to")}
                label={t('To')}
                isMandatory={false}
                name={'to'}
                // error={errors.to}
                selectedDate={state.europeanCitizen.to}
                minDate={state.europeanCitizen.from}
              ></Calender>
            </div>
          </div>
        </>
      )
      }

      <Documents />
    </div>
    <div style={{ padding: "1vw 0" }}>
      <Navigation validStatus={validStatus} />
    </div>
  </>);
}
export default EmployeeDocumentsTab;