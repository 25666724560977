import CandidateForm from "../create-candidate/form-data/CandidateForm";
import { FormProvider } from "./Context";

export const MultiFormProviderCandidate = () => {
  return (
    <>
      <FormProvider>
        <CandidateForm />
      </FormProvider>
    </>
  );
};