import React from "react";
import {
  useFilterOptions,
  useFilterOptionsDispatch,
} from "../work-pay-bill-roll-redux/context/FilterOptionsContext";
import { MANAGE_PAYROLL_CONSTANTS } from "../constants/WorkPayBillRollConstants";
import { t } from "pages/microservices/masterData/translation/Translation";
import { handleFilterChange } from "../utils/Utils";
import Popup from "components/molecules/Popup";
import ExportPopup from "../atoms/ExportPopup";
import { useTable } from "../work-pay-bill-roll-redux/context/TableContext";
import DatePicker from "react-multi-date-picker";
import { Button } from "react-bootstrap";

interface ManagePayrollHeadersProps {
  handlePrint: (type: string, page?: string) => void;
}

const ManagePayrollHeaders: React.FC<ManagePayrollHeadersProps> = ({
  handlePrint,
}) => {
  const filterState = useFilterOptions();
  const filterDispatch = useFilterOptionsDispatch();
  const tableState = useTable();
  return (
    <div
      className="d-flex align-items-center"
      style={{ marginBottom: "0.6vw" }}
    >
      <span className="w-auto cursor-pointer">
        {!tableState?.overview && (
          <DatePicker
            inputClass="form-control field-shadow"
            name="selected_date"
            placeholder={"year"}
            value={filterState?.selected_date}
            onChange={(e) => {
              let selectedDate = null;
              if (e && !Array.isArray(e)) {
                selectedDate = new Date(e.year, e.month.number - 1, e.day); // Extract and create a Date object
              }
              handleFilterChange(filterState, filterDispatch, "selected_date", selectedDate);
            }}
            format="MMMM YYYY"
            onlyMonthPicker={true}
            minDate={new Date(2014, 0, 1)}
            zIndex={1000}
          />
        )}
      </span>
      <div className="d-flex ms-auto">
        <Button
          onClick={() => handlePrint("print")}
          className="btn form-button shadow-none text-uppercase search-btns me-3"
        >
          {t(MANAGE_PAYROLL_CONSTANTS?.PRINT)}
        </Button>
        <Button
          onClick={() =>
            tableState?.overview || tableState?.selected_data?.length > 0
              ? handlePrint("exportoverview", tableState?.overview ? "overview" : "manage")
              : handleFilterChange(filterState, filterDispatch, "export", {...filterState?.popup?.export, show: true})
          }
          className="btn form-button shadow-none text-uppercase search-btns "
        >
          {t(MANAGE_PAYROLL_CONSTANTS?.EXPORT)}
        </Button>
      </div>
      {filterState?.popup?.export?.show && (
        <Popup
          title="Select date"
          body={
            <ExportPopup
              handleChange={(e: any, name: any) =>
                handleFilterChange(filterState, filterDispatch, "export", {
                  ...filterState?.popup?.export,
                  [name]: e,
                })
              }
            />
          }
          yestext="Submit"
          notext="Cancel"
          submit={() => handlePrint("export")}
          cancel={() =>
            handleFilterChange(filterState, filterDispatch, "export", {
              ...filterState?.popup?.export,
              from: null,
              to: null,
              show: false,
            })
          }
        />
      )}
    </div>
  );
};

export default ManagePayrollHeaders;
