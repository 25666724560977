import React, { useEffect, useState } from "react";
import {
  useFilterOptions,
  useFilterOptionsDispatch,
} from "../work-pay-bill-roll-redux/context/FilterOptionsContext";
import {
  MANAGE_PAYROLL_CONSTANTS,
  filterValues,
} from "../constants/WorkPayBillRollConstants";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import { renderFormElements } from "services/form/FormElements";
import { handleFilterChange } from "../utils/Utils";
import { useTable } from "../work-pay-bill-roll-redux/context/TableContext";
import { IconData } from "services/IconData";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";

const ManagePayrollFilters = () => {
  const filterState = useFilterOptions();
  const filterDispatch = useFilterOptionsDispatch();
  const tableState = useTable();
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    let row = filterValues();
    setRows(row);
  }, []);

  return (
    <>
      {!tableState?.overview && (
        <div className="d-flex gap-3 hideLabel">
          {rows.map((value: any) => (
            <div className={`flex-fill w-25`} key={value?.name}>
              {renderFormElements(
                { type: value?.type, name: value?.name, value },
                (e: any) =>
                  handleFilterChange(
                    filterState,
                    filterDispatch,
                    value?.name,
                    e?.target?.value ?? ""
                  ),
                filterState
              )}
            </div>
          ))}
          <div className="d-flex m-auto">
            <div className="d-flex align-items-center">
              <ResetBtn
                handleResetClick={() =>
                  handleFilterChange(filterState, filterDispatch, "reset")
                }
              />
              <SearchBtn
                handleSearchClick={() =>
                  handleFilterChange(filterState, filterDispatch, "submit")
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManagePayrollFilters;
