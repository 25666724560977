import { getOptions } from "../utils/FetchOptions";

export const rentalFunction = (payload: any, dependentFields: any) => {
  let { fieldData, state } = payload;
  let { mainIndex, subIndex } = fieldData;
  const fieldValue = fieldData.value;

  const currentState = state[mainIndex]?.form?.[mainIndex];

  let newState = currentState.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData?.error;
    }
    if (dependentFields.includes(item.name)) {
      item.display = fieldValue ? true : false;
    }
    return item
  })
  state[mainIndex].form[mainIndex] = newState;
  return state;

}

export const usersSetFunction = async (payload: any, dataObj: any, entityType: any) => {
  let { fieldData, state } = payload;
  let { mainIndex, subIndex } = fieldData
  const fieldValue = fieldData?.value?.value == undefined ? fieldData?.value : fieldData?.value?.value;
  const response = await getOptions(fieldValue, dataObj, entityType);

  const currentState = state[mainIndex]?.form?.[subIndex];

  let newState = (currentState.map((item: any) => {
    if (item.name == "clientOrEmployee") {
      item.options = response?.data;
      if ((dataObj != null || dataObj != undefined)) {
        item.value = dataObj.clientOrEmployee;
      }
      else {
        item.value = '';
      }
    }
    if (item.dependencyAction == "users") {
      item.value = fieldData.value;
    }
    return item;
  }));
  state[mainIndex].form[mainIndex] = newState;
  return state;
}


