import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";

interface ProjectRateMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ProjectRateMolecule: React.FC<ProjectRateMoleculeProps> = ({
  unit,
  name,
  value,
  disabled,
  error,
  handleChange,
  field,
}) => {
  const renderField = () => {
    switch (field.field_id) {
      case 4:
        return (
          <SelectWithSearch
            search={true}
            title={t(name)}
            value={value}
            name={name}
            options={[
              {
                label: t("Rate(€/hr)"),
                value: t("Rate($/hr)"),
              },
              {
                label: t("Rate(€/day)"),
                value: t("Rate($/day)"),
              },
            ]}
            onChange={handleChange}
            isDisabled={true}
            placeHolder={t(field?.field_name)}
            error={t(error)}
            containerClassName="col-6"
            className="field-shadow"
          />
        );
      case 41:
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={false}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            readOnly={false}
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            unit={unit}
            error={t(error)}
          />
        );
    }
  };

  return <>{renderField()}</>;
};

export default ProjectRateMolecule;
